import styled from 'styled-components'
import StyleGuide from '../../styles/StyleGuide'

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  background-color: ${StyleGuide.constants.COLOR_SECONDARY};
  color: ${StyleGuide.constants.COLOR_WHITE};
  padding: 32px 48px;

  a {
    color: white;
    cursor: pointer;
  }
`

export { StyledFooter }
