/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type TransactionQueryTransactionTypesItem = typeof TransactionQueryTransactionTypesItem[keyof typeof TransactionQueryTransactionTypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionQueryTransactionTypesItem = {
  DOMESTIC_TRANSFER: 'DOMESTIC_TRANSFER',
  EXTERNAL_TRANSFER: 'EXTERNAL_TRANSFER',
  EXTERNAL_TRANSFER_ROLLBACK: 'EXTERNAL_TRANSFER_ROLLBACK',
  DOMESTIC_CANCELLATION: 'DOMESTIC_CANCELLATION',
  EXTERNAL_CANCELLATION: 'EXTERNAL_CANCELLATION',
  CONVERSION_ENERGY_AUTHORITY: 'CONVERSION_ENERGY_AUTHORITY',
  CONVERSION_GASGRID_FINLAND: 'CONVERSION_GASGRID_FINLAND',
  ISSUING: 'ISSUING',
  EXPIRATION: 'EXPIRATION',
  CORRECTION: 'CORRECTION',
  CANCELLATION_ROLLBACK: 'CANCELLATION_ROLLBACK',
} as const;
