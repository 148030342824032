import styled from 'styled-components'
import StyleGuide from '../src/styles/StyleGuide'

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;
  padding: ${(props) => (props.isLogin ? '0' : '20px 20px 120px')};
`

const StyledSkipLink = styled.a`
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-150%);

  &:focus {
    position: static;
    transform: translateX(0);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_3};
    height: 32px;
    align-items: center;
  }
`

export { StyledMain, StyledSkipLink }
