import { useTranslation } from 'react-i18next'
import { OrganizationListItemDto } from '../../../../api/types'
import { ControlledDateRangePicker } from '../../../../features/inputs/ControlledDateRangePicker'
import { ProductionDeviceManagerSchemaType } from '../../../../validation/production-device-manager-schema'
import { useFormContext } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import ControlledSelect from '../../../../features/inputs/ControlledSelect'
import { Typography } from '@mui/material'
import StyleGuide from '../../../../styles/StyleGuide'

interface ManagerManagementFormProps {
  mode: 'CREATE' | 'EDIT_CURRENT' | 'EDIT_PREVIOUS'
  organizations: OrganizationListItemDto[]
  managementDateValidity: boolean
}

export const ManagerManagementForm = ({ organizations, managementDateValidity, mode }: ManagerManagementFormProps) => {
  const { t } = useTranslation()
  const { control, getValues } = useFormContext<ProductionDeviceManagerSchemaType>()

  return (
    <FlexRow gap="16px">
      <FlexColumn>
        <ControlledDateRangePicker
          startDateLabel={t('productionDevice.management.managementStartTime')}
          endDateLabel={t('productionDevice.management.managementEndTime')}
          startDateName="dateRange.managementStartTime"
          endDateName="dateRange.managementEndTime"
          control={control}
          required
        />
        {!managementDateValidity && mode === 'CREATE' && (
          <Typography variant="caption" sx={{ color: StyleGuide.constants.COLOR_BRAND, maxWidth: '210px' }}>
            {t('productionDevice.management.startDateOverlaps')}
          </Typography>
        )}
      </FlexColumn>

      <FlexColumn minWidth="480px">
        <ControlledSelect
          id="organizationId"
          name="organizationId"
          label={t('title.organization')}
          placeholder={t('common.choose')}
          required
          customErrorText={!getValues().organizationId ? t(`form.invalid.account.organizationId.required`) : undefined}
          items={organizations.map((org) => ({
            key: org.id!,
            value: org.id!,
            label: org.name!
          }))}
          control={control}
        />
      </FlexColumn>
    </FlexRow>
  )
}
