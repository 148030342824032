import { Dispatch, SetStateAction } from 'react'
import { PermissionDto, PermissionDtoPermissionsItem } from '../../../api/types'
import PermissionAccordion from '../../permission-select/PermissionAccordion'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { EcertAutoCompleteMultiSelect } from '../../autocomplete/EcertAutoCompleteMultiSelect'
import {
  getTranslationForPermission,
  getWithoutPermissions,
  isAnyPermissionSet,
  isPermissionSet
} from '../../../utils/permission-utils'

interface AutorizationTarget {
  id: number
  label: string
}

interface AutorizationEntityPermissionsProps {
  localizationKeyPerfix: string
  authorizationTargetAccessor: string
  permissionsDefinition: PermissionDto
  setPermissionsDefinition: Dispatch<SetStateAction<PermissionDto>>
  generalPermission: PermissionDtoPermissionsItem
  unlimitedPermission: PermissionDtoPermissionsItem
  selectablePermissions: PermissionDtoPermissionsItem[]
  selectableAuthorizationTargets: AutorizationTarget[]
}

export default function AutorizationEntityPermissions({
  localizationKeyPerfix,
  authorizationTargetAccessor,
  permissionsDefinition,
  setPermissionsDefinition,
  generalPermission,
  unlimitedPermission,
  selectablePermissions,
  selectableAuthorizationTargets
}: AutorizationEntityPermissionsProps) {
  const { t } = useTranslation()
  const generalPermissionSelected = isPermissionSet(permissionsDefinition, generalPermission)
  const unlimitedPermissionSelected = isPermissionSet(permissionsDefinition, unlimitedPermission)

  const getTranslation = (localizationKeySuffix: string): string => {
    return t(`${localizationKeyPerfix}.${localizationKeySuffix}`)
  }

  const getPermissionsDescription = (): string => {
    if (generalPermissionSelected) {
      return t('user.permission.permission.full')
    }
    if (!unlimitedPermissionSelected && !permissionsDefinition[authorizationTargetAccessor].length) {
      return t('user.permission.permission.none')
    }
    if (isAnyPermissionSet(permissionsDefinition, selectablePermissions)) {
      return t('user.permission.permission.restricted')
    }
    return t('user.permission.permission.none')
  }

  const setPermissionEnabled = (enabled: boolean, permission: PermissionDtoPermissionsItem) => {
    setPermissionsDefinition((prevState) => {
      const newState = { ...prevState }
      if (enabled) {
        newState.permissions = prevState.permissions?.concat([permission]) ?? []
      } else {
        newState.permissions = prevState.permissions ? getWithoutPermissions(prevState.permissions, [permission]) : []
      }
      return newState
    })
  }

  const setAutorizationTargets = (autorizationTargets: AutorizationTarget[]) => {
    setPermissionsDefinition((prevState) => {
      const newState = { ...prevState }
      newState[authorizationTargetAccessor] = autorizationTargets.map((autorizationTarget) => autorizationTarget.id)
      return newState
    })
  }

  return (
    <PermissionAccordion title={getTranslation('title')} permission={getPermissionsDescription()}>
      <Box>
        <Box>
          <FormControl>
            <FormLabel>{t('user.permission.restriction.title')}</FormLabel>
            <RadioGroup
              row
              value={generalPermissionSelected}
              onChange={(event) => setPermissionEnabled(event.target.value === 'true', generalPermission)}
            >
              <FormControlLabel label={t('user.permission.restriction.restricted')} value={false} control={<Radio />} />
              <FormControlLabel label={t('user.permission.restriction.all')} value={true} control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Box>
        {!generalPermissionSelected ? (
          <>
            <Box>
              <FormControl>
                <FormGroup>
                  {selectablePermissions.map((permission) => (
                    <FormControlLabel
                      key={permission}
                      label={getTranslationForPermission(permission)}
                      value={permission}
                      checked={isPermissionSet(permissionsDefinition, permission)}
                      onChange={(_event, checked) => setPermissionEnabled(checked, permission)}
                      control={<Checkbox />}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel>{getTranslation('restriction.title')}</FormLabel>
                <RadioGroup
                  row
                  value={unlimitedPermissionSelected}
                  onChange={(event) => setPermissionEnabled(event.target.value === 'true', unlimitedPermission)}
                >
                  <FormControlLabel label={getTranslation('restriction.all')} value={true} control={<Radio />} />
                  <FormControlLabel
                    label={getTranslation('restriction.restricted')}
                    value={false}
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {!unlimitedPermissionSelected && (
              <EcertAutoCompleteMultiSelect
                id="authorization-targets"
                label={getTranslation('title')}
                selectableItems={selectableAuthorizationTargets}
                getOptionLabel={(entity) => entity.label}
                value={selectableAuthorizationTargets.filter((entity) =>
                  permissionsDefinition[authorizationTargetAccessor].includes(entity.id)
                )}
                handleChange={setAutorizationTargets}
              />
            )}
          </>
        ) : (
          <Typography>{getTranslation('restriction.fullDescription')}</Typography>
        )}
      </Box>
    </PermissionAccordion>
  )
}
