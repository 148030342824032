import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CountryView } from '../../api/types'
import { ECERT_API } from '../../services/ecert-api'
import { RootState } from '../../store'
import { useDataSliceArray } from './hooks'

interface ICountryState {
  countries: CountryView[] | undefined
  cancellationCountries: CountryView[] | undefined
  aibHubCountries: CountryView[] | undefined
}

const initialState: ICountryState = {
  countries: undefined,
  cancellationCountries: undefined,
  aibHubCountries: undefined
}

// Reducers
export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.countries = action.payload
      })
      .addCase(fetchCancellationCountries.fulfilled, (state, action) => {
        state.cancellationCountries = action.payload
      })
      .addCase(fetchAibHubCountries.fulfilled, (state, action) => {
        state.aibHubCountries = action.payload
      })
  }
})

// Selectors
export const selectCountries = (state: RootState) => state.countries.countries

export const selectCancellationCountries = (state: RootState) => state.countries.cancellationCountries

export const selectAibHubCountries = (state: RootState) => state.countries.aibHubCountries

// Effects/Thunks
export const fetchCountries = createAsyncThunk('countries/fetch', async () => {
  const response = await ECERT_API.getCountries()
  return response.data
})

export const fetchCancellationCountries = createAsyncThunk('cancellation-countries/fetch', async () => {
  const response = await ECERT_API.getCancellationCountries()
  return response.data
})

export const fetchAibHubCountries = createAsyncThunk('aib-hub-countries/fetch', async () => {
  const response = await ECERT_API.getAibHubCountries()
  return response.data
})

// Hooks
export function useCountries() {
  return useDataSliceArray(selectCountries, fetchCountries, true, false, [])
}

export function useCancellationCountries() {
  return useDataSliceArray(selectCancellationCountries, fetchCancellationCountries, true, false, [])
}

export function useAibHubCountries() {
  return useDataSliceArray(selectAibHubCountries, fetchAibHubCountries, true, false, [])
}

export default countriesSlice.reducer
