import { z } from 'zod'
import { formPrefix, stringFromLengthRange, stringOfForm } from './schema-utils'

export function createUserFormSchema() {
  return z.object({
    username: stringFromLengthRange(1, 50, `${formPrefix}.user.username`),
    firstName: stringFromLengthRange(1, 50, `${formPrefix}.user.firstName`),
    lastName: stringFromLengthRange(1, 50, `${formPrefix}.user.lastName`),
    email: stringOfForm('^\\S+@\\S+$', `${formPrefix}.user.email.email`),
    phone: stringOfForm('^\\+ *\\d{10,20}$', `${formPrefix}.user.phone.pattern`),
    language: z.string()
  })
}

const schema = createUserFormSchema()
export type UserFormSchemaType = z.infer<typeof schema>
