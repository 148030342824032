import styled from 'styled-components'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  padding: 12px 48px;
  align-items: center;
  justify-content: space-between;
`

const StyledLogo = styled.img`
  height: 24px;
`

export { StyledHeader, StyledLogo }
