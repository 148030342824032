/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type CancellationDetailsUpdateDtoBeneficiaryType = typeof CancellationDetailsUpdateDtoBeneficiaryType[keyof typeof CancellationDetailsUpdateDtoBeneficiaryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancellationDetailsUpdateDtoBeneficiaryType = {
  SUPPLIER: 'SUPPLIER',
  ENDUSER: 'ENDUSER',
  OWNER: 'OWNER',
} as const;
