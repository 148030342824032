import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface IProductionDeviceSelectDialogProps {
  isOpen: boolean
  toggleModal: () => void
  onConfirm: () => void
}

export default function PublishDialog({ onConfirm, isOpen, toggleModal }: IProductionDeviceSelectDialogProps) {
  const { t } = useTranslation()

  const handleClose = () => {
    toggleModal()
  }

  const confirm = () => {
    onConfirm()
    toggleModal()
  }

  return (
    <CustomDialog
      title={t('invoiceDetailsReport.publish')}
      isOpen={isOpen}
      handleClose={() => handleClose()}
      buttons={
        <>
          <Button variant="contained" startIcon={<CheckIcon />} onClick={() => confirm()}>
            {t('common.yes')}
          </Button>
          <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => handleClose()}>
            {t('common.cancel')}
          </Button>
        </>
      }
    >
      <Typography variant="body1">{t('invoiceDetailsReport.confirmPublishDialogMessage')}</Typography>
    </CustomDialog>
  )
}
