import { AsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useCallback, useEffect, useRef } from 'react'

export function useDataSliceSingle<T>(
  selector: (state: RootState) => T | undefined,
  dataFetch: AsyncThunk<T, void, any>,
  intialFetch: boolean,
  refreshFetch: boolean
): [T | undefined, () => Promise<any>]

export function useDataSliceSingle<T>(
  selector: (state: RootState) => T | undefined,
  dataFetch: AsyncThunk<T, void, any>,
  intialFetch: boolean,
  refreshFetch: boolean,
  initialData: T
): [T, () => Promise<any>]

export function useDataSliceSingle<T>(
  selector: (state: RootState) => T | undefined,
  dataFetch: AsyncThunk<T, void, any>,
  intialFetch: boolean,
  refreshFetch: boolean,
  initialData: T | undefined = undefined
): [T | undefined, () => Promise<any>] {
  return useDataSlice(selector, dataFetch, intialFetch, refreshFetch, initialData)
}

export function useDataSliceArray<T>(
  selector: (state: RootState) => T[] | undefined,
  dataFetch: AsyncThunk<T[], void, any>,
  intialFetch: boolean,
  refreshFetch: boolean
): [T[] | undefined, () => Promise<any>]

export function useDataSliceArray<T>(
  selector: (state: RootState) => T[] | undefined,
  dataFetch: AsyncThunk<T[], void, any>,
  intialFetch: boolean,
  refreshFetch: boolean,
  initialData: T[]
): [T[], () => Promise<any>]

export function useDataSliceArray<T>(
  selector: (state: RootState) => T[] | undefined,
  dataFetch: AsyncThunk<T[], void, any>,
  intialFetch: boolean,
  refreshFetch: boolean,
  initialData: T[] | undefined = undefined
): [T[] | undefined, () => Promise<any>] {
  return useDataSlice(selector, dataFetch, intialFetch, refreshFetch, initialData)
}

export function useDataSlice<T>(
  selector: (state: RootState) => T | undefined,
  dataFetch: AsyncThunk<T, void, any>,
  intialFetch: boolean,
  refreshFetch: boolean,
  initialData: T | undefined = undefined
): [T | undefined, () => Promise<any>] {
  const dispatch = useAppDispatch()

  const data = useAppSelector(selector)

  const request = useRef<Promise<any> | null>(null)

  const fetch = useCallback(() => {
    if (!request.current) {
      request.current = dispatch(dataFetch()).finally(() => {
        request.current = null
      })
    }
    return request.current
  }, [dataFetch, dispatch])

  useEffect(() => {
    if (intialFetch && !data) {
      fetch()
    } else if (refreshFetch) {
      fetch()
    }
  }, [intialFetch, refreshFetch, data, fetch])

  return [data ?? initialData, fetch]
}
