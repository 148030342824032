import { Box, Button, Typography } from '@mui/material'
import EcertTable, { IColumn } from '../../../../features/table/EcertTable'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface ISummaryDialog {
  summaryData: any
  show: boolean
  handleModalClose: () => void
}

export default function SummaryDialog({ summaryData, show, handleModalClose }: ISummaryDialog) {
  const { t } = useTranslation()

  const columns: IColumn[] = [
    {
      label: t('issuingReport.account'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountNumber,
      sortable: false
    },
    {
      label: t('issuingReport.accountOwner'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountOrganizationName,
      sortable: false
    },
    {
      label: t('issuingReport.amount'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountProductionAmount,
      sortable: false
    }
  ]

  return (
    <CustomDialog
      isOpen={show}
      title={`${t('issuingReport.monthDialogTitle')} ${summaryData?.date}`}
      handleClose={() => handleModalClose()}
      buttons={
        <Button startIcon={<CloseOutlinedIcon />} variant="outlined" onClick={() => handleModalClose()}>
          {t('common.close')}
        </Button>
      }
    >
      {summaryData &&
        summaryData.data.map((device) => {
          return (
            <Box key={device.deviceId}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {`${device.deviceName} (${device.deviceGsrn})`}
              </Typography>
              {device.production
                .filter((prod) => prod.productionMonth === summaryData.date)
                .map((filteredProd) => {
                  return (
                    <Box key={filteredProd.productionMonth} sx={{ marginBottom: '24px' }}>
                      <EcertTable
                        data={filteredProd.issuingAccounts}
                        columns={columns}
                        noDataString={t('issuingReport.noCertificatesIssued')}
                      />
                    </Box>
                  )
                })}
            </Box>
          )
        })}
    </CustomDialog>
  )
}
