import { useEffect, useRef, useState } from 'react'
import { Box, Button, InputLabel, Link, TextField, Typography } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../services/ecert-api'
import StyleGuide from '../../styles/StyleGuide'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import AsyncButton from '../../features/buttons/AsyncButton'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { LoginStatus, useLoginContext } from '../../LoginContext'
import { isClientErrorResponse, isStringEmail } from '../../utils/utils'
import { InfoBox } from '../../features/info-box/InfoBox'

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loginStatus, refreshLoginContext, consumeInitialPath } = useLoginContext()
  const [userInfo, setUserInfo] = useState({
    username: '',
    password: '',
    pin: ''
  })
  const inputRef = useRef<HTMLElement>(null)
  const pinRef = useRef<HTMLElement>(null)
  const [loginAttemptFailed, setLoginAttemptFailed] = useState(false)

  useEffect(() => {
    document.title = `Ecert - ${t('title.login')}`
  }, [t])

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      pinRef.current?.focus()
    })
  }, [])

  const handleStateChange = (event: any, stateName: string) => {
    setUserInfo((oldInfo) => {
      const newInfo: any = { ...oldInfo }
      newInfo[stateName] = event.target.value
      return newInfo
    })
  }

  const login = async () => {
    try {
      await ECERT_API.login(userInfo.username, userInfo.password)
      await refreshLoginContext()
    } catch (error) {
      if (isClientErrorResponse(error)) {
        enqueueSnackbar(t('loginPage.invalidCredentials'), snacky.errorOpts)

        if (isStringEmail(userInfo.username)) {
          setLoginAttemptFailed(true)
        }
      }
    }
  }

  const verifyLogin = async () => {
    try {
      await ECERT_API.verifyLogin(userInfo.pin)
      await refreshLoginContext()
      navigate(consumeInitialPath())
    } catch (error) {
      if (isClientErrorResponse(error)) {
        enqueueSnackbar(t('loginVerifyPage.invalidPin'), snacky.errorOpts)
      }
    }
  }

  const cancelVerify = async () => {
    resetUserInfo()
    await ECERT_API.logout()
    await refreshLoginContext()
  }

  const resetUserInfo = () => {
    setUserInfo({
      username: '',
      password: '',
      pin: ''
    })
  }

  return (
    <Box
      sx={{
        backgroundImage: 'url(./img/login_bg.png)',
        backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'min-content',
          minWidth: '400px',
          alignSelf: 'center',
          padding: '48px 168px',
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '600px'
        }}
      >
        {loginStatus === LoginStatus.NO_LOGIN && (
          <FlexColumn sx={{ textAlign: 'center' }}>
            <Box
              id="login"
              component="img"
              src="./img/fingrid-logo.svg"
              alt="ecert-navbar-logo"
              sx={{ height: '30px', marginBottom: '16px' }}
            />
            <FlexColumn>
              <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: '16px' }}>
                {t('loginPage.title')}
              </Typography>

              <FlexColumn gap="16px" id="login">
                <FlexColumn>
                  <InputLabel htmlFor="username" sx={{ alignSelf: 'flex-start' }}>
                    {t('loginPage.username')}
                  </InputLabel>
                  <TextField
                    inputRef={inputRef}
                    autoFocus
                    variant="outlined"
                    id="username"
                    name="username"
                    value={userInfo.username}
                    onChange={(event) => handleStateChange(event, 'username')}
                  />
                </FlexColumn>

                <FlexColumn>
                  <InputLabel htmlFor="password" sx={{ alignSelf: 'flex-start' }}>
                    {t('loginPage.password')}
                  </InputLabel>
                  <TextField
                    onKeyDown={(e) => {
                      if (e.key.toLowerCase() === 'enter') login()
                    }}
                    variant="outlined"
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={userInfo.password}
                    onChange={(event) => handleStateChange(event, 'password')}
                  />
                </FlexColumn>
                {loginAttemptFailed && (
                  <FlexColumn alignSelf="center" minWidth="500px">
                    <InfoBox type="WARN" size="MEDIUM">
                      <Typography>{t('loginPage.emailLoginWarning')}</Typography>
                    </InfoBox>
                  </FlexColumn>
                )}

                <AsyncButton
                  type="submit"
                  id="login-button"
                  startIcon={<LoginIcon />}
                  variant="contained"
                  sx={{ marginBottom: '28px' }}
                  asyncOperation={login}
                >
                  {t('loginVerifyPage.login')}
                </AsyncButton>
              </FlexColumn>

              <Link
                id="forgot-password"
                href={`.${EcertRoutes.paths.forgotPassword}`}
                sx={{
                  color: StyleGuide.constants.COLOR_BRAND,
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '28px'
                }}
              >
                {t('loginPage.forgotPassword')}
              </Link>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
                  padding: '16px 60px',
                  gap: '8px',
                  textAlign: 'center'
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  {t('loginPage.supportedBrowsersMessage')}
                </Typography>

                <Typography>{t('loginPage.supportedBrowserEdge')}</Typography>
                <Typography>{t('loginPage.supportedBrowserFirefox')}</Typography>
                <Typography>{t('loginPage.supportedBrowserChrome')}</Typography>
                <Typography>{t('loginPage.supportedBrowserSafari')}</Typography>
              </Box>
            </FlexColumn>
          </FlexColumn>
        )}

        {loginStatus === LoginStatus.UNVERIFIED_LOGIN && (
          <Box sx={{ textAlign: 'center' }}>
            <Box
              component="img"
              src="./img/fingrid-logo.svg"
              alt="ecert-navbar-logo"
              sx={{ height: '30px', marginBottom: '16px' }}
            />
            <Typography variant="h2" sx={{ marginBottom: '16px' }}>
              {t('loginVerifyPage.title')}
            </Typography>

            <Box>
              <Typography variant="body1" sx={{ marginBottom: '16px' }}>
                {t('loginVerifyPage.communicationChannelNone')}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '16px', maxWidth: '400px' }}>
                {t('loginVerifyPage.instruction')}
              </Typography>
            </Box>

            <FlexColumn gap="16px">
              <FlexColumn>
                <InputLabel htmlFor="pin" sx={{ alignSelf: 'flex-start' }}>
                  {'pin'}
                </InputLabel>
                <TextField
                  inputRef={pinRef}
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() === 'enter') verifyLogin()
                  }}
                  autoFocus
                  variant="outlined"
                  id="pin"
                  name="pin"
                  value={userInfo.pin}
                  onChange={(event) => handleStateChange(event, 'pin')}
                  sx={{ marginBottom: '24px' }}
                />
              </FlexColumn>
              <AsyncButton
                id="verify-pin-button"
                variant="contained"
                startIcon={<LoginIcon />}
                sx={{ padding: '16px' }}
                asyncOperation={verifyLogin}
              >
                {t('loginVerifyPage.login')}
              </AsyncButton>

              <Button variant="outlined" onClick={cancelVerify} startIcon={<ClearIcon />} sx={{ padding: '16px' }}>
                {t('common.cancel')}
              </Button>
            </FlexColumn>
          </Box>
        )}
      </Box>
    </Box>
  )
}
