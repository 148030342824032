import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductionDeviceTypeDto } from '../../../api/types'
import { ECERT_API } from '../../../services/ecert-api'
import { useFormContext } from 'react-hook-form'
import ControlledNumberField from '../../../features/inputs/ControlledNumberField'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

export default function EnergySourceForm() {
  const { t } = useTranslation()
  const [installationTypes, setInstallationTypes] = useState<ProductionDeviceTypeDto[] | undefined>()
  const {
    formState: { errors },
    getValues,
    setValue,
    control
  } = useFormContext<ProductionDeviceSchemaType>()
  const [tier1, setTier1] = useState<ProductionDeviceTypeDto[]>([])
  const [tier2, setTier2] = useState<ProductionDeviceTypeDto[]>([])
  const [tier3, setTier3] = useState<ProductionDeviceTypeDto[]>([])
  const [selectedTier1, setSelectedTier1] = useState<string | null>(null)
  const [selectedTier2, setSelectedTier2] = useState<string | null>(null)
  const [selectedTier3, setSelectedTier3] = useState<string | null>(null)
  const [technologyCode, setTechnologyCode] = useState<string>('T000000')
  const FUEL_TYPE_NUCLEAR = 'Nuclear'

  useEffect(() => {
    ECERT_API.getAllInstallationTypes().then((response) => {
      setInstallationTypes(response.data)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (installationTypes) {
      setTier1([])
      setTier2([])
      setTier3([])
      installationTypes.forEach((type) => {
        if (!type.description2 && !type.description3) {
          setTier1((prevState) => [...prevState, type])
        }
      })

      setSelectedTier1(getValues('technology').description1 ?? null)
      setSelectedTier2(getValues('technology').description2 ?? null)
      setSelectedTier3(getValues('technology').description3 ?? null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installationTypes])

  useEffect(() => {
    setTier2([])
    setTier3([])
    installationTypes?.forEach((type) => {
      if (type.description1 === selectedTier1 && type.description2 && !type.description3) {
        setTier2((prevState) => [...prevState, type])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier1])

  useEffect(() => {
    setTier3([])
    installationTypes?.forEach((type) => {
      if (type.description1 === selectedTier1 && type.description2 === selectedTier2 && type.description3) {
        setTier3((prevState) => [...prevState, type])
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier2])

  useEffect(() => {
    installationTypes?.forEach((type) => {
      if (
        type.description1 === selectedTier1 &&
        type.description2 === selectedTier2 &&
        type.description3 === selectedTier3 &&
        type.code !== technologyCode
      ) {
        setValue('technologyType', type.code!)
        setTechnologyCode(type.code!)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier1, selectedTier2, selectedTier3, installationTypes])

  const handleTier1Change = (event: SelectChangeEvent) => {
    if (event.target.value !== FUEL_TYPE_NUCLEAR) {
      setValue('technology.radioactiveWasteProduced', null)
    }
    setSelectedTier1(event.target.value as string)
    setValue('technology.description1', event.target.value, {
      shouldValidate: true
    })
    setSelectedTier2(null)
    setSelectedTier3(null)
  }

  const handleTier2Change = (event: SelectChangeEvent) => {
    setSelectedTier2(event.target.value as string)
    setSelectedTier3(null)
  }

  const handleTier3Change = (event: SelectChangeEvent) => {
    setSelectedTier3(event.target.value as string)
  }

  return (
    <Box>
      <FlexColumn gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.production.production')}</Typography>
      </FlexColumn>

      <FlexRow gap="16px">
        <FlexColumn flex="1">
          <ControlledNumberField
            acceptFloat
            fullWidth
            endSuffix="MW"
            id="capacity"
            name="capacity"
            label={t('productionDevice.capacity')}
            required
            control={control}
          />
        </FlexColumn>
        {tier1 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.production.technology')}</InputLabel>
            <Select
              labelId="energy-source-tier1-select-label"
              id="energy-source-tier1-select"
              value={selectedTier1 && selectedTier1 !== '' ? selectedTier1 : t('form.unspecified')}
              onChange={handleTier1Change}
              renderValue={(value) => {
                return value
              }}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier1.map((e) => {
                return (
                  <MenuItem key={e.code} value={e.description1}>
                    {e.description1}
                  </MenuItem>
                )
              })}
            </Select>
            {errors && errors.technology && errors.technology['description1'] && (
              <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                {errors.technology ? errors.technology['description1'].message : ''}
              </FormHelperText>
            )}
          </FlexColumn>
        )}

        {tier2 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.production.extension')}</InputLabel>
            <Select
              labelId="energy-source-tier2-select-label"
              id="energy-source-tier2-select"
              value={selectedTier2 ?? t('form.unspecified')}
              onChange={handleTier2Change}
              renderValue={(value) => {
                return value
              }}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier2.map((e) => {
                return (
                  <MenuItem key={e.code} value={e.description2}>
                    {e.description2}
                  </MenuItem>
                )
              })}
            </Select>
          </FlexColumn>
        )}

        {tier3 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.production.extension')}</InputLabel>
            <Select
              labelId="energy-source-tier3-select-label"
              id="energy-source-tier3-select"
              value={selectedTier3 ?? t('form.unspecified')}
              onChange={handleTier3Change}
              renderValue={(value) => {
                return value
              }}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier3.map((e) => {
                return (
                  <MenuItem key={e.code} value={e.description3}>
                    {e.description3}
                  </MenuItem>
                )
              })}
            </Select>
          </FlexColumn>
        )}

        {selectedTier1 === FUEL_TYPE_NUCLEAR && (
          <FlexColumn flex="1">
            <ControlledNumberField
              fullWidth
              acceptFloat
              id="technology"
              name="technology.radioactiveWasteProduced"
              label={t('bundle.radioactiveWasteProduced')}
              required
              customErrorText={{
                enabled: errors && errors.technology && errors.technology['radioactiveWasteProduced'] ? true : false,
                message: t('form.invalid.productionDevice.radioactiveWasteProduced.min', {
                  min: 0.1,
                  max: 100
                })
              }}
              control={control}
            />
          </FlexColumn>
        )}

        <FlexColumn flex="1">
          <InputLabel htmlFor="energy-code">{t('productionDevice.production.code')}</InputLabel>
          <TextField
            id="energy-code"
            name="energy-code"
            fullWidth
            disabled
            value={technologyCode}
            InputLabelProps={{ shrink: true }}
          />
        </FlexColumn>
      </FlexRow>
    </Box>
  )
}
