import { Location, matchRoutes } from 'react-router-dom'

// TODO if given undefined as ID parameter, return some sort of invalid route url
type OrganizationId = string | number | undefined
type UserId = string | number | undefined
type AccountId = string | number | undefined
type ProductionDeviceId = string | number | undefined
type ProductionInfoTransferId = string | number | undefined
type TransactionId = string | number | undefined

export class EcertRoutes {
  static paths = {
    root: '/',
    login: '/login',
    forgotPassword: '/forgot-password',
    changePasswordForced: '/change-password',
    organizations: {
      root: '/organizations',
      organization: '/organizations/:id',
      create: '/organizations/new',
      edit: '/organization-edit/:id'
    },
    accounts: {
      root: '/accounts',
      account: '/accounts/organizations/:id/account/:accountId',
      accountTransaction: '/accounts/organizations/:id/account/:accountId/transaction/:transactionId',
      accountTransactionEdit: '/accounts/organizations/:id/account/:accountId/transaction/:transactionId/edit'
    },
    users: {
      root: 'users',
      user: {
        root: 'users/organizations/:organizationId/user/:userId',
        edit: 'users/organizations/:organizationId/user/:userId/edit',
        notificationEdit: 'users/organizations/:organizationId/user/:userId/notification-edit'
      },
      create: 'users/organizations/:organizationId/user/new'
    },
    productionDevice: {
      root: '/production-devices/organization/:organizationId/production-device/:productionDeviceId'
    },
    productionDevices: '/production-devices',
    productionInfoTransfers: {
      root: '/production-info-transfers',
      productionInfoTransfer: '/production-info-transfers/:productionInfoTransferId'
    },
    addProductionDevice: '/production-devices/organization/:organizationId/production-device/new',
    editProductionDevice: '/production-devices/organization/:organizationId/production-device-edit/:productionDeviceId',
    reports: {
      registeredProductionDevices: '/reports/registered-production-devices',
      labels: '/reports/labels',
      contacts: '/reports/contacts',
      invoice: '/reports/invoice',
      invoicePeriod: '/reports/invoice/period/:id',
      issuing: '/reports/issuing',
      externalTransfer: '/reports/external-transfer',
      eventLog: '/reports/event-log',
      transactions: '/reports/transactions',
      signedDocuments: '/reports/signed-documents'
    },
    notifications: {
      root: '/notifications',
      create: '/notification-create',
      edit: '/notification-edit/:id',
      archive: '/notification-archive'
    },
    configuration: '/configuration',
    aibMembers: { root: '/aib-members', edit: '/edit' }
  }

  // Organizations
  static organizations() {
    return '/organizations'
  }

  static organization(organizationId: OrganizationId) {
    return `${this.organizations()}/${organizationId}`
  }

  static newOrganization() {
    return `${this.organizations()}/new`
  }

  static organizationEdit(organizationId: OrganizationId) {
    return `/organization-edit/${organizationId}`
  }

  static organizationUsers(organizationId: OrganizationId) {
    return `${this.users()}?organizationId=${organizationId}`
  }

  static organizationAccounts(organizationId: OrganizationId) {
    return `${this.accounts()}?organizationId=${organizationId}`
  }

  static organizationProductionDevices(organizationId: OrganizationId) {
    return `${this.productionDevices()}?organizationId=${organizationId}`
  }

  static organizationUser(organizationId: OrganizationId, userId: UserId) {
    return `${this.users()}${this.organization(organizationId)}/user/${userId}`
  }

  static organizationAccount(organizationId: OrganizationId, accountId: AccountId, tabIndex?: number) {
    return tabIndex !== undefined
      ? `${this.accounts()}/${this.organization(organizationId)}/account/${accountId}?tab=${tabIndex}`
      : `${this.accounts()}/${this.organization(organizationId)}/account/${accountId}`
  }

  static accounts() {
    return '/accounts'
  }

  static account(accountId: AccountId) {
    return `${this.accounts()}/${accountId}`
  }

  static users() {
    return '/users'
  }

  static user(organizationId: OrganizationId, userId: UserId) {
    return `${this.users()}/${this.organization(organizationId)}/user/${userId}`
  }

  static userEdit(organizationId: OrganizationId, userId: UserId) {
    return `${this.user(organizationId, userId)}/edit`
  }

  static userNotificationEdit(organizationId: OrganizationId, userId: UserId) {
    return `${this.user(organizationId, userId)}/notification-edit`
  }

  static newUser(organizationId: OrganizationId) {
    return `${this.users()}/${this.organization(organizationId)}/user/new`
  }

  static productionDevices() {
    return '/production-devices'
  }

  static productionDevice(organizationId: OrganizationId, productionDeviceId: ProductionDeviceId) {
    return `/production-devices/organization/${organizationId}/production-device/${productionDeviceId}`
  }

  static newProductionDevice(organizationId: OrganizationId) {
    return `/production-devices/organization/${organizationId}/production-device/new/`
  }

  static productionInfoTransfers() {
    return '/production-info-transfers'
  }

  static notifications() {
    return '/notifications'
  }

  static notificationEdit(notificationId: number) {
    return `/notification-edit/${notificationId}`
  }

  static productionInfoTransfer(productionInfoTransferId: ProductionInfoTransferId) {
    return `${this.productionInfoTransfers()}/${productionInfoTransferId}`
  }

  static report() {
    return '/reports'
  }

  static reportRegisteredProductionDevices() {
    return `${this.report()}/registered-production-devices`
  }

  static reportIssuing() {
    return `${this.report()}/issuing`
  }

  static reportLabel() {
    return `${this.report()}/labels`
  }

  static reportTransactions() {
    return `${this.report()}/transactions`
  }

  static reportSignedDocuments() {
    return `${this.report()}/signed-documents`
  }

  static reportExternalTransfer() {
    return `${this.report()}/external-transfer`
  }

  static reportEventLog() {
    return `${this.report()}/event-log`
  }

  static reportInvoice() {
    return `${this.report()}/invoice`
  }

  static reportContacts() {
    return `${this.report()}/contacts`
  }

  static invoicingPeriod(id: number) {
    return `${this.reportInvoice()}/period/${id}`
  }

  static notificationArchive() {
    return '/notification-archive'
  }

  static organizationAccountTransaction(
    organizationId: OrganizationId,
    accountId: AccountId,
    transactionId: TransactionId
  ) {
    return `${this.organizationAccount(organizationId, accountId)}/transaction/${transactionId}`
  }

  static transactionEdit(organizationId: OrganizationId, accountId: AccountId, transactionId: TransactionId) {
    return `${this.organizationAccountTransaction(organizationId, accountId, transactionId)}/edit`
  }

  static productionDeviceEdit(orgId: number, deviceId: number) {
    return `/production-devices/organization/${orgId}/production-device-edit/${deviceId}`
  }

  static isLoginPage(location: Location): boolean {
    const loginRoutes = [
      { path: EcertRoutes.paths.login },
      { path: EcertRoutes.paths.forgotPassword },
      { path: EcertRoutes.paths.changePasswordForced }
    ]
    const matches = matchRoutes(loginRoutes, location)
    return !!matches
  }
}
