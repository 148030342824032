import { ReactNode } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DoneIcon from '@mui/icons-material/Done'
import { FlexRow } from '../../styles/containers/FlexContainers'
import StyleGuide from '../../styles/StyleGuide'
import { Typography } from '@mui/material'

interface InfoBoxProps {
  type?: 'SUBTLE' | 'INFO' | 'WARN' | 'ERROR' | 'SUCCESS'
  size?: 'SMALL' | 'MEDIUM' | 'LARGE'
  margin?: string
  children: ReactNode
}

export const InfoBox = ({ type = 'SUBTLE', size = 'MEDIUM', margin = '8px 0', children }: InfoBoxProps) => {
  const colorStyles = (() => {
    switch (type) {
      case 'SUBTLE':
        return {
          color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
          backgroundColor: 'transparent'
        }
      case 'INFO':
        return {
          color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
        }
      case 'WARN':
        return {
          color: StyleGuide.constants.COLOR_ORANGE_20,
          backgroundColor: StyleGuide.constants.COLOR_ORANGE_95
        }
      case 'ERROR':
        return {
          color: StyleGuide.constants.COLOR_RED_30,
          backgroundColor: StyleGuide.constants.COLOR_RED_95
        }
      case 'SUCCESS':
        return {
          color: StyleGuide.constants.COLOR_GREEN_20,
          backgroundColor: StyleGuide.constants.COLOR_GREEN_95
        }
      default:
        throw Error(`Unknown InfoBox type ${type}`)
    }
  })()

  const sizeStyles = (() => {
    switch (size) {
      case 'SMALL':
        return {
          fontSize: 'small'
        }
      case 'MEDIUM':
        return {
          fontSize: 'initial'
        }
      case 'LARGE':
        return {
          fontSize: 'large'
        }
      default:
        throw Error(`Unknown InfoBox size ${size}`)
    }
  })()

  const infoBoxStyles = {
    ...colorStyles,
    ...sizeStyles,
    padding: type === 'SUBTLE' ? '0' : '14px 24px 14px 24px',
    margin: margin
  }

  return (
    <FlexRow gap="12px" styles={infoBoxStyles} alignItems="center">
      {type === 'SUCCESS' ? <DoneIcon /> : <InfoOutlinedIcon />}
      <Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>{children}</Typography>
    </FlexRow>
  )
}
