/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type AutomaticTransferRecipientDtoType = typeof AutomaticTransferRecipientDtoType[keyof typeof AutomaticTransferRecipientDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AutomaticTransferRecipientDtoType = {
  DOMESTIC: 'DOMESTIC',
  ORGANIZATION: 'ORGANIZATION',
  EXTERNAL: 'EXTERNAL',
} as const;
