import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NotificationViewDto } from '../../api/types'
import { RootState } from '../../store'
import { ECERT_API } from '../../services/ecert-api'
import { useDataSliceSingle } from './hooks'

interface INotificationState {
  notifications: NotificationViewDto[] | undefined
}

const initialState: INotificationState = {
  notifications: undefined
}

// Reducers
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload
    })
  }
})

// Selectors
export const selectNotifications = (state: RootState) => state.notification.notifications

// Effects/Thunks
export const fetchNotifications = createAsyncThunk('notification/fetch', async () => {
  const response = await ECERT_API.getActiveNotifications()
  return response.data
})

// Hooks
export function useNotification(intialFetch: boolean = true) {
  return useDataSliceSingle(selectNotifications, fetchNotifications, intialFetch, false)
}

export default notificationSlice.reducer
