import React, { useEffect, useState } from 'react'
import { Device, OrganizationListItemDto, ProductionDeviceViewDtoIssuingPeriod } from '../../../api/types'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import { EcertRoutes } from '../../../ecert-routes'
import { StyledTable } from '../../../styles/containers/TableContainers'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../services/ecert-api'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import YearSelect from '../../../features/year-select/YearSelect'
import dayjs, { Dayjs } from 'dayjs'
import LockIcon from '@mui/icons-material/Lock'
import StyleGuide from '../../../styles/StyleGuide'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SummaryDialog from './components/SummaryDialog'
import CellDialog from './components/CellDialog'
import { canViewOrganizations, canViewProductionDevice, isAdmin } from '../../../utils/permission-utils'
import { FlexRow } from '../../../styles/containers/FlexContainers'
import { useLoginUser } from '../../../LoginContext'
import { InfoBox } from '../../../features/info-box/InfoBox'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import { range } from '../../../utils/utils'

interface IModalData {
  data: any
  issuingAccount: any
  production: any
}

interface ISummaryModalData {
  data: any
  date: string
}

export function Issuing() {
  const loginUser = useLoginUser()
  const { t } = useTranslation()
  const [data, setData] = useState<Device[] | undefined>(undefined)
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>(loginUser.organizationId!)
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year())
  const [filterText, setFilterText] = useState<string>('')

  const [modal, setModal] = useState<boolean>(false)
  const [summaryModal, setSummaryModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<IModalData>()
  const [summaryData, setSummaryData] = useState<ISummaryModalData>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.issuingReport')}`
  }, [t])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    setData(undefined)
    ECERT_API.getIssuingReport(selectedOrganizationId, selectedYear).then((response) => setData(response.data.devices))
  }, [selectedOrganizationId, selectedYear])

  const handleOrganizationSelect = (newValue: OrganizationListItemDto | null) => {
    setSelectedOrganizationId(newValue ? newValue.id! : 0)
  }

  const handleYearSelect = (newValue: Dayjs | null) => {
    if (newValue?.isValid()) {
      setSelectedYear(newValue.year())
    }
  }

  const cellStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  interface IButtonProps {
    orgId: number
    year: number
    issuingPeriodFilter: ProductionDeviceViewDtoIssuingPeriod | 'ALL'
    includeOnlyApproved: boolean
  }
  const FunctionButton = ({ orgId, year, issuingPeriodFilter, includeOnlyApproved }: IButtonProps) => {
    const DOWNLOAD_URL = `${orgId}/issuing/report-export/${year}?issuingPeriodFilter=${
      issuingPeriodFilter !== 'ALL' ? issuingPeriodFilter : ''
    }&includeOnlyApproved=${includeOnlyApproved ? true : ''}&downloadId=`
    return (
      <DownloadButton
        variant="contained"
        fileName={`IssuingReport-${selectedOrganizationId}.xlsx`}
        fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_ISSUING_REPORT}${DOWNLOAD_URL}`}
        startIcon={<FileDownloadOutlinedIcon />}
      >
        {t('issuingReport.export')}
      </DownloadButton>
    )
  }

  const openDialog = (data: any, production) => {
    setModalData({
      data: data,
      production: production,
      issuingAccount: production.issuingAccounts
    })
    setModal(true)
  }

  const openMonthDialog = (data: any, date: string) => {
    setSummaryData({
      data: data,
      date: date
    })
    setSummaryModal(true)
  }

  const columns: IColumn[] = [
    {
      label: t('productionDevice.productionDevice'),
      accessor: (data) => data,
      sortable: true,
      sortByOrder: 'asc',
      accessibilityHrefFn: (obj: any) => {
        return canViewProductionDevice(loginUser, {
          organizationId: obj.deviceOrganizationId,
          productionDeviceId: obj.deviceId
        })
          ? EcertRoutes.productionDevice(obj.deviceOrganizationId!, obj.deviceId!)
          : null
      },
      transformFn: (data) => {
        return (
          <FlexRow alignItems="center">
            {data.deviceName}
            {data.deviceLocked && <LockIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
          </FlexRow>
        )
      },
      role: 'rowheader'
    },
    ...(!isAdmin(loginUser)
      ? []
      : [
          {
            label: t('productionDevice.code'),
            accessor: (data: any) => data.productionDeviceCode,
            sortable: true,
            sortByOrder: 'asc' as any
          }
        ]),
    {
      label: t('productionDevice.deviceOwners'),
      accessor: 'owners',
      sortable: true,
      sortByOrder: 'asc',
      transformFn: (data) => <Typography sx={{ textWrap: 'balance' }}>{data}</Typography>
    },
    ...(!isAdmin(loginUser)
      ? []
      : [
          {
            label: t('productionDevice.deviceManager'),
            accessor: (data: any) => data.managerName,
            sortable: true,
            sortByOrder: 'asc'
          }
        ]),
    {
      label: t('table.period'),
      accessor: 'issuingPeriod',
      transformFn: (issuingPeriod: ProductionDeviceViewDtoIssuingPeriod) => (
        <Typography>{t(`productionDevice.issuingPeriodType.${issuingPeriod}`)}</Typography>
      ),
      sortable: false,
      sortByOrder: 'asc'
    },
    ...range(1, 12).map((monthNumber) => ({
      label: `${monthNumber}`,
      accessor: (data: any) => data,
      transformFn: (data: any) => {
        const production = data.production[monthNumber - 1]
        return (
          <Box sx={cellStyles}>
            {production.hasProduction ? (
              <>
                {production.monthProductionAmount}
                <IconButton aria-label="info" onClick={() => openDialog(data, production)}>
                  <InfoOutlinedIcon />
                </IconButton>
              </>
            ) : (
              '-'
            )}
          </Box>
        )
      }
    })),
    {
      label: `${t('common.total')} (MWh)`,
      accessor: (data: any) => data.production,
      transformFn: (data: any) => data.reduce((total, production) => production.monthProductionAmount + total, 0) || '-'
    }
  ]

  const [issuingPeriod, setIssuingPeriod] = React.useState<ProductionDeviceViewDtoIssuingPeriod | 'ALL'>('ALL')

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as ProductionDeviceViewDtoIssuingPeriod
    setIssuingPeriod(value)
  }

  const RadioGroupMonth = () => {
    return (
      <FormControl>
        <FormLabel id="radio-group-month">{t('productionDevice.issuingPeriod')}</FormLabel>
        <RadioGroup row value={issuingPeriod} onChange={handleRadioChange}>
          <FormControlLabel value="ALL" control={<Radio />} label={t('productionDevice.issuingPeriodType.ALL')} />
          <FormControlLabel
            value="EVERY_MONTH"
            control={<Radio />}
            label={t('productionDevice.issuingPeriodType.EVERY_MONTH')}
          />
          <FormControlLabel
            value="EVERY_3_MONTHS"
            control={<Radio />}
            label={t('productionDevice.issuingPeriodType.EVERY_3_MONTHS')}
          />
          <FormControlLabel
            value="EVERY_6_MONTHS"
            control={<Radio />}
            label={t('productionDevice.issuingPeriodType.EVERY_6_MONTHS')}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const SummaryGroup = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-around', flex: 1 }}>
        {range(1, 12).map((monthNumber) => (
          <Button
            key={monthNumber}
            variant="text"
            onClick={() => openMonthDialog(data, `${selectedYear}-${monthNumber.toString().padStart(2, '0')}`)}
          >
            {`${monthNumber}/${selectedYear}`}
          </Button>
        ))}
      </Box>
    )
  }

  const [includeOnlyApproved, setIncludeOnlyApproved] = React.useState(false)

  const handleOnlyIncludeApprovedGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    setIncludeOnlyApproved(value === 'true')
  }

  const RadioGroupIncludeApproved = () => {
    return (
      <FormControl>
        <FormLabel id="radio-group-include-approved">{t('invoiceDetailsReport.show')}</FormLabel>
        <RadioGroup row value={includeOnlyApproved} onChange={handleOnlyIncludeApprovedGroup}>
          <FormControlLabel value="false" control={<Radio />} label={t('issuingReport.filter.all')} />
          <FormControlLabel value="true" control={<Radio />} label={t('issuingReport.filter.approved')} />
        </RadioGroup>
      </FormControl>
    )
  }

  const filterResults = (data: Device[]) => {
    return data
      .filter((o) => (includeOnlyApproved ? o.approved === true : o))
      .filter((o) =>
        filterText
          ? (o['deviceName'] || '').toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) ||
            (o['deviceGsrn'] || '').toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) ||
            (o['productionDeviceCode'] || '').toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1
          : true
      )
      .filter((o) => (issuingPeriod !== 'ALL' ? o['issuingPeriod'] === issuingPeriod : o))
  }

  const handleSummaryModalClose = () => {
    setSummaryModal(false)
  }

  return (
    <>
      <StyledTable gap="10px">
        <Box>
          <Typography id="title-report-issuing" variant="h1" sx={{ marginBottom: '16px' }}>
            {t('issuingReport.menuName')}
          </Typography>
          <FlexRow gap="16px" alignItems="flex-end">
            {organizations.length > 0 && (
              <OrganizationSelect
                value={organizations.find((org) => org.id === selectedOrganizationId) ?? null}
                organizations={organizations}
                onSelect={handleOrganizationSelect}
              />
            )}
            <TextField
              placeholder={t('issuingReport.filterPlaceholder')}
              value={filterText}
              sx={{ height: 'fit-content', flex: '1' }}
              InputProps={{
                sx: { height: 'fit-content' }
              }}
              onChange={(event) => setFilterText(event.target.value)}
            />
            <YearSelect valueToShow={dayjs()} callBack={handleYearSelect} />
            <RadioGroupMonth />
            <FunctionButton
              year={selectedYear}
              orgId={selectedOrganizationId}
              issuingPeriodFilter={issuingPeriod}
              includeOnlyApproved={includeOnlyApproved}
            />
          </FlexRow>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '26px' }}>
            <RadioGroupIncludeApproved />
            <Box sx={{ marginTop: '22px' }}>
              <InfoBox>{t('issuingReport.tableInfo')}</InfoBox>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1">{t('issuingReport.openSummary')}</Typography>
            <SummaryGroup />
          </Box>
        </Box>
        <EcertTable
          unfilteredResultsLength={data?.length || 0}
          ariaDescribedBy="title-report-issuing"
          data={data ? filterResults(data) : undefined}
          columns={columns}
        />
      </StyledTable>
      <CellDialog show={modal} data={modalData} handleModalClose={() => setModal(false)} />
      <SummaryDialog show={summaryModal} summaryData={summaryData} handleModalClose={handleSummaryModalClose} />
    </>
  )
}
