import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ControlledRadioGroup } from '../../../features/inputs/ControlledRadioGroup'
import { useFormContext } from 'react-hook-form'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

export default function IssuingPeriodForm() {
  const { t } = useTranslation()

  const { control } = useFormContext<ProductionDeviceSchemaType>()

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.issuingPeriod')}</Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        <ControlledRadioGroup
          name="issuingPeriod"
          label={t('productionDevice.issuingPeriod')}
          required
          values={[
            {
              label: t('productionDevice.issuingPeriodType.EVERY_MONTH'),
              value: 'EVERY_MONTH'
            },
            {
              label: t('productionDevice.issuingPeriodType.EVERY_3_MONTHS'),
              value: 'EVERY_3_MONTHS'
            },
            {
              label: t('productionDevice.issuingPeriodType.EVERY_6_MONTHS'),
              value: 'EVERY_6_MONTHS'
            }
          ]}
          control={control}
        />
      </Box>
    </Box>
  )
}
