import dayjs from 'dayjs'
import { IColumn } from '../../../features/table/EcertTable'
import { getTranslation, parseDate } from '../../../utils/utils'
import { Tooltip, Typography, Button } from '@mui/material'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import StyleGuide from '../../../styles/StyleGuide'
import { ProductionDeviceFuelDto, ProductionDeviceFuelSupportDto } from '../../../api/types'
import { useState } from 'react'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import CloseButton from '../../../features/buttons/CloseButton'
import { FlexRow } from '../../../styles/containers/FlexContainers'
import { Translation } from 'react-i18next'
import i18n from '../../../services/i18n'

const getTechnologyDescription = (data: string | null) => {
  return data ? data : i18n.t('form.unspecified')
}

export const technologyTypeColumn: IColumn[] = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.production.code')}</>}</Translation>,
    accessor: 'code',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.production.technology')}</>}</Translation>,
    accessor: (data) => [data.description1, data.description2, data.description3],
    transformFn: (data) =>
      `${getTechnologyDescription(data[0])}/${getTechnologyDescription(data[1])}/${getTechnologyDescription(data[2])}`,
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.capacity')}</>}</Translation>,
    accessor: 'capacity',
    sortable: false
  }
]

export const updateColumns: IColumn[] = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.modified')}</>}</Translation>,
    accessor: 'modified',
    transformFn: (data) => parseDate(data).format('DD.MM.YYYY hh:mm'),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.modifier')}</>}</Translation>,
    accessor: 'modifier',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.status.status')}</>}</Translation>,
    accessor: 'status',
    transformFn: (data) => getTranslation(`productionDevice.status.tabs.${data.toLowerCase()}`),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.comments')}</>}</Translation>,
    accessor: 'description',
    sortable: false
  }
]

export const versionHistoryUpdateEventsColumns: IColumn[] = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.modified')}</>}</Translation>,
    accessor: 'modified',
    transformFn: (data) => parseDate(data).format('DD.MM.YYYY hh:mm'),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.modifier')}</>}</Translation>,
    accessor: 'modifier',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.status.status')}</>}</Translation>,
    accessor: 'status',
    transformFn: (data) => (
      <Translation>{(t) => <>{t(`productionDevice.status.tabs.${data.toLowerCase()}`)}</>}</Translation>
    ),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.comments')}</>}</Translation>,
    accessor: 'description',
    sortable: false
  }
]

export const auditColumns: IColumn[] = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.validity.validityDate')}</>}</Translation>,
    accessor: (data) => [data.start, data.end],
    transformFn: (data) => `${parseDate(data[0]).format('DD.MM.YYYY')} - ${parseDate(data[1]).format('DD.MM.YYYY')}`,
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.investment.status')}</>}</Translation>,
    accessor: (data) => data,
    transformFn: (data) => {
      return dayjs().isAfter(dayjs(data.end)) ? (
        <Translation>{(t) => <>{t('productionDevice.invalid')}</>}</Translation>
      ) : (
        <Translation>{(t) => <>{t('productionDevice.valid')}</>}</Translation>
      )
    },
    sortable: false
  }
]

export const UseDialog = () => {
  const [isOpen, setIsOpen] = useState(false)

  return { isOpen, setIsOpen }
}

export const fuelColumns: IColumn[] = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.energySource.code')}</>}</Translation>,
    accessor: 'fuel.code',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.energySource.energySource')}</>}</Translation>,
    accessor: (data) => [
      data.fuel.description1,
      data.fuel.description2,
      data.fuel.description3,
      data.fuel.description4
    ],
    transformFn: (data) =>
      `${getTechnologyDescription(data[0])}/${getTechnologyDescription(data[1])}/${getTechnologyDescription(
        data[2]
      )}/${getTechnologyDescription(data[3])}`,
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.energySource.labels.types')}</>}</Translation>,
    accessor: (data: ProductionDeviceFuelDto) => data.labels,
    transformFn: (data) => (
      <>
        {data.map((e, idx) => (
          <Tooltip
            key={`${e.typecode}-${idx}`}
            sx={{
              backgroundColor: StyleGuide.constants.COLOR_WHITE
            }}
            title={
              <>
                <Typography variant="body1" color={StyleGuide.constants.COLOR_WHITE}>
                  {e.typeCode}
                </Typography>
                <Typography variant="body1" color={StyleGuide.constants.COLOR_WHITE}>{`${parseDate(
                  e.validityStartDate
                ).format('DD.MM.YYYY')} - ${parseDate(e.validityEndDate).format('DD.MM.YYYY')}`}</Typography>
              </>
            }
          >
            <ElectricalServicesIcon
              sx={{
                marginRight: '8px',
                color: StyleGuide.constants.COLOR_BRAND
              }}
            />
          </Tooltip>
        ))}
      </>
    ),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('bundle.productionSupport')}</>}</Translation>,
    accessor: (data: any) => data.productionSupports,
    transformFn: (data: ProductionDeviceFuelSupportDto[]) => {
      const { isOpen, setIsOpen } = UseDialog()
      return (
        <>
          {data.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                setIsOpen(true)
              }}
            >
              <>{<Translation>{(t) => <>{t('invoiceDetailsReport.show')}</>}</Translation>}</>
            </Button>
          )}
          <CustomDialog
            isOpen={isOpen}
            title={getTranslation('productionDevice.investment.productionSupportTitle')}
            handleClose={() => setIsOpen(false)}
            buttons={<CloseButton handleClose={() => setIsOpen(false)} />}
          >
            {data.map((e, idx) => {
              return (
                <FlexRow marginBottom="8px" key={`${e.investment.scheme}-${idx}`}>
                  <Typography flex="1">{e.investment.scheme}</Typography>
                  <Typography flex="1">{`${e.validityStartDate} - ${e.validityEndDate}`}</Typography>
                  <Typography flex="1">
                    {dayjs().isAfter(e.validityEndDate) ? (
                      <Translation>{(t) => <>{t('productionDevice.invalid')}</>}</Translation>
                    ) : (
                      <Translation>{(t) => <>{t('productionDevice.valid')}</>}</Translation>
                    )}
                  </Typography>
                </FlexRow>
              )
            })}
          </CustomDialog>
        </>
      )
    },
    sortable: false
  }
]

export const investmentColumns = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.investment.investment')}</>}</Translation>,
    accessor: 'investment.scheme',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.investment.investmentStartDate')}</>}</Translation>,
    accessor: 'investment.expiryDate',
    transformFn: (data) =>
      data ? (
        parseDate(data).format('DD.MM.YYYY')
      ) : (
        <Translation>{(t) => <>{t('productionDevice.investment.indefinitely')}</>}</Translation>
      ),
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.investment.status')}</>}</Translation>,
    accessor: 'investment.expiryDate',
    transformFn: (data) =>
      data && dayjs().isBefore(data) ? (
        <Translation>{(t) => <>{t('productionDevice.invalid')}</>}</Translation>
      ) : (
        <Translation>{(t) => <>{t('productionDevice.valid')}</>}</Translation>
      ),
    sortable: false
  }
]

export const beneficiariesColumns = [
  {
    label: <Translation>{(t) => <>{t('productionDevice.beneficiary.beneficiary')}</>}</Translation>,
    accessor: 'name',
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.beneficiary.share')}</>}</Translation>,
    accessor: 'share',
    transformFn: (data) => `${data} %`,
    sortable: false
  },
  {
    label: <Translation>{(t) => <>{t('productionDevice.beneficiary.account')}</>}</Translation>,
    accessor: (data) => [data.accountNumber, data.accountOrganizationName],
    transformFn: (data) => `${data[0]} (${data[1]})`,
    sortable: false
  }
]
