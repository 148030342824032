import { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Tooltip,
  Button,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductionDeviceInvestmentTypeDto } from '../../../api/types'
import { ECERT_API } from '../../../services/ecert-api'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import StyleGuide from '../../../styles/StyleGuide'
import AddIcon from '@mui/icons-material/Add'
import { FlexColumn } from '../../../styles/containers/FlexContainers'
import { isJSONObject } from '../../../utils/utils'
import { ClearOutlined } from '@mui/icons-material'

export default function InvestmentSupportform() {
  const { t } = useTranslation()
  const [investmentTypes, setInvestmentTypes] = useState<ProductionDeviceInvestmentTypeDto[] | undefined>()
  const {
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'investments'
  })

  const watchFieldArray = useWatch({
    control,
    name: 'investments'
  })

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index]
    }
  })

  useEffect(() => {
    ECERT_API.getProductionDeviceInvestmentTypes().then((response) => {
      setInvestmentTypes(response.data)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInvestmentChange = (event: SelectChangeEvent, idx: number) => {
    setValue(`investments.${idx}.investment` as any, JSON.parse(event.target.value), { shouldValidate: true })
  }

  return (
    <Box>
      <Box display="flex" gap="16px" alignItems="center" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.investment.investmentTitle')}</Typography>

        <Tooltip title={t('productionDevice.energySource.investmentInfo')}>
          <HelpOutlineOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
        </Tooltip>
      </Box>

      <Box display="flex" gap="24px" flexDirection="column" maxWidth="500px">
        {controlledFields.map((e, idx) => {
          return (
            <Box key={e.id} display="flex" gap="16px" alignItems="center">
              {investmentTypes && (
                <>
                  <FlexColumn flex="1">
                    <InputLabel>{t('productionDevice.investment.investment')}</InputLabel>
                    <Select
                      key={`${e.id}-${idx}`}
                      labelId="investment-select"
                      id="investment-select"
                      value={
                        getValues(`investments.${idx}.investment` as any)
                          ? JSON.stringify(getValues(`investments.${idx}.investment` as any))
                          : t('common.choose')
                      }
                      onChange={(e) => {
                        handleInvestmentChange(e, idx)
                      }}
                      renderValue={(value) => {
                        return isJSONObject(value) && JSON.parse(value).scheme ? JSON.parse(value).scheme : value
                      }}
                    >
                      {investmentTypes.map((e, idx) => {
                        return (
                          <MenuItem key={`${e.scheme}-${idx}`} value={JSON.stringify(e)}>
                            {e.scheme}
                          </MenuItem>
                        )
                      })}
                    </Select>
                    {errors && errors.investments && errors.investments[idx] && (
                      <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                        {errors.investments[idx].investment
                          ? errors.investments[idx].investment.message.toString()
                          : ''}
                      </FormHelperText>
                    )}
                  </FlexColumn>
                </>
              )}
              <Button
                variant="outlined"
                onClick={() => remove(idx)}
                sx={{
                  marginTop: '24px',
                  maxWidth: '40px',
                  minWidth: '40px',
                  height: '40px',
                  padding: '12px 16px'
                }}
              >
                <ClearOutlined />
              </Button>
            </Box>
          )
        })}
      </Box>
      <Box display="flex" gap="16px" marginTop="16px">
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => append({})}>
          {t('productionDevice.investment.addInvestment')}
        </Button>
      </Box>
    </Box>
  )
}
