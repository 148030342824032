import ControlledDatePicker from './ControlledDatePicker'
import { InputLabel, Typography } from '@mui/material'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form'
import InputContainer from './InputContainer'

interface ControlledDateRangePickerProps<T extends FieldValues, P1 extends Path<T>, P2 extends Path<T>> {
  startDateName: P1
  endDateName: P2
  label?: string
  required?: boolean
  smallLabel?: string
  startDateLabel?: string
  endDateLabel?: string
  control: Control<T>
}

export const ControlledDateRangePicker = <T extends FieldValues, P1 extends Path<T>, P2 extends Path<T>>({
  startDateName,
  endDateName,
  label,
  required,
  smallLabel,
  control,
  startDateLabel,
  endDateLabel
}: ControlledDateRangePickerProps<T, P1, P2>) => {
  const { formState } = useFormContext<T>()

  const isEndDateInvalid = () => {
    return !!formState.errors[endDateName]
  }

  return (
    <InputContainer label={label} required={required}>
      <FlexColumn>
        <FlexRow alignItems="center" gap="10px">
          <FlexColumn>
            {startDateLabel && <InputLabel htmlFor="start-date">{startDateLabel}</InputLabel>}
            <ControlledDatePicker id="start-date" name={startDateName} control={control} />
          </FlexColumn>
          <span>&mdash;</span>
          <FlexColumn>
            {endDateLabel && <InputLabel htmlFor="end-date">{endDateLabel}</InputLabel>}
            <ControlledDatePicker id="end-date" name={endDateName} control={control} />
          </FlexColumn>
        </FlexRow>
        {smallLabel && !isEndDateInvalid() && (
          <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
            {smallLabel}
          </Typography>
        )}
      </FlexColumn>
    </InputContainer>
  )
}
