import { z } from 'zod'
import { createAttachmentSchema, formPrefix } from './schema-utils'
import { getTranslation } from '../utils/utils'

export function createProductionDeviceManagerSchema() {
  return z.object({
    dateRange: z
      .object({
        managementStartTime: z.coerce.date({ required_error: getTranslation(`${formPrefix}.dateRange.startRequired`) }),
        managementEndTime: z.coerce.date().nullable().optional()
      })
      .refine(
        (data) => {
          if (!data.managementEndTime) {
            return true
          }

          return data.managementStartTime < data.managementEndTime
        },
        { message: getTranslation(`${formPrefix}.dateRange.rangeValid`), path: ['managementEndTime'] }
      ),
    organizationId: z.number({ required_error: getTranslation(`${formPrefix}.account.organizationId.required`) }),
    poa: createAttachmentSchema().nullable(),
    attachments: z.array(createAttachmentSchema()).nullable()
  })
}

const schema = createProductionDeviceManagerSchema()
export type ProductionDeviceManagerSchemaType = z.infer<typeof schema>
