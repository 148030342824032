import { forwardRef, ReactNode } from 'react'
import { SnackbarContent } from '@mui/material'
import { CustomContentProps } from 'notistack'

interface ICustomSnackbarVariantProps extends CustomContentProps {
  node: ReactNode
}

export const CustomSnackbarVariant = forwardRef<HTMLDivElement, ICustomSnackbarVariantProps>(
  (props: ICustomSnackbarVariantProps, ref) => {
    const { node, action, id, style } = props

    return (
      <SnackbarContent
        // Call the action defined in the CustomSnackbarProvider to be able to close the snackbar
        // @ts-ignore
        action={action(id)}
        style={style}
        ref={ref}
        message={node}
      />
    )
  }
)
