import { Button, ButtonProps, Typography } from '@mui/material'
import ConfirmButton from './ConfirmButton'
import CancelButton from './CancelButton'
import CustomDialog from '../custom-dialog/CustomDialog'
import { useState } from 'react'
import LockOutlined from '@mui/icons-material/LockOutlined'
import LockOpenOutlined from '@mui/icons-material/LockOpenOutlined'
import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { EcertTextArea } from '../forms/text-area/EcertTextArea'
import {useLoginUser} from '../../LoginContext';
import {LockedStatus, LockedStatusEntityLockingLevel} from '../../api/types';
import {isAccountAdmin, isAdmin} from '../../utils/permission-utils';

interface ILockEntityButtonProps extends ButtonProps {
  lockStatus: LockedStatus | undefined
  onLock: (additionalInfoText: string) => void
  onUnlock: () => void
}

export const LockEntityButton = ({ lockStatus, onLock, onUnlock }: ILockEntityButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [additionalInfoText, setAdditionalInfoText] = useState('')
  const [formValid, setFormValid] = useState(true)
  const loginUser = useLoginUser()
  const { t } = useTranslation()

  const handleCancel = () => {
    setDialogOpen(false)
  }

  const handleConfirmLock = () => {
    setDialogOpen(false)
    onLock(additionalInfoText)
  }

  const handleConfirmUnlock = () => {
    setDialogOpen(false)
    onUnlock()
  }

  const hasAuthorityToUnlock = () => {
    const lockerLevel = (() => {
      switch (lockStatus?.entityLockingLevel) {
        case LockedStatusEntityLockingLevel.ADMIN:
          return 3
        case LockedStatusEntityLockingLevel.USER: // USER means account admin in this case
          return 2
        default:
          return 1
      }
    })()

    let userLevel = 1

    if (isAdmin(loginUser))
      userLevel = 3

    if (isAccountAdmin(loginUser))
      userLevel = 2

    return userLevel >= lockerLevel
  }

  return (
    <>
      {lockStatus?.locked === true && hasAuthorityToUnlock() && (
        <>
          <Button onClick={() => setDialogOpen(true)} startIcon={<LockOpenOutlined />} variant="outlined">
            {t('locking.unlock')}
          </Button>
          <CustomDialog
            isOpen={dialogOpen}
            title={t('locking.unlocking.confirmDialogTitle')}
            handleClose={handleCancel}
            buttons={
              <>
                <ConfirmButton handleConfirm={handleConfirmUnlock} />
                <CancelButton handleCancel={handleCancel} />
              </>
            }
          >
            <Typography variant="body1">{t('locking.unlocking.confirmDialogMessage')}</Typography>
          </CustomDialog>
        </>
      )}

      {lockStatus?.locked === false && (
        <>
          <Button onClick={() => setDialogOpen(true)} startIcon={<LockOutlined />} variant="outlined">
            {t('locking.lock')}
          </Button>
          <CustomDialog
            isOpen={dialogOpen}
            title={t('locking.locking.confirmDialogTitle')}
            handleClose={handleCancel}
            buttons={
              <>
                <ConfirmButton disabled={!formValid} handleConfirm={handleConfirmLock} />
                <CancelButton handleCancel={handleCancel} />
              </>
            }
          >
            <FlexColumn gap="10px">
              <Typography variant="body1">{t('locking.locking.confirmDialogMessage')}</Typography>
              <Typography variant="body2">{t('locking.locking.confirmDialogInfo')}</Typography>
              <EcertTextArea
                autoFocus={true}
                initialText={additionalInfoText}
                minRows={4}
                onChange={(event) => setAdditionalInfoText(event.target.value)}
                characterLimitExceededErrorText={t('form.invalid.additionalDetails.maxlength')}
                characterLimit={1000}
                label={`${t('locking.comment')} (${t('form.optional')})`}
                onError={(error) => setFormValid(!error)}
              />
            </FlexColumn>
          </CustomDialog>
        </>
      )}
    </>
  )
}
