import { Button } from '@mui/material'
import EcertTable, { IColumn } from '../../../../features/table/EcertTable'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

interface ISummaryDialog {
  data: any
  show: boolean
  handleModalClose: () => void
}

export default function CellDialog({ data, show, handleModalClose }: ISummaryDialog) {
  const { t } = useTranslation()

  const columns: IColumn[] = [
    {
      label: t('issuingReport.account'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountNumber,
      sortable: false
    },
    {
      label: t('issuingReport.accountOwner'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountOrganizationName,
      sortable: false
    },
    {
      label: t('issuingReport.amount'),
      accessor: (data: any) => data,
      transformFn: (issuingAccount: any) => issuingAccount.accountProductionAmount,
      sortable: false
    }
  ]

  return (
    <CustomDialog
      isOpen={show}
      title={
        data &&
        `${t('issuingReport.singleIssuingDialogTitle')} (${data.production.productionMonth}) | ${
          data.data.deviceName
        } (${data.data.deviceGsrn})`
      }
      handleClose={() => handleModalClose()}
      buttons={
        <Button startIcon={<CloseOutlinedIcon />} variant="outlined" onClick={() => handleModalClose()}>
          {t('common.close')}
        </Button>
      }
    >
      {data && <EcertTable data={data.issuingAccount} columns={columns} />}
    </CustomDialog>
  )
}
