import StyleGuide from '../../../styles/StyleGuide'
import { Button, Typography } from '@mui/material'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { Check, Clear, InfoOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  OrganizationListItemDto,
  ProductionDeviceManagementHistoryEntryView,
  ProductionDeviceManagementHistoryView
} from '../../../api/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  ProductionDeviceManagerSchemaType,
  createProductionDeviceManagerSchema
} from '../../../validation/production-device-manager-schema'
import { ManagerManagementForm } from './manager-form/ManagerManagementForm'
import { ManagerPoaFileForm } from './manager-form/ManagerPoaFileForm'
import { ManagerExtraAttachmentsForm } from './manager-form/ManagerExtraAttachmentsForm'
import dayjs from 'dayjs'

interface IManagerProps {
  mode: 'CREATE' | 'EDIT_CURRENT' | 'EDIT_PREVIOUS'
  currentEditingManagementHistoryEntry?: ProductionDeviceManagementHistoryEntryView
  setCurrentEditingManagementHistoryEntry?: Dispatch<SetStateAction<ProductionDeviceManagementHistoryEntryView | null>>
  organizations: OrganizationListItemDto[] | null
  setSelectedAttachments: Dispatch<SetStateAction<File[] | null>>
  selectedAttachments: File[] | null
  handleSubmit: (event) => void
  handleCancel: (event) => void
  managementHistory?: ProductionDeviceManagementHistoryView | null
}

export const Manager = ({
  mode,
  currentEditingManagementHistoryEntry,
  setCurrentEditingManagementHistoryEntry,
  organizations,
  setSelectedAttachments,
  selectedAttachments,
  handleSubmit,
  handleCancel,
  managementHistory
}: IManagerProps) => {
  const { t } = useTranslation()
  const titleText = (() => {
    switch (mode) {
      case 'CREATE':
        return t('productionDevice.management.setNewManager')
      case 'EDIT_CURRENT':
        return t('productionDevice.management.updateCurrentManager')
      case 'EDIT_PREVIOUS':
        return t('productionDevice.management.updateManager')
    }
  })()
  const [isDatesValid, setIsDatesValid] = useState<boolean>(false)

  const form = useForm<ProductionDeviceManagerSchemaType>({
    resolver: zodResolver(createProductionDeviceManagerSchema()),
    mode: 'all'
  })

  useEffect(() => {
    setIsDatesValid(checkManagementDateValidity())

    form.watch((value, { name, type }) => {
      if (name === 'dateRange.managementStartTime' && type === 'change') {
        setIsDatesValid(checkManagementDateValidity())
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, form.watch])

  useEffect(() => {
    if (mode === 'CREATE') {
      form.reset({
        dateRange: {
          managementStartTime: dayjs().toDate(),
          managementEndTime: null
        },
        organizationId: undefined,
        poa: null,
        attachments: []
      })
    } else {
      form.reset({
        dateRange: {
          managementStartTime: dayjs(currentEditingManagementHistoryEntry?.startTime!).toDate(),
          managementEndTime: currentEditingManagementHistoryEntry?.endTime
            ? dayjs(currentEditingManagementHistoryEntry?.endTime!).toDate()
            : null
        },
        organizationId: currentEditingManagementHistoryEntry?.organizationId!,
        poa: null,
        attachments: []
      })
    }
  }, [
    form,
    currentEditingManagementHistoryEntry?.endTime,
    currentEditingManagementHistoryEntry?.startTime,
    currentEditingManagementHistoryEntry?.organizationId,
    mode
  ])

  const handleClearExistingPoa = () => {
    setCurrentEditingManagementHistoryEntry?.((prevState) => {
      const newState = { ...prevState }
      newState.poa = undefined
      return newState
    })
  }

  const handleClearExistingAttachment = (attachmentId: number | null, index: number | null) => {
    if (attachmentId)
      setCurrentEditingManagementHistoryEntry?.((prevState) => {
        const newState = { ...prevState }
        newState.attachments?.splice(newState.attachments?.findIndex((attachment) => attachment.id === attachmentId), 1)
        return newState
      })

    if (index && selectedAttachments) {
      const temp = selectedAttachments
      temp.splice(index, 1)
      setSelectedAttachments([...temp])
    }
  }

  const toManagerBody = (formData: ProductionDeviceManagerSchemaType) => {
    return {
      dateRange: {
        managementStartTime: formData.dateRange.managementStartTime,
        managementEndTime: formData.dateRange.managementEndTime
      },
      organizationId: formData.organizationId,
      poa: formData.poa,
      attachments: formData.attachments
    }
  }

  /** Check that management time doesn't overlap with earlier management times */
  const checkManagementDateValidity = () => {
    if (managementHistory && managementHistory.managementHistoryEntrys) {
      const prevManagerEndTime = dayjs(managementHistory.managementHistoryEntrys[0].endTime)
      const prevManagerStartTime = dayjs(managementHistory.managementHistoryEntrys[0].startTime) || undefined
      const startTime = dayjs(form.getValues().dateRange.managementStartTime)

      // New manager's start time can't overlap with current manager's dateRange or start before it
      if (mode === 'CREATE') {
        if (
          (prevManagerEndTime.isValid() && startTime.isBefore(prevManagerEndTime)) ||
          startTime.isBefore(prevManagerStartTime) ||
          startTime.isSame(prevManagerStartTime)
        ) {
          return false
        }
      }
    }

    return true
  }

  return (
    <FlexColumn
      gap="16px"
      styles={{
        backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
        padding: '16px'
      }}
    >
      <Typography variant="h1">{titleText}</Typography>
      <FlexRow gap="16px" alignItems="center">
        <InfoOutlined />
        <Typography>{t('productionDevice.management.infoText')}</Typography>
      </FlexRow>
      <FormProvider {...form}>
        <form>
          <FlexRow gap="16px">
            {organizations && (
              <ManagerManagementForm mode={mode} managementDateValidity={isDatesValid} organizations={organizations} />
            )}
          </FlexRow>

          <FlexRow marginTop="16px" gap="24px">
            <FlexColumn alignItems="flex-start" gap="4px" flex="1">
              <ManagerPoaFileForm
                mode={mode}
                currentEditingManagementHistoryEntry={currentEditingManagementHistoryEntry}
                handleClearExistingPoa={handleClearExistingPoa}
              />
            </FlexColumn>

            <FlexColumn alignItems="flex-start" gap="4px" flex="1">
              <ManagerExtraAttachmentsForm
                mode={mode}
                currentEditingManagementHistoryEntry={currentEditingManagementHistoryEntry}
                handleClearExistingAttachment={handleClearExistingAttachment}
              />
            </FlexColumn>
          </FlexRow>
        </form>
      </FormProvider>

      <FlexRow gap="16px">
        {mode === 'CREATE' && (
          <Button
            disabled={!form.formState.isValid || !isDatesValid}
            onClick={() => handleSubmit(toManagerBody(form.getValues()))}
            variant="contained"
            startIcon={<Check />}
          >
            {t('productionDevice.management.setNewManager')}
          </Button>
        )}
        {mode !== 'CREATE' && (
          <Button
            disabled={!form.formState.isValid || !isDatesValid}
            onClick={() => handleSubmit(toManagerBody(form.getValues()))}
            variant="contained"
            startIcon={<Check />}
          >
            {mode === 'EDIT_CURRENT'
              ? t('productionDevice.management.updateCurrentManager')
              : t('productionDevice.management.updateManager')}
          </Button>
        )}
        <Button onClick={handleCancel} variant="outlined" startIcon={<Clear />}>
          {t('common.cancel')}
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
