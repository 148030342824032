import { Box, Popper, PopperPlacementType, Typography } from '@mui/material'
import React, { ReactNode } from 'react'

interface IInfoPopper {
  text: string
  children: ReactNode
  placement?: PopperPlacementType
  maxWidth?: number
}

export const CustomPopper = ({ text, children, maxWidth = 400, placement = 'top' }: IInfoPopper) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openPopper = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopper = () => {
    setAnchorEl(null)
  }

  const popperOpen = Boolean(anchorEl)
  const id = popperOpen ? 'info-popper' : undefined

  return (
    <>
      <Box aria-describedby={id} onMouseEnter={openPopper} onMouseLeave={closePopper}>
        {children}
      </Box>
      <Popper
        style={{ zIndex: 99999 }}
        open={popperOpen}
        anchorEl={anchorEl}
        placement={placement}
        sx={{
          pointerEvents: 'none'
        }}
      >
        <Box sx={{ border: 1, bgcolor: 'background.paper', maxWidth: maxWidth ? `${maxWidth}px` : '' }}>
          <Typography sx={{ pointerEvents: 'auto', p: 1 }}>{text}</Typography>
        </Box>
      </Popper>
    </>
  )
}
