import { z } from 'zod'
import { getTranslation } from '../utils/utils'
import { formPrefix } from './schema-utils'

export function createChangePasswordSchema() {
  return z
    .object({
      currentPassword: z.string().min(1, getTranslation(`${formPrefix}.password.password.required`)),
      newPassword: z
        .string()
        .min(8, getTranslation(`${formPrefix}.password.newPassword.minlength`))
        .refine(
          (pass) => /\d/.test(pass) && /[A-Z]/.test(pass) && /[a-z]/.test(pass),
          getTranslation(`${formPrefix}.password.newPassword.pattern`)
        ),
      newPasswordRepeated: z
        .string()
        .min(8, getTranslation(`${formPrefix}.password.newPasswordAgain.minlength`))
        .refine(
          (pass) => /\d/.test(pass) && /[A-Z]/.test(pass) && /[a-z]/.test(pass),
          getTranslation(`${formPrefix}.password.newPasswordAgain.pattern`)
        )
    })
    .refine((schema) => schema.newPassword === schema.newPasswordRepeated, {
      path: ['newPasswordRepeated'],
      message: getTranslation(`${formPrefix}.password.newPasswordAgain.equals`)
    })
}

const schema = createChangePasswordSchema()
export type ChangePasswordSchemaType = z.infer<typeof schema>
