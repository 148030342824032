import { Autocomplete, InputLabel, TextField } from '@mui/material'
import { OrganizationListItemDto } from '../../api/types'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'

interface IOrganizationSelectProps {
  value?: OrganizationListItemDto | null
  organizations: OrganizationListItemDto[]
  onSelect: (organization: OrganizationListItemDto | null) => void
  canSelectAll?: boolean
}

export default function OrganizationSelect({
  value,
  organizations,
  onSelect,
  canSelectAll = true
}: IOrganizationSelectProps) {
  const { t } = useTranslation()
  return (
    <FlexColumn>
      <InputLabel htmlFor="organization-select">{t('title.organization')}</InputLabel>
      <Autocomplete
        id="organization-select"
        sx={{ minWidth: '480px' }}
        value={value}
        options={[...(canSelectAll ? [null] : []), ...organizations]}
        getOptionLabel={(option: OrganizationListItemDto | null) => (option ? option.name! : t('common.all'))}
        renderInput={(params) => {
          params.inputProps.value =
            params.inputProps.value === '' && canSelectAll ? t('common.all') : params.inputProps.value
          return <TextField {...params} label="" placeholder="" />
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(event, newValue) => {
          onSelect(newValue)
        }}
      />
    </FlexColumn>
  )
}
