import { z } from 'zod'
import { formPrefix } from './schema-utils'
import { getTranslation } from '../utils/utils'

function createBaseSchema() {
  return z.object({
    description: z
      .string()
      .max(300, getTranslation(`${formPrefix}.additionalDetails.maxlength`))
      .optional()
  })
}

function createDescriptionOnlySchema() {
  return z
    .object({
      registration: z.literal(false),
      code: z.undefined(),
      gs1CodeId: z.undefined()
    })
    .merge(createBaseSchema())
}

function createRegistrationSchema() {
  return z
    .object({
      registration: z.literal(true),
      code: z
        .string()
        .min(1, getTranslation(`${formPrefix}.productionDevice.code.minlength`))
        .max(50, getTranslation(`${formPrefix}.productionDevice.code.maxlength`)),
      gs1CodeId: z.number().min(1)
    })
    .merge(createBaseSchema())
}

export function createProductionDeviceStateChangeInfoSchema() {
  return z.discriminatedUnion('registration', [createDescriptionOnlySchema(), createRegistrationSchema()])
}

const schema = createProductionDeviceStateChangeInfoSchema()
export type ProductionDeviceStateChangeInfoSchemaType = z.infer<typeof schema>
