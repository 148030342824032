import { Autocomplete, Box, InputLabel, TextField } from '@mui/material'
import { UserListItemDto } from '../../api/types'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'

interface IUserSelect {
  displayOrganization: boolean
  users: UserListItemDto[]
  selectedUser: UserListItemDto | null
  onSelect: (user: UserListItemDto | null) => void
}

export const UserSelect = ({ displayOrganization, users, selectedUser, onSelect }: IUserSelect) => {
  const { t } = useTranslation()

  const getLabel = (user: UserListItemDto): string => {
    return `${user.firstName!} ${user.lastName!} ${displayOrganization ? `(${user.organizationName})` : ''}`
  }

  return (
    <FlexColumn>
      <InputLabel htmlFor="user-select">{t('title.user')}</InputLabel>
      <Autocomplete
        id="user-select"
        sx={{ minWidth: '400px' }}
        value={selectedUser}
        options={users}
        getOptionLabel={getLabel}
        renderInput={(params) => <TextField {...params} label="" placeholder="" />}
        renderOption={(props, option) => (
          <Box {...props} component="li" key={option.id}>
            {getLabel(option)}
          </Box>
        )}
        isOptionEqualToValue={(option, value) => option.id! === value.id!}
        onChange={(_event, newValue) => {
          onSelect(newValue)
        }}
      />
    </FlexColumn>
  )
}
