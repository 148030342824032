import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { ReactNode } from 'react'
import InputContainer from './InputContainer'

type Value<V> = {
  label: string
  value: V
}

interface IControlledRadioGroupProps<T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>> {
  id?: string
  name: P
  label: string
  required?: boolean
  values?: Value<V>[]
  nodes?: ReactNode[]
  onChange?: (event) => void
  control: Control<T>
}

export const ControlledRadioGroup = <T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>>({
  id,
  name,
  label,
  required,
  values,
  nodes,
  onChange,
  control
}: IControlledRadioGroupProps<T, P, V>) => {
  return (
    <InputContainer id={id} label={label} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            row
            aria-label={name}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
              onChange?.(event)
            }}
            name={name}
          >
            {nodes
              ? nodes.map((node) => node)
              : values?.map((v) => (
                  <FormControlLabel key={v.value} value={v.value} control={<Radio />} label={v.label} />
                ))}
          </RadioGroup>
        )}
      />
    </InputContainer>
  )
}
