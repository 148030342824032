import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import StyleGuide from '../../styles/StyleGuide'

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  padding: 12px 48px;
  align-items: center;
  justify-content: space-between;
`

const StyledNav = styled.nav`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 100%;
  background-color: ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_3};
`

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  margin-right: 48px;
  flex-grow: 1;
  list-style: none;
  display: flex;
  justify-content: flex-end;
`

const StyledLink = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 19px 16px;
  font-size: 1.25rem;
  line-height: 1.625rem;
  box-sizing: border-box;
  color: ${StyleGuide.constants.COLOR_TEXT_PRIMARY};

  :hover {
    background-color: ${StyleGuide.constants.COLOR_SECONDARY};
    color: ${StyleGuide.constants.COLOR_WHITE};
  }

  &.active {
    background-color: ${StyleGuide.constants.COLOR_SECONDARY};
    color: ${StyleGuide.constants.COLOR_WHITE};
  }
`

export { StyledHeader, StyledNav, StyledList, StyledLink }
