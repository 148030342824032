import { Box, Button, Typography } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import OwnerForm from '../forms/OwnerForm'
import StyleGuide from '../../../styles/StyleGuide'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import { ParticipantDto } from '../../../api/types'

export default function OwnersFormContainer() {
  const { t } = useTranslation()
  const [participants, setParticipants] = useState<ParticipantDto[]>([])
  const { control } = useFormContext<ProductionDeviceSchemaType>()
  const { fields, remove, append } = useFieldArray({ control, name: 'owners' })

  useEffect(() => {
    ECERT_API.getOwnerParticipants().then((response) => {
      setParticipants(response.data)
    })
  }, [])

  return (
    <Box>
      <Box marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.deviceOwners')}</Typography>
      </Box>
      <Box>
        {fields.map((field, index) => (
          <Box
            key={`${field.id}-${index}`}
            bgcolor={StyleGuide.constants.COLOR_SECONDARY_LIGHT_4}
            padding="16px"
            marginBottom="16px"
            display="flex"
            maxWidth="600px"
          >
            <OwnerForm index={index} participants={participants} />
            {fields.length > 1 && (
              <Box display="flex" alignItems="flex-end">
                <Button
                  variant="outlined"
                  sx={{ marginBottom: '24px', minWidth: '40px', maxWidth: '40px' }}
                  onClick={() => remove(index)}
                >
                  <CloseIcon />
                </Button>
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" gap="16px">
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => append({ name: '', code: '' })}>
          {t('productionDevice.owner.addOwner')}
        </Button>
      </Box>
    </Box>
  )
}
