import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import StyleGuide from '../../styles/StyleGuide'

interface IRichTextEditorProps {
  editorState: any
  onUpdate: any
}
export default function RichTextEditor({ editorState, onUpdate }: IRichTextEditorProps) {
  const wrapperStyle = {
    display: 'flex',
    flexDirection: 'column'
  }
  const editorStyle = {
    height: '240px',
    padding: '1rem',
    border: `1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_1}`
  }
  const toolbarStyle = {
    padding: '12px 16px',
    marginBottom: '5px',
    height: 'auto',
    gap: '8px',
    border: `1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_3}`
  }

  const options = [
    'blockType',
    'fontSize',
    'fontFamily',
    'inline',
    'textAlign',
    'list',
    'link',
    'embedded',
    'image',
    'history'
  ]

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperStyle={wrapperStyle}
        editorStyle={editorStyle}
        toolbarStyle={toolbarStyle}
        toolbar={{
          options: options,
          blockType: {
            className: 'custom-blocktype',
            dropdownClassName: 'custom-dropdown'
          },
          fontSize: {
            className: 'custom-fontsize',
            dropdownClassName: 'custom-dropdown'
          },
          fontFamily: {
            className: 'custom-fontfamily',
            dropdownClassName: 'custom-dropdown'
          },
          inline: {
            className: 'custom-inline',
            options: ['italic', 'bold', 'underline', 'strikethrough'],
            italic: { className: 'custom-italic' }
          },
          list: {
            className: 'custom-list',
            options: ['indent', 'outdent']
          },
          link: {
            className: 'custom-link',
            options: ['link']
          }
        }}
        onEditorStateChange={onUpdate}
      />
    </>
  )
}
