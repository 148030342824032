import { useTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { InfoBox } from '../../../features/info-box/InfoBox'
import { Box, Button, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { AddButton } from '../../../features/buttons/AddButton'
import { ECERT_API } from '../../../services/ecert-api'
import { AutomaticTransferDto, AutomaticTransferRecipientDtoType } from '../../../api/types'
import { Add } from '@mui/icons-material'
import { range } from '../../../utils/utils'
import CancelButton from '../../../features/buttons/CancelButton'
import { TransferRecipient } from './TransferRecipient'
import { ExistingAutomaticTransfer } from './ExistingAutomaticTransfer'
import StyleGuide from '../../../styles/StyleGuide'
import { useParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useLoginUser } from '../../../LoginContext'
import { canEditAccount } from '../../../utils/permission-utils'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  AutomaticTransferSchemaType,
  createAutomaticTransferSchema
} from '../../../validation/automatic-transfer-schema'
import ControlledSelect from '../../../features/inputs/ControlledSelect'
import { SaveButton } from '../../../features/buttons/SaveButton'

interface IAutomaticTransfersProps {
  accountId: number
}

enum UiMode {
  VIEW,
  CREATE,
  EDIT
}

const recipientDefaultValues = {
  amount: 0,
  transfer: {
    type: AutomaticTransferRecipientDtoType.DOMESTIC,
    selectedAccount: null,
    recipientName: '',
    targetAccountNumber: '',
    additionalDetails: undefined
  }
}

const formDefaultValues = {
  transferRecipients: [],
  transferDay: 1
}

export const AutomaticTransfers = ({ accountId }: IAutomaticTransfersProps) => {
  const params = useParams()
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [automaticTransfer, setAutomaticTransfer] = useState<AutomaticTransferDto>()
  const [uiMode, setUiMode] = useState<UiMode>(UiMode.VIEW)
  const [totalAmounts, setTotalAmounts] = useState<number>(0)
  const selectableDays = range(1, 28)
  const form = useForm<AutomaticTransferSchemaType>({
    resolver: zodResolver(createAutomaticTransferSchema()),
    mode: 'all',
    defaultValues: formDefaultValues
  })
  const fieldArray = useFieldArray({ control: form.control, name: 'transferRecipients' })
  const formWatch = form.watch()

  useEffect(() => {
    setTotalAmounts(form.getValues('transferRecipients').reduce((prev, curr) => prev + curr.amount, 0))
  }, [formWatch, form])

  const updateData = useCallback(() => {
    ECERT_API.getAutomaticTransfers(+params.id!, accountId).then((response) => {
      setAutomaticTransfer(response.data)
    })
  }, [params.id, accountId])

  useEffect(() => {
    updateData()
  }, [updateData])

  const handleAddAutomaticTransfer = () => {
    setUiMode(UiMode.CREATE)
    addRecipient()
  }

  const handleAddRecipient = () => {
    addRecipient()
  }

  const handleSave = () => {
    const values: AutomaticTransferSchemaType = form.getValues()
    const automaticTransfer: AutomaticTransferDto = {
      organizationId: +params.id!,
      accountId: accountId,
      transferDay: values.transferDay,
      recipients: values.transferRecipients.map((recipient) => ({
        id: 0,
        type: recipient.transfer.type,
        amount: recipient.amount,
        targetAccountNumber: recipient.transfer.targetAccountNumber,
        recipientName: recipient.transfer.recipientName,
        additionalDetails: recipient.transfer.additionalDetails || undefined
      }))
    }
    ECERT_API.updateAutomaticTransfer(+params.id!, accountId, automaticTransfer!).then(() => {
      form.reset(formDefaultValues)
      updateData()
      setUiMode(UiMode.VIEW)
      enqueueSnackbar(t('form.valid.automaticTransfer.updated'), snacky.successOpts)
    })
  }

  const handleCancel = () => {
    if (uiMode === UiMode.CREATE) {
      deleteRecipients()
    }
    setUiMode(UiMode.VIEW)
  }

  const deleteRecipients = () => {
    form.reset()
  }

  const addRecipient = () => {
    fieldArray.append(recipientDefaultValues)
  }

  const handleClear = (index: number) => {
    fieldArray.remove(index)
  }

  const handleEditExistingAutomaticTransfer = () => {
    form.setValue('transferDay', automaticTransfer?.transferDay!)
    form.setValue(
      'transferRecipients',
      (automaticTransfer?.recipients || []).map((recipient) => {
        return {
          amount: recipient.amount!,
          transfer: {
            type: recipient.type,
            selectedAccount: null,
            recipientName: recipient.recipientName,
            targetAccountNumber: recipient.targetAccountNumber,
            additionalDetails: recipient.additionalDetails
          }
        }
      })
    )

    setUiMode(UiMode.EDIT)
  }

  const handleDeleteExistingAutomaticTransfer = () => {
    const newAutomaticTransferState = { ...automaticTransfer }
    newAutomaticTransferState.recipients = []
    ECERT_API.updateAutomaticTransfer(+params.id!, accountId, newAutomaticTransferState).then(() => {
      form.reset(formDefaultValues)
      updateData()
      setUiMode(UiMode.VIEW)
      enqueueSnackbar(t('form.valid.automaticTransfer.removed'), snacky.successOpts)
    })
  }

  return (
    <FlexColumn gap="24px" alignItems="flex-start">
      {uiMode === UiMode.VIEW && (
        <>
          <InfoBox type="INFO">{t('account.automaticTransfer.generalInfo')}</InfoBox>
          {(!automaticTransfer?.recipients || automaticTransfer?.recipients.length === 0) && (
            <Typography>{t('account.automaticTransfer.empty')}</Typography>
          )}
        </>
      )}

      {(uiMode === UiMode.CREATE || uiMode === UiMode.EDIT) && (
        <>
          <FormProvider {...form}>
            <Typography variant="h2">{t('account.automaticTransfer.editPrompt')}</Typography>
            {fieldArray.fields.map((field, index) => (
              <TransferRecipient
                targetOrganizationId={+params.id!}
                accountId={accountId}
                key={field.id}
                recipientNumber={index + 1}
                handleClear={handleClear}
              />
            ))}

            <Box>
              <Button onClick={handleAddRecipient} startIcon={<Add />} variant="outlined">
                {t('account.automaticTransfer.addRecipient')}
              </Button>
            </Box>

            <Box>
              <Typography display="inline">{`${t('common.total')}: `}</Typography>
              <Typography display="inline" sx={{ color: StyleGuide.constants.COLOR_BRAND, fontWeight: 'bold' }}>
                {`${Number(totalAmounts.toFixed(3))}%`}
              </Typography>
            </Box>

            <FlexColumn gap="12px">
              <Box>
                <ControlledSelect
                  id="transferDay"
                  name="transferDay"
                  label={t('account.automaticTransfer.transferDay')}
                  items={selectableDays.map((day) => ({ value: day, label: `${day}`, key: day }))}
                  control={form.control}
                />
              </Box>
              <FlexRow gap="12px">
                <SaveButton
                  disabled={!form.formState.isValid || totalAmounts <= 0 || totalAmounts > 100}
                  handleSave={handleSave}
                />
                <CancelButton handleCancel={handleCancel} />
              </FlexRow>
            </FlexColumn>
          </FormProvider>
        </>
      )}

      {canEditAccount(loginUser, params) && uiMode === UiMode.VIEW && automaticTransfer?.recipients?.length === 0 && (
        <Box>
          <AddButton onClick={handleAddAutomaticTransfer}>{t('common.add')}</AddButton>
        </Box>
      )}

      {uiMode === UiMode.VIEW && automaticTransfer?.recipients && automaticTransfer.recipients.length > 0 && (
        <ExistingAutomaticTransfer
          automaticTransfer={automaticTransfer}
          handleEdit={handleEditExistingAutomaticTransfer}
          handleDelete={handleDeleteExistingAutomaticTransfer}
        />
      )}
    </FlexColumn>
  )
}
