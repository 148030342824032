import { Box, Popper, PopperPlacementType, Typography } from '@mui/material'
import React from 'react'
import HelpOutline from '@mui/icons-material/HelpOutline'
import StyleGuide from '../../styles/StyleGuide'

interface IInfoPopper {
  text: string
  placement?: PopperPlacementType
  maxWidth?: number
  translationElement?: JSX.Element
}

export const InfoPopper = ({ text, maxWidth = 400, placement = 'top', translationElement }: IInfoPopper) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openPopper = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopper = () => {
    setAnchorEl(null)
  }

  const popperOpen = Boolean(anchorEl)
  const id = popperOpen ? 'info-popper' : undefined

  return (
    <>
      <HelpOutline
        sx={{ color: StyleGuide.constants.COLOR_BRAND }}
        aria-describedby={id}
        onMouseEnter={openPopper}
        onMouseLeave={closePopper}
      />
      <Popper
        style={{ zIndex: 99999 }}
        open={popperOpen}
        anchorEl={anchorEl}
        placement={placement}
        sx={{
          pointerEvents: 'none'
        }}
      >
        <Box sx={{ border: 1, bgcolor: 'background.paper', maxWidth: maxWidth ? `${maxWidth}px` : '' }}>
          <Typography sx={{ pointerEvents: 'auto', p: 1 }}>{text ? text : translationElement}</Typography>
        </Box>
      </Popper>
    </>
  )
}
