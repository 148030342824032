import dayjs from 'dayjs'
import { InvoiceRowDto, InvoicingPeriodDto } from '../../../api/types'

export const calculateSum = (row: InvoiceRowDto) => {
  const total = row?.amount! * row?.unitPrice!
  return total
}

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(
    Math.round((value + Number.EPSILON) * 100) / 100
  )
}

export const formatNumberWithSeparator = (value: number) => {
  return new Intl.NumberFormat('fi-FI').format(value)
}

export const formatInvoicingPeriod = (invoicingPeriod: InvoicingPeriodDto) => {
  if (invoicingPeriod && invoicingPeriod.invoicingPeriodStart && invoicingPeriod.invoicingPeriodEnd)
    return `${dayjs(invoicingPeriod.invoicingPeriodStart).format('MM/YYYY')} - ${dayjs(
      invoicingPeriod.invoicingPeriodEnd
    ).format('MM/YYYY')}`
}
