import { createTheme } from '@mui/material'
import StyleGuide from './styles/StyleGuide'
import { checkboxClasses } from '@mui/material/Checkbox'

// Typescript magic to allow new variants for Typography
declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true
  }
}

export const muiOverridesTheme = createTheme({
  typography: {
    label: {
      fontFamily: 'LabGrotesqueWeb',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '1rem',
      color: StyleGuide.constants.COLOR_TEXT_PRIMARY
    },
    h1: {
      fontFamily: 'LabGrotesqueWeb',
      fontSize: '2.5rem',
      lineHeight: '2.5rem',
      color: StyleGuide.constants.COLOR_TEXT_PRIMARY
    },
    h2: {
      fontFamily: 'LabGrotesqueWeb',
      fontSize: '2rem',
      lineHeight: '2rem',
      color: StyleGuide.constants.COLOR_TEXT_PRIMARY
    },
    h3: {
      fontFamily: 'LabGrotesqueWeb',
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      color: StyleGuide.constants.COLOR_TEXT_PRIMARY
    },
    body1: {
      fontFamily: 'LabGrotesqueWeb',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: StyleGuide.constants.COLOR_TEXT_PRIMARY
    }
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_1,
          color: StyleGuide.constants.COLOR_WHITE,
          borderRadius: 0
        },
        deleteIcon: {
          color: StyleGuide.constants.COLOR_WHITE
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.${checkboxClasses.checked}`]: {
            color: StyleGuide.constants.COLOR_SECONDARY
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          display: 'inline-block'
        },
        grouped: {
          ':not(:last-of-type)': {
            lineHeight: '1px',
            borderColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_2
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'none'
        },
        contained: {
          color: StyleGuide.constants.COLOR_WHITE,
          backgroundColor: StyleGuide.constants.COLOR_BRAND,
          borderColor: StyleGuide.constants.COLOR_BRAND,
          ':hover': {
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY,
            borderColor: StyleGuide.constants.COLOR_SECONDARY
          },
          '&& .MuiTouchRipple-child': {
            backgroundColor: StyleGuide.constants.COLOR_NEUTRAL_20,
            borderColor: StyleGuide.constants.COLOR_NEUTRAL_20
          },
          ':disabled': {
            backgroundColor: StyleGuide.constants.COLOR_NEUTRAL_70,
            borderColor: StyleGuide.constants.COLOR_NEUTRAL_70
          }
        },
        outlined: {
          borderWidth: '2px',
          color: StyleGuide.constants.COLOR_BRAND,
          borderColor: StyleGuide.constants.COLOR_BRAND,
          ':hover': {
            color: StyleGuide.constants.COLOR_SECONDARY,
            borderColor: StyleGuide.constants.COLOR_SECONDARY,
            backgroundColor: 'transparent',
            borderWidth: '2px'
          },
          ':disabled': {
            color: StyleGuide.constants.COLOR_NEUTRAL_70,
            borderColor: StyleGuide.constants.COLOR_NEUTRAL_70,
            borderWidth: '2px'
          }
        },
        text: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          color: StyleGuide.constants.COLOR_BRAND,
          ':hover': {
            color: StyleGuide.constants.COLOR_SECONDARY,
            backgroundColor: 'transparent'
          },
          ':active': {
            color: StyleGuide.constants.COLOR_NEUTRAL_20,
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: StyleGuide.constants.COLOR_SECONDARY,
          backgroundColor: StyleGuide.constants.COLOR_WHITE,
          borderRadius: 0
        },
        input: {
          color: StyleGuide.constants.COLOR_SECONDARY,
          backgroundColor: StyleGuide.constants.COLOR_WHITE
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          '& input::placeholder': {
            color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
            fontSize: '1rem',
            lineHeight: '1.5rem'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: StyleGuide.constants.COLOR_BRAND,
          textDecoration: 'none',
          cursor: 'pointer',
          ':active': {
            color: StyleGuide.constants.COLOR_BRAND
          },
          ':hover': {
            color: StyleGuide.constants.COLOR_BRAND,
            textDecoration: 'underline'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '0px',
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
          borderBottom: `1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_2}`,
          boxShadow: 'none',
          h4: {
            fontSize: '1rem',
            lineHeight: '1rem',
            color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
            fontWeight: 500
          },
          '&.Mui-expanded': {
            margin: 0
          },
          minHeight: '40px'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: '40px',
          minHeight: '40px',
          '&.Mui-expanded': {
            height: '40px',
            minHeight: '40px'
          }
        },
        content: {
          margin: 0,
          display: 'flex',
          alignItems: 'center'
        },
        expandIconWrapper: {
          order: -1,
          marginRight: '15px'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.clickable': {
            cursor: 'pointer'
          },
          '&.clickable:hover': {
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '50px',
          fontFamily: 'LabGrotesqueWeb',
          fontSize: '1rem'
        },
        body: {
          backgroundColor: StyleGuide.constants.COLOR_WHITE
        },
        stickyHeader: {
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
          '&.sortable:hover': {
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4
          }
        },
        head: {
          color: StyleGuide.constants.COLOR_SECONDARY,
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '24px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: StyleGuide.constants.COLOR_SECONDARY,
          '&.Mui-checked': {
            color: StyleGuide.constants.COLOR_SECONDARY
          },

          '& > span > svg': {
            width: '1.25em',
            height: '1.25em'
          }
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          '& .SnackbarItem-variantWarning': {
            backgroundColor: 'white !important'
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .SnackbarItem-variantWarning': {
            backgroundColor: 'white !important'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
            },
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_2
          },
          '&:hover': {
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
          },
          backgroundColor: StyleGuide.constants.COLOR_WHITE,
          color: StyleGuide.constants.COLOR_SECONDARY
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          height: 0,
          margin: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: 'red',
        root: {
          color: StyleGuide.constants.COLOR_TEXT_PRIMARY,
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,

          '&.Mui-selected': {
            color: StyleGuide.constants.COLOR_WHITE,
            backgroundColor: StyleGuide.constants.COLOR_TEXT_PRIMARY
          },

          '&:hover': {
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_1,
            color: StyleGuide.constants.COLOR_WHITE
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: StyleGuide.constants.COLOR_BRAND
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 0
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'LabGrotesqueWeb',
          fontWeight: 400,
          fontSize: '1rem'
        }
      }
    }
  }
})
