import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useDeferredCallback } from '../../../../hooks'

interface OrganizationFilterProps {
  onChange: (value: string) => void
}

export default function OrganizationFilter({ onChange }: OrganizationFilterProps) {
  const { t } = useTranslation()

  const [filterText, setFilterText] = useState('')

  const onChangeDeferred = useDeferredCallback(onChange)

  const handleChange = (newValue: string) => {
    setFilterText(newValue)
    onChangeDeferred(newValue)
  }

  return (
    <TextField
      label={t('user.list.filter.name')}
      variant="outlined"
      value={filterText}
      InputProps={{
        sx: { height: '50px' }
      }}
      onChange={(event) => handleChange(event.target.value)}
    />
  )
}
