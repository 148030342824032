import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { useCallback, useEffect, useState } from 'react'
import { ECERT_API } from '../../services/ecert-api'
import { NotificationDto, NotificationViewDto } from '../../api/types'
import { parseDate } from '../../utils/utils'
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import AddIcon from '@mui/icons-material/Add'
import { useTranslation } from 'react-i18next'
import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { Archive, DeleteOutlined, Edit, Unarchive } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useAppLanguage } from '../../hooks'
import dayjs from 'dayjs'

export const Notifications = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState<NotificationViewDto[] | undefined>(undefined)
  const [notificationStatus, setNotificationStatus] = useState<string>('active')
  const [notificationIdToDelete, setNotificationIdToDelete] = useState(-1)
  const { currentLanguage } = useAppLanguage()

  const fetchNotifications = useCallback(() => {
    ECERT_API.getAllNotifications().then((res) => {
      setNotifications(res.data)
    })
  }, [])

  const archiveNotification = (notification: NotificationViewDto) => {
    const updateDto: NotificationDto = notification as NotificationDto
    updateDto.archived = true
    ECERT_API.updateNotification(notification.id!, updateDto).then(() => {
      enqueueSnackbar(t('notification.notificationArchived'), snacky.successOpts)
      fetchNotifications()
    })
  }

  const unArchiveNotification = (notification: NotificationViewDto) => {
    const updateDto: NotificationDto = notification as NotificationDto
    updateDto.archived = false
    ECERT_API.updateNotification(notification.id!, updateDto).then(() => {
      enqueueSnackbar(t('notification.notificationUnArchived'), snacky.successOpts)
      fetchNotifications()
    })
  }

  const columns: IColumn[] = [
    {
      label: t('notification.timeRange'),
      accessor: (notification: NotificationViewDto) => (
        <FlexRow>
          {parseDate(notification.startTime!).format('DD.MM.YYYY')} -{' '}
          {notification.endTime ? parseDate(notification.endTime).format('DD.MM.YYYY') : t('notification.rangeActive')}
        </FlexRow>
      )
    },
    {
      label: t('notification.content'),
      accessor: (notification: NotificationViewDto) => {
        const content = (() => {
          switch (currentLanguage) {
            case 'fi':
              return notification.contentFi!
            case 'en':
              return notification.contentEn!
            case 'sv':
              return notification.contentSv!
          }
        })()
        return <Box dangerouslySetInnerHTML={{ __html: content }}></Box>
      }
    },
    {
      label: t('notification.actions'),
      accessor: (notification: NotificationViewDto) => (
        <FlexRow gap="8px">
          <Tooltip arrow placement="top" describeChild title={t('notification.edit')}>
            <Button
              aria-label="edit-notification"
              variant="contained"
              onClick={() => navigate(EcertRoutes.notificationEdit(notification.id!))}
            >
              <Edit></Edit>
            </Button>
          </Tooltip>
          {notification.archived ? (
            <Tooltip arrow placement="top" describeChild title={t('notification.unarchive')}>
              <Button
                aria-label="unarchive-notification"
                variant="contained"
                onClick={() => unArchiveNotification(notification)}
              >
                <Unarchive></Unarchive>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip arrow placement="top" describeChild title={t('notification.archive')}>
              <Button
                aria-label="archive-notification"
                variant="contained"
                onClick={() => archiveNotification(notification)}
              >
                <Archive></Archive>
              </Button>
            </Tooltip>
          )}
          <Tooltip arrow placement="top" describeChild title={t('notification.delete')}>
            <Button
              aria-label="delete-notification"
              variant="contained"
              onClick={() => setNotificationIdToDelete(notification.id!)}
            >
              <DeleteOutlined></DeleteOutlined>
            </Button>
          </Tooltip>
        </FlexRow>
      )
    }
  ]

  useEffect(() => {
    fetchNotifications()
  }, [fetchNotifications])

  const notificationsFiltered = () => {
    return notifications?.filter((n: NotificationViewDto) => {
      if (notificationStatus === 'archived') {
        return n.archived || (n.endTime && parseDate(n.endTime).isBefore(dayjs()))
      } else if (notificationStatus === 'active') {
        return n.active && n.published && !n.archived
      } else if (notificationStatus === 'draft') {
        return !n.published && !n.archived
      }
    })
  }

  const handleConfirm = () => {
    ECERT_API.deleteNotification(notificationIdToDelete).then(() => {
      enqueueSnackbar(t('notification.notificationDeleted'), snacky.successOpts)
      setNotificationIdToDelete(-1)
      fetchNotifications()
    })
  }

  const handleCancel = () => {
    setNotificationIdToDelete(-1)
  }

  return (
    <>
      <FlexColumn gap="16px">
        <FlexRow justifyContent="space-between">
          <FormControl>
            <RadioGroup
              aria-label="notification-status"
              row
              value={notificationStatus}
              onChange={(event, value) => setNotificationStatus(value)}
            >
              <FormControlLabel value="active" control={<Radio />} label={t('notification.active')} />
              <FormControlLabel value="draft" control={<Radio />} label={t('notification.drafts')} />
              <FormControlLabel value="archived" control={<Radio />} label={t('notification.archived')} />
            </RadioGroup>
          </FormControl>
          <Button
            sx={{ height: '50px' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate(EcertRoutes.paths.notifications.create)}
          >
            {t('notification.create')}
          </Button>
        </FlexRow>
        <EcertTable data={notificationsFiltered()} columns={columns} />
      </FlexColumn>
      <CustomDialog
        isOpen={notificationIdToDelete !== -1}
        title={t('notification.notificationDeleteDialogTitle')}
        handleClose={handleCancel}
        buttons={
          <>
            <ConfirmButton handleConfirm={handleConfirm} />
            <CancelButton handleCancel={handleCancel} />
          </>
        }
      >
        <Typography variant="body1">{t('notification.notificationDeleteDialogMessage')}</Typography>
      </CustomDialog>
    </>
  )
}
