import { useMemo } from 'react'
import { createAsyncThunk, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { AccountDetailsDto, AccountDto, AccountUpdateDto } from '../../api/types'
import { ECERT_API } from '../../services/ecert-api'
import { useDataSliceArray } from './hooks'

interface IFilters {
  productionTimeDateRange: { start?: string; end?: string }
  productionDeviceTechnologies: string[]
  productionDeviceNames: string[]
  productionDeviceCommissioningDateRange: { start?: string; end?: string }
  productionDeviceInvestments: { yes?: boolean; no?: boolean }
  productionDeviceCapacity: { min?: string; max?: string }
  noLabels: boolean
  labelCodes: string[]
  certificateIssuingCountries: string[]
}

interface IOrganizationAccountsState {
  selectedTabIndex: number
  accountDetails: AccountDetailsDto | null
  selectedBundles: Dictionary<number>
  filters: IFilters
  accounts: AccountDto[] | undefined
}

const initialFiltersState = {
  productionTimeDateRange: {},
  productionDeviceTechnologies: [],
  productionDeviceNames: [],
  productionDeviceCommissioningDateRange: {},
  productionDeviceInvestments: {
    no: false,
    yes: false
  },
  productionDeviceCapacity: {},
  noLabels: true,
  labelCodes: [],
  certificateIssuingCountries: []
}

const initialState: IOrganizationAccountsState = {
  selectedTabIndex: 0,
  accountDetails: null,
  selectedBundles: {},
  filters: initialFiltersState,
  accounts: undefined
}

// Reducers
export const organizationAccountsSlice = createSlice({
  name: 'organizationAccounts',
  initialState,
  reducers: {
    setSelectedTabIndex: (state, action: PayloadAction<{ tabIndex: number }>) => {
      state.selectedTabIndex = action.payload.tabIndex
    },
    setSelectedBundle: (state, action: PayloadAction<{ id: string; count: number }>) => {
      state.selectedBundles[action.payload.id] = action.payload.count
    },
    unSelectBundles: (state) => {
      state.selectedBundles = {}
    },
    setFilterProductionTimeStartDate: (state, action: PayloadAction<{ start: string }>) => {
      state.filters.productionTimeDateRange.start = action.payload.start
    },
    setFilterProductionTimeEndDate: (state, action: PayloadAction<{ end: string }>) => {
      state.filters.productionTimeDateRange.end = action.payload.end
    },
    setFilterProductionDeviceTechnologies: (state, action: PayloadAction<{ technologies: string[] }>) => {
      state.filters.productionDeviceTechnologies = action.payload.technologies
    },
    setFilterProductionDeviceNames: (state, action: PayloadAction<{ names: string[] }>) => {
      state.filters.productionDeviceNames = action.payload.names
    },
    setFilterProductionDeviceCommissioningStartDate: (state, action: PayloadAction<{ start: string }>) => {
      state.filters.productionDeviceCommissioningDateRange.start = action.payload.start
    },
    setFilterProductionDeviceCommissioningEndDate: (state, action: PayloadAction<{ end: string }>) => {
      state.filters.productionDeviceCommissioningDateRange.end = action.payload.end
    },
    setFilterInvestmentsNo: (state) => {
      state.filters.productionDeviceInvestments.no = !state.filters.productionDeviceInvestments.no
    },
    setFilterInvestmentsYes: (state) => {
      state.filters.productionDeviceInvestments.yes = !state.filters.productionDeviceInvestments.yes
    },
    setFilterProductionDeviceCapacityMin: (state, action: PayloadAction<{ min: string }>) => {
      state.filters.productionDeviceCapacity.min = action.payload.min
    },
    setFilterProductionDeviceCapacityMax: (state, action: PayloadAction<{ max: string }>) => {
      state.filters.productionDeviceCapacity.max = action.payload.max
    },
    setFilterLabelCodes: (state, action: PayloadAction<{ labelCodes: string[] }>) => {
      state.filters.labelCodes = action.payload.labelCodes
    },
    setFilterCertificateIssuingCountries: (state, action: PayloadAction<{ countries: string[] }>) => {
      state.filters.certificateIssuingCountries = action.payload.countries
    },
    resetFilters: (state) => {
      state.filters = initialFiltersState
    },
    clearAccountDetails: (state) => {
      state.accountDetails = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAccountDetails.fulfilled, (state, action) => {
        state.accountDetails = action.payload
      })
      .addCase(updateAccountDetails.pending, (state, action) => {
        const { name, publicVisibility } = action.meta.arg.accountUpdateDto
        state.accountDetails!.name = name
        state.accountDetails!.publicVisibility = publicVisibility
      })
      .addCase(fetchAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload
      })
      .addCase(fetchAccountsForOrganization.fulfilled, (state, action) => {
        state.accounts = action.payload
      })
  }
})

// Actions
export const {
  setSelectedTabIndex,
  setSelectedBundle,
  unSelectBundles,
  setFilterProductionTimeStartDate,
  setFilterProductionTimeEndDate,
  setFilterProductionDeviceTechnologies,
  setFilterProductionDeviceNames,
  setFilterProductionDeviceCommissioningStartDate,
  setFilterProductionDeviceCommissioningEndDate,
  setFilterInvestmentsNo,
  setFilterInvestmentsYes,
  setFilterProductionDeviceCapacityMin,
  setFilterProductionDeviceCapacityMax,
  setFilterLabelCodes,
  setFilterCertificateIssuingCountries,
  resetFilters,
  clearAccountDetails
} = organizationAccountsSlice.actions

// Selectors
export const selectTabIndex = (state: RootState) => state.organizationAccounts.selectedTabIndex

export const selectSelectedBundles = (state: RootState) => state.organizationAccounts.selectedBundles

export const selectFilters = (state: RootState) => state.organizationAccounts.filters

export const selectAccountDetails = (state: RootState) => state.organizationAccounts.accountDetails

export const selectAccounts = (state: RootState) => state.organizationAccounts.accounts

// Effects/Thunks
export const fetchAccounts = createAsyncThunk('organizationAccounts/fetchAll', async () => {
  const response = await ECERT_API.getAllAccounts()
  return response.data
})

export const fetchAccountsForOrganization = createAsyncThunk(
  'organizationAccounts/fetchForOrganization',
  async ({ organizationId }: { organizationId: number }) => {
    const response = await ECERT_API.getAccountsForOrganization(organizationId)
    return response.data
  }
)

export const fetchAccountDetails = createAsyncThunk(
  'organizationAccounts/details/fetch',
  async ({ organizationId, accountId }: { organizationId: number; accountId: number }) => {
    const response = await ECERT_API.getOrganizationAccount(organizationId, accountId)
    return response.data
  }
)

export const updateAccountDetails = createAsyncThunk(
  'organizationAccount/details/update',
  async ({
    organizationId,
    accountId,
    accountUpdateDto
  }: {
    organizationId: number
    accountId: number
    accountUpdateDto: AccountUpdateDto
  }) => {
    const response = await ECERT_API.updateAccount(organizationId, accountId, accountUpdateDto)
    return response.data
  }
)

// Hooks
export function useAccounts(initialFetch: boolean, refreshFetch: boolean, organizationId?: number) {
  const dataFetch = useMemo(() => {
    return organizationId === undefined
      ? fetchAccounts
      : createAsyncThunk('organizationAccounts/fetchForOrganization', async () => {
          const response = await ECERT_API.getAccountsForOrganization(organizationId)
          return response.data
        })
  }, [organizationId])
  return useDataSliceArray(selectAccounts, dataFetch, initialFetch, refreshFetch)
}

export default organizationAccountsSlice.reducer
