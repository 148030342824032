import { Navigate, Outlet, useParams } from 'react-router-dom'
import { ReactNode } from 'react'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../custom-snackbar-provider/CustomSnackbarProvider'
import { useTranslation } from 'react-i18next'
import { LoginStatus, useLoginContext } from '../../LoginContext'

interface RequireAuthProps {
  children?: ReactNode
  hasPermissionToRoute?: boolean | ((user?, params?) => boolean)
}

export default function RequireAuth({ children, hasPermissionToRoute }: RequireAuthProps) {
  const { t } = useTranslation()
  const params = useParams()
  const { loginStatus, loginUser } = useLoginContext()

  // Don't show any content if login is missing or incomplete.
  // Needed when login context is changed to NO_LOGIN state
  // but forced redirect useEffect in login context is not yet executed.
  if (!loginUser || loginStatus !== LoginStatus.VERIFIED_LOGIN) {
    return <></>
  }

  if (typeof hasPermissionToRoute === 'boolean' && !hasPermissionToRoute) {
    enqueueSnackbar(t('user.permission.error.forbidden'), snacky.errorOpts)
    return <Navigate to="/" />
  }

  if (typeof hasPermissionToRoute === 'function' && !hasPermissionToRoute?.(loginUser, params)) {
    enqueueSnackbar(t('user.permission.error.forbidden'), snacky.errorOpts)
    return <Navigate to="/" />
  }

  return children ? <>{children}</> : <Outlet />
}
