import { PermissionDto, PermissionDtoPermissionsItem, UserViewDto } from '../api/types'
import { Params } from 'react-router-dom'
import { getTranslation } from './utils'

type ProductionDevicePermission =
  | 'PERMISSION_PRODUCTIONDEVICE_GENERAL'
  | 'PERMISSION_PRODUCTIONDEVICE_VIEW'
  | 'PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION'

type AccountPermission =
  | 'PERMISSION_ACCOUNT_GENERAL'
  | 'PERMISSION_ACCOUNT_VIEW'
  | 'PERMISSION_ACCOUNT_CANCELLATION'
  | 'PERMISSION_ACCOUNT_TRANSFER_EXTERNAL'
  | 'PERMISSION_ACCOUNT_TRANSFER_DOMESTIC'
  | 'PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION'

type OrganizationSelector = { organizationId: number } | Params<string>

const resolveOrganizationSelector = (selector: OrganizationSelector) => {
  if (selector.organizationId || selector['id']) {
    return +(selector.organizationId && selector['id'])
  }
  throw Error(`Invalid organization selector: ${JSON.stringify(selector)}`)
}

type ProductionDeviceSelector = { organizationId: number; productionDeviceId: number } | Params<string>

const resolveProductionDeviceSelector = (selector: ProductionDeviceSelector) => {
  if (selector.organizationId && selector.productionDeviceId) {
    return { organizationId: +selector.organizationId, productionDeviceId: +selector.productionDeviceId }
  }
  throw Error(`Invalid production device selector: ${JSON.stringify(selector)}`)
}

type AccountSelector = { organizationId: number; accountId: number } | Params<string>

const resolveAccountSelector = (selector: AccountSelector) => {
  if ((selector.organizationId || selector['id']) && selector.accountId) {
    return { organizationId: +(selector.organizationId ?? selector['id']), accountId: +selector.accountId }
  }
  throw Error(`Invalid account selector: ${JSON.stringify(selector)}`)
}

type UserSelector = { organizationId: number; userId: number } | Params<string>

const resolveUserSelector = (selector: UserSelector) => {
  if (selector.organizationId && selector.userId) {
    return { organizationId: +selector.organizationId, userId: +selector.userId }
  }
  throw Error(`Invalid user selector: ${JSON.stringify(selector)}`)
}

const hasRole = (user: UserViewDto, role: string): boolean => {
  return !!user.authorities && user.authorities.includes(role)
}

export const isAdmin = (user: UserViewDto): boolean => {
  return hasRole(user, 'ROLE_ADMIN')
}

export const isAccountAdmin = (user: UserViewDto): boolean => {
  return hasRole(user, 'ROLE_ACCOUNT_ADMIN')
}

export const isInspector = (user: UserViewDto): boolean => {
  return hasRole(user, 'ROLE_INSPECTOR')
}

export const isUser = (user: UserViewDto): boolean => {
  return !isAdmin(user) && !isAccountAdmin(user) && !isInspector(user)
}

export const isPermissionSet = (
  permissionsDefinition: PermissionDto,
  permission: PermissionDtoPermissionsItem
): boolean => {
  return permissionsDefinition.permissions?.includes(permission) ?? false
}

export const isAnyPermissionSet = (
  permissionsDefinition: PermissionDto,
  permissions: PermissionDtoPermissionsItem[]
): boolean => {
  return permissions.some((permission) => isPermissionSet(permissionsDefinition, permission))
}

const hasPermission = (user: UserViewDto, permission: PermissionDtoPermissionsItem): boolean => {
  return user.permission ? isPermissionSet(user.permission, permission) : false
}

const hasAnyPermission = (user: UserViewDto, permissions: PermissionDtoPermissionsItem[]): boolean => {
  return user.permission ? isAnyPermissionSet(user.permission, permissions) : false
}

const hasProductionDevicePermission = (
  user: UserViewDto,
  productionDeviceId: number,
  permissions: ProductionDevicePermission[]
): boolean => {
  return (
    hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL) ||
    (hasAnyPermission(user, permissions) &&
      (hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED) ||
        (!!user.permission?.productionDevices && user.permission.productionDevices.includes(productionDeviceId))))
  )
}

const hasAccountPermission = (user: UserViewDto, accountId: number, permissions: AccountPermission[]): boolean => {
  return (
    hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL) ||
    (hasAnyPermission(user, permissions) &&
      (hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED) ||
        (!!user.permission?.accounts && user.permission.accounts.includes(accountId))))
  )
}

/**
 * View permissions that determine if user can view certain type of data.
 * These permissions don't check permission per specific entity.
 **/

export const canViewOrganizations = (user: UserViewDto): boolean => {
  return isAdmin(user) || isInspector(user)
}

export const canViewUsers = (user: UserViewDto): boolean => {
  return isAdmin(user) || isAccountAdmin(user) || isInspector(user)
}

export const canViewProductionDevices = (user: UserViewDto): boolean => {
  return (
    isAdmin(user) ||
    isAccountAdmin(user) ||
    isInspector(user) ||
    hasAnyPermission(user, [
      PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_VIEW,
      PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION,
      PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL
    ])
  )
}

export const canViewProductionInfoTransfers = (user: UserViewDto): boolean => {
  return isAdmin(user)
}

export const canViewTransactions = (user: UserViewDto): boolean => {
  return canViewAccounts(user)
}

export const canViewSignedDocuments = (user: UserViewDto): boolean => {
  return isAdmin(user) || isAccountAdmin(user)
}

export const canViewInvoices = (user: UserViewDto): boolean => {
  return isAdmin(user) || isAccountAdmin(user)
}

export const canViewEventLog = (user: UserViewDto): boolean => {
  return isAdmin(user) || isAccountAdmin(user)
}

export const canViewExternalTransfers = (user: UserViewDto): boolean => {
  return isAdmin(user) || isAccountAdmin(user)
}

export const canViewIssuings = (user: UserViewDto): boolean => {
  return canViewProductionDevices(user)
}

export const canViewLabel = (user: UserViewDto): boolean => {
  return isAdmin(user)
}

export const canViewAccounts = (user: UserViewDto): boolean => {
  return (
    isAdmin(user) ||
    isAccountAdmin(user) ||
    isInspector(user) ||
    hasAnyPermission(user, [
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL
    ])
  )
}

/**
 * Operation permissions that determine if user can view or perform operation related to a specific entity.
 * These functions need to verify user organization and in some cases also the entity specific permission.
 **/

const canPerformProductionDeviceOperation = (
  user: UserViewDto,
  selector: ProductionDeviceSelector,
  permissions: ProductionDevicePermission[]
): boolean => {
  const { organizationId, productionDeviceId } = resolveProductionDeviceSelector(selector)
  if (isAdmin(user)) return true
  if (user.organizationId !== organizationId) return false
  if (isAccountAdmin(user)) return true
  if (isUser(user) && hasProductionDevicePermission(user, productionDeviceId, permissions)) return true
  return false
}

const canPerformAccountOperation = (
  user: UserViewDto,
  selector: AccountSelector,
  permissions: AccountPermission[]
): boolean => {
  const { organizationId, accountId } = resolveAccountSelector(selector)
  if (isAdmin(user)) return true
  if (user.organizationId !== organizationId) return false
  if (isAccountAdmin(user)) return true
  if (isUser(user) && hasAccountPermission(user, accountId, permissions)) return true
  return false
}

const canPerformUserOperation = (user: UserViewDto, selector: UserSelector): boolean => {
  const { organizationId, userId } = resolveUserSelector(selector)
  if (isAdmin(user)) return true
  if (user.organizationId !== organizationId) return false
  if (isAccountAdmin(user)) return true
  if ((isInspector(user) || isUser(user)) && userId === user.id) return true
  return false
}

const canPerformOrganizationalAdminOperation = (user: UserViewDto, selector: OrganizationSelector): boolean => {
  const organizationId = resolveOrganizationSelector(selector)
  if (isAdmin(user)) return true
  if (isAccountAdmin(user) && user.organizationId !== organizationId) return true
  return false
}

export const canViewOrganization = (user: UserViewDto, selector: OrganizationSelector): boolean => {
  return isInspector(user) || canPerformOrganizationalAdminOperation(user, selector)
}

export const canViewProductionDevice = (user: UserViewDto, selector: ProductionDeviceSelector): boolean => {
  return (
    isInspector(user) ||
    canPerformProductionDeviceOperation(user, selector, [
      PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_VIEW,
      PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION
    ])
  )
}

export const canCreateProductionDevices = (user: UserViewDto, selector: OrganizationSelector): boolean => {
  const organizationId = resolveOrganizationSelector(selector)
  return (
    canPerformOrganizationalAdminOperation(user, selector) ||
    (isUser(user) &&
      user.organizationId !== organizationId &&
      hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL))
  )
}

export const canEditProductionDevice = (user: UserViewDto, selector: ProductionDeviceSelector): boolean => {
  return canPerformProductionDeviceOperation(user, selector, [])
}

export const canEditFuelDistribution = (user: UserViewDto, selector: ProductionDeviceSelector): boolean => {
  return canPerformProductionDeviceOperation(user, selector, [
    PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION
  ])
}

export const canViewAccount = (user: UserViewDto, selector: AccountSelector): boolean => {
  return (
    isInspector(user) ||
    canPerformAccountOperation(user, selector, [
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION,
      PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL
    ])
  )
}

export const canViewTransaction = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canViewAccount(user, selector)
}

export const canEditAccount = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [])
}

export const canTransferCertificates = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [
    PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC,
    PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION,
    PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL
  ])
}

export const canTransferDomestic = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC])
}

export const canTransferOrganization = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [
    PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION
  ])
}

export const canTransferExternal = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL])
}

export const canCancelCertificates = (user: UserViewDto, selector: AccountSelector): boolean => {
  return canPerformAccountOperation(user, selector, [PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION])
}

export const canViewUser = (user: UserViewDto, selector: UserSelector): boolean => {
  return isInspector(user) || canPerformUserOperation(user, selector)
}

export const canCreateUser = (user: UserViewDto, selector: OrganizationSelector): boolean => {
  return canPerformOrganizationalAdminOperation(user, selector)
}

export const canEditUser = (user: UserViewDto, selector: UserSelector): boolean => {
  return canPerformUserOperation(user, selector)
}

/**
 * Operation permissions that determine if user can perform a general operation.
 * These functions don't check permission per specific organization or entity.
 **/

export const canCreateAccounts = (user: UserViewDto): boolean => {
  return (
    isAdmin(user) ||
    isAccountAdmin(user) ||
    hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL)
  )
}

export const canDownloadAccountsExcel = (user: UserViewDto): boolean => {
  return (
    isAdmin(user) || isAccountAdmin(user) || hasPermission(user, PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW)
  )
}

export const canCorrectCertificates = (user: UserViewDto): boolean => {
  return isAdmin(user)
}

/**
 * MISCELLANEOUS
 **/

export const singleOrganization = (organizationId: string | null, loginUser: UserViewDto): number | undefined => {
  if (isAdmin(loginUser) || isInspector(loginUser)) {
    return organizationId ? +organizationId : undefined
  } else {
    return loginUser.organizationId
  }
}

export const getWithoutPermissions = (
  permissions: PermissionDtoPermissionsItem[],
  removePermissions: PermissionDtoPermissionsItem[]
): PermissionDtoPermissionsItem[] => {
  return permissions.filter((permission) => !removePermissions.includes(permission))
}

export const getTranslationForPermission = (permission: PermissionDtoPermissionsItem): string => {
  switch (permission) {
    case PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL:
      return getTranslation('user.permission.permission.full')
    case PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_VIEW:
      return getTranslation('user.permission.type.productionDevice.view')
    case PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION:
      return getTranslation('user.permission.type.productionDevice.fuelDistribution')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL:
      return getTranslation('user.permission.permission.full')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW:
      return getTranslation('user.permission.type.account.view')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION:
      return getTranslation('user.permission.type.account.cancellation')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC:
      return getTranslation('user.permission.type.account.transfer.domestic')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION:
      return getTranslation('user.permission.type.account.transfer.organization')
    case PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL:
      return getTranslation('user.permission.type.account.transfer.external')
    default:
      return ''
  }
}
