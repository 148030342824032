import { Typography } from '@mui/material'
import { getTranslation } from '../../../../utils/utils'
import { parseDate } from '../../../../utils/utils'
import { ProductionDeviceOwnerDto } from '../../../../api/types'

interface IDetailsInfoProps {
  dateOfCommissioning: string
  gsrn: string
  productionDeviceCode: string
  issuingPeriod: string
  owners: ProductionDeviceOwnerDto[]
}

export default function DetailsInfo({
  dateOfCommissioning,
  gsrn,
  productionDeviceCode,
  issuingPeriod,
  owners
}: IDetailsInfoProps) {
  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        {getTranslation('productionDevice.dateOfCommissioning')}
      </Typography>
      <Typography variant="body1" marginBottom="8px">
        {parseDate(dateOfCommissioning).format('DD.MM.YYYY')}
      </Typography>

      <Typography variant="body1" fontWeight="bold">
        {getTranslation('productionDevice.gsrn')}
      </Typography>
      <Typography variant="body1" marginBottom="8px">
        {gsrn}
      </Typography>

      <Typography variant="body1" fontWeight="bold">
        {getTranslation('productionDevice.code')}
      </Typography>
      <Typography variant="body1" marginBottom="8px">
        {productionDeviceCode}
      </Typography>

      <Typography variant="body1" fontWeight="bold">
        {getTranslation('productionDevice.issuingPeriod')}
      </Typography>
      <Typography variant="body1">{getTranslation(`productionDevice.issuingPeriodType.${issuingPeriod}`)}</Typography>

      <Typography variant="body1" fontWeight="bold">
        {getTranslation('productionDevice.deviceOwners')}
      </Typography>
      <Typography variant="body1" marginBottom="8px">
        {owners.map((owner) => `${owner.name} (${owner.code})`).join(', ')}
      </Typography>
    </>
  )
}
