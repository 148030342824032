import { useTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { Button, Checkbox, FormControlLabel, IconButton, InputLabel, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../services/ecert-api'
import { useNavigate, useParams } from 'react-router-dom'
import { UserSettingDto, UserSettingDtoNotificationsItem } from '../../api/types'
import { isAccountAdmin, isAdmin } from '../../utils/permission-utils'
import { Check, Clear } from '@mui/icons-material'
import { EcertRoutes } from '../../ecert-routes'
import { deepClone, enqueueSuccessNotification } from '../../utils/utils'
import StyleGuide from '../../styles/StyleGuide'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { useLoginContext, useLoginUser } from '../../LoginContext'

export const UserNotificationEdit = () => {
  const loginUser = useLoginUser()
  const { refreshLoginContext } = useLoginContext()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [settings, setSettings] = useState<UserSettingDto | undefined>(undefined)

  useEffect(() => {
    if (loginUser.id === +params.userId!) {
      setSettings(deepClone(loginUser.settings))
    } else {
      if (isAdmin(loginUser) || isAccountAdmin(loginUser)) {
        ECERT_API.getUser(+params.organizationId!, +params.userId!).then((response) => {
          setSettings(response.data.settings)
        })
      }
    }
  }, [loginUser, params])

  useEffect(() => {
    document.title = `Ecert - ${t('title.userNotificationEdit')}`
  }, [t])

  const handleToggleSetting = (event, checked) => {
    setSettings((prevState) => {
      const newState = { ...prevState }
      let existingValueIndex = newState?.notifications?.findIndex((type) => type === event.target.value)
      existingValueIndex = existingValueIndex === undefined ? -1 : existingValueIndex
      if (checked && existingValueIndex === -1) {
        newState?.notifications?.push(event.target.value)
      } else if (!checked && existingValueIndex !== -1) {
        newState?.notifications?.splice(existingValueIndex, 1)
      }
      return newState
    })
  }

  const saveSettings = () => {
    if (!settings) {
      return
    }

    if (loginUser.id === +params.userId!) {
      ECERT_API.saveCurrentUserSettings(settings).then(() => {
        refreshLoginContext()
        navigate(EcertRoutes.user(params.organizationId, params.userId))
        enqueueSuccessNotification(<Typography>{t('form.valid.user.setting.notification.saved')}</Typography>)
      })
    } else {
      ECERT_API.saveUserSettings(+params.organizationId!, +params.userId!, settings).then(() => {
        navigate(EcertRoutes.user(params.organizationId, params.userId))
        enqueueSuccessNotification(<Typography>{t('form.valid.user.setting.notification.saved')}</Typography>)
      })
    }
  }

  return (
    <FlexColumn gap="16px">
      <Typography variant="h1">{t('user.setting.notification.edit.title')}</Typography>

      {settings?.notifications && (
        <FlexColumn gap="8px">
          <InputLabel>{t('user.setting.notification.edit.description')}</InputLabel>
          <FlexRow alignItems="center">
            <FormControlLabel
              checked={settings.notifications.includes(
                UserSettingDtoNotificationsItem.EXPIRING_CERTIFICATE_WARNING_EMAIL
              )}
              onChange={handleToggleSetting}
              value={UserSettingDtoNotificationsItem.EXPIRING_CERTIFICATE_WARNING_EMAIL}
              control={<Checkbox />}
              label={t('user.setting.notification.expiringCertificate.title')}
            />
            <Tooltip
              arrow
              placement="right"
              describeChild
              title={t('user.setting.notification.expiringCertificate.info')}
            >
              <IconButton>
                <HelpOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              </IconButton>
            </Tooltip>
          </FlexRow>
          <FlexRow alignItems="center">
            <FormControlLabel
              checked={settings.notifications.includes(
                UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL
              )}
              onChange={handleToggleSetting}
              value={UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL}
              control={<Checkbox />}
              label={t('user.setting.notification.expiringProductionDeviceValidity.title')}
            />
            <Tooltip
              arrow
              placement="right"
              describeChild
              title={t('user.setting.notification.expiringProductionDeviceValidity.info')}
            >
              <IconButton>
                <HelpOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              </IconButton>
            </Tooltip>
          </FlexRow>
          <FlexRow alignItems="center">
            <FormControlLabel
              checked={settings.notifications.includes(
                UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL
              )}
              onChange={handleToggleSetting}
              value={UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL}
              control={<Checkbox />}
              label={t('user.setting.notification.expiringProductionDeviceManagement.title')}
            />
            <Tooltip
              arrow
              placement="right"
              describeChild
              title={t('user.setting.notification.expiringProductionDeviceManagement.info')}
            >
              <IconButton>
                <HelpOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              </IconButton>
            </Tooltip>
          </FlexRow>
        </FlexColumn>
      )}

      <FlexRow gap="8px">
        <Button onClick={saveSettings} variant="contained" startIcon={<Check />}>
          {t('common.save')}
        </Button>
        <Button
          onClick={() => navigate(EcertRoutes.user(params.organizationId, params.userId))}
          variant="outlined"
          startIcon={<Clear />}
        >
          {t('user.cancel')}
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
