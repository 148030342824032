import { useState } from 'react'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import { Link, Typography } from '@mui/material'
import EcertTable, { IColumn } from '../../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { parseDate, transformDate } from '../../../../utils/utils'
import { SelectedCertificate, selectSelectedCertificates } from '../../../../features/store-slices/certificateSlice'
import { Check } from '@mui/icons-material'
import { useAppSelector } from '../../../../hooks'
import { useCountries } from '../../../../features/store-slices/countriesSlice'
import { CertificateLabelCode } from '../../../../features/certificate-label-code/CertificateLabelCode'

interface ISelectedCertificateBundlesProps {
  titleText: string
  bundleDescription: string
}

export const SelectedCertificateBundles = ({ titleText, bundleDescription }: ISelectedCertificateBundlesProps) => {
  const { t } = useTranslation()
  const [showBundles, setShowBundles] = useState<boolean>(false)
  const [countries] = useCountries()
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)

  const columns: IColumn[] = [
    {
      label: t('bundle.productionTime'),
      accessor: 'bundle.certificateProductionPeriodEnd',
      transformFn: transformDate('MM/YYYY'),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: SelectedCertificate, b: SelectedCertificate) => {
        const aDate = parseDate(a.bundle.certificateProductionPeriodEnd!)
        const bDate = parseDate(b.bundle.certificateProductionPeriodEnd!)

        if (aDate.isBefore(bDate, 'month')) {
          return -1
        } else if (aDate.isAfter(bDate, 'month')) {
          return 1
        } else {
          const aCommissioningDate = parseDate(a.bundle.productionDeviceDateOfCommissioning!)
          const bCommissioningDate = parseDate(b.bundle.productionDeviceDateOfCommissioning!)

          return aCommissioningDate.isBefore(bCommissioningDate)
            ? -1
            : aCommissioningDate.isAfter(bCommissioningDate)
            ? 1
            : 0
        }
      }
    },
    {
      label: t('account.filter.productionDevice'),
      accessor: 'bundle.productionDeviceName',
      sortable: true
    },
    {
      label: t('bundle.productionDeviceGsrn'),
      accessor: 'bundle.productionDeviceGsrn'
    },
    {
      label: t('account.filter.productionDeviceTechnology'),
      accessor: 'bundle.productionDeviceTechnologyName'
    },
    {
      label: t('account.filter.certificateIssuingCountry'),
      accessor: 'bundle.certificateIssuingCountry',
      transformFn: (countryId: string) => countries.find((c) => c.code === countryId)?.name
    },
    {
      label: t('account.filter.capacityLabel'),
      accessor: 'bundle.productionDeviceCapacity'
    },
    {
      label: t('bundle.productionDeviceDateOfCommissioning'),
      accessor: 'bundle.productionDeviceDateOfCommissioning',
      transformFn: transformDate('DD.MM.YYYY')
    },
    {
      label: t('account.mark'),
      accessor: (selectedCertificate: SelectedCertificate) => selectedCertificate.bundle.certificateLabelCodes,
      transformFn: (certificateIcsCodes?: string[]) => (
        <>{certificateIcsCodes && certificateIcsCodes.map((code) => <CertificateLabelCode key={code} code={code} />)}</>
      )
    },
    {
      label: t('account.investment'),
      accessor: (selectedCertificate: SelectedCertificate) => (
        <>
          {(selectedCertificate.bundle.certificateInvestmentSupportDescriptions?.length || 0) > 0 ||
          (selectedCertificate.bundle.certificateProductionSupportDescriptions?.length || 0) > 0 ? (
            <Check></Check>
          ) : null}
        </>
      )
    },
    {
      label: t('bundle.amount'),
      accessor: (selectedCertificate: SelectedCertificate) => selectedCertificate.selectedAmount
    }
  ]

  const toggleShowBundles = () => {
    setShowBundles(!showBundles)
  }

  return (
    <FlexColumn gap="10px">
      <Typography variant="h2" sx={{ marginTop: '16px' }}>
        {titleText}
      </Typography>
      <FlexRow alignItems="center" gap="5px" marginTop="16px">
        <Typography variant="body1">{bundleDescription}</Typography>
        <Link onClick={toggleShowBundles}>
          {t('cancellation.showBundles', { amount: selectedCertificates.length })}
        </Link>
      </FlexRow>
      {showBundles && <EcertTable data={[...selectedCertificates]} columns={columns} />}
    </FlexColumn>
  )
}
