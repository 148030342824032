import AddIcon from '@mui/icons-material/Add'
import { Button, ButtonProps } from '@mui/material'

interface IAddButtonProps extends ButtonProps {}

export const AddButton = (props: IAddButtonProps) => {
  return (
    <Button
      sx={{
        padding: '12px 24px 12px 24px'
      }}
      variant="contained"
      startIcon={<AddIcon />}
      {...props}
    >
      {props.children}
    </Button>
  )
}
