import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { BundleFilters } from './BundleFilters'
import { Button, Divider, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { FixedBottom } from '../../../features/fixed-containers/FixedBottom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  selectFilters,
  selectSelectedBundles,
  setSelectedBundle
} from '../../../features/store-slices/organizationAccountsSlice'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { calculateFullDaysUntil, parseDate, transformDate } from '../../../utils/utils'
import { Check, WarningAmber } from '@mui/icons-material'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import { BundleInfo } from './BundleInfo'
import { useCountries } from '../../../features/store-slices/countriesSlice'
import { Dictionary } from '@reduxjs/toolkit'
import { FilteredTotalBundleCount } from './FilteredTotalBundleCount'
import { FilteredTotalCertificateCount } from './FilteredTotalCertificateCount'
import { BottomContent } from './BottomContent'
import { bundleHasSupport, filteredBundles } from './filters'
import StyleGuide from '../../../styles/StyleGuide'
import { isInspector } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import { UnfilteredTotalCertificateCount } from './UnfilteredTotalCertificateCount'
import { CertificateLabelCode } from '../../../features/certificate-label-code/CertificateLabelCode'
import { AccountDetailsDto, CertificateBundleDto } from '../../../api/types'

interface IActionsProps {
  accountDetails: AccountDetailsDto
}

const bundleSortFn = (a: CertificateBundleDto, b: CertificateBundleDto) => {
  const dateA = parseDate(a.certificateProductionPeriodEnd!)
  const dateB = parseDate(b.certificateProductionPeriodEnd!)
  if (dateA.isBefore(dateB, 'days')) {
    return -1
  } else if (dateB.isBefore(dateA, 'days')) {
    return 1
  } else {
    return 0
  }
}

export const Actions = ({ accountDetails }: IActionsProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [countries] = useCountries()
  const loginUser = useLoginUser()

  const columns: IColumn[] = [
    {
      label: t('account.filter.productionTime'),
      accessor: (obj: CertificateBundleDto) => (
        <FlexRow alignItems="center" gap="8px">
          <ExpirationIndicator obj={obj} />
          <Typography variant="body1">{parseDate(obj.certificateProductionPeriodEnd!).format('MM/YYYY')}</Typography>
        </FlexRow>
      ),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: bundleSortFn
    },
    {
      label: t('account.filter.productionDevice'),
      accessor: 'productionDeviceName',
      sortable: true,
      role: 'rowheader'
    },
    {
      label: t('bundle.productionDeviceGsrn'),
      accessor: 'productionDeviceGsrn'
    },
    {
      label: t('account.filter.productionDeviceTechnology'),
      accessor: 'productionDeviceTechnologyName'
    },
    {
      label: t('account.filter.certificateIssuingCountry'),
      accessor: 'certificateIssuingCountry',
      transformFn: (countryId: string) => countries.find((c) => c.code === countryId)?.name
    },
    {
      label: t('account.filter.capacityLabel'),
      accessor: 'productionDeviceCapacity'
    },
    {
      label: t('bundle.productionDeviceDateOfCommissioning'),
      accessor: 'productionDeviceDateOfCommissioning',
      transformFn: transformDate('DD.MM.YYYY')
    },
    {
      label: t('account.labels'),
      accessor: (obj: CertificateBundleDto) => obj.certificateLabelCodes,
      transformFn: (certificateLabelCodes?: string[]) => (
        <>
          {certificateLabelCodes &&
            certificateLabelCodes.map((code) => <CertificateLabelCode key={code} code={code} />)}
        </>
      ),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: CertificateBundleDto) => (a.certificateLabelCodes && a.certificateLabelCodes.length > 0 ? 1 : -1)
    },
    {
      label: t('account.investment'),
      accessor: (obj: CertificateBundleDto) => obj,
      transformFn: (obj: CertificateBundleDto) => <>{bundleHasSupport(obj) ? <Check></Check> : null}</>
    },
    {
      label: t('account.amount'),
      accessor: (obj: CertificateBundleDto) => obj,
      transformFn: (obj: CertificateBundleDto) => (
        <FlexRow gap="5px" alignItems="center">
          <Typography>{obj.amount}</Typography>
          {accountDetails?.lockedStatus?.locked || isInspector(loginUser) ? undefined : (
            <IconButton aria-label="amount-select" onClick={() => handleBundle(obj)}>
              <ArrowCircleRightOutlinedIcon />
            </IconButton>
          )}
        </FlexRow>
      )
    },
    ...(accountDetails?.lockedStatus?.locked || isInspector(loginUser)
      ? []
      : [
          {
            label: t('transaction.amount'),
            accessor: (obj: CertificateBundleDto) => obj,
            transformFn: (obj: CertificateBundleDto) => <SelectedBundleInput bundle={obj} />
          }
        ]),
    {
      label: t('account.info'),
      accessor: (obj: CertificateBundleDto) => <BundleInfo bundle={obj} />
    }
  ]

  const ExpirationIndicator = ({ obj }: { obj: CertificateBundleDto }) => {
    const daysRemaining = calculateFullDaysUntil(parseDate(obj.certificateProductionPeriodEnd!).add(1, 'year'))
    const expirationDaysLimit = 30

    return (
      <>
        {daysRemaining >= 0 && daysRemaining <= expirationDaysLimit ? (
          <>
            <Tooltip
              arrow
              placement="right"
              describeChild
              title={
                daysRemaining === 0 ? t('account.daysValidNight') : t('account.daysValid', { days: daysRemaining })
              }
            >
              <IconButton>
                <WarningAmber sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        )}
      </>
    )
  }

  const SelectedBundleInput = (props: { bundle: CertificateBundleDto }) => {
    const selectedBundles = useAppSelector<Dictionary<number>>(selectSelectedBundles)

    const handleChange = (event) => {
      dispatch(setSelectedBundle({ id: `${props.bundle.id}`, count: event.target.value }))
    }

    return (
      <TextField
        type="number"
        value={selectedBundles[`${props.bundle.id}`] || ''}
        id={`${props.bundle.id}`}
        className="selected-bundle-input"
        inputProps={{
          max: props.bundle.amount,
          'aria-label': `selected-certificates-input-${props.bundle.id}`
        }}
        onChange={handleChange}
      />
    )
  }

  const initialSortedData = () => {
    const data = accountDetails.activeCertificateBundles!.slice()
    data.sort(bundleSortFn)
    return data
  }

  const TableResultText = (): JSX.Element => {
    return (
      <FlexRow>
        {t('table.showingResults')} <FilteredTotalBundleCount accountDetails={accountDetails!} />
        {'/'}
        {accountDetails?.activeCertificateBundles?.length} {t('account.amountBundles')}
        {' ('}
        <FilteredTotalCertificateCount accountDetails={accountDetails} />
        {'/'}
        <UnfilteredTotalCertificateCount accountDetails={accountDetails!} /> {t('account.bundleAmount')}
        {') '}
      </FlexRow>
    )
  }

  const TableContainer = () => {
    const filters = useAppSelector(selectFilters)
    const data = initialSortedData()

    return (
      <>
        {accountDetails?.activeCertificateBundles && (
          <EcertTable
            tableResultString={TableResultText()}
            unfilteredResultsLength={data.length}
            data={filteredBundles(filters, data, t)}
            columns={columns}
          />
        )}
      </>
    )
  }

  const SelectOldestButton = () => {
    const filters = useAppSelector(selectFilters)

    const handleSelectOldest = (filters) => {
      let remainingBundleAmount = document.getElementById('select-oldest-bundles-input')?.['value']
      filteredBundles(filters, initialSortedData(), t).every((bundle) => {
        if (!bundle.amount) return false

        if (remainingBundleAmount <= bundle.amount) {
          dispatch(setSelectedBundle({ id: `${bundle.id}`, count: remainingBundleAmount }))
          remainingBundleAmount = 0
          return true
        } else {
          dispatch(setSelectedBundle({ id: `${bundle.id}`, count: bundle.amount }))
          remainingBundleAmount -= bundle.amount
          return true
        }
      })
    }

    return (
      <Button variant="contained" onClick={() => handleSelectOldest(filters)}>
        {t('account.select')}
      </Button>
    )
  }

  const SelectAllButton = () => {
    const filters = useAppSelector(selectFilters)

    const handleSelectAll = (filters) => {
      filteredBundles(filters, accountDetails?.activeCertificateBundles!, t).forEach((bundle) => {
        dispatch(setSelectedBundle({ id: `${bundle.id}`, count: bundle.amount || 0 }))
      })
    }

    return (
      <Button variant="outlined" onClick={() => handleSelectAll(filters)}>
        {t('account.selectAll')}
      </Button>
    )
  }

  const handleBundle = (bundle: CertificateBundleDto) => {
    dispatch(setSelectedBundle({ id: `${bundle.id}`, count: bundle.amount || 0 }))
  }

  const handleDeselectAll = () => {
    accountDetails?.activeCertificateBundles?.forEach((bundle) => {
      dispatch(setSelectedBundle({ id: `${bundle.id}`, count: 0 }))
    })
  }

  return (
    <>
      <FlexColumn gap="10px">
        <Typography variant="label" fontWeight="bold">
          {t('account.filterBundles')}
        </Typography>
        <FlexRow gap="10px" alignItems="flex-start">
          {accountDetails && <BundleFilters accountDetails={accountDetails} />}
        </FlexRow>
        <Divider sx={{ margin: '8px 0 5px 0' }} />
        <FlexRow justifyContent="space-between">
          <FlexColumn gap="10px" alignItems="center">
            {!isInspector(loginUser) && !accountDetails.lockedStatus?.locked && (
              <>
                <Typography alignSelf="flex-start" variant="label" fontWeight="bold">
                  {t('account.selectOldest')}
                </Typography>
                <FlexRow gap="10px">
                  <TextField
                    inputProps={{
                      'aria-label': 'select-oldest-bundles-input'
                    }}
                    id="select-oldest-bundles-input"
                    sx={{
                      '& .MuiInputBase-root': {
                        height: 36.5
                      }
                    }}
                  />
                  <SelectOldestButton />
                  <SelectAllButton />
                  <Button variant="outlined" onClick={handleDeselectAll}>
                    {t('account.deselectAll')}
                  </Button>
                </FlexRow>
              </>
            )}
          </FlexColumn>
        </FlexRow>
        <Divider sx={{ paddingTop: '8px', marginBottom: '16px' }} />
        <TableContainer />
      </FlexColumn>

      {accountDetails?.lockedStatus?.locked || isInspector(loginUser) ? undefined : (
        <FixedBottom justifyContent="flex-end">
          <BottomContent accountDetails={accountDetails} />
        </FixedBottom>
      )}
    </>
  )
}
