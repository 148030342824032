import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledRightAligned, StyledTable, StyledTableHeaderContainer } from '../../styles/containers/TableContainers'
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { ECERT_API } from '../../services/ecert-api'
import { AibMemberDto } from '../../api/types'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import StyleGuide from '../../styles/StyleGuide'
import AddIcon from '@mui/icons-material/Add'
import CountrySelect from '../../features/country-select/CountrySelect'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { useCountries } from '../../features/store-slices/countriesSlice'
import { isValidationError } from '../../utils/utils'
import { useAppDispatch } from "../../hooks"
import { fetchAibHubCountries, fetchCancellationCountries } from "../../features/store-slices/countriesSlice";

export default function AibMembers() {
  const { t } = useTranslation()
  const [aibMembers, setAibMembers] = useState<AibMemberDto[] | any>()
  const [isEditMode, setIsEditMode] = useState(false)
  const editableData = useRef<AibMemberDto[]>(aibMembers)
  const [editableTable, setEditableTable] = useState<AibMemberDto[]>()
  const [countries] = useCountries()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const getIcon = (isTrue) => {
    return isTrue ? (
      <CheckOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_SUCCESS }} />
    ) : (
      <CloseOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
    )
  }

  const TableCheckBox = ({ data, accessor, index }: { data: AibMemberDto | any; accessor: string; index: number }) => {
    const [checked, setChecked] = useState(data[accessor])

    const handleCheckBoxChange = (e) => {
      const tempData = data
      tempData[accessor] = e.target.checked
      setChecked(e.target.checked)

      if (editableData && editableData) editableData[index] = tempData
    }

    return <Checkbox checked={checked} onChange={handleCheckBoxChange} />
  }

  const PropertyEdit = (
    data: AibMemberDto | any,
    accessor: string,
    index: number,
    maxLength?: number,
    checkboxOrSelect?: 'checkbox' | 'select'
  ) => {
    const handleTextFieldChange = (e) => {
      const tempData = data
      tempData[accessor] = e.target.value

      if (editableData && editableData) editableData[index] = tempData
    }

    if (!checkboxOrSelect)
      return (
        <TextField inputProps={{ maxLength: maxLength }} defaultValue={data[accessor]} onBlur={handleTextFieldChange} />
      )

    if (checkboxOrSelect === 'checkbox') {
      return <TableCheckBox data={data} accessor={accessor} index={index} />
    }

    if (checkboxOrSelect === 'select' && countries)
      return <CountrySelect valueToShow={data[accessor]} onChangeSelect={(newValue) => (data[accessor] = newValue)} />
  }

  const columns: IColumn[] = [
    {
      label: t('aibMember.code'),
      accessor: (data: AibMemberDto) => data,
      sortable: true,
      sortByOrder: 'asc',
      transformFn: (data: AibMemberDto) => data.code,
      whenCreatingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'code', index, 2),
      sortFn: (a: AibMemberDto, b: AibMemberDto) => {
        if (!a.code) return 1
        if (!b.code) return 0
        return a.code!.localeCompare(b.code!)
      }
    },
    {
      label: t('aibMember.name'),
      accessor: (data: AibMemberDto) => data,
      sortable: true,
      sortByOrder: 'asc',
      transformFn: (data: AibMemberDto) => data.name,
      whenEditingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'name', index, 50),
      sortFn: (a: AibMemberDto, b: AibMemberDto) => {
        if (!a.name) return 1
        if (!b.name) return 0
        return a.name!.localeCompare(b.name!)
      }
    },
    {
      label: t('aibMember.country'),
      accessor: (data: AibMemberDto) => data,
      sortable: true,
      transformFn: (data: AibMemberDto) => data.country?.name,
      sortByOrder: 'asc',
      whenCreatingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'country', index, undefined, 'select'),
      sortFn: (a: AibMemberDto, b: AibMemberDto) => {
        if (!a.country) return 1
        if (!b.country) return 0
        return a.country!.name!.localeCompare(b.country!.code!)
      }
    },
    {
      label: t('aibMember.domainCode'),
      accessor: (data: AibMemberDto) => data,
      transformFn: (data: AibMemberDto) => data.domainCode,
      sortable: true,
      sortByOrder: 'asc',
      whenEditingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'domainCode', index, 3),
      sortFn: (a: AibMemberDto, b: AibMemberDto) => {
        if (!a.domainCode) return 1
        if (!b.domainCode) return 0
        return a.domainCode!.localeCompare(b.domainCode!)
      }
    },
    {
      label: t('aibMember.domainName'),
      accessor: (data: AibMemberDto) => data,
      sortable: false,
      transformFn: (data: AibMemberDto) => data.domainName,
      whenEditingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'domainName', index, 100)
    },
    {
      label: t('aibMember.registryGs1Code'),
      accessor: (data: AibMemberDto) => data,
      transformFn: (data: AibMemberDto) => data.registryGs1Code,
      sortable: false,
      whenEditingFn: (data: AibMemberDto, index: number) => PropertyEdit(data, 'registryGs1Code', index, 30)
    },
    {
      label: t('aibMember.issuerCertificatesCancellable'),
      accessor: (data: AibMemberDto) => data,
      sortable: false,
      transformFn: (data: AibMemberDto) => getIcon(data.issuerCertificatesCancellable),
      tooltip: t('aibMember.issuerCertificatesInfo'),
      whenEditingFn: (data: AibMemberDto, index: number) =>
        PropertyEdit(data, 'issuerCertificatesCancellable', index, undefined, 'checkbox')
    },
    {
      label: t('aibMember.incomingExternalTransferBlockActive'),
      accessor: (data: AibMemberDto) => data,
      sortable: false,
      transformFn: (data: AibMemberDto) => getIcon(data.incomingExternalTransferBlockActive),
      tooltip: t('aibMember.incomingExternalTransferBlockInfo'),
      whenEditingFn: (data: AibMemberDto, index: number) =>
        PropertyEdit(data, 'incomingExternalTransferBlockActive', index, undefined, 'checkbox')
    },
    {
      label: '',
      accessor: 'code',
      transformFn: () => '',
      sortable: false,
      whenEditingFn: (code: string) => (
        <Button
          startIcon={<HighlightOffOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
          onClick={() => {
            editableData.current.splice(
              editableData.current.findIndex((v) => v.code === code),
              1
            )
            setEditableTable([...editableData.current])
          }}
          sx={{ color: StyleGuide.constants.COLOR_TEXT_PRIMARY }}
        >
          {t('common.remove')}
        </Button>
      )
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('aibMember.title')}`
  }, [t])

  useEffect(() => {
    loadTableData()
  }, [])

  const loadTableData = () => {
    ECERT_API.getAibMembers().then((response) => {
      setAibMembers(response.data)
      editableData.current = response.data
      setIsEditMode(false)
    })
  }

  useEffect(() => {
    if (isEditMode) setEditableTable([...aibMembers])

    // eslint-disable-next-line
  }, [isEditMode])

  const handleEdit = () => {
    editableData.current = [...aibMembers]
    setIsEditMode(true)
  }

  const save = () => {
    if (editableData)
      ECERT_API.setAibMembers(editableData.current)
        .then(() => {
          loadTableData()
          dispatch(fetchAibHubCountries())
          dispatch(fetchCancellationCountries())
          enqueueSnackbar(t('form.valid.aibMember.updated'), snacky.successOpts)
        })
        .catch((error) => {
          if (isValidationError(error)) {
            enqueueSnackbar(t('common.statusError'), snacky.errorOpts)
          }
        })
        .finally(() => setIsModalOpen(false))
  }

  const cancel = () => {
    loadTableData()
  }

  const addRow = () => {
    const newRow: any = {}
    if (editableData) {
      editableData.current.push(newRow)
      setEditableTable([...editableData.current])
    }
  }

  const ButtonContainer = () => {
    const { t } = useTranslation()
    return (
      <Box>
        <Button sx={{ marginBottom: '24px' }} variant="outlined" startIcon={<AddIcon />} onClick={addRow}>
          {t('aibMember.addRow')}
        </Button>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <Button variant="contained" startIcon={<CheckOutlinedIcon />} onClick={() => setIsModalOpen(true)}>
            {t('common.save')}
          </Button>
          <Button variant="outlined" startIcon={<CloseOutlinedIcon />} onClick={cancel}>
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    )
  }

  const ModalButtons = () => {
    return (
      <Box sx={{ display: 'flex', gap: '14px' }}>
        <Button variant="contained" startIcon={<CheckOutlinedIcon />} onClick={save}>
          {t('common.yes')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<CloseOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
          onClick={() => setIsModalOpen(false)}
        >
          {t('common.cancel')}
        </Button>
      </Box>
    )
  }

  return (
    <>
      {aibMembers && !isEditMode && (
        <StyledTable gap="10px">
          <StyledTableHeaderContainer>
            <Typography variant="h1" sx={{ marginBottom: '16px' }}>
              {t('aibMember.title')}
            </Typography>
            <StyledRightAligned>
              <Button sx={{ height: '50px' }} variant="contained" startIcon={<EditOutlinedIcon />} onClick={handleEdit}>
                {t('aibMember.editMembers')}
              </Button>
            </StyledRightAligned>
          </StyledTableHeaderContainer>
          <EcertTable data={aibMembers} columns={columns} mode={'VIEW'}></EcertTable>
        </StyledTable>
      )}

      {editableTable && isEditMode && (
        <StyledTable gap="10px">
          <StyledTableHeaderContainer>
            <Typography variant="h1" sx={{ marginBottom: '16px' }}>
              {t('aibMember.title')}
            </Typography>
            <StyledRightAligned>
              <Button sx={{ height: '50px' }} variant="contained" startIcon={<EditOutlinedIcon />} onClick={handleEdit}>
                {t('aibMember.editMembers')}
              </Button>
            </StyledRightAligned>
          </StyledTableHeaderContainer>
          <EcertTable data={editableTable} columns={columns} mode={'EDIT'}></EcertTable>
          <ButtonContainer />
        </StyledTable>
      )}
      <CustomDialog
        isOpen={isModalOpen}
        title={t('aibMember.confirmDialog.title')}
        handleClose={() => setIsModalOpen(false)}
        buttons={ModalButtons()}
      >
        {t('aibMember.confirmDialog.message')}
      </CustomDialog>
    </>
  )
}
