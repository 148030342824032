import { Button } from '@mui/material'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import CancelButton from '../../../../features/buttons/CancelButton'
import { FlexColumn } from '../../../../styles/containers/FlexContainers'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { useForm } from 'react-hook-form'
import {
  ProductionDeviceStateChangeInfoSchemaType,
  createProductionDeviceStateChangeInfoSchema
} from '../../../../validation/production-device-state-change-info-schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ControlledTextField from '../../../../features/inputs/ControlledTextField'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../../services/ecert-api'
import { CodeGeneratorDto } from '../../../../api/types'
import ControlledSelect from '../../../../features/inputs/ControlledSelect'

interface StatusChangeConfirmDialogProps {
  title: string
  confirmButtonText: string
  registrationFields: boolean
  open: boolean
  handleConfirm: (description: string, productionDeviceCode: string | undefined, gs1CodeId: number | undefined) => void
  handleCancel: () => void
}

export default function StatusChangeConfirmDialog({
  title,
  confirmButtonText,
  registrationFields,
  open,
  handleConfirm,
  handleCancel
}: StatusChangeConfirmDialogProps) {
  const { t } = useTranslation()

  const form = useForm<ProductionDeviceStateChangeInfoSchemaType>({
    resolver: zodResolver(createProductionDeviceStateChangeInfoSchema()),
    mode: 'all',
    defaultValues: {
      registration: registrationFields
    }
  })

  const [gs1Codes, setGs1Codes] = useState<CodeGeneratorDto[]>([])

  useEffect(() => {
    if (registrationFields) {
      ECERT_API.getCodeGenerators().then((response) => setGs1Codes(response.data))
    }
  }, [registrationFields])

  useEffect(() => {
    if (!open) {
      form.reset()
    }
  }, [open, form])

  return (
    <CustomDialog
      isOpen={open}
      title={title}
      handleClose={handleCancel}
      buttons={
        <>
          <Button
            variant="contained"
            disabled={!form.formState.isValid}
            onClick={() => {
              const values = form.getValues()
              handleConfirm(values.description ?? '', values.code, values.gs1CodeId)
            }}
            startIcon={<CheckOutlinedIcon />}
          >
            {confirmButtonText}
          </Button>
          <CancelButton handleCancel={handleCancel} />
        </>
      }
    >
      <FlexColumn gap="20px">
        {registrationFields && (
          <>
            <ControlledTextField
              id="code"
              name="code"
              label={t('productionDevice.code')}
              required
              control={form.control}
            />
            <ControlledSelect
              id="gs1CodeId"
              name="gs1CodeId"
              label={t('productionDevice.gs1Code')}
              required
              placeholder={t('common.choose')}
              items={gs1Codes.map((code) => ({
                key: code.id!,
                value: code.id!,
                label: code.label!
              }))}
              control={form.control}
            />
          </>
        )}
        <ControlledTextField
          id="description"
          name="description"
          label={t('productionDevice.description.description')}
          multiline
          minRows={6}
          characterLimit={300}
          control={form.control}
        />
      </FlexColumn>
    </CustomDialog>
  )
}
