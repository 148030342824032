import { useEffect } from 'react'
import { ProductionDeviceLabelReportDto } from '../../../api/types'
import { Box, Typography } from '@mui/material'
import { EcertRoutes } from '../../../ecert-routes'
import { StyledRightAligned, StyledTable, StyledTableHeaderContainer } from '../../../styles/containers/TableContainers'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { useLabelTags } from '../../../features/store-slices/reportSlice'
import { parseDate, transformDate } from '../../../utils/utils'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { FileExcelOutline } from 'mdi-material-ui'
import { ECERT_API } from '../../../services/ecert-api'
import { FlexColumn } from '../../../styles/containers/FlexContainers'

export function LabelTags() {
  const { t } = useTranslation()
  const [labelTags] = useLabelTags()

  const columns: IColumn[] = [
    {
      label: t('labelReport.deviceName'),
      accessor: 'deviceName',
      sortable: true,
      sortByOrder: 'asc',
      accessibilityHrefFn: (obj: ProductionDeviceLabelReportDto) =>
        EcertRoutes.productionDevice(obj.organizationId, obj.deviceId),
      role: 'rowheader'
    },
    {
      label: t('labelReport.deviceGsrn'),
      accessor: 'gsrn',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('labelReport.organization'),
      accessor: 'organization',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('labelReport.validityEndDate'),
      accessor: 'validityEndDate',
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceLabelReportDto, b: ProductionDeviceLabelReportDto) => {
        if (!a.validityEndDate) return 1
        if (!b.validityEndDate) return -1
        return parseDate(a.validityEndDate).isBefore(b.validityEndDate, 'day') ? -1 : 1
      },
      transformFn: transformDate('DD.MM.YYYY')
    },
    {
      label: t('labelReport.fuel'),
      accessor: (data: ProductionDeviceLabelReportDto) => (
        <FlexColumn>{data.fuels?.map((fuel, idx) => <Box key={`${fuel.code} + ${idx}`}>{fuel.code}</Box>)}</FlexColumn>
      ),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceLabelReportDto, b: ProductionDeviceLabelReportDto) => {
        return a.fuels![0].code!.localeCompare(b.fuels![0].code!)
      }
    },
    {
      label: t('labelReport.labelType'),
      accessor: (data: ProductionDeviceLabelReportDto) => (
        <FlexColumn>{data.fuels?.map((fuel, idx) => <Box key={idx}>{fuel.label}</Box>)}</FlexColumn>
      ),
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('labelReport.labelValidityEnd'),
      accessor: (data: ProductionDeviceLabelReportDto) => (
        <FlexColumn>
          {data.fuels?.map((fuel, idx) => (
            <Box key={idx}>{parseDate(fuel.labelValidityEnd!).format('DD.MM.YYYY')}</Box>
          ))}
        </FlexColumn>
      ),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceLabelReportDto, b: ProductionDeviceLabelReportDto) => {
        const aStart = parseDate(a.fuels![0].labelValidityEnd!)
        const bStart = parseDate(b.fuels![0].labelValidityEnd!)

        return aStart.isBefore(bStart, 'day') ? -1 : 1
      }
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.labelReport')}`
  }, [t])

  return (
    <>
      <StyledTable gap="10px">
        <StyledTableHeaderContainer>
          <Typography id="title-report-label" variant="h1">
            {t('labelReport.title')}
          </Typography>
          <StyledRightAligned>
            <DownloadButton
              fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.LABEL_REPORT}
              fileName={`${t('labelReport.title')}.xlsx`}
              startIcon={<FileExcelOutline />}
            >
              {t('labelReport.export')}
            </DownloadButton>
          </StyledRightAligned>
        </StyledTableHeaderContainer>
        <EcertTable ariaDescribedBy="title-report-label" data={labelTags} columns={columns}></EcertTable>
      </StyledTable>
    </>
  )
}
