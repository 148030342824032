/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type CertificateBundleDtoCertificateSupportFlag = typeof CertificateBundleDtoCertificateSupportFlag[keyof typeof CertificateBundleDtoCertificateSupportFlag];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CertificateBundleDtoCertificateSupportFlag = {
  NO_SUPPORT: 'NO_SUPPORT',
  INVESTMENT_SUPPORT: 'INVESTMENT_SUPPORT',
  PRODUCTION_SUPPORT: 'PRODUCTION_SUPPORT',
  INVESTMENT_AND_PRODUCTION_SUPPORT: 'INVESTMENT_AND_PRODUCTION_SUPPORT',
  UNKNOWN_SUPPORT: 'UNKNOWN_SUPPORT',
} as const;
