import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { useState } from 'react'

interface IAsyncButtonProps extends ButtonProps {
  asyncOperation?: () => Promise<any>
}

export default function AsyncButton(props: IAsyncButtonProps) {
  const [loading, setLoading] = useState(false)
  const buttonProps = Object.assign({}, props)
  delete buttonProps.asyncOperation

  const handleClick = () => {
    if (!props.asyncOperation) return

    setLoading(true)
    props.asyncOperation().finally(() => {
      setLoading(false)
    })
  }

  return (
    <Button
      {...buttonProps}
      onClick={handleClick}
      disabled={props.disabled || loading}
      startIcon={loading ? <CircularProgress size={20} /> : props.startIcon}
    >
      {props.children}
    </Button>
  )
}
