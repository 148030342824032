import { z, ZodType } from 'zod'
import { getTranslation } from '../utils/utils'

export const formPrefix = 'form.invalid'

export function stringFromLengthRange(from: number, to: number, translationPrefix: string) {
  const str = z
    .string({
      required_error: getTranslation(`${translationPrefix}.required`)
    })
    .max(to, { message: getTranslation(`${translationPrefix}.maxlength`) })
  return from === 1
    ? str.refine((text) => text.length > 0, {
        message: getTranslation(`${translationPrefix}.required`)
      })
    : str.min(from, {
        message: getTranslation(`${translationPrefix}.minlength`)
      })
}

export function stringOfForm(regex: string, errorMsg: string) {
  return z.string().regex(new RegExp(regex), { message: getTranslation(errorMsg) })
}

export function date(translationPrefix: string) {
  return z
    .string({
      invalid_type_error: getTranslation(`${translationPrefix}.required`),
      required_error: getTranslation(`${translationPrefix}.required`)
    })
    .regex(new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/), {
      message: getTranslation(`${formPrefix}.date.format`)
    })
}

export function nullable(zodType: ZodType) {
  return z.nullable(zodType)
}

export function createAttachmentSchema() {
  return z.object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string(),
    content: z.custom<Blob>().nullable()
  })
}
