import { useAppSelector } from '../../../hooks'
import { selectFilters } from '../../../features/store-slices/organizationAccountsSlice'
import { AccountDetailsDto } from '../../../api/types'
import { filteredBundles } from './filters'
import { useTranslation } from 'react-i18next'

interface IFilteredTotalBundleCountProps {
  accountDetails: AccountDetailsDto
}

export const FilteredTotalBundleCount = ({ accountDetails }: IFilteredTotalBundleCountProps) => {
  const filters = useAppSelector(selectFilters)
  const { t } = useTranslation()

  const totalBundleCount = (): number => {
    if (!accountDetails?.activeCertificateBundles) return 0

    return filteredBundles(filters, accountDetails.activeCertificateBundles, t).length
  }

  return <>{totalBundleCount()}</>
}
