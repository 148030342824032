import { Box, Button, CircularProgress, FormControlLabel, InputLabel, Link, Radio, Typography } from '@mui/material'
import {
  CancellationDetailsUpdateDto,
  CancellationTransactionDto,
  CancellationTransactionDtoBeneficiaryType,
  CancellationTransactionDtoConversionRegistry,
  CountryView,
  TransactionWithFullDetailsDto
} from '../../../../api/types'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Check, InfoOutlined } from '@mui/icons-material'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import { ECERT_API } from '../../../../services/ecert-api'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  useAibHubCountries,
  useCancellationCountries,
  useCountries
} from '../../../../features/store-slices/countriesSlice'
import dayjs, { Dayjs } from 'dayjs'
import { selectSelectedCertificates, unSelectCertificates } from '../../../../features/store-slices/certificateSlice'
import { enqueueSuccessNotification, isValidationError, range } from '../../../../utils/utils'
import CancelButton from '../../../../features/buttons/CancelButton'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import { EcertRoutes } from '../../../../ecert-routes'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import * as snacky from '../../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useNavigate } from 'react-router-dom'
import ControlledTextField from '../../../../features/inputs/ControlledTextField'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  CancellationTarget,
  CertificateCancellationSchemaType,
  Supplier,
  createCertificateCancellationSchema
} from '../../../../validation/certificate-cancellation-schema'
import CheckIcon from '@mui/icons-material/Check'
import { ControlledAutocomplete } from '../../../../features/inputs/ControlledAutocomplete'
import { ControlledDateRangePicker } from '../../../../features/inputs/ControlledDateRangePicker'
import ControlledSelect from '../../../../features/inputs/ControlledSelect'
import { ControlledRadioGroup } from '../../../../features/inputs/ControlledRadioGroup'
import { InfoPopper } from '../../../../features/info-popper/InfoPopper'
import { cleanAfterSubmit } from '../../account-utils'
import { isAdmin } from '../../../../utils/permission-utils'
import { useLoginUser } from '../../../../LoginContext'

const FINLAND_COUNTRY_NAME = 'Finland'

const FINLAND_COUNTRY_ID = 4929

function findCountryByName(name: string, countries: CountryView[]) {
  return countries.find((country) => country.name === name)
}

const getExistingCancellationTarget = (
  organizationCode: string,
  existingTransaction: TransactionWithFullDetailsDto
) => {
  if (existingTransaction.cancellationDetailsConversion) {
    return CancellationTarget.CONVERSION
  }
  if (existingTransaction.cancellationDetailsBeneficiaryCode === organizationCode) {
    return CancellationTarget.ORGANIZATION
  }
  return CancellationTarget.COMPANY
}

interface ICancellationRecipientProps {
  organizationId: number
  accountId: number
  domesticCancellationPermitted: boolean
  existingTransaction?: TransactionWithFullDetailsDto
  cancelCallback?: () => void
}

export const CancellationRecipient = ({
  organizationId,
  accountId,
  domesticCancellationPermitted,
  existingTransaction,
  cancelCallback
}: ICancellationRecipientProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [countries] = useCountries()
  const [cancellationCountries] = useCancellationCountries()
  const [aibHubCountries] = useAibHubCountries()
  const dispatch = useAppDispatch()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const selectedCertificates = useAppSelector(selectSelectedCertificates)
  const [aibCountryCancellationsAllowed, setAibCountryCancellationsAllowed] = useState(false)
  const [selectableYears, setSelectableYears] = useState<number[]>([])
  const [organization, setOrganization] = useState<{ id: number; name: string; code: string }>()
  const [cancellationEnabled, setCancellationEnabled] = useState(domesticCancellationPermitted)
  const form = useForm<CertificateCancellationSchemaType>({
    resolver: zodResolver(createCertificateCancellationSchema()),
    mode: 'all',
    defaultValues: {
      cancellationTarget: CancellationTarget.COMPANY,
      beneficiaryType: CancellationTransactionDtoBeneficiaryType.SUPPLIER,
      supplier: Supplier.FINLAND,
      countryOfConsumption: FINLAND_COUNTRY_ID,
      conversionRegistry: CancellationTransactionDtoConversionRegistry.ENERGY_AUTHORITY,
      consumptionPeriodStart: '',
      consumptionPeriodEnd: ''
    }
  })

  const cancellationTarget = form.watch('cancellationTarget')
  const consumptionYear = form.watch('consumptionYear')
  const consumptionPeriodStart = form.watch('consumptionPeriodStart')
  const consumptionPeriodEnd = form.watch('consumptionPeriodEnd')
  const supplier = form.watch('supplier')
  const countryOfConsumption = form.watch('countryOfConsumption')

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    ECERT_API.getAIBCountryCancellationsAllowed().then((response) => {
      setAibCountryCancellationsAllowed(response.data)
    })
  }, [])

  useEffect(() => {
    if (isAdmin(loginUser)) {
      ECERT_API.getOrganization(organizationId).then((response) => {
        setOrganization({
          id: response.data.id!,
          name: response.data.name,
          code: response.data.code
        })
      })
    } else {
      setOrganization({
        id: loginUser.organizationId!,
        name: loginUser.organizationName!,
        code: loginUser.organizationCode!
      })
    }
  }, [organizationId, loginUser])

  useEffect(() => {
    if (existingTransaction) {
      if (organization) {
        form.setValue('cancellationTarget', getExistingCancellationTarget(organization.code, existingTransaction))
      }
      form.setValue(
        'beneficiaryType',
        existingTransaction.cancellationDetailsBeneficiaryType ?? CancellationTransactionDtoBeneficiaryType.SUPPLIER
      )
      form.setValue('beneficiaryName', existingTransaction.cancellationDetailsBeneficiaryName ?? '')
      form.setValue('organizationCode', existingTransaction.cancellationDetailsBeneficiaryCode ?? '')
      form.setValue(
        'conversionRegistry',
        existingTransaction.cancellationDetailsConversionRegistry ??
          CancellationTransactionDtoConversionRegistry.ENERGY_AUTHORITY
      )
      form.setValue('conversionDeviceGsrn', existingTransaction.cancellationDetailsConversionDeviceGsrn ?? '')
      form.setValue('conversionDeviceName', existingTransaction.cancellationDetailsConversionDeviceName ?? '')
      form.setValue(
        'supplier',
        (() => {
          const selectedCountryName = existingTransaction.cancellationDetailsCountryOfConsumption
          if (!selectedCountryName || selectedCountryName === FINLAND_COUNTRY_NAME) {
            return Supplier.FINLAND
          }
          if (findCountryByName(selectedCountryName, cancellationCountries)) {
            return Supplier.NOT_AIB
          }
          if (findCountryByName(selectedCountryName, aibHubCountries)) {
            return Supplier.AIB
          }
          return undefined
        })()
      )
      form.setValue(
        'countryOfConsumption',
        existingTransaction.cancellationDetailsCountryOfConsumption
          ? findCountryByName(existingTransaction.cancellationDetailsCountryOfConsumption, countries)?.id ??
              FINLAND_COUNTRY_ID
          : FINLAND_COUNTRY_ID
      )
      const now = dayjs()
      if (existingTransaction.cancellationDetailsConsumptionPeriodStart) {
        form.setValue(
          'consumptionYear',
          dayjs(existingTransaction.cancellationDetailsConsumptionPeriodStart).year() ?? now.year()
        )
        form.setValue(
          'consumptionPeriodStart',
          existingTransaction.cancellationDetailsConsumptionPeriodStart ?? now.startOf('year').format('YYYY-MM-DD')
        )
      }
      if (existingTransaction.cancellationDetailsConsumptionPeriodEnd) {
        form.setValue(
          'consumptionPeriodEnd',
          existingTransaction.cancellationDetailsConsumptionPeriodEnd ?? now.endOf('year').format('YYYY-MM-DD')
        )
      }
      form.setValue('additionalDetails', existingTransaction.additionalDetails ?? '')
      form.trigger()
    }
  }, [organization, existingTransaction, countries, cancellationCountries, aibHubCountries, form])

  useEffect(() => {
    if (selectedCertificates.length) {
      const now = dayjs()
      const minYear = existingTransaction ? 2014 : now.get('month') <= 2 ? now.year() - 1 : now.year()
      const maxYear = Math.max(
        minYear,
        selectedCertificates.reduce((acc, cert) => {
          return Math.max(acc, dayjs(cert.bundle.certificateProductionPeriodEnd).year() + 1)
        }, 0)
      )
      const abailableYears = range(minYear, maxYear).reverse()
      setSelectableYears(abailableYears)
      if (!form.getValues('consumptionYear')) {
        form.setValue('consumptionYear', abailableYears.includes(now.year()) ? now.year() : abailableYears[0])
      }
    }
  }, [selectedCertificates, existingTransaction, form])

  useEffect(() => {
    if (!domesticCancellationPermitted) {
      setCancellationEnabled(supplier !== Supplier.FINLAND && countryOfConsumption !== FINLAND_COUNTRY_ID)
    }
  }, [domesticCancellationPermitted, supplier, countryOfConsumption])

  useEffect(() => {
    if (consumptionYear) {
      const fixConsumptionPeriodField = (
        fieldName: 'consumptionPeriodStart' | 'consumptionPeriodEnd',
        mutator: (value: Dayjs) => Dayjs
      ) => {
        const value = form.getValues(fieldName)
        if (!value || (value && !value.startsWith(consumptionYear.toString()))) {
          form.setValue(fieldName, mutator(dayjs(new Date(+consumptionYear, 0, 1))).format('YYYY-MM-DD'))
        }
      }

      fixConsumptionPeriodField('consumptionPeriodStart', (value) => value.startOf('year'))
      fixConsumptionPeriodField('consumptionPeriodEnd', (value) => value.endOf('year'))
    }
  }, [consumptionYear, form])

  useEffect(() => {
    form.trigger('consumptionPeriodStart')
  }, [consumptionPeriodEnd, form])

  useEffect(() => {
    form.trigger('consumptionPeriodEnd')
  }, [consumptionPeriodStart, form])

  const ConsumptionPeriodRange = useMemo(() => {
    return consumptionYear !== undefined ? (
      <ControlledDateRangePicker
        startDateName="consumptionPeriodStart"
        endDateName="consumptionPeriodEnd"
        key={consumptionYear}
        label={t('cancellation.consumptionPeriod')}
        required
        control={form.control}
      />
    ) : (
      <></>
    )
    // eslint-disable-next-line
  }, [consumptionYear, consumptionPeriodStart, consumptionPeriodEnd])

  const getCountrySelectOptions = () => {
    if (supplier === Supplier.FINLAND) {
      return []
    }
    const availableCountries = supplier === Supplier.NOT_AIB ? cancellationCountries : aibHubCountries
    return availableCountries.map((country) => ({
      id: country.id!,
      name: country.name!
    }))
  }

  const enqueueCancelCertificatesNotification = (cancelAmount: number, transactionId: number) => {
    const certificateTransferCompletedContent: React.ReactNode = (
      <Box>
        {t('form.valid.cancellation.confirmed', { cancelAmount: cancelAmount })}
        <Link
          onClick={() => {
            closeSnackbar()
            navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, transactionId))
          }}
        >
          {t('transaction.transactionInfo')}
        </Link>
      </Box>
    )
    enqueueSuccessNotification(certificateTransferCompletedContent)
  }

  const handleCancel = () => {
    dispatch(unSelectCertificates())
    cancelCallback?.()
  }

  const getSubmitFormData = (data: CertificateCancellationSchemaType) => {
    return {
      beneficiaryType: data.cancellationTarget !== CancellationTarget.CONVERSION ? data.beneficiaryType : undefined,
      beneficiaryName: (() => {
        switch (data.cancellationTarget) {
          case CancellationTarget.COMPANY:
            return data.beneficiaryName
          case CancellationTarget.ORGANIZATION:
            return organization?.name
          case CancellationTarget.CONVERSION:
            return undefined
          default:
            throw Error('Unknown cancellation target')
        }
      })(),
      beneficiaryCode: (() => {
        switch (data.cancellationTarget) {
          case CancellationTarget.COMPANY:
            return data.organizationCode
          case CancellationTarget.ORGANIZATION:
            return organization?.code
          case CancellationTarget.CONVERSION:
            return undefined
          default:
            throw Error('Unknown cancellation target')
        }
      })(),
      conversionRegistry:
        data.cancellationTarget === CancellationTarget.CONVERSION ? data.conversionRegistry : undefined,
      conversionDeviceGsrn:
        data.cancellationTarget === CancellationTarget.CONVERSION ? data.conversionDeviceGsrn : undefined,
      conversionDeviceName:
        data.cancellationTarget === CancellationTarget.CONVERSION ? data.conversionDeviceName : undefined,
      countryOfConsumption: (() => {
        switch (data.cancellationTarget) {
          case CancellationTarget.COMPANY:
          case CancellationTarget.ORGANIZATION:
            return supplier === Supplier.FINLAND ? FINLAND_COUNTRY_ID : data.countryOfConsumption
          case CancellationTarget.CONVERSION:
            return FINLAND_COUNTRY_ID
          default:
            throw Error('Unknown cancellation target')
        }
      })(),
      consumptionYear: data.consumptionYear.toString(),
      consumptionPeriodEnd: data.consumptionPeriodEnd,
      consumptionPeriodStart: data.consumptionPeriodStart,
      additionalDetails: data.additionalDetails
    }
  }

  const handleFormSubmit: SubmitHandler<CertificateCancellationSchemaType> = (
    data: CertificateCancellationSchemaType
  ) => {
    setIsSubmitting(true)

    if (existingTransaction) {
      const dto: CancellationDetailsUpdateDto = {
        ...getSubmitFormData(data)
      }
      ECERT_API.updateCancellationDetails(existingTransaction.id!, dto)
        .then(() => {
          enqueueSnackbar(t('form.valid.cancellation.edit.saved'), snacky.successOpts)
          setCancelDialogOpen(false)
          cleanAfterSubmit(dispatch, organizationId, accountId)
          navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, existingTransaction.id))
        })
        .catch((error) => {
          if (isValidationError(error)) {
            enqueueSnackbar(t('common.error.unexpected'), snacky.errorOpts)
          }
        })
        .finally(() => setIsSubmitting(false))
    } else {
      const dto: CancellationTransactionDto = {
        sourceAccountId: accountId,
        cancellations: selectedCertificates.map((certificate) => ({
          id: certificate.bundle.id,
          amount: certificate.selectedAmount
        })),
        ...getSubmitFormData(data)
      }
      ECERT_API.cancelCertificates(organizationId, accountId, dto)
        .then((response) => {
          const amountCertificatesCancelled = dto.cancellations.reduce((prev, curr) => (prev += +curr.amount!), 0)
          enqueueCancelCertificatesNotification(amountCertificatesCancelled!, response.data)
          setCancelDialogOpen(false)
          cleanAfterSubmit(dispatch, organizationId, accountId)
        })
        .catch((error) => {
          if (isValidationError(error)) {
            enqueueSnackbar(t('common.error.unexpected'), snacky.errorOpts)
          }
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <FormProvider {...(form as any)}>
      <form id="certificate-cancellation" onSubmit={form.handleSubmit(handleFormSubmit)}>
        <FlexColumn gap="20px">
          <Box>
            {cancellationTarget && (
              <ControlledRadioGroup
                name="cancellationTarget"
                label={t('cancellation.target')}
                required
                values={[
                  {
                    label: t('cancellation.company'),
                    value: CancellationTarget.COMPANY
                  },
                  {
                    label: t('cancellation.organization'),
                    value: CancellationTarget.ORGANIZATION
                  },
                  {
                    label: t('cancellation.conversion'),
                    value: CancellationTarget.CONVERSION
                  }
                ]}
                control={form.control}
              />
            )}
          </Box>

          <FlexRow gap="10px">
            <InfoOutlined />
            <Typography>{t('common.mandatoryFields')}</Typography>
          </FlexRow>

          {cancellationTarget !== CancellationTarget.CONVERSION && (
            <ControlledRadioGroup
              name="beneficiaryType"
              label={t('cancellation.beneficiaryType')}
              required
              values={[
                {
                  label: t('cancellation.supplier'),
                  value: CancellationTransactionDtoBeneficiaryType.SUPPLIER
                },
                {
                  label: t('cancellation.enduser'),
                  value: CancellationTransactionDtoBeneficiaryType.ENDUSER
                }
              ]}
              control={form.control}
            />
          )}

          {cancellationTarget === CancellationTarget.CONVERSION && (
            <ControlledRadioGroup
              name="conversionRegistry"
              label={t('cancellation.conversionRegistry')}
              required
              values={[
                {
                  label: t('transaction.conversionRegistryEnum.ENERGY_AUTHORITY'),
                  value: CancellationTransactionDtoConversionRegistry.ENERGY_AUTHORITY
                },
                {
                  label: t('transaction.conversionRegistryEnum.GASGRID_FINLAND'),
                  value: CancellationTransactionDtoConversionRegistry.GASGRID_FINLAND
                }
              ]}
              control={form.control}
            />
          )}

          {cancellationTarget !== CancellationTarget.CONVERSION && (
            <>
              <FlexColumn>
                <InputLabel htmlFor="beneficiaryName">{t('cancellation.beneficiaryName')}</InputLabel>

                {cancellationTarget === CancellationTarget.COMPANY && (
                  <ControlledTextField id="beneficiaryName" name="beneficiaryName" required control={form.control} />
                )}

                {cancellationTarget === CancellationTarget.ORGANIZATION && (
                  <Typography variant="body1">{organization?.name}</Typography>
                )}
              </FlexColumn>
              <FlexColumn>
                <InputLabel htmlFor="organizationCode">{t('cancellation.organizationCode')}</InputLabel>

                {cancellationTarget === CancellationTarget.COMPANY && (
                  <ControlledTextField id="organizationCode" name="organizationCode" required control={form.control} />
                )}

                {cancellationTarget === CancellationTarget.ORGANIZATION && (
                  <Typography variant="body1">{organization?.code}</Typography>
                )}
              </FlexColumn>
            </>
          )}

          {cancellationTarget === CancellationTarget.CONVERSION && (
            <>
              <ControlledTextField
                id="conversionDeviceGsrn"
                name="conversionDeviceGsrn"
                label={t('cancellation.conversionDeviceGsrn')}
                required
                control={form.control}
              />
              <ControlledTextField
                id="conversionDeviceName"
                name="conversionDeviceName"
                label={t('cancellation.conversionDeviceName')}
                required
                control={form.control}
              />
            </>
          )}

          <FlexColumn gap="10px">
            <Typography variant="h2">{t('cancellation.supplierTitle')}</Typography>

            {cancellationTarget !== CancellationTarget.CONVERSION && (
              <>
                <ControlledRadioGroup
                  name="supplier"
                  label={t('cancellation.countryOfConsumption')}
                  required
                  values={[]}
                  nodes={[
                    <FormControlLabel
                      key={Supplier.FINLAND}
                      value={Supplier.FINLAND}
                      control={<Radio />}
                      label={t('cancellation.finland')}
                    />,
                    <FormControlLabel
                      key={Supplier.NOT_AIB}
                      value={Supplier.NOT_AIB}
                      control={<Radio />}
                      label={t('cancellation.countryOfConsumptionEnabled')}
                    />,
                    <FlexRow key={Supplier.AIB} alignItems="center">
                      <FormControlLabel
                        disabled={!aibCountryCancellationsAllowed}
                        value={Supplier.AIB}
                        control={<Radio />}
                        label={t('cancellation.aibHubCountryEnabled')}
                      />
                      <InfoPopper text={t('cancellation.aibHubCountryInfo')} />
                    </FlexRow>
                  ]}
                  control={form.control}
                />
                {(supplier === Supplier.NOT_AIB || supplier === Supplier.AIB) && (
                  <ControlledAutocomplete
                    id="countryOfConsumption"
                    name="countryOfConsumption"
                    required
                    options={getCountrySelectOptions()}
                    control={form.control}
                  />
                )}
              </>
            )}
          </FlexColumn>

          {consumptionYear !== undefined && selectableYears.length > 0 && (
            <ControlledSelect
              id="consumptionYear"
              name="consumptionYear"
              label={t('cancellation.consumptionYear')}
              required
              items={selectableYears.map((y) => ({
                value: y,
                label: `${y}`,
                key: y
              }))}
              control={form.control}
            />
          )}

          <FlexColumn>{ConsumptionPeriodRange}</FlexColumn>

          <ControlledTextField
            id="additionalDetails"
            name="additionalDetails"
            label={t('cancellation.additionaldetailsTitle')}
            required
            multiline={true}
            minRows={6}
            characterLimit={1000}
            control={form.control}
          />

          <FlexRow gap="10px">
            <Button
              onClick={() => setCancelDialogOpen(true)}
              disabled={!form.formState.isDirty || !form.formState.isValid || !cancellationEnabled || isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <Check />}
              variant="contained"
            >
              {!existingTransaction ? t('cancellation.confirmCancel') : t('common.save')}
            </Button>
            <CancelButton disabled={isSubmitting} handleCancel={handleCancel} />
          </FlexRow>
        </FlexColumn>
      </form>

      <CustomDialog
        isOpen={cancelDialogOpen}
        title={!existingTransaction ? t('cancellation.confirmDialogTitle') : t('cancellation.edit.confirmDialogTitle')}
        handleClose={() => setCancelDialogOpen(false)}
        buttons={
          <>
            <Button
              disabled={isSubmitting}
              type="submit"
              form="certificate-cancellation"
              variant="contained"
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <CheckIcon />}
            >
              {t('common.yes')}
            </Button>
            <CancelButton disabled={isSubmitting} handleCancel={() => setCancelDialogOpen(false)} />
          </>
        }
      >
        <FlexColumn gap="8px">
          <Box>
            {!existingTransaction
              ? t('cancellation.confirmDialogMessage')
              : t('cancellation.edit.confirmDialogMessage')}
          </Box>
          <Box>{t('cancellation.esignNotification')}</Box>
        </FlexColumn>
      </CustomDialog>
    </FormProvider>
  )
}
