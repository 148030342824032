import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IToggleLockedProps {
  showLocked: boolean
  toggleLocked: () => void
}

export const ToggleLocked = ({ showLocked, toggleLocked }: IToggleLockedProps) => {
  const { t } = useTranslation()

  return (
    <FormControl>
      <RadioGroup aria-label="toggle-locked" row value={showLocked} onChange={toggleLocked}>
        <FormControlLabel value="false" control={<Radio />} label={t('locking.showOpen')} />
        <FormControlLabel value="true" control={<Radio />} label={t('locking.showLocked')} />
      </RadioGroup>
    </FormControl>
  )
}
