import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Button, Menu } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MenuItem from '@mui/material/MenuItem'
import StyleGuide from '../../styles/StyleGuide'
import { StyledLink } from './NavMenu.styles'

export default function NavMenu({
  buttonText,
  menuItems
}: {
  buttonText: string
  menuItems: { label: string; link: string }[]
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const buttonStyles = {
    color:
      window.location.href.indexOf('reports') > -1
        ? StyleGuide.constants.COLOR_WHITE
        : StyleGuide.constants.COLOR_TEXT_PRIMARY,
    textTransform: 'none',
    fontSize: '1.25rem',
    lineHeight: '1.625rem',

    ':hover': {
      backgroundColor: StyleGuide.constants.COLOR_SECONDARY,
      color: StyleGuide.constants.COLOR_WHITE
    }
  }

  return (
    <Box
      sx={{
        minWidth: 60,
        height: '100%',
        display: 'flex',
        backgroundColor: window.location.href.indexOf('reports') > -1 ? StyleGuide.constants.COLOR_SECONDARY : ''
      }}
    >
      <Button
        aria-controls={open ? 'nav-menu-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={
          open ? (
            <ExpandLessIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          ) : (
            <ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          )
        }
        onClick={handleMenuClick}
        sx={buttonStyles}
      >
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'nav-menu-button',
          sx: {
            padding: 0,
            a: {
              color: StyleGuide.constants.COLOR_TEXT_PRIMARY
            }
          }
        }}
      >
        {menuItems.map((mi) => (
          <MenuItem
            key={mi.label}
            sx={{
              backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
              color: StyleGuide.constants.COLOR_TEXT_PRIMARY
            }}
          >
            <StyledLink to={mi.link} onClick={handleMenuClose}>
              {mi.label}
            </StyledLink>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
