import { useParams } from 'react-router-dom'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { Box, Divider, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  ProductionDeviceViewDto,
  ProductionDeviceViewDtoStatus,
  ProductionDeviceViewDtoStatusOfDraft
} from '../../../api/types'
import EcertTable from '../../../features/table/EcertTable'
import { auditColumns, fuelColumns, investmentColumns, beneficiariesColumns, technologyTypeColumn } from './Columns'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../services/ecert-api'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { isArraysDifferent, getValue } from '../../../utils/utils'
import AmberTooltip from './InfoTabPanel/AmberTooltip'
import { InfoOutlined } from '@mui/icons-material'
import StyleGuide from '../../../styles/StyleGuide'
import AdminButtons from './InfoTabPanel/AdminButtons'
import UserButtons from './InfoTabPanel/UserButtons'
import Events from './InfoTabPanel/Events'
import { canEditProductionDevice, isAdmin } from '../../../utils/permission-utils'
import LocationInfo from './InfoTabPanel/LocationInfo'
import DetailsInfo from './InfoTabPanel/DetailsInfo'
import PoaInfo from './InfoTabPanel/PoaInfo'
import AttachmentsInfo from './InfoTabPanel/AttachmentsInfo'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import StatusChangeConfirmDialog from './InfoTabPanel/StatusChangeConfirmDialog'
import { useLoginUser } from '../../../LoginContext'
import { InfoBox } from '../../../features/info-box/InfoBox'

interface InfoTabProps {
  device1: ProductionDeviceViewDto
  device2: ProductionDeviceViewDto | undefined
  reloadProductionDevice: () => void
}

export default function InfoTab({ device1, device2, reloadProductionDevice }: InfoTabProps) {
  const { t } = useTranslation()
  const params = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const { organizationId } = useParams()
  const [isApprovedDialogOpen, setIsApprovedDialogOpen] = useState(false)
  const [isReturnDialogOpen, setIsReturnDialogOpen] = useState(false)
  const [isRefusedDialogOpen, setIsRefusedDialogOpen] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState(device1)
  const loginUser = useLoginUser()

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`
    }
  }

  interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
  }
  const TabPanel = (props: TabPanelProps) => {
    const { children, index, value, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
    if (device2) {
      setSelectedDevice(newValue === 0 ? device1 : device2)
    }
  }

  const getTabLabel = (status: ProductionDeviceViewDtoStatus | ProductionDeviceViewDtoStatusOfDraft) => {
    if (status === 'APPROVED') return t('productionDevice.status.approved')

    if (status === 'DRAFT') return t('productionDevice.status.draft')

    if (status === 'WAITING_APPROVAL') return t('productionDevice.status.waiting_approval')

    if (status === 'RETURNED') return t('productionDevice.status.returned')

    if (status === 'REFUSED') return t('productionDevice.status.refused')
  }

  const updateStatus = (
    status: ProductionDeviceViewDtoStatus,
    comment: string,
    productionDeviceCode?: string,
    codeGeneratorId?: number
  ) => {
    ECERT_API.updateProductionDeviceStatus(+organizationId!, device1.id, {
      status: status,
      description: comment,
      codeGeneratorId: codeGeneratorId,
      productionDeviceCode: productionDeviceCode
    }).then(() => {
      reloadProductionDevice()
      enqueueSnackbar(t(`form.valid.productionDevice.status.${status.toLowerCase()}`), snacky.successOpts)
    })
  }

  const handleRemoveDraft = () => {
    ECERT_API.deleteProductionDeviceDraft(+organizationId!, device1.id).then(() => {
      reloadProductionDevice()
      enqueueSnackbar(t('form.valid.productionDevice.draftRemoved'), snacky.successOpts)
    })
  }

  const hasDifference = (fieldsToCheck: string[]) => {
    if (selectedDevice !== device2) {
      return false
    }
    return fieldsToCheck.some((key) => getValue(device1, key) !== getValue(device2, key))
  }

  const hasFuelDifference = () => {
    if (selectedDevice !== device2) {
      return false
    }
    if (device1.fuels.length !== device2.fuels.length) {
      return true
    }
    if (isArraysDifferent(device1.fuels, device2!.fuels, 'fuel.code')) {
      return true
    }

    return device1.fuels.some((fuel1) => {
      return device2.fuels.some((fuel2) => {
        if (fuel1?.fuel?.code !== fuel2?.fuel?.code) return false
        if (!fuel1.labels && fuel2.labels) return false
        if (fuel1.labels && !fuel2.labels) return false
        if (!fuel1.productionSupports && fuel2.productionSupports) return false
        if (fuel1.productionSupports && !fuel2.productionSupports) return false

        if (fuel1.labels && fuel2.labels) {
          if (
            isArraysDifferent(fuel1.labels, fuel2.labels, 'typeCode') ||
            isArraysDifferent(fuel1.labels, fuel2.labels, 'validityStartDate') ||
            isArraysDifferent(fuel1.labels, fuel2.labels, 'validityEndDate')
          ) {
            return true
          }
        }

        if (fuel1.productionSupports && fuel2.productionSupports) {
          if (
            isArraysDifferent(fuel1.productionSupports, fuel2.productionSupports, 'investment.scheme') ||
            isArraysDifferent(fuel1.productionSupports, fuel2.productionSupports, 'validityStartDate') ||
            isArraysDifferent(fuel1.productionSupports, fuel2.productionSupports, 'validityEndDate')
          ) {
            return true
          }
        }
        return false
      })
    })
  }

  const hasOwnerDifference = () => {
    if (selectedDevice !== device2) {
      return false
    }
    return (
      device1.beneficiaries.length !== device2.beneficiaries.length ||
      isArraysDifferent(device1.owners, device2.owners, 'code') ||
      isArraysDifferent(device1.owners, device2.owners, 'name')
    )
  }

  const hasBeneficiaryDifference = () => {
    if (selectedDevice !== device2) {
      return false
    }
    return (
      device1.beneficiaries.length !== device2.beneficiaries.length ||
      isArraysDifferent(device1.beneficiaries, device2.beneficiaries, 'accountId') ||
      isArraysDifferent(device1.beneficiaries, device2.beneficiaries, 'accountNumber') ||
      isArraysDifferent(device1.beneficiaries, device2.beneficiaries, 'name') ||
      isArraysDifferent(device1.beneficiaries, device2.beneficiaries, 'share')
    )
  }

  const hasAttachmentDifference = () => {
    if (selectedDevice !== device2) {
      return false
    }
    return isArraysDifferent(device1.attachments!, device2.attachments!, 'id')
  }

  const hasInvestmentDifference = () => {
    if (selectedDevice !== device2) {
      return false
    }
    return device1.investments?.length !== device2.investments?.length
  }

  return (
    <FlexColumn marginTop="16px">
      <Tabs value={selectedTab} onChange={handleChange} aria-label="production device tabs">
        <Tab label={getTabLabel(device1.status!)} {...a11yProps(0)} />
        {device2 && <Tab label={getTabLabel(device2.status!)} {...a11yProps(0)} />}
      </Tabs>
      <TabPanel value={selectedTab} index={selectedTab}>
        {(selectedDevice.status === 'DRAFT' || selectedDevice.status === 'RETURNED') && (
          <FlexRow
            marginTop="16px"
            padding="16px"
            backgroundColor={StyleGuide.constants.COLOR_SECONDARY_LIGHT_3}
            gap="8px"
          >
            <InfoOutlined />
            <Typography variant="body1">{t('productionDevice.status.draftDescription')}</Typography>
          </FlexRow>
        )}
        {selectedDevice.status === 'WAITING_APPROVAL' && (
          <InfoBox type="INFO" margin="16px 0 0 0">
            {t('productionDevice.waitingForApprovalFuelInfo')}
          </InfoBox>
        )}
        <FlexColumn gap="16px">
          {!selectedDevice.lockedStatus?.locked && (
            <FlexRow marginTop="16px" gap="8px">
              {isAdmin(loginUser) ? (
                <AdminButtons
                  productionDeviceStatus={selectedDevice.status!}
                  registrationCodeMissing={!device1.productionDeviceCode || !device1.codeGeneratorId}
                  approvedDialogFunc={() => setIsApprovedDialogOpen(true)}
                  refusedDialogFunc={() => setIsRefusedDialogOpen(true)}
                  returnDialogFunc={() => setIsReturnDialogOpen(true)}
                />
              ) : (
                canEditProductionDevice(loginUser, params) && (
                  <UserButtons
                    isDraftAvailable={device2 !== undefined}
                    productionDeviceStatus={selectedDevice.status!}
                    submitForApproval={() => updateStatus(ProductionDeviceViewDtoStatus.WAITING_APPROVAL, '')}
                    removeDraft={handleRemoveDraft}
                  />
                )
              )}
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn marginTop="16px" marginBottom="16px" gap="16px">
          <Events versionHistory={selectedDevice.versionHistory!} updateEvents={selectedDevice.updateEvents!} />
        </FlexColumn>
        <FlexRow marginTop="16px" gap="21px">
          <FlexColumn flex=".5">
            <FlexRow gap="5px">
              <Typography variant="h3">{t('productionDevice.location')}</Typography>
              {hasDifference(['postNumber', 'region', 'latitude', 'longitude']) && <AmberTooltip />}
            </FlexRow>
            <Divider flexItem sx={{ marginTop: '16px', marginBottom: '16px' }} />
            <LocationInfo
              postNumber={selectedDevice.postNumber!}
              region={selectedDevice.region!}
              latitude={selectedDevice.latitude!}
              longitude={selectedDevice.longitude!}
            />

            <FlexRow gap="5px">
              <Typography variant="h3" marginTop="16px">
                {t('productionDevice.details')}
              </Typography>
              {(hasDifference(['dateOfCommissioning', 'issuingPeriod']) || hasOwnerDifference()) && <AmberTooltip />}
            </FlexRow>
            <Divider flexItem sx={{ marginTop: '16px', marginBottom: '16px' }} />
            <DetailsInfo
              dateOfCommissioning={selectedDevice.dateOfCommissioning ?? '-'}
              gsrn={selectedDevice.gsrn ?? '-'}
              productionDeviceCode={selectedDevice.productionDeviceCode ?? '-'}
              issuingPeriod={selectedDevice.issuingPeriod ?? '-'}
              owners={selectedDevice.owners}
            />

            <FlexRow gap="5px">
              <Typography variant="h3" marginTop="16px">
                {t('productionDevice.management.poa')}
              </Typography>
              {hasDifference(['poa.id']) && <AmberTooltip />}
            </FlexRow>
            <Divider flexItem sx={{ marginTop: '16px', marginBottom: '16px' }} />
            {selectedDevice.poa && selectedDevice.poa.id ? (
              <PoaInfo
                createdAt={selectedDevice.poa!.createdAt!}
                name={selectedDevice.poa!.name!}
                id={selectedDevice.poa!.id!}
                poaValidityEndDate={selectedDevice.poaValidityEndDate!}
                poaAttachments={selectedDevice.poaAttachments}
              />
            ) : (
              <Typography variant="body1">{t(`productionDevice.management.poaNotAttached`)}</Typography>
            )}

            <FlexRow gap="5px">
              <Typography variant="h3" marginTop="16px">
                {t('productionDevice.attachments')}
              </Typography>
              {hasAttachmentDifference() && <AmberTooltip />}
            </FlexRow>
            <Divider flexItem sx={{ marginTop: '16px', marginBottom: '16px' }} />
            {selectedDevice.attachments ? <AttachmentsInfo attachments={selectedDevice.attachments} /> : ''}
          </FlexColumn>

          <FlexColumn gap="24px" flex="1">
            <FlexColumn>
              <FlexRow gap="5px">
                <Typography variant="h3" marginBottom="16px">
                  {t('productionDevice.validity.title')}
                </Typography>
                {hasDifference(['validityStartDate', 'validityEndDate']) && (
                  <FlexRow marginBottom="16px">
                    <AmberTooltip />
                  </FlexRow>
                )}
              </FlexRow>
              <EcertTable
                data={[
                  {
                    start: selectedDevice.validityStartDate,
                    end: selectedDevice.validityEndDate
                  }
                ]}
                columns={auditColumns}
              />
            </FlexColumn>

            <FlexColumn>
              <FlexRow gap="5px">
                <Typography variant="h3" marginBottom="16px">
                  {t('productionDevice.production.production')}
                </Typography>
                {hasDifference(['technologyType.code', 'capacity']) && (
                  <FlexRow marginBottom="16px">
                    <AmberTooltip />
                  </FlexRow>
                )}
              </FlexRow>
              <EcertTable
                data={[
                  {
                    ...selectedDevice.technologyType,
                    capacity: selectedDevice.capacity,
                    radioactiveWasteProduced: selectedDevice.radioactiveWasteProduced
                  }
                ]}
                columns={
                  selectedDevice.radioactiveWasteProduced !== null &&
                  selectedDevice.technologyType.description1 === 'Nuclear'
                    ? [
                        ...technologyTypeColumn,
                        {
                          label: t('productionDevice.radioactiveWasteProduced'),
                          accessor: (data) => (data.radioactiveWasteProduced ? data.radioactiveWasteProduced : '0'),
                          sortable: false
                        }
                      ]
                    : technologyTypeColumn
                }
              />
            </FlexColumn>

            <FlexColumn>
              <FlexRow justifyContent="space-between" alignItems="center" marginBottom="8px">
                <FlexRow gap="5px">
                  <Typography variant="h3">{t('productionDevice.energySource.energySources')}</Typography>
                  {hasFuelDifference() && (
                    <FlexRow marginBottom="16px">
                      <AmberTooltip />
                    </FlexRow>
                  )}
                </FlexRow>
                <DownloadButton
                  variant="outlined"
                  fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_FUEL_CORRESPONDENCE}
                  fileName={t('productionDevice.energySource.guide') + '.xlsx'}
                  startIcon={<DownloadOutlinedIcon />}
                >
                  {t('productionDevice.energySource.guide')}
                </DownloadButton>
              </FlexRow>
              <EcertTable data={selectedDevice.fuels} columns={fuelColumns} />
            </FlexColumn>

            <FlexColumn>
              <FlexRow gap="5px">
                <Typography variant="h3" marginBottom="16px">
                  {t('productionDevice.investment.investmentTitle')}
                </Typography>
                {hasInvestmentDifference() && (
                  <FlexRow marginBottom="16px">
                    <AmberTooltip />
                  </FlexRow>
                )}
              </FlexRow>
              <EcertTable
                noDataString={t('table.noInvestments')}
                customNoDataHeight="100px"
                data={selectedDevice.investments}
                columns={investmentColumns}
              />
            </FlexColumn>

            <FlexColumn>
              <FlexRow gap="5px">
                <Typography variant="h3" marginBottom="16px">
                  {t('productionDevice.beneficiary.issuingAccounts')}
                </Typography>
                {hasBeneficiaryDifference() && (
                  <FlexRow marginBottom="16px">
                    <AmberTooltip />
                  </FlexRow>
                )}
              </FlexRow>
              <EcertTable data={selectedDevice.beneficiaries} columns={beneficiariesColumns} />
            </FlexColumn>
          </FlexColumn>
        </FlexRow>
      </TabPanel>
      <StatusChangeConfirmDialog
        title={t('productionDevice.status.title.approved')}
        confirmButtonText={t('productionDevice.status.submit.approved')}
        registrationFields={!device1.productionDeviceCode || !device1.codeGeneratorId}
        open={isApprovedDialogOpen}
        handleConfirm={(description, productionDeviceCode, gs1CodeId) => {
          setIsApprovedDialogOpen(false)
          updateStatus(ProductionDeviceViewDtoStatus.APPROVED, description, productionDeviceCode, gs1CodeId)
        }}
        handleCancel={() => setIsApprovedDialogOpen(false)}
      />
      <StatusChangeConfirmDialog
        title={t('productionDevice.status.title.returned')}
        confirmButtonText={t('productionDevice.status.submit.returned')}
        registrationFields={false}
        open={isReturnDialogOpen}
        handleConfirm={(description) => {
          setIsReturnDialogOpen(false)
          updateStatus(ProductionDeviceViewDtoStatus.RETURNED, description)
        }}
        handleCancel={() => setIsReturnDialogOpen(false)}
      />
      <StatusChangeConfirmDialog
        title={t('productionDevice.status.title.refused')}
        confirmButtonText={t('productionDevice.status.submit.refused')}
        registrationFields={false}
        open={isRefusedDialogOpen}
        handleConfirm={(description) => {
          setIsRefusedDialogOpen(false)
          updateStatus(ProductionDeviceViewDtoStatus.REFUSED, description)
        }}
        handleCancel={() => setIsRefusedDialogOpen(false)}
      />
    </FlexColumn>
  )
}
