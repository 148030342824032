import { Check } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ISaveButtonProps extends ButtonProps {
  handleSave: () => void
}

export const SaveButton = ({ handleSave, ...props }: ISaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button onClick={handleSave} variant="contained" startIcon={<Check />} {...props}>
      {t('common.save')}
    </Button>
  )
}
