import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import {
  AccountSelectItemDto,
  PasswordChangeDto,
  PermissionDtoPermissionsItem,
  ProductionDeviceListViewDto,
  UserSettingDtoNotificationsItem,
  UserViewDto
} from '../../api/types'
import { Box, Button, Typography, useMediaQuery, useTheme, Link, Alert } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { EcertLabel } from '../../features/styled-components/EcertLabel'
import { ECERT_API } from '../../services/ecert-api'
import { useTranslation } from 'react-i18next'
import { EcertRoutes } from '../../ecert-routes'
import NotificationsNone from '@mui/icons-material/NotificationsNone'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { Notifications } from '@mui/icons-material'
import StyleGuide from '../../styles/StyleGuide'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import {
  canEditUser,
  canViewOrganization,
  getTranslationForPermission,
  getWithoutPermissions,
  isAccountAdmin,
  isAdmin,
  isAnyPermissionSet,
  isInspector,
  isUser
} from '../../utils/permission-utils'
import { getRoleTranslation } from '../../utils/utils'
import { ChangePasswordDialog } from './ChangePasswordDialog'
import { ChangePasswordSchemaType } from '../../validation/change-password-schema'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { LockEntityButton } from '../../features/buttons/LockEntityButton'
import { useLoginUser } from '../../LoginContext'

interface IPermissionData {
  label: string
  permissions: PermissionDtoPermissionsItem[]
  relatesTo: string[]
}

export default function User() {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [user, setUser] = useState<UserViewDto | null>(null)
  const { organizationId, userId } = useParams()
  const theme = useTheme()
  const lgBreakpoint = useMediaQuery(theme.breakpoints.down(1400))
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up(1400))
  const navigate = useNavigate()
  const params = useParams()
  const [organizationAccounts, setOrganizationAccounts] = useState<AccountSelectItemDto[]>([])
  const [productionDevices, setProductionDevices] = useState<ProductionDeviceListViewDto[]>([])
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)

  const columns: IColumn[] = [
    {
      label: '',
      accessor: (permission: IPermissionData) => <Typography variant="body1">{permission.label}</Typography>,
      sortable: false
    },
    {
      label: t('user.permission.view.permission'),
      accessor: (permission: IPermissionData) => {
        const filteredPermissions = getWithoutPermissions(permission.permissions, [
          PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED,
          PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED
        ])
        return (
          <ul>
            {filteredPermissions.length === 0 && <li>{t('user.permission.permission.none')}</li>}
            {filteredPermissions.map((p) => (
              <li key={p}>{getTranslationForPermission(p)}</li>
            ))}
          </ul>
        )
      },
      sortable: false
    },
    {
      label: t('user.permission.view.affect'),
      accessor: (permission: IPermissionData) => (
        <ul>
          {permission.relatesTo?.length === 0 &&
            !isAnyPermissionSet(permission, [
              PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL,
              PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED,
              PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL,
              PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED
            ]) && <li>{t('user.permission.permission.none')}</li>}
          {isAnyPermissionSet(permission, [
            PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL,
            PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED
          ]) && <li>{t('user.permission.productionDevice.restriction.all')}</li>}
          {isAnyPermissionSet(permission, [
            PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL,
            PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED
          ]) && <li>{t('user.permission.account.restriction.all')}</li>}
          {permission.relatesTo?.map((r, i) => <li key={i}>{r}</li>)}
        </ul>
      ),
      sortable: false
    }
  ]

  const reloadData = useCallback((currentUser: UserViewDto, organizationId: number, userId: number) => {
    ECERT_API.getOrganizationProductionDevices(organizationId).then((response) => {
      setProductionDevices(response.data)
    })

    ECERT_API.getOrganizationAccounts(organizationId).then((response) => {
      setOrganizationAccounts(response.data)
    })

    reloadUser(currentUser, organizationId, userId)
  }, [])

  const reloadUser = (currentUser: UserViewDto, organizationId: number, userId: number) => {
    if (isAdmin(currentUser) || isAccountAdmin(currentUser) || isInspector(currentUser)) {
      ECERT_API.getUser(organizationId, userId).then((response) => {
        setUser(response.data)
      })
    } else {
      setUser(currentUser)
    }
  }

  useEffect(() => {
    document.title = `Ecert - ${t('title.user')}`
  }, [t])

  useEffect(() => {
    if (organizationId && userId) {
      reloadData(loginUser, +organizationId, +userId)
    }
  }, [reloadData, loginUser, organizationId, userId])

  interface IInfoRowProps {
    label: string
    propertyName: string
    href?: string
    navigateTo?: string
  }

  const InfoRow = ({ label, propertyName, href, navigateTo }: IInfoRowProps) => {
    return (
      <Box>
        <EcertLabel>{label}</EcertLabel>
        {!href && !navigateTo ? (
          <Typography variant="body1" marginTop="4px">
            {user?.[propertyName]}
          </Typography>
        ) : (
          <Box marginTop="4px">
            {href && (
              <Link aria-label={propertyName} href={href}>
                {user?.[propertyName]}
              </Link>
            )}
            {navigateTo && (
              <Link component="button" onClick={() => navigate(navigateTo)}>
                {user?.[propertyName]}
              </Link>
            )}
          </Box>
        )}
      </Box>
    )
  }

  const getPermissionsData = (): IPermissionData[] => {
    return [
      {
        label: t('user.permission.productionDevice.title'),
        permissions: user?.permission?.permissions?.filter((permission) => permission.includes('PRODUCTIONDEVICE'))!,
        relatesTo: user?.permission?.productionDevices!.map((deviceId) => {
          const pd = productionDevices.find((pd) => pd.id === deviceId)!
          return pd ? `${pd.name} (${pd.gsrn})` : ''
        })!
      },
      {
        label: t('user.permission.account.title'),
        permissions: user?.permission?.permissions?.filter((permission) => permission.includes('ACCOUNT'))!,
        relatesTo: user?.permission?.accounts!.map((accountId) => {
          const oa = organizationAccounts.find((oa) => oa.id === accountId)!
          return oa ? `${oa.name} (${oa.accountNumber})` : ''
        })!
      }
    ]
  }

  const notificationTitleStyles = {
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '24px'
  }

  const handleLockUser = (comment: string) => {
    if (!organizationId || !userId) return

    ECERT_API.lockUser(+organizationId, +userId, comment).then(() => {
      reloadUser(loginUser, +organizationId, +userId)
    })
  }

  const handleUnlockUser = () => {
    if (!organizationId || !userId) return

    ECERT_API.unlockUser(+organizationId, +userId).then(() => {
      reloadUser(loginUser, +organizationId, +userId)
    })
  }

  const handleChangePassword = (data: ChangePasswordSchemaType) => {
    const passwordChangeDto: PasswordChangeDto = {
      password: data.currentPassword,
      newPassword: data.newPassword,
      newPasswordAgain: data.newPasswordRepeated
    }

    if (data.currentPassword === data.newPassword) {
      enqueueSnackbar(t('form.invalid.samePassword'), snacky.errorOpts)
      return
    }

    ECERT_API.updateLoggedInUserPassword(passwordChangeDto)
      .then(() => {
        enqueueSnackbar(t('form.updated'), snacky.successOpts)
        setOpenChangePasswordModal(false)
      })
      .catch(() => enqueueSnackbar(t('form.invalid.password.password.invalid'), snacky.errorOpts))
  }

  const handleClickChangePassword = () => {
    setOpenChangePasswordModal(true)
  }

  return (
    <FlexColumn gap="16px">
      {user?.lockedStatus?.locked && (
        <Alert severity="warning">
          <Box>
            {user.lockedStatus.organizationLocked === true && (
              <>
                {t('user.organizationLockedMessage', { locker: user.lockedStatus.organizationLockerName })}&nbsp;
                <Link onClick={() => navigate(EcertRoutes.organization(user?.lockedStatus?.organizationId))}>
                  {user.lockedStatus.organizationName}
                </Link>
              </>
            )}
            {user.lockedStatus.organizationLocked === false && (
              <>
                {t('user.lockedMessage', {
                  locker: user?.lockedStatus?.entityLockerName
                })}{' '}
                {t('locking.comment')}:
              </>
            )}
          </Box>
          {user.lockedStatus.organizationLocked === false && (
            <Box>
              <pre>{user?.lockedStatus?.lockingComment ?? t('locking.noComment')}</pre>
            </Box>
          )}
        </Alert>
      )}
      <FlexRow gap="24px">
        <Typography variant="h1">{`${user?.firstName} ${user?.lastName}`}</Typography>

        {user && canEditUser(loginUser, params) && !isAccountAdmin(user) && loginUser.id !== user.id && (
          <LockEntityButton
            lockStatus={user?.lockedStatus}
            onLock={handleLockUser}
            onUnlock={handleUnlockUser}
          />
        )}
      </FlexRow>

      <Box
        gap="16px"
        sx={{
          display: 'flex',
          ...(lgBreakpoint && { flexDirection: 'column' }),
          ...(xlBreakpoint && { flexDirection: 'row' })
        }}
      >
        <FlexColumn gap="16px" styles={{ flex: 1 }}>
          <FlexRow
            justifyContent="space-between"
            styles={{
              'padding-bottom': '16px',
              'border-bottom': `1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_2}`
            }}
          >
            <Typography variant="h2">{t('user.info.title')}</Typography>
            <FlexRow gap="8px">
              {user?.lockedStatus?.locked === false && canEditUser(loginUser, params) && (
                <Button
                  onClick={() => navigate(EcertRoutes.userEdit(params.organizationId, params.userId))}
                  className="ecert-btn-secondary"
                  variant="outlined"
                  startIcon={<EditOutlinedIcon />}
                >
                  {t('user.info.link.edit')}
                </Button>
              )}
              {loginUser.id === user?.id && (
                <Button onClick={handleClickChangePassword} variant="outlined" startIcon={<EditOutlinedIcon />}>
                  {t('user.changePassword')}
                </Button>
              )}
            </FlexRow>
          </FlexRow>
          <FlexColumn gap="8px">
            <InfoRow label={t('user.info.username')} propertyName="username" />
            <Box>
              <EcertLabel>{t('user.role.title')}</EcertLabel>
              {user && (
                <Typography variant="body1" marginTop="4px">
                  {getRoleTranslation(user.authorities?.at(-1) || 'Roolia ei löytynyt')}
                </Typography>
              )}
            </Box>
            <InfoRow label={t('user.info.firstName')} propertyName="firstName" />
            <InfoRow label={t('user.info.lastName')} propertyName="lastName" />
            <InfoRow label={t('user.info.email')} propertyName="email"></InfoRow>
            <InfoRow label={t('user.info.phone')} propertyName="phone" />
            <InfoRow
              label={t('user.info.language')}
              propertyName="language" //TODO translation
            />
            <InfoRow
              propertyName="organizationName"
              label={t('user.info.organization')}
              navigateTo={canViewOrganization(loginUser, params) ? EcertRoutes.organization(organizationId) : undefined}
            />
            <Box>
              <InfoRow label={t('user.permission.view.permission')} propertyName="permission.permissions" />
              {user && isUser(user) && <EcertTable data={getPermissionsData()} columns={columns} />}
              {user && isAdmin(user) && <Typography variant="body1">{t('user.permission.view.role.admin')}</Typography>}
              {user && isInspector(user) && (
                <Typography variant="body1">{t('user.permission.view.role.inspector')}</Typography>
              )}
              {user && isAccountAdmin(user) && (
                <Typography variant="body1">{t('user.permission.view.role.accountAdmin')}</Typography>
              )}
            </Box>
          </FlexColumn>
        </FlexColumn>
        <FlexColumn gap="16px" styles={{ flex: 1 }}>
          <FlexRow
            justifyContent="space-between"
            styles={{
              'padding-bottom': '16px',
              'border-bottom': `1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_2}`
            }}
          >
            <Typography variant="h2">{t('user.setting.notification.title')}</Typography>
            {user?.lockedStatus?.locked === false && canEditUser(loginUser, params) && (
              <Button
                onClick={() => navigate(EcertRoutes.userNotificationEdit(params.organizationId, params.userId))}
                className="ecert-btn-secondary"
                variant="outlined"
                startIcon={<EditOutlinedIcon />}
              >
                {t('user.setting.notification.link.edit')}
              </Button>
            )}
          </FlexRow>
          <FlexColumn gap="16px">
            <FlexColumn gap="4px">
              <FlexRow>
                {user?.settings?.notifications?.includes(
                  UserSettingDtoNotificationsItem.EXPIRING_CERTIFICATE_WARNING_EMAIL
                ) ? (
                  <Notifications />
                ) : (
                  <NotificationsNone />
                )}
                <Typography sx={notificationTitleStyles}>
                  {t('user.setting.notification.expiringCertificate.title')}
                </Typography>
              </FlexRow>

              {user?.settings?.notifications?.includes(
                UserSettingDtoNotificationsItem.EXPIRING_CERTIFICATE_WARNING_EMAIL
              ) ? (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringCertificate.enabled')}</Typography>
                </FlexRow>
              ) : (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringCertificate.disabled')}</Typography>
                </FlexRow>
              )}

              <Typography variant="caption">
                {t('user.setting.notification.expiringCertificate.description')}
              </Typography>
            </FlexColumn>
            <FlexColumn gap="4px">
              <FlexRow>
                {user?.settings?.notifications?.includes(
                  UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL
                ) ? (
                  <Notifications />
                ) : (
                  <NotificationsNone />
                )}
                <Typography sx={notificationTitleStyles}>
                  {t('user.setting.notification.expiringProductionDeviceValidity.title')}
                </Typography>
              </FlexRow>

              {user?.settings?.notifications?.includes(
                UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL
              ) ? (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringProductionDeviceValidity.enabled')}</Typography>
                </FlexRow>
              ) : (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringProductionDeviceValidity.disabled')}</Typography>
                </FlexRow>
              )}

              <Typography variant="caption">
                {t('user.setting.notification.expiringProductionDeviceValidity.description')}
              </Typography>
            </FlexColumn>
            <FlexColumn gap="4px">
              <FlexRow>
                {user?.settings?.notifications?.includes(
                  UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL
                ) ? (
                  <Notifications />
                ) : (
                  <NotificationsNone />
                )}

                <Typography sx={notificationTitleStyles}>
                  {t('user.setting.notification.expiringProductionDeviceManagement.title')}
                </Typography>
              </FlexRow>

              {user?.settings?.notifications?.includes(
                UserSettingDtoNotificationsItem.PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL
              ) ? (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringProductionDeviceManagement.enabled')}</Typography>
                </FlexRow>
              ) : (
                <FlexRow>
                  <Typography>{t('user.setting.notification.expiringProductionDeviceManagement.disabled')}</Typography>
                </FlexRow>
              )}
              <Typography variant="caption">
                {t('user.setting.notification.expiringProductionDeviceManagement.description')}
              </Typography>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      </Box>

      {openChangePasswordModal && (
        <ChangePasswordDialog
          isOpen={openChangePasswordModal}
          handleConfirm={handleChangePassword}
          handleClose={() => setOpenChangePasswordModal(false)}
        />
      )}
    </FlexColumn>
  )
}
