/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type UserSettingDtoNotificationsItem = typeof UserSettingDtoNotificationsItem[keyof typeof UserSettingDtoNotificationsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserSettingDtoNotificationsItem = {
  ACCOUNT_CREATED_EMAIL: 'ACCOUNT_CREATED_EMAIL',
  LOGIN_VERIFICATION_PIN_EMAIL: 'LOGIN_VERIFICATION_PIN_EMAIL',
  LOGIN_VERIFICATION_PIN_SMS: 'LOGIN_VERIFICATION_PIN_SMS',
  PASSWORD_RESET_EMAIL: 'PASSWORD_RESET_EMAIL',
  EXPIRING_CERTIFICATE_WARNING_EMAIL: 'EXPIRING_CERTIFICATE_WARNING_EMAIL',
  EXPIRING_CERTIFICATE_WARNING_SMS: 'EXPIRING_CERTIFICATE_WARNING_SMS',
  CANCELLATION_STATEMENT_PDF: 'CANCELLATION_STATEMENT_PDF',
  TRANSFER_STATEMENT_PDF: 'TRANSFER_STATEMENT_PDF',
  PRODUCTION_DEVICE_UPDATED_EMAIL: 'PRODUCTION_DEVICE_UPDATED_EMAIL',
  PRODUCTION_DEVICE_STATUS_APPROVED_EMAIL: 'PRODUCTION_DEVICE_STATUS_APPROVED_EMAIL',
  PRODUCTION_DEVICE_STATUS_REFUSED_EMAIL: 'PRODUCTION_DEVICE_STATUS_REFUSED_EMAIL',
  PRODUCTION_DEVICE_STATUS_RETURNED_EMAIL: 'PRODUCTION_DEVICE_STATUS_RETURNED_EMAIL',
  CERTIFICATE_ISSUED_TO_ACCOUNT_EMAIL: 'CERTIFICATE_ISSUED_TO_ACCOUNT_EMAIL',
  EXPIRING_OLD_CERTIFICATE_EMAIL: 'EXPIRING_OLD_CERTIFICATE_EMAIL',
  PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL: 'PRODUCTION_DEVICE_EXPIRING_MANAGEMENT_EMAIL',
  PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL: 'PRODUCTION_DEVICE_EXPIRING_VALIDITY_EMAIL',
  EXTERNAL_TRANSFER_FAILED_EMAIL: 'EXTERNAL_TRANSFER_FAILED_EMAIL',
  PASSWORD_EXPIRED_NOTIFICATION_EMAIL: 'PASSWORD_EXPIRED_NOTIFICATION_EMAIL',
  PASSWORD_EXPIRING_NOTIFICATION_EMAIL: 'PASSWORD_EXPIRING_NOTIFICATION_EMAIL',
  SYSTEM_FAILURE_NOTIFICATION_EMAIL: 'SYSTEM_FAILURE_NOTIFICATION_EMAIL',
  USER_ACCOUNT_LOCKED_EMAIL: 'USER_ACCOUNT_LOCKED_EMAIL',
  AUTOMATIC_TRANSFER_ERROR_EMAIL: 'AUTOMATIC_TRANSFER_ERROR_EMAIL',
  EXTERNAL_TRANSFER_FAILURE_ADMIN_NOTIFICATION_EMAIL: 'EXTERNAL_TRANSFER_FAILURE_ADMIN_NOTIFICATION_EMAIL',
  INVOICING_PERIOD_PUBLISHED_EMAIL: 'INVOICING_PERIOD_PUBLISHED_EMAIL',
  PRODUCTION_DEVICE_LOCKED_EMAIL: 'PRODUCTION_DEVICE_LOCKED_EMAIL',
  PRODUCTION_DEVICE_UNLOCKED_EMAIL: 'PRODUCTION_DEVICE_UNLOCKED_EMAIL',
} as const;
