import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../../services/ecert-api'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import DownloadButton from '../../../features/buttons/DownloadButton'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Box, Typography, Tab, Tabs, TextField } from '@mui/material'
import { EcertLabel } from '../../../features/styled-components/EcertLabel'
import dayjs, { Dayjs } from 'dayjs'
import { SignedDocumentBatchDto } from '../../../api/types'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useLoginUser } from '../../../LoginContext'
import { canViewOrganizations } from '../../../utils/permission-utils'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import { useSearchParams } from 'react-router-dom'
import { isValidationErrorWithBlob, getValidationErrorsfromBlob } from '../../../utils/utils'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'

type Filter = {
  organizationId: number | null
  signingTimeStart: Dayjs | null
  signingTimeEnd: Dayjs | null
  consumptionPeriodStart: Dayjs | null
  consumptionPeriodEnd: Dayjs | null
  beneficiaryNameText: string | null
}

export function SignedDocuments() {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [filter, setFilter] = useState<Filter>({
    organizationId: canViewOrganizations(loginUser) ? null : loginUser.organizationId!,
    signingTimeStart: null,
    signingTimeEnd: null,
    consumptionPeriodStart: null,
    consumptionPeriodEnd: null,
    beneficiaryNameText: null
  })
  const [documentBatches, setDocumentBatches] = useState<SignedDocumentBatchDto[]>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  useEffect(() => {
    document.title = `Ecert - ${t('title.signedDocuments')}`
  }, [t])

  useEffect(() => {
    const tabIndex = searchParams.get('tab')
    if (tabIndex !== null) {
      setSelectedTabIndex(+tabIndex!)
    }
  }, [searchParams])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    setDocumentBatches(undefined)
    ECERT_API.getSignedDocumentBatches(filter.organizationId).then((response) => setDocumentBatches(response.data))
  }, [filter.organizationId])

  const updateFilter = (update: Partial<Filter>) => {
    setFilter({ ...filter, ...update })
  }

  const handleTabChanged = (event, value) => {
    setSearchParams(`?${new URLSearchParams({ tab: value })}`)
    setSelectedTabIndex(value)
  }

  const matchesFilter = (value: SignedDocumentBatchDto) => {
    if (filter.signingTimeStart && filter.signingTimeStart.isAfter(value.completedDate)) {
      return false
    }
    if (filter.signingTimeEnd && filter.signingTimeEnd.isBefore(value.completedDate)) {
      return false
    }
    return true
  }

  const submitIsDisabled = () => {
    return filter.consumptionPeriodStart === null || filter.consumptionPeriodEnd === null
  }

  const errorHandler = async (error) => {
    if (await isValidationErrorWithBlob(error)) {
      const validationErrors = await getValidationErrorsfromBlob(error)
      if (validationErrors[0].messageKey === 'notFound') {
        enqueueSnackbar(t('form.invalid.notFound'), snacky.errorOpts)
      }
      if (validationErrors[0].messageKey === 'zipTooBig') {
        enqueueSnackbar(t('form.invalid.zipTooBig'), snacky.errorOpts)
      }
    }
  }

  const columns: IColumn[] = [
    {
      label: t('signedDocumentsReport.signingDate'),
      accessor: (data: SignedDocumentBatchDto) => dayjs(data.completedDate).format('DD.MM.YYYY'),
      role: 'rowheader'
    },
    {
      label: t('signedDocumentsReport.documentCount'),
      accessor: (data: SignedDocumentBatchDto) => data.documentCount
    },
    {
      label: t('signedDocumentsReport.transactionPeriod'),
      accessor: (data: SignedDocumentBatchDto) =>
        [
          dayjs(data.transactionPeriodStart).format('DD.MM.YYYY HH:mm'),
          dayjs(data.transactionPeriodEnd).format('DD.MM.YYYY HH:mm')
        ].join(' - ')
    },
    {
      label: t('common.functions'),
      accessor: (data: SignedDocumentBatchDto) => {
        return (
          <DownloadButton
            fileName={`${t('signedDocumentsReport.downloadFilename')}_${data.esignOperationId!}.zip`}
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.SIGNED_DOCUMENTS(filter.organizationId, data.esignOperationId!)}
            startIcon={<FileDownloadOutlinedIcon />}
            customErrorHandler={errorHandler}
          >
            {t('signedDocumentsReport.download')}
          </DownloadButton>
        )
      }
    }
  ]

  return (
    <FlexColumn gap="20px">
      <Typography variant="h1">{t('signedDocumentsReport.title')}</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTabIndex} onChange={handleTabChanged} aria-label="tabs">
          <Tab label={t('signedDocumentsReport.tabs.tab1')} />
          <Tab label={t('signedDocumentsReport.tabs.tab2')} />
        </Tabs>
      </Box>
      {selectedTabIndex === 0 && (
        <FlexRow gap="20px" justifyContent="stretch" alignItems="flex-end">
          {canViewOrganizations(loginUser) && organizations.length > 0 && (
            <OrganizationSelect
              value={organizations.find((org) => org.id === filter.organizationId) ?? null}
              organizations={organizations}
              onSelect={(organization) => {
                updateFilter({ organizationId: organization ? organization.id! : null })
              }}
            />
          )}
          <EcertDateRangePicker
              clearable={true}
              label={t('signedDocumentsReport.consumptionPeriodFilter')}
              defaultValue={{}}
              onUpdateStart={(date) => updateFilter({ consumptionPeriodStart: date })}
              onUpdateEnd={(date) => updateFilter({ consumptionPeriodEnd: date })}
          />
          <Box className="flex-column flex-gap-5">
            <EcertLabel>{t('signedDocumentsReport.searchBeneficiaryNameFilter')}</EcertLabel>
            <TextField
              placeholder={t('signedDocumentsReport.searchBeneficiaryNamePlaceholder')}
              variant="outlined"
              value={filter.beneficiaryNameText}
              InputProps={{
                sx: { height: '56px', width: '300px' }
              }}
              onChange={(event) => {
                updateFilter({ beneficiaryNameText: event.target.value })
              }}
            />
          </Box>
          <DownloadButton
            fileName={`${t('signedDocumentsReport.downloadFilename')}_all.zip`}
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.SIGNED_DOCUMENTS_BY_BENEFICIARY_NAME(
              filter.organizationId,
              filter.consumptionPeriodStart?.format('YYYY-MM-DD'),
              filter.consumptionPeriodEnd?.format('YYYY-MM-DD'),
              filter.beneficiaryNameText
            )}
            customErrorHandler={errorHandler}
            disabled={submitIsDisabled()}
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {t('signedDocumentsReport.download')}
          </DownloadButton>
        </FlexRow>
      )}
      {selectedTabIndex === 1 && (
        <>
          <FlexRow gap="20px" justifyContent="stretch" alignItems="flex-end">
            {canViewOrganizations(loginUser) && organizations.length > 0 && (
              <OrganizationSelect
                value={organizations.find((org) => org.id === filter.organizationId) ?? null}
                organizations={organizations}
                onSelect={(organization) => {
                  updateFilter({ organizationId: organization ? organization.id! : null })
                }}
              />
            )}
            <EcertDateRangePicker
              clearable={true}
              label={t('signedDocumentsReport.signingDateFilter')}
              defaultValue={{}}
              onUpdateStart={(date) => updateFilter({ signingTimeStart: date })}
              onUpdateEnd={(date) => updateFilter({ signingTimeEnd: date })}
            />
          </FlexRow>

          <EcertTable
            ariaDescribedBy="title-report-signed-documents"
            data={documentBatches?.filter((value) => matchesFilter(value))}
            columns={columns}
          ></EcertTable>
        </>
      )}
    </FlexColumn>
  )
}
