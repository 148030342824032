import { Clear } from '@mui/icons-material'
import { Button } from '@mui/material'

interface IClearButtonProps {
  handleClear: () => void
}

export const ClearButton = ({ handleClear }: IClearButtonProps) => {
  const clearButtonStyles = {
    maxHeight: '40px',
    maxWidth: '40px',
    minWidth: '0px'
  }

  return (
    <Button sx={clearButtonStyles} variant="outlined" onClick={handleClear}>
      <Clear />
    </Button>
  )
}
