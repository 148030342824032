import { useEffect, useState } from 'react'
import { TransactionDto } from '../../../api/types'
import dayjs from 'dayjs'
import { Box, IconButton } from '@mui/material'
import { EcertRoutes } from '../../../ecert-routes'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ECERT_API } from '../../../services/ecert-api'
import EcertTable from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TransferButton } from './TransferButton'

interface ITransactionsProps {
  organizationId: number
  accountId: number
  isLocked: boolean
}

export const Transactions = ({ organizationId, accountId, isLocked }: ITransactionsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [transactions, setTransactions] = useState<TransactionDto[] | undefined>(undefined)

  const columns = [
    {
      label: t('account.transactionTime'),
      accessor: (obj: TransactionDto) => dayjs(obj.time!).format('DD.MM.YYYY HH:mm'),
      sortable: false
    },
    {
      label: t('account.transactionId'),
      accessor: 'id',
      sortable: false,
      role: 'rowheader'
    },
    {
      label: t('account.transactionType'),
      accessor: (obj: TransactionDto) => t(`transaction.transactionType.${obj.type}`),
      sortable: false
    },
    {
      label: t('transaction.beneficiaryType'),
      accessor: 'participant',
      sortable: false
    },
    {
      label: t('account.amount'),
      accessor: (obj: TransactionDto) => `${obj.sign}${obj.processedCertificateAmount}`,
      sortable: false
    },
    {
      label: t('account.process'),
      accessor: (obj: TransactionDto) => {
        return (
          <Box>
            {displayTransferButton(obj) ? (
              <TransferButton
                disabled={isLocked}
                organizationId={organizationId}
                accountId={accountId}
                transaction={obj}
              />
            ) : null}
          </Box>
        )
      },
      sortable: false
    },
    {
      label: t('account.info'),
      accessor: (obj: TransactionDto) => {
        const navigateToTransactionInfo = () => {
          navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, obj.id))
        }

        return (
          <Box>
            <IconButton aria-label={`transaction-info-${obj.id}`} onClick={navigateToTransactionInfo}>
              <InfoOutlinedIcon />
            </IconButton>
          </Box>
        )
      },
      sortable: false
    }
  ]

  const displayTransferButton = (obj: TransactionDto) => {
    return obj.sign === '+'
  }

  useEffect(() => {
    if (organizationId && accountId) {
      ECERT_API.getTransactionsForAccount(organizationId, accountId).then((response) => {
        setTransactions(response.data)
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box>
      <EcertTable columns={columns} data={transactions} />
    </Box>
  )
}
