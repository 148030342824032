import { OrganizationDto, OrganizationListItemDto, OrganizationViewDto } from '../../api/types'
import { RequestStatus, RequestError } from './store-types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { ECERT_API } from '../../services/ecert-api'
import { NavigateFunction } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import { useDataSliceArray } from './hooks'
import { enqueueSnackbar } from 'notistack'
import { t } from 'i18next'
import * as snacky from '../custom-snackbar-provider/CustomSnackbarProvider'
import { hasValidationErrorWithField, isValidationError } from '../../utils/utils'

interface IOrganizationsState {
  organizations: OrganizationListItemDto[] | undefined
  singleOrganization: OrganizationViewDto | null
  fetchOrganizationsStatus: RequestStatus
  fetchOrganizationStatus: RequestStatus
  createOrganizationStatus: RequestStatus
  requestError: RequestError
}

const initialState: IOrganizationsState = {
  organizations: undefined,
  singleOrganization: null,
  fetchOrganizationsStatus: 'idle',
  fetchOrganizationStatus: 'idle',
  createOrganizationStatus: 'idle',
  requestError: undefined
}

// Reducers
export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<OrganizationListItemDto>) => {
      if (state.organizations) {
        state.organizations.push({ ...action.payload })
      } else {
        state.organizations = [action.payload]
      }
    },
    clearOrganization: (state) => {
      state.singleOrganization = null
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.fetchOrganizationsStatus = 'pending'
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.fetchOrganizationsStatus = 'success'
        state.organizations = action.payload
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.fetchOrganizationsStatus = 'fail'
        state.requestError = action.error.message
      })
      .addCase(createOrganization.pending, (state, action) => {
        state.createOrganizationStatus = 'pending'
        state.singleOrganization = { ...action.meta.arg.organization }
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.createOrganizationStatus = 'success'
        state.singleOrganization = { ...action.payload }
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.createOrganizationStatus = 'fail'
        state.requestError = action.error.message
        state.singleOrganization = null
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.fetchOrganizationStatus = 'success'
        state.singleOrganization = { ...action.payload }
      })
  }
})

// Actions
export const { add, clearOrganization } = organizationsSlice.actions

// Selectors
export const selectOrganizations = (state: RootState) => state.organizations.organizations

export const selectOrganization = (state: RootState) => state.organizations.singleOrganization

// Effects/Thunks
export const fetchOrganizations = createAsyncThunk('organizations/fetchAll', async () => {
  const response = await ECERT_API.getAllOrganizations()
  return response.data
})

export const fetchOrganization = createAsyncThunk('organization/fetch', async (organizationId: number) => {
  const response = await ECERT_API.getOrganization(organizationId)
  return response.data
})

export const createOrganization = createAsyncThunk(
  'organizations/create',
  async ({ organization, navigate }: { organization: OrganizationDto; navigate: NavigateFunction }, thunkAPI) => {
    let response
    try {
      response = await ECERT_API.createOrganization(organization)
    } catch (error) {
      if (hasValidationErrorWithField('name', error)) {
        enqueueSnackbar(t('form.invalid.organization.name.reserved'), snacky.errorOpts)
      } else if (hasValidationErrorWithField('code', error)) {
        enqueueSnackbar(t('form.invalid.organization.code.reserved'), snacky.errorOpts)
      } else if (hasValidationErrorWithField('mainUser.username', error)) {
        enqueueSnackbar(t('form.invalid.mainUser.username.reserved'), snacky.errorOpts)
      } else if (isValidationError(error)) {
        enqueueSnackbar(t('common.statusError'), snacky.errorOpts)
      }
    }
    if (response.data.id) {
      thunkAPI.dispatch(fetchOrganization(response.data.id))
      navigate(EcertRoutes.organization(response.data.id))
    }
    return response.data
  }
)

// Hooks
export function useOrganizations(intialFetch: boolean, refreshFetch: boolean = false) {
  return useDataSliceArray(selectOrganizations, fetchOrganizations, intialFetch, refreshFetch, [])
}

export default organizationsSlice.reducer
