import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ECERT_API } from '../../services/ecert-api'
import {
  ProductionInfoDeviceDto,
  ProductionInfoDto,
  ProductionInfoNotificationDto,
  ProductionInfoNotificationDtoSeverity,
  ProductionInfoNotificationDtoType,
  ProductionInfoTransferDetailsDto
} from '../../api/types'
import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
  getTranslation,
  idGenerator,
  parseDate
} from '../../utils/utils'
import { Check, ExpandMore } from '@mui/icons-material'
import StyleGuide from '../../styles/StyleGuide'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import WarningAmber from '@mui/icons-material/WarningAmber'
import RefreshIcon from '@mui/icons-material/Refresh'
import AsyncButton from '../../features/buttons/AsyncButton'
import { EcertRoutes } from '../../ecert-routes'
import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'

export const ProductionInfoTransfer = () => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [productionInfoTransferDetails, setProductionInfoTransferDetails] =
    useState<ProductionInfoTransferDetailsDto | null>(null)
  const [notificationsExpanded, setNotificationsExpanded] = useState(false)
  const [issuablesExpanded, setIssuablesExpanded] = useState(false)
  const [issuedExpanded, setIssuedExpanded] = useState(false)
  const [markTransferredDialogOpen, setMarkTransferredDialogOpen] = useState(false)
  const [removeProductionInfoTransferDialogOpen, setRemoveProductionInfoTransferDialogOpen] = useState(false)
  const [isEditingNote, setIsEditingNote] = useState(false)
  const textArea = useRef<HTMLInputElement>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionInfoTransfer')}`
  }, [t])

  const fetchProductionInfoTransfer = useCallback(
    (displayDifference: boolean = false) => {
      ECERT_API.getProductionInfoTransfer(+params.productionInfoTransferId!)
        .then((response) => {
          setProductionInfoTransferDetails((prevState) => {
            if (displayDifference) {
              const errorDiff = response.data.errorCount! - prevState?.errorCount!
              const warningDiff = response.data.warningCount! - prevState?.warningCount!
              displayDiffNotification(errorDiff, warningDiff)
            }

            // Generate keys for react to iterate over, due to id not being unique (can be 0)
            response.data?.devices?.forEach((device) => {
              device['key'] = idGenerator.next().value
            })
            return response.data
          })
        })
        .then(() => {
          setIsEditingNote(false)
        })
    },
    [params.productionInfoTransferId]
  )

  useEffect(() => {
    fetchProductionInfoTransfer()
  }, [fetchProductionInfoTransfer])

  const displayDiffNotification = (errorDiff: number, warningDiff: number) => {
    let notificationMsg = ''

    if (errorDiff === 0 && warningDiff === 0) {
      notificationMsg = getTranslation('productionInfo.notificationsUpdated.noChange')
    } else {
      if (errorDiff > 0) {
        notificationMsg = getTranslation('productionInfo.notificationsUpdated.errorsIncreased')
      } else if (errorDiff < 0) {
        notificationMsg = getTranslation('productionInfo.notificationsUpdated.errorsDecreased')
      } else {
        notificationMsg = getTranslation('productionInfo.notificationsUpdated.errorsNoChange')
      }

      if (warningDiff > 0) {
        notificationMsg += ' ' + getTranslation('productionInfo.notificationsUpdated.warningsIncreased')
      } else if (warningDiff < 0) {
        notificationMsg += ' ' + getTranslation('productionInfo.notificationsUpdated.warningsDecreased')
      } else {
        notificationMsg += ' ' + getTranslation('productionInfo.notificationsUpdated.warningsNoChange')
      }
    }

    enqueueSuccessNotification(<Typography>{notificationMsg}</Typography>)
  }

  const updateProductionInfoTransferErrors = () => {
    return ECERT_API.updateProductionInfoTransferErrors(+params.productionInfoTransferId!).then(() => {
      fetchProductionInfoTransfer(true)
    })
  }

  const markProcessed = () => {
    ECERT_API.markProductionInfoTransferProcessed(+params.productionInfoTransferId!).then(() => {
      enqueueSuccessNotification(
        <Typography variant="body1">{t('form.valid.productionInfo.markedProcessed')}</Typography>
      )
      navigate(EcertRoutes.productionInfoTransfers())
    })
  }

  const removeTransfer = () => {
    ECERT_API.removeProductionInfoTransfer(+params.productionInfoTransferId!).then(() => {
      enqueueSuccessNotification(<Typography variant="body1">{t('form.valid.productionInfo.deleted')}</Typography>)
      navigate(EcertRoutes.productionInfoTransfers())
    })
  }

  const issueCertificates = () => {
    return ECERT_API.issueCertificates(+params.productionInfoTransferId!).then(() => {
      enqueueSuccessNotification(<Typography variant="body1">{t('form.valid.productionInfo.issued')}</Typography>)
      navigate(EcertRoutes.productionInfoTransfers())
    })
  }

  const cancel = () => {
    navigate(EcertRoutes.productionInfoTransfers())
  }

  const hasErrorNotifications = (notifications: ProductionInfoNotificationDto[]) => {
    return (
      notifications.find((notification) => notification.severity === ProductionInfoNotificationDtoSeverity.ERROR) !==
      undefined
    )
  }

  const hasUnacknowledgedWarningNotifications = (notifications: ProductionInfoNotificationDto[]) => {
    return (
      notifications.find(
        (notification) =>
          notification.severity === ProductionInfoNotificationDtoSeverity.WARNING && !notification.acknowledged
      ) !== undefined
    )
  }

  const isDeviceRemoved = (device: ProductionInfoDeviceDto) => {
    return device.productionInfos?.every((pi) => pi.removed)
  }

  const devicesWithNotifications = (): ProductionInfoDeviceDto[] => {
    return (
      productionInfoTransferDetails?.devices?.filter(
        (device) =>
          !isDeviceRemoved(device) &&
          device.productionInfos?.find(
            (pi) => hasErrorNotifications(pi.notifications!) || hasUnacknowledgedWarningNotifications(pi.notifications!)
          ) !== undefined
      ) || []
    )
  }

  const devicesWithIssuables = (): ProductionInfoDeviceDto[] => {
    return (
      productionInfoTransferDetails?.devices?.filter(
        (device) =>
          !isDeviceRemoved(device) &&
          device.productionInfos?.find(
            (pi) =>
              !hasErrorNotifications(pi.notifications!) &&
              !hasUnacknowledgedWarningNotifications(pi.notifications!) &&
              !pi.transferInfoIssued
          ) !== undefined
      ) || []
    )
  }

  const devicesWithIssued = (): ProductionInfoDeviceDto[] => {
    return (
      productionInfoTransferDetails?.devices?.filter(
        (device) =>
          device.productionInfos?.find((pi) => pi.notifications!.length === 0 && pi.transferInfoIssued) !== undefined
      ) || []
    )
  }

  const devicesRemoved = () => {
    return (
      productionInfoTransferDetails?.devices?.filter((device: ProductionInfoDeviceDto) => isDeviceRemoved(device)) || []
    )
  }

  const acknowledgeWarning = (productionInfoId: number) => {
    ECERT_API.acknowledgeProductionInfoWarnings(productionInfoId).then(() => {
      fetchProductionInfoTransfer()
    })
  }

  const unacknowledgeProductionInfoNotification = (productionInfoId: number) => {
    ECERT_API.unacknowledgeProductionInfoNotification(productionInfoId).then(() => {
      fetchProductionInfoTransfer()
    })
  }

  const getProperNotificationMsg = (notification: ProductionInfoNotificationDto, productionInfo: ProductionInfoDto) => {
    switch (notification.type) {
      case ProductionInfoNotificationDtoType.INVALID_AMOUNT:
        return t(`productionInfo.notification.${notification.type}`, { amountValue: productionInfo.amountValue })
      case ProductionInfoNotificationDtoType.INVALID_STATUS:
        return t(`productionInfo.notification.${notification.type}`, { status: productionInfo.status })
      case ProductionInfoNotificationDtoType.MISSING_PRODUCTION_DEVICE:
        return t(`productionInfo.notification.${notification.type}`, { productionDeviceCode: '' })
      default:
        return t(`productionInfo.notification.${notification.type}`)
    }
  }

  const getProperProductionDeviceText = (device: ProductionInfoDeviceDto) => {
    return device.organizationId === 0 || device.id === 0 ? (
      <Typography>{device.name}</Typography>
    ) : (
      <Link onClick={() => navigate(EcertRoutes.productionDevice(device.organizationId, device.id))}>
        {device.name} ({device.gsrn})
      </Link>
    )
  }

  const saveNote = () => {
    ECERT_API.updateProductionInfoNote(+params.productionInfoTransferId!, textArea.current!.value)
      .then(() => {
        fetchProductionInfoTransfer()
        enqueueSuccessNotification(<Typography>{t('productionInfo.note.updated')}</Typography>)
      })
      .catch(() => {
        enqueueErrorNotification(<Typography>{t('form.invalid.unknown')}</Typography>)
      })
  }

  const removeProductionDevice = (deviceId: number) => {
    ECERT_API.updateRemovedStatus(+params.productionInfoTransferId!, deviceId, true).then(() => {
      fetchProductionInfoTransfer()
    })
  }

  const unRemoveProductionDevice = (deviceId: number) => {
    ECERT_API.updateRemovedStatus(+params.productionInfoTransferId!, deviceId, false).then(() => {
      fetchProductionInfoTransfer()
    })
  }

  return (
    <FlexColumn gap="16px">
      <Typography variant="h1">{t('productionInfo.process.title')}</Typography>
      <FlexColumn width="50vw" gap="16px">
        {!isEditingNote && productionInfoTransferDetails?.note && (
          <Typography component="pre">{productionInfoTransferDetails?.note}</Typography>
        )}
        {!isEditingNote && (
          <Box>
            <Button variant="contained" onClick={() => setIsEditingNote(true)}>
              {productionInfoTransferDetails?.note
                ? t('productionInfo.note.editNote')
                : t('productionInfo.note.addNote')}
            </Button>
          </Box>
        )}
        {isEditingNote && (
          <FlexColumn gap="16px">
            <TextField
              inputProps={{ maxLength: 1000 }}
              multiline
              defaultValue={productionInfoTransferDetails?.note}
              minRows={5}
              inputRef={textArea}
            />
            <FlexRow gap="10px">
              <Button variant="contained" onClick={() => saveNote()}>
                {t('common.save')}
              </Button>
              <Button variant="outlined" onClick={() => setIsEditingNote(false)}>
                {t('common.cancel')}
              </Button>
            </FlexRow>
          </FlexColumn>
        )}
      </FlexColumn>
      <FlexRow justifyContent="space-between">
        <FlexRow gap="16px">
          <Typography variant="body1">{productionInfoTransferDetails?.code}</Typography>
          <Typography variant="body1">
            {parseDate(productionInfoTransferDetails?.createTime!).format('DD.MM.YYYY HH:mm')}
          </Typography>
          <Typography variant="body1">
            {`${t('productionInfo.process.totalAmount')} ${productionInfoTransferDetails?.totalProductionAmount} MWh`}
          </Typography>
        </FlexRow>
        <FlexRow gap="8px">
          <Button onClick={() => setMarkTransferredDialogOpen(true)} variant="outlined">
            {t('productionInfo.process.markProcessed')}
          </Button>
          {devicesWithIssued().length === 0 && (
            <Button onClick={() => setRemoveProductionInfoTransferDialogOpen(true)} variant="outlined">
              {t('productionInfo.process.delete')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>
      <Accordion
        expanded={notificationsExpanded}
        onChange={(event, expanded) => setNotificationsExpanded(expanded)}
        square
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <FlexRow gap="8px">
            <Typography variant="body1">{t('productionInfo.process.notificationsTitle')}</Typography>
            <ErrorOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
            <Typography sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
              {t('productionInfo.process.errorsTitle', {
                errorCount: productionInfoTransferDetails?.errorCount
              })}
            </Typography>
            <WarningAmber sx={{ color: StyleGuide.constants.COLOR_ORANGE }} />
            <Typography sx={{ color: StyleGuide.constants.COLOR_ORANGE }}>
              {t('productionInfo.process.warningsTitle', {
                warningCount: productionInfoTransferDetails?.warningCount
              })}
            </Typography>
          </FlexRow>
        </AccordionSummary>
        <AccordionDetails>
          <FlexColumn gap="16px">
            {productionInfoTransferDetails?.errorCount === 0 && productionInfoTransferDetails.warningCount === 0 && (
              <Typography variant="body1">{t('productionInfo.process.noNotificationsDescription')}</Typography>
            )}
            <FlexRow>
              <AsyncButton
                disabled={devicesWithNotifications().length === 0}
                variant="outlined"
                startIcon={<RefreshIcon />}
                asyncOperation={updateProductionInfoTransferErrors}
              >
                {t('productionInfo.process.updateErrors')}
              </AsyncButton>
            </FlexRow>
            {devicesWithNotifications().map((device) => (
              <FlexColumn key={device['key']} gap="16px">
                <FlexRow alignItems="center" gap="16px">
                  <Button variant="contained" onClick={() => removeProductionDevice(device.id!)}>
                    {t('productionInfo.process.removeProductionDevice')}
                  </Button>
                  {getProperProductionDeviceText(device)}
                </FlexRow>
                <FlexColumn styles={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
                  {device.productionInfos
                    ?.filter((productionInfo) => (productionInfo.notifications!.length || 0) > 0)
                    .map((productionInfo) => (
                      <FlexColumn key={productionInfo.id}>
                        <FlexRow styles={{ padding: '16px' }}>
                          <Typography sx={{ flex: 1 }}>
                            {parseDate(productionInfo.month as string).format('MM/YYYY')}
                          </Typography>
                          <Typography sx={{ flex: 1 }}>{productionInfo.amount} MWh</Typography>
                          <FlexColumn styles={{ flex: 1 }} gap="5px">
                            {productionInfo.notifications?.map((notification) => (
                              <FlexRow key={notification.id} alignItems="center" gap="8px">
                                {notification.severity === ProductionInfoNotificationDtoSeverity.ERROR && (
                                  <ErrorOutline
                                    sx={{
                                      color: StyleGuide.constants.COLOR_BRAND
                                    }}
                                  />
                                )}
                                {notification.severity === ProductionInfoNotificationDtoSeverity.WARNING && (
                                  <WarningAmber
                                    sx={{
                                      color: StyleGuide.constants.COLOR_ORANGE
                                    }}
                                  />
                                )}
                                <Typography sx={{ flex: 1 }}>
                                  {getProperNotificationMsg(notification, productionInfo)}
                                </Typography>
                                {productionInfo.notifications?.filter(
                                  (notification) =>
                                    notification.severity === ProductionInfoNotificationDtoSeverity.ERROR
                                ).length === 0 && (
                                  <Button
                                    sx={{ marginLeft: 'auto' }}
                                    variant="contained"
                                    onClick={() => acknowledgeWarning(productionInfo.id!)}
                                  >
                                    {t('productionInfo.process.acknowledgeWarning')}
                                  </Button>
                                )}
                              </FlexRow>
                            ))}
                          </FlexColumn>
                        </FlexRow>
                        <Divider sx={{ height: '4px' }} />
                      </FlexColumn>
                    ))}
                </FlexColumn>
              </FlexColumn>
            ))}
          </FlexColumn>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={issuablesExpanded}
        onChange={(event, expanded) => setIssuablesExpanded(expanded)}
        square
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <Typography variant="body1">{t('productionInfo.process.issuablesTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexColumn gap="16px">
            {devicesWithIssuables().length === 0 && (
              <Typography variant="body1">{t('productionInfo.process.noIssuablesDescription')}</Typography>
            )}
            {devicesWithIssuables().map((device) => (
              <FlexColumn key={device['key']} gap="16px">
                <FlexRow alignItems="center" gap="16px">
                  <Button variant="contained" onClick={() => removeProductionDevice(device.id!)}>
                    {t('productionInfo.process.removeProductionDevice')}
                  </Button>
                  {getProperProductionDeviceText(device)}
                </FlexRow>
                <FlexColumn styles={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
                  {device.productionInfos
                    ?.filter(
                      (pi) =>
                        !hasErrorNotifications(pi.notifications!) &&
                        !hasUnacknowledgedWarningNotifications(pi.notifications!) &&
                        !pi.transferInfoIssued
                    )
                    .map((productionInfo) => (
                      <FlexColumn key={productionInfo.id}>
                        <FlexRow alignItems="center" styles={{ padding: '16px' }}>
                          <Typography sx={{ flex: 1 }}>
                            {parseDate(productionInfo.month as string).format('MM/YYYY')}
                          </Typography>
                          <Typography sx={{ flex: 1 }}>{productionInfo.amount} MWh</Typography>
                          <FlexColumn styles={{ flex: 1 }}>
                            {productionInfo.notifications?.map((notification) => (
                              <FlexRow key={notification.id} alignItems="center" gap="8px">
                                {notification.severity === ProductionInfoNotificationDtoSeverity.WARNING && (
                                  <WarningAmber
                                    sx={{
                                      color: StyleGuide.constants.COLOR_ORANGE
                                    }}
                                  />
                                )}
                                <Typography>{getProperNotificationMsg(notification, productionInfo)}</Typography>
                                <Box
                                  sx={{
                                    color: StyleGuide.constants.COLOR_SUCCESS
                                  }}
                                >
                                  {t('productionInfo.process.acknowledged')}
                                </Box>
                                <Button
                                  variant="contained"
                                  onClick={() => unacknowledgeProductionInfoNotification(productionInfo.id!)}
                                >
                                  {t('productionInfo.process.unacknowledgeWarning')}
                                </Button>
                              </FlexRow>
                            ))}
                          </FlexColumn>
                        </FlexRow>
                        <Divider sx={{ height: '4px' }} />
                      </FlexColumn>
                    ))}
                </FlexColumn>
              </FlexColumn>
            ))}
          </FlexColumn>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={issuedExpanded}
        onChange={(event, expanded) => setIssuedExpanded(expanded)}
        square
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <Typography variant="body1">{t('productionInfo.process.issuedTitle')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {devicesWithIssued().length === 0 && (
            <Typography variant="body1">{t('productionInfo.process.noIssuedDescription')}</Typography>
          )}
          {devicesWithIssued().map((device) => (
            <FlexColumn key={device['key']} gap="16px">
              {getProperProductionDeviceText(device)}
              <FlexColumn styles={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
                {device.productionInfos
                  ?.filter(
                    (productionInfo) => productionInfo.notifications!.length === 0 && productionInfo.transferInfoIssued
                  )
                  .map((productionInfo) => (
                    <FlexColumn key={productionInfo.id}>
                      <FlexRow styles={{ padding: '16px', width: '50%' }}>
                        <Typography sx={{ flex: 1 }}>
                          {parseDate(productionInfo.month as string).format('MM/YYYY')}
                        </Typography>
                        <Typography sx={{ flex: 1 }}>{productionInfo.amount} MWh</Typography>
                        <Typography sx={{ flex: 1 }}>
                          {t('productionInfo.process.issuingTime')}{' '}
                          {parseDate(productionInfo.issuingTime!).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                      </FlexRow>
                      <Divider sx={{ height: '4px' }} />
                    </FlexColumn>
                  ))}
              </FlexColumn>
            </FlexColumn>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion square disableGutters>
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <Typography variant="body1">{t('productionInfo.process.removedProductionDevices')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexColumn gap="16px">
            {devicesRemoved().length === 0 && (
              <Typography variant="body1">{t('productionInfo.process.noProductionDevicesRemoved')}</Typography>
            )}
            {devicesRemoved().map((device) => (
              <FlexColumn key={device['key']} gap="16px">
                <FlexRow alignItems="center" gap="16px">
                  <Button variant="contained" onClick={() => unRemoveProductionDevice(device.id!)}>
                    {t('productionInfo.process.unRemoveProductionDevice')}
                  </Button>
                  {getProperProductionDeviceText(device)}
                </FlexRow>
                <FlexColumn styles={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
                  {device.productionInfos?.map((productionInfo) => (
                    <FlexColumn key={productionInfo.id}>
                      <FlexRow styles={{ padding: '16px' }}>
                        <Typography sx={{ flex: 1 }}>
                          {parseDate(productionInfo.month as string).format('MM/YYYY')}
                        </Typography>
                        <Typography sx={{ flex: 1 }}>{productionInfo.amount} MWh</Typography>
                        <FlexColumn styles={{ flex: 1 }} gap="5px">
                          {productionInfo.notifications?.map((notification) => (
                            <FlexRow key={notification.id} alignItems="center" gap="8px">
                              {notification.severity === ProductionInfoNotificationDtoSeverity.ERROR && (
                                <ErrorOutline
                                  sx={{
                                    color: StyleGuide.constants.COLOR_BRAND
                                  }}
                                />
                              )}
                              {notification.severity === ProductionInfoNotificationDtoSeverity.WARNING && (
                                <WarningAmber
                                  sx={{
                                    color: StyleGuide.constants.COLOR_ORANGE
                                  }}
                                />
                              )}
                              <Typography sx={{ flex: 1 }}>
                                {getProperNotificationMsg(notification, productionInfo)}
                              </Typography>
                            </FlexRow>
                          ))}
                        </FlexColumn>
                      </FlexRow>
                      <Divider sx={{ height: '4px' }} />
                    </FlexColumn>
                  ))}
                </FlexColumn>
              </FlexColumn>
            ))}
          </FlexColumn>
        </AccordionDetails>
      </Accordion>

      <FlexRow justifyContent="space-between">
        <FlexRow gap="8px">
          <AsyncButton
            disabled={devicesWithIssuables().length === 0}
            variant="contained"
            startIcon={<Check />}
            asyncOperation={issueCertificates}
          >
            {t('productionInfo.process.issueCertificates')}
          </AsyncButton>
          <Button onClick={cancel} variant="outlined">
            {t('common.cancel')}
          </Button>
        </FlexRow>
        <Typography variant="body1">
          {t('productionInfo.process.status')} {productionInfoTransferDetails?.issuableProductionAmount} MWh /{' '}
          {productionInfoTransferDetails?.totalProductionAmount} MWh
        </Typography>
      </FlexRow>
      {/******** Dialogs ********/}
      <CustomDialog
        isOpen={markTransferredDialogOpen}
        title={t('productionInfo.process.confirmMarkProcessedDialogTitle')}
        handleClose={() => setMarkTransferredDialogOpen(false)}
        buttons={
          <>
            <ConfirmButton handleConfirm={markProcessed} />
            <CancelButton handleCancel={() => setMarkTransferredDialogOpen(false)} />
          </>
        }
      >
        <Box>{t('productionInfo.process.confirmMarkProcessedDialogMessage')}</Box>
      </CustomDialog>
      <CustomDialog
        isOpen={removeProductionInfoTransferDialogOpen}
        title={t('productionInfo.process.confirmDeleteDialog.title')}
        handleClose={() => setRemoveProductionInfoTransferDialogOpen(false)}
        buttons={
          <>
            <ConfirmButton handleConfirm={removeTransfer} />
            <CancelButton handleCancel={() => setRemoveProductionInfoTransferDialogOpen(false)} />
          </>
        }
      >
        <Box>{t('productionInfo.process.confirmDeleteDialog.message')}</Box>
      </CustomDialog>
    </FlexColumn>
  )
}
