import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../services/ecert-api'
import { UserListItemDto } from '../../api/types'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { Button, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import AddIcon from '@mui/icons-material/Add'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'

interface ISelectRepresentativesDialogProps {
  isOpen: boolean
  organizationId: number
  handleClose: () => void
  handleConfirm: (mainUserIds: number[]) => void
}

export default function SelectRepresentativesDialog({
  isOpen,
  organizationId,
  handleClose,
  handleConfirm
}: ISelectRepresentativesDialogProps) {
  const { t } = useTranslation()
  const [currentRepresentatives, setCurrentRepresentatives] = useState<UserListItemDto[]>([])
  const [representatives, setRepresentatives] = useState<UserListItemDto[]>([])

  useEffect(() => {
    ECERT_API.getOrganizationUsers(organizationId).then((response: AxiosResponse<UserListItemDto[]>) => {
      setCurrentRepresentatives(response.data.filter((u) => u.accountAdmin))
      setRepresentatives(response.data.filter((e) => !e.locked))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  useEffect(() => {
    ECERT_API.getOrganizationUsers(organizationId).then((response: AxiosResponse<UserListItemDto[]>) => {
      setCurrentRepresentatives(response.data.filter((u) => u.accountAdmin))
      setRepresentatives(response.data.filter((e) => !e.locked))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose])

  const handleAddNewCurrentRepresentative = () => {
    setCurrentRepresentatives((prevCurrentRepresentatives) => {
      const newRepresentatives = [...prevCurrentRepresentatives]
      newRepresentatives.push({})
      return newRepresentatives
    })
  }

  const removeCurrentRepresentative = (index: number) => {
    setCurrentRepresentatives((prevCurrentRepresentatives) => {
      const newRepresentatives = [...prevCurrentRepresentatives]
      newRepresentatives.splice(index, 1)
      return newRepresentatives
    })
  }

  const handleChange = (event: SelectChangeEvent<number>, index: number) => {
    const selectedRepresentative = representatives.find((r) => r.id === event.target.value)
    if (!selectedRepresentative) return

    setCurrentRepresentatives((prevCurrentRepresentatives) => {
      const newRepresentatives = [...prevCurrentRepresentatives]
      newRepresentatives[index] = selectedRepresentative
      return newRepresentatives
    })
  }

  return (
    <CustomDialog
      isOpen={isOpen}
      title={t('organization.mainUserChange.title')}
      handleClose={() => handleClose()}
      buttons={
        <>
          <ConfirmButton
            disabled={currentRepresentatives.some((r) => r.id === undefined)}
            handleConfirm={() => handleConfirm(currentRepresentatives.map((r) => r.id || -1))}
          />
          <CancelButton handleCancel={handleClose} />
        </>
      }
    >
      <FlexColumn gap="20px">
        <Typography variant="body1">{t('organization.mainUserChange.msg')}</Typography>
        <FlexColumn gap="10px">
          {currentRepresentatives.map((r, index) => (
            <FlexRow key={`${index}-${r.id}`}>
              <Select
                autoFocus={r?.id ? index === 0 : true}
                fullWidth
                value={r?.id || ''}
                onChange={(event) => handleChange(event, index)}
                renderValue={(e) =>
                  `${representatives.find((r) => r.id === e)?.firstName} ${representatives.find((r) => r.id === e)
                    ?.lastName}`
                }
              >
                {representatives
                  .filter((e) => !currentRepresentatives.includes(e))
                  .map((rr) => (
                    <MenuItem key={rr.id} value={rr.id}>{`${rr.firstName} ${rr.lastName}`}</MenuItem>
                  ))}
              </Select>
              <IconButton onClick={() => removeCurrentRepresentative(index)}>
                <CancelIcon />
              </IconButton>
            </FlexRow>
          ))}
          <FlexRow>
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddNewCurrentRepresentative}>
              {t('organization.mainuser.add')}
            </Button>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </CustomDialog>
  )
}
