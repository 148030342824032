import React from 'react'
import { IconButton } from '@mui/material'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { SnackbarProvider, OptionsObject } from 'notistack'
import CloseIcon from '@mui/icons-material/Close'
import StyleGuide from '../../styles/StyleGuide'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CustomSnackbarVariant } from '../custom-snackbars/CustomSnackbarVariant'

const iconStyles = {
  marginRight: '16px'
}

const notistackRef = React.createRef<any>()

export function CustomSnackbarProvider({ children }: { children: JSX.Element }) {
  const onClickDismiss = (key: string | number) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    // Ignore due to typescript complaining about custom variants
    // @ts-ignore
    <SnackbarProvider
      Components={{ customSnackbarVariant: CustomSnackbarVariant }}
      maxSnack={1} // Set to 1 for accessibility reasons
      iconVariant={{
        error: <ErrorIcon fontSize="medium" sx={iconStyles} />,
        warning: <WarningAmberOutlinedIcon fontSize="medium" sx={iconStyles} />,
        success: <DoneOutlinedIcon fontSize="medium" sx={iconStyles} />,
        info: <InfoOutlinedIcon fontSize="medium" sx={iconStyles} />,
        customSnackbarVariant: <DoneOutlinedIcon fontSize="medium" sx={iconStyles} />
      }}
      ref={notistackRef}
      action={(key: string | number) => (
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}

const snackStyles = {
  padding: '24px'
}

export const errorOpts: OptionsObject = {
  variant: 'error',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_RED_95,
    color: StyleGuide.constants.COLOR_RED_30,
    ...snackStyles
  }
}
export const infoOpts: OptionsObject = {
  variant: 'info',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_BLUE_95,
    color: StyleGuide.constants.COLOR_BLUE_20,
    ...snackStyles
  }
}
export const successOpts: OptionsObject = {
  variant: 'success',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_GREEN_95,
    color: StyleGuide.constants.COLOR_GREEN_20,
    ...snackStyles
  }
}

export const warningOpts: OptionsObject = {
  variant: 'warning',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_ORANGE_95,
    color: StyleGuide.constants.COLOR_ORANGE_20,
    ...snackStyles
  }
}

export const customSuccessOpts: OptionsObject = {
  // @ts-ignore
  variant: 'customSnackbarVariant',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_GREEN_95,
    color: StyleGuide.constants.COLOR_GREEN_20,
    ...snackStyles
  }
}

export const customErrorOpts: OptionsObject = {
  // @ts-ignore
  variant: 'customSnackbarVariant',
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
  preventDuplicate: false,
  persist: true, // Accessibility reasons
  style: {
    backgroundColor: StyleGuide.constants.COLOR_RED_95,
    color: StyleGuide.constants.COLOR_RED_30,
    ...snackStyles
  }
}
