import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useFormContext } from 'react-hook-form'
import { ClearOutlined } from '@mui/icons-material'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import dayjs from 'dayjs'
import { UploadButton } from '../../../features/buttons/UploadButton'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'

export default function PowerOfAttorney() {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext<ProductionDeviceSchemaType>()
  const poaAttachment = watch('poa')

  const handleFileSelected = (file: File) => {
    setValue('poa', {
      id: 0,
      name: file.name,
      createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      content: file
    })
  }

  const removeFile = () => {
    setValue('poa', null)
  }

  const deleteButtonStyles = {
    maxHeight: '40px',
    maxWidth: '40px',
    minWidth: '0px',
    justifyContent: 'center',
    alignSelf: 'flex-end'
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.management.poa')}</Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        {!poaAttachment && <Typography>{t('productionDevice.management.poaNotAttached')}</Typography>}

        {poaAttachment && (
          <Box
            display="flex"
            gap="16px"
            justifyContent="space-between"
            padding="0 0 8px"
            maxWidth="fit-content"
            minWidth="435px"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <Typography
              variant="body1"
              noWrap
              sx={{
                maxWidth: '435px',
                display: 'flex',
                alignSelf: 'center',
                padding: '8px'
              }}
            >
              {poaAttachment.name}
            </Typography>
            <Button variant="outlined" sx={deleteButtonStyles} onClick={removeFile}>
              <ClearOutlined />
            </Button>
          </Box>
        )}
        {!poaAttachment && (
          <Box>
            <UploadButton
              aria-label="add-power-of-attorney"
              startIcon={<AttachFileIcon />}
              variant="contained"
              multiple={false}
              handleSelected={(file) => {
                if (file.type !== 'application/pdf') {
                  enqueueSnackbar(
                    t('productionDevice.attachmentTypeNotSupported', { supportedTypes: '.pdf' }),
                    snacky.errorOpts
                  )
                  return
                }

                handleFileSelected(file)
              }}
              acceptFileTypes=".pdf"
            >
              {t('productionDevice.attachFile')}
            </UploadButton>
          </Box>
        )}
      </Box>
    </Box>
  )
}
