/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type OrganizationDtoContactLanguage = typeof OrganizationDtoContactLanguage[keyof typeof OrganizationDtoContactLanguage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationDtoContactLanguage = {
  FINNISH: 'FINNISH',
  ENGLISH: 'ENGLISH',
  SWEDISH: 'SWEDISH',
} as const;
