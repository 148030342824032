import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button } from '@mui/material'
import ControlledTextField from '../../features/inputs/ControlledTextField'
import { ChangePasswordSchemaType, createChangePasswordSchema } from '../../validation/change-password-schema'

interface IChangePasswordDialogProps {
  isOpen: boolean
  handleConfirm: (data: ChangePasswordSchemaType) => void
  handleClose: () => void
}

export const ChangePasswordDialog = ({ isOpen, handleConfirm, handleClose }: IChangePasswordDialogProps) => {
  const { t } = useTranslation()
  const form = useForm<ChangePasswordSchemaType>({
    resolver: zodResolver(createChangePasswordSchema()),
    mode: 'all'
  })

  return (
    <CustomDialog
      title={t('user.changePassword')}
      isOpen={isOpen}
      handleClose={handleClose}
      buttons={
        <>
          <Button variant="contained" type="submit" form="change-password">
            {t('user.save')}
          </Button>
          <CancelButton handleCancel={handleClose} />
        </>
      }
    >
      <FormProvider {...(form as any)}>
        <form id="change-password" onSubmit={form.handleSubmit(handleConfirm)}>
          <FlexColumn gap="16px">
            <Box>{t('user.passwordFormatInfo')}</Box>
            <ControlledTextField
              id="currentPassword"
              name="currentPassword"
              label={t('user.oldPassword')}
              required
              type="password"
              control={form.control}
            />
            <ControlledTextField
              id="newPassword"
              name="newPassword"
              label={t('user.newPassword')}
              required
              type="password"
              control={form.control}
            />
            <ControlledTextField
              id="newPasswordRepeated"
              name="newPasswordRepeated"
              label={t('user.newPasswordAgain')}
              required
              type="password"
              control={form.control}
            />
          </FlexColumn>
        </form>
      </FormProvider>
    </CustomDialog>
  )
}
