import { Autocomplete, Box, createFilterOptions, InputLabel, TextField, Typography } from '@mui/material'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { InfoOutlined } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountSelectItemDto, AutomaticTransferRecipientDtoType, UserViewDto } from '../../api/types'
import { ECERT_API } from '../../services/ecert-api'
import { canTransferDomestic, canTransferExternal, canTransferOrganization } from '../../utils/permission-utils'
import { useLoginUser } from '../../LoginContext'
import { Params, useParams } from 'react-router-dom'
import ControlledTextField from '../inputs/ControlledTextField'
import { Path, useFormContext } from 'react-hook-form'
import { ControlledRadioGroup } from '../inputs/ControlledRadioGroup'
import { AutomaticTransferSchemaType } from '../../validation/automatic-transfer-schema'
import { CertificateTransferSchemaType } from '../../validation/certificate-transfer-schema'

function getPermittedTransferTypes(
  loginUser: UserViewDto,
  params: Params<string>
): AutomaticTransferRecipientDtoType[] {
  return [
    ...(canTransferDomestic(loginUser, params) ? [AutomaticTransferRecipientDtoType.DOMESTIC] : []),
    ...(canTransferOrganization(loginUser, params) ? [AutomaticTransferRecipientDtoType.ORGANIZATION] : []),
    ...(canTransferExternal(loginUser, params) ? [AutomaticTransferRecipientDtoType.EXTERNAL] : [])
  ]
}

type FormContextType = AutomaticTransferSchemaType | CertificateTransferSchemaType

interface ICertificateTransferProps {
  targetOrganizationId: number
  accountId: number
  paths: {
    type: Path<FormContextType>
    selectedAccount: Path<FormContextType>
    targetAccountNumber: Path<FormContextType>
    recipientName: Path<FormContextType>
    additionalDetails: Path<FormContextType>
  }
}

export const CertificateTransfer = ({ targetOrganizationId, accountId, paths }: ICertificateTransferProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const loginUser = useLoginUser()
  const [organizationAccounts, setOrganizationAccounts] = useState<AccountSelectItemDto[]>([])
  const [externalAccounts, setExternalAccounts] = useState<AccountSelectItemDto[]>([])
  const [publicAccounts, setPublicAccounts] = useState<AccountSelectItemDto[]>([])
  const [selectableAccounts, setSelectableAccounts] = useState<AccountSelectItemDto[]>([])
  const [selectedAccount, setSelectedAccount] = useState<AccountSelectItemDto | null>(null)
  const accountFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: AccountSelectItemDto) => option.organizationName! + option.name! + option.accountNumber!
  })
  const { control, setValue, getValues, watch } = useFormContext<FormContextType>()
  const transferType = watch(paths.type)

  useEffect(() => {
    ECERT_API.getOrganizationAccounts(targetOrganizationId).then((response) => {
      setOrganizationAccounts(response.data.filter((account) => account.id !== accountId))
    })

    ECERT_API.getExternalAccounts().then((response) => {
      setExternalAccounts(response.data)
    })

    ECERT_API.getPublicAccounts().then((response) => {
      setPublicAccounts(response.data.filter((account) => account.organizationId !== targetOrganizationId))
    })
  }, [accountId, targetOrganizationId])

  useEffect(() => {
    const accounts = (() => {
      switch (transferType) {
        case AutomaticTransferRecipientDtoType.DOMESTIC:
          return publicAccounts
        case AutomaticTransferRecipientDtoType.ORGANIZATION:
          return organizationAccounts
        case AutomaticTransferRecipientDtoType.EXTERNAL:
          return externalAccounts
        default:
          return []
      }
    })()
    setSelectableAccounts(accounts)
  }, [organizationAccounts, externalAccounts, publicAccounts, transferType])

  useEffect(() => {
    const permittedTransferTypes = getPermittedTransferTypes(loginUser, params)
    if (!permittedTransferTypes.includes(getValues(paths.type) as AutomaticTransferRecipientDtoType)) {
      setValue(paths.type, permittedTransferTypes[0])
    }
  }, [transferType, loginUser, params, paths, getValues, setValue])

  const changeAccount = useCallback(
    (account: AccountSelectItemDto | null) => {
      setSelectedAccount(account)
      setValue(paths.selectedAccount, account)
      if (account) {
        setValue(paths.targetAccountNumber, account.accountNumber, { shouldValidate: true })
        setValue(paths.recipientName, account.organizationId !== 0 ? account.organizationName : account.name, {
          shouldValidate: true
        })
      } else {
        setValue(paths.targetAccountNumber, '', { shouldValidate: true })
        setValue(paths.recipientName, '', { shouldValidate: true })
      }
    },
    [paths.recipientName, paths.selectedAccount, paths.targetAccountNumber, setValue]
  )

  useEffect(() => {
    if (selectableAccounts.length === 0) return
    const existingAccount = selectableAccounts.find((a) => a.accountNumber === getValues(paths.targetAccountNumber))
    if (existingAccount) {
      changeAccount(existingAccount)
    }
  }, [selectableAccounts, paths.targetAccountNumber, changeAccount, getValues])

  const handleAccountSelected = (_event, value: AccountSelectItemDto | null) => {
    changeAccount(value)
  }

  const getTrasferTypeLabel = (type: AutomaticTransferRecipientDtoType): string => {
    switch (type) {
      case AutomaticTransferRecipientDtoType.DOMESTIC:
        return t('transfer.domestic')
      case AutomaticTransferRecipientDtoType.ORGANIZATION:
        return t('transfer.organization')
      case AutomaticTransferRecipientDtoType.EXTERNAL:
        return t('transfer.external')
      default:
        return ''
    }
  }

  return (
    <Box>
      <ControlledRadioGroup
        id={paths.type}
        name={paths.type}
        label=""
        values={getPermittedTransferTypes(loginUser, params).map((value) => ({
          label: getTrasferTypeLabel(value),
          value: value
        }))}
        onChange={() => changeAccount(null)}
        control={control}
      />
      <FlexRow gap="10px" marginTop="16px">
        <InfoOutlined />
        <Typography>{t('common.mandatoryFields')}</Typography>
      </FlexRow>
      <FlexColumn gap="10px">
        <InputLabel sx={{ marginTop: '16px' }}>{`${t('transaction.recipientName')} (${t(
          'form.optional'
        )})`}</InputLabel>
        <Autocomplete
          value={selectedAccount}
          onChange={handleAccountSelected}
          sx={{ minWidth: '400px' }}
          aria-label={`${t('transaction.recipientName')} (${t('form.optional')})`}
          options={selectableAccounts}
          filterOptions={accountFilterOptions}
          groupBy={(account) => account.organizationName!}
          getOptionLabel={(account: AccountSelectItemDto) => `${account.name ? account.name : ''} (${account.accountNumber!})`}
          renderInput={(params) => <TextField autoFocus={true} {...params} />}
        />

        {transferType !== AutomaticTransferRecipientDtoType.ORGANIZATION && (
          <>
            <ControlledTextField
              id={paths.recipientName}
              name={paths.recipientName}
              label={t('transfer.recipientName')}
              required
              control={control}
            />

            <ControlledTextField
              id={paths.targetAccountNumber}
              name={paths.targetAccountNumber}
              label={t('transfer.recipientAccountNumber')}
              required
              control={control}
            />
          </>
        )}

        <ControlledTextField
          id={paths.additionalDetails}
          name={paths.additionalDetails}
          multiline={true}
          minRows={6}
          characterLimit={1000}
          label={`${t('transfer.additionalDetails')}`}
          control={control}
        />
      </FlexColumn>
    </Box>
  )
}
