import { useEffect } from 'react'
import { ProductionDeviceReportViewDto } from '../../../api/types'
import { Typography } from '@mui/material'
import { StyledRightAligned, StyledTable, StyledTableHeaderContainer } from '../../../styles/containers/TableContainers'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { useRegisteredProductionDevices } from '../../../features/store-slices/reportSlice'
import { parseDate, transformDate } from '../../../utils/utils'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { FileExcelOutline } from 'mdi-material-ui'
import { ECERT_API } from '../../../services/ecert-api'

export function RegisteredProductionDevices() {
  const { t } = useTranslation()
  const [registeredProductionDevices] = useRegisteredProductionDevices()

  const columns: IColumn[] = [
    {
      label: t('productionDeviceReport.deviceName'),
      accessor: 'name',
      sortable: true,
      sortByOrder: 'asc',
      role: 'rowheader'
    },
    {
      label: t('productionDeviceReport.deviceGsrn'),
      accessor: 'gsrn',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDeviceReport.dateOfCommissioning'),
      accessor: 'dateOfCommissioning',
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceReportViewDto, b: ProductionDeviceReportViewDto) => {
        if (!a.dateOfCommissioning) return 1
        if (!b.dateOfCommissioning) return -1
        return parseDate(a.dateOfCommissioning).isBefore(parseDate(b.dateOfCommissioning), 'day') ? -1 : 1
      },
      transformFn: transformDate('DD.MM.YYYY')
    },
    {
      label: t('productionDeviceReport.deviceCapacity'),
      accessor: 'capacity',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDeviceReport.deviceTechnology'),
      accessor: (data: ProductionDeviceReportViewDto) => `${data.technologyTypeCode} - ${data.technologyTypeName}`,
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDeviceReport.deviceOwners'),
      accessor: 'owners',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDeviceReport.deviceLocation'),
      accessor: (data: ProductionDeviceReportViewDto) =>
        `${data.postNumber && data.region ? `${data.postNumber}, ${data.region}` : ''} ${
          data.latitude && data.longitude ? `(${data.latitude}, ${data.longitude})` : ''
        }`,
      sortable: true,
      sortByOrder: 'asc'
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionDeviceReport')}`
  }, [t])

  return (
    <>
      <StyledTable gap="10px">
        <StyledTableHeaderContainer>
          <Typography id="title-registered-production-devices" variant="h1">
            {t('productionDeviceReport.title')}
          </Typography>
          <StyledRightAligned>
            <DownloadButton
              fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.REGISTERED_PRODUCTION_DEVICES}
              fileName={`${t('productionDeviceReport.title')}.xlsx`}
              startIcon={<FileExcelOutline />}
            >
              {t('productionDeviceReport.export')}
            </DownloadButton>
          </StyledRightAligned>
        </StyledTableHeaderContainer>
        <EcertTable
          ariaDescribedBy="title-registered-production-devices"
          data={registeredProductionDevices}
          columns={columns}
        ></EcertTable>
      </StyledTable>
    </>
  )
}
