import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductionDeviceInvestmentTypeDto } from '../../../api/types'
import { useFieldArray, useFormContext } from 'react-hook-form'
import AddIcon from '@mui/icons-material/Add'
import { ControlledDateRangePicker } from '../../../features/inputs/ControlledDateRangePicker'
import { FlexColumn } from '../../../styles/containers/FlexContainers'
import { ClearOutlined } from '@mui/icons-material'
import CustomTooltip from '../../../features/custom-tooltip/CustomTooltip'
import ControlledSelect from '../../../features/inputs/ControlledSelect'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

interface FuelProductionSupportsProps {
  fuelIndex: number
  productionSupportSelectItems: ProductionDeviceInvestmentTypeDto[]
}

export default function FuelProductionSupports({
  fuelIndex,
  productionSupportSelectItems
}: FuelProductionSupportsProps) {
  const { t } = useTranslation()

  const { control } = useFormContext<ProductionDeviceSchemaType>()

  const productionSupports = useFieldArray({
    control,
    name: `fuels.${fuelIndex}.productionSupports`
  })

  return (
    <Box>
      {productionSupportSelectItems.length > 0 && (
        <>
          <Box display="flex" flexDirection="column" gap="16px">
            {productionSupports.fields.map((prodSupport, index: number) => {
              return (
                <Box key={prodSupport.id} display="flex" alignItems="center" gap="16px">
                  <FlexColumn flex="1">
                    <ControlledSelect
                      id={`fuels.${fuelIndex}.productionSupports.${index}.investment`}
                      name={`fuels.${fuelIndex}.productionSupports.${index}.investment`}
                      label={t('productionDevice.investment.investment')}
                      required
                      placeholder={t('common.choose')}
                      items={productionSupportSelectItems.map((item) => ({
                        key: item.scheme,
                        value: item,
                        label: item.scheme!
                      }))}
                      control={control}
                    />
                  </FlexColumn>
                  <FlexColumn>
                    <Box marginTop="24px">
                      <CustomTooltip
                        fontSize="30px"
                        questionMarkIcon
                        title={t('productionDevice.energySource.investmentInfo')}
                      />
                    </Box>
                  </FlexColumn>
                  <ControlledDateRangePicker
                    startDateName={`fuels.${fuelIndex}.productionSupports.${index}.validityStartDate`}
                    endDateName={`fuels.${fuelIndex}.productionSupports.${index}.validityEndDate`}
                    label={t('productionDevice.energySource.labels.validity')}
                    required
                    control={control}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      marginTop: '24px',
                      maxWidth: '40px',
                      minWidth: '40px',
                      height: '40px',
                      padding: '12px 16px'
                    }}
                    onClick={() => productionSupports.remove(index)}
                  >
                    <ClearOutlined />
                  </Button>
                </Box>
              )
            })}
          </Box>
          <Button
            sx={{ marginTop: '16px' }}
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              productionSupports.append({
                investment: {},
                validityStartDate: '',
                validityEndDate: ''
              })
            }
          >
            {t('productionDevice.investment.addInvestment')}
          </Button>
        </>
      )}
    </Box>
  )
}
