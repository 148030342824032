import OrganizationForm from '../../features/forms/organization/OrganizationForm'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../services/ecert-api'
import { AxiosResponse } from 'axios'
import { OrganizationDto, OrganizationViewDto } from '../../api/types'
import { useNavigate, useParams } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'

export default function OrganizationEdit() {
  const params = useParams()
  const [organization, setOrganization] = useState<OrganizationViewDto>()
  const navigate = useNavigate()

  useEffect(() => {
    if (params.id) {
      ECERT_API.getOrganization(+params.id).then((response: AxiosResponse<OrganizationViewDto>) => {
        setOrganization(response.data)
      })
    }

    document.title = `Ecert - ${t('title.organizationEdit')}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (organizationDto: OrganizationDto) => {
    if (!params.id) return

    ECERT_API.updateOrganization(+params.id, organizationDto).then((response) => {
      if (response.data.id) enqueueSnackbar(t('form.valid.organization.edited'), snacky.successOpts)
      navigate(EcertRoutes.organization(response.data.id))
    })
  }

  const handleCancel = () => {
    if (!params.id) return

    navigate(EcertRoutes.organization(params.id))
  }

  return (
    <>
      {organization && (
        <OrganizationForm
          mode="EDIT"
          existingOrganization={organization}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
