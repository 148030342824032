/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type AdditionalInvoiceRowDtoProductCode = typeof AdditionalInvoiceRowDtoProductCode[keyof typeof AdditionalInvoiceRowDtoProductCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdditionalInvoiceRowDtoProductCode = {
  VOID: 'VOID',
  ALKUE: 'ALKUE',
  ALKUE255: 'ALKUE255',
  ALKUM: 'ALKUM',
  ALKUM255: 'ALKUM255',
  ALKUT: 'ALKUT',
  ALKUT255: 'ALKUT255',
  ALKUV: 'ALKUV',
  ALKUV255: 'ALKUV255',
  ALKUTV: 'ALKUTV',
  ALKUTV255: 'ALKUTV255',
  ALKUVR: 'ALKUVR',
  ALKUVR255: 'ALKUVR255',
  ALKUTO: 'ALKUTO',
  ALKUTO255: 'ALKUTO255',
  ALKUMU: 'ALKUMU',
  ALKUMU255: 'ALKUMU255',
} as const;
