import { Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import StyleGuide from '../../styles/StyleGuide'

interface ICustomTooltipProps {
  title: string
  questionMarkIcon?: boolean
  fontSize?: string
}

export default function CustomTooltip({ title, questionMarkIcon = false, fontSize }: ICustomTooltipProps) {
  return (
    <>
      <Tooltip title={title}>
        {questionMarkIcon ? (
          <HelpOutlineOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND, fontSize: fontSize }} />
        ) : (
          <InfoOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND, fontSize: fontSize }} />
        )}
      </Tooltip>
    </>
  )
}
