import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { ReturnButton } from './components/ReturnButton'
import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import { SelectedCertificateBundles } from './components/SelectedCertificateBundles'
import { useTranslation } from 'react-i18next'
import { EcertTextArea } from '../../../features/forms/text-area/EcertTextArea'
import { Check, InfoOutlined } from '@mui/icons-material'
import CancelButton from '../../../features/buttons/CancelButton'
import ConfirmButton from '../../../features/buttons/ConfirmButton'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import { ECERT_API } from '../../../services/ecert-api'
import { CorrectionTransactionDto } from '../../../api/types'
import {
  SelectedCertificate,
  selectSelectedCertificates,
  unSelectCertificates
} from '../../../features/store-slices/certificateSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { EcertRoutes } from '../../../ecert-routes'
import { enqueueSuccessNotification, hasValidationErrorWithKey, isValidationError } from '../../../utils/utils'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { cleanAfterSubmit } from '../account-utils'

interface IOrganizationAccountTransactionCorrection {
  organizationId: number
  accountId: number
}

export const OrganizationAccountTransactionCorrection = ({
  organizationId,
  accountId
}: IOrganizationAccountTransactionCorrection) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [additionalInfo, setAdditionalInfo] = useState('')
  const [correctionDialogOpen, setCorrectionDialogOpen] = useState(false)
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)
  const [additionalInfoError, setAdditionalInfoError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    document.title = `Ecert - ${t('title.correctCertificates')}`
  }, [t])

  const handleAdditionalInfoChanged = (event) => {
    setAdditionalInfo(event.target.value)
  }

  const handleAdditionalInfoError = (error) => {
    setAdditionalInfoError(error)
  }

  const enqueueCorrectCertificatesNotification = (correctionAmount: number, transactionId: number) => {
    const certificateTransferCompletedContent: React.ReactNode = (
      <Box>
        {t('form.valid.correction.confirmed', {
          correctionAmount: correctionAmount
        })}
        <Link
          onClick={() => {
            closeSnackbar()
            navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, transactionId))
          }}
        >
          {t('transaction.transactionInfo')}
        </Link>
      </Box>
    )
    enqueueSuccessNotification(certificateTransferCompletedContent)
  }

  const handleConfirmCorrection = () => {
    setIsSubmitting(true)

    const correctionTransactionDto: CorrectionTransactionDto = {
      sourceAccountId: accountId,
      additionalDetails: additionalInfo,
      corrections: selectedCertificates.map((certificate) => ({
        id: certificate.bundle.id,
        amount: certificate.selectedAmount
      }))
    }
    ECERT_API.correctCertificates(organizationId, accountId, correctionTransactionDto)
      .then((response) => {
        const correctionsAmount = correctionTransactionDto.corrections.reduce(
          (prev, curr) => (prev += +curr.amount!),
          0
        )
        enqueueCorrectCertificatesNotification(correctionsAmount, response.data)
        cleanAfterSubmit(dispatch, organizationId, accountId)
      })
      .catch((error) => {
        if (hasValidationErrorWithKey('abroadIssued', error)) {
          enqueueSnackbar(t('form.invalid.abroadIssued'), snacky.errorOpts)
        } else if (hasValidationErrorWithKey('organizationMismatch', error)) {
          enqueueSnackbar(t('form.invalid.organizationMismatch'), snacky.errorOpts)
        } else if (isValidationError(error)) {
          enqueueSnackbar(t('form.invalid.unknown'), snacky.errorOpts)
        }
      })
      .finally(() => {
        setIsSubmitting(false)
        setCorrectionDialogOpen(false)
      })
  }

  const handleCancel = () => {
    dispatch(unSelectCertificates())
  }

  const totalSelectedCertificatesCount = () => {
    return selectedCertificates.reduce((prev, curr) => (prev += +curr.selectedAmount), 0)
  }

  return (
    <FlexColumn styles={{ width: '800px' }} gap="10px">
      <FlexRow>
        <ReturnButton organizationId={organizationId} accountId={accountId} />
      </FlexRow>
      <FlexColumn gap="10px">
        <Typography variant="h1">{t('correction.title')}</Typography>

        <SelectedCertificateBundles
          titleText={t('correction.bundles')}
          bundleDescription={t('correction.amount', {
            amount: totalSelectedCertificatesCount()
          })}
        />

        <FlexRow gap="10px">
          <InfoOutlined />
          <Typography>{t('common.mandatoryFields')}</Typography>
        </FlexRow>

        <EcertTextArea
          initialText={additionalInfo}
          label={t('correction.additionaldetailsTitle')}
          characterLimit={1000}
          characterLimitExceededErrorText={t('form.invalid.additionalDetails.maxlength')}
          minRows={6}
          onChange={handleAdditionalInfoChanged}
          onError={handleAdditionalInfoError}
        />

        <FlexRow gap="10px">
          <Button
            disabled={additionalInfoError || isSubmitting}
            onClick={() => setCorrectionDialogOpen(true)}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : <Check />}
            variant="contained"
          >
            {t('correction.confirmCorrection')}
          </Button>
          <CancelButton disabled={isSubmitting} handleCancel={handleCancel} />
        </FlexRow>

        <CustomDialog
          isOpen={correctionDialogOpen}
          title={t('correction.confirmDialogTitle')}
          handleClose={() => setCorrectionDialogOpen(false)}
          buttons={
            <>
              <ConfirmButton
                startIcon={isSubmitting ? <CircularProgress size={20} /> : undefined}
                disabled={isSubmitting}
                handleConfirm={handleConfirmCorrection}
              />
              <CancelButton disabled={isSubmitting} handleCancel={() => setCorrectionDialogOpen(false)} />
            </>
          }
        >
          <Box>{t('correction.confirmDialogMessage')}</Box>
        </CustomDialog>
      </FlexColumn>
    </FlexColumn>
  )
}
