import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { LocaleCode } from './utils/utils'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { ApiErrorType } from './services/ecert-api'
import { AxiosResponse } from 'axios'

export const useAppDispatch: () => AppDispatch = useDispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

type DeferredCallbackType<T1, T2, T3> = (param1: T1, param2: T2, param3: T3) => void

export function useDeferredCallback<T1 = void, T2 = void, T3 = void>(
  callback: DeferredCallbackType<T1, T2, T3>,
  delayMs: number = 300
): DeferredCallbackType<T1, T2, T3> {
  const timeoutHandle = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => clearCurrentTimeout()
  }, [])

  const clearCurrentTimeout = () => {
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current)
      timeoutHandle.current = null
    }
  }

  return (param1: T1, param2: T2, param3: T3) => {
    clearCurrentTimeout()
    timeoutHandle.current = setTimeout(() => {
      timeoutHandle.current = null
      callback(param1, param2, param3)
    }, delayMs)
  }
}

export function useAppLanguage(): {
  currentLanguage: LocaleCode
  changeLanguage: (language: LocaleCode) => Promise<TFunction>
} {
  const { i18n } = useTranslation()

  const changeLanguage = useCallback(
    (language: LocaleCode) => {
      return i18n.changeLanguage(language)
    },
    [i18n]
  )

  return {
    currentLanguage: i18n.language as LocaleCode,
    changeLanguage: changeLanguage
  }
}

export function useApiError(callback: (error: ApiErrorType, response: AxiosResponse) => void): void {
  // Pass the latest version of callback to useEffect without adding callback to dependency array
  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    const handleEvent = (event: Event) => {
      const detail = (event as CustomEvent).detail
      callbackRef.current(detail.error, detail.response)
    }
    window.addEventListener('api-error', handleEvent)
    return () => {
      window.removeEventListener('api-error', handleEvent)
    }
  }, [])
}

export function useVirtualScroll<T>(
  containerRef: RefObject<HTMLElement | null | undefined>,
  rows: T[] | undefined,
  pageSize: number,
  triggerOffset: number = 10
): T[] | undefined {
  const [visibleRowCount, setVisibleRowCount] = useState(pageSize)

  const prevScrollDistance = useRef(Number.MAX_VALUE)

  useEffect(() => {
    const scrollHandler = () => {
      if (containerRef.current && rows && rows.length > pageSize) {
        const scrollDistance =
          containerRef.current.getBoundingClientRect().bottom - document.documentElement.clientHeight
        if (prevScrollDistance.current > triggerOffset && scrollDistance <= triggerOffset) {
          setVisibleRowCount((prev) => {
            return Math.min(rows.length, prev + pageSize)
          })
        }
        prevScrollDistance.current = scrollDistance
      }
    }

    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [containerRef, rows, pageSize, triggerOffset])

  return rows ? rows.slice(0, visibleRowCount) : undefined
}
