import { Dispatch, SetStateAction } from 'react'
import { Box, IconButton, TableCell, TableHead, TableRow } from '@mui/material'
import { IColumn } from './EcertTable'
import CustomTooltip from '../custom-tooltip/CustomTooltip'
import { FlexRow } from '../../styles/containers/FlexContainers'
import { ExpandLess, ExpandMore, UnfoldMore } from '@mui/icons-material'

interface IEcertTableHeadProps {
  columns: IColumn[]
  sortColumn: IColumn | null
  setSortColumn: Dispatch<SetStateAction<IColumn | null>>
  sortOrder: string
  setSortOrder: Dispatch<SetStateAction<'asc' | 'desc'>>
}

export default function EcertTableHead({
  columns,
  sortColumn,
  setSortColumn,
  sortOrder,
  setSortOrder
}: IEcertTableHeadProps) {
  const handleSortingChange = (col: IColumn) => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
    setSortColumn(col)
    setSortOrder(newSortOrder)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((col, idx) => {
          let sortIcon = <></>
          if (col.sortable) {
            sortIcon = <UnfoldMore />
          }
          if (col.sortable && sortColumn?.label === col.label) {
            sortIcon = sortOrder === 'asc' ? <ExpandLess /> : <ExpandMore />
          }

          const ariaSort = sortColumn?.label === col.label ? (sortOrder === 'asc' ? 'ascending' : 'descending') : 'none'

          return (
            <TableCell
              aria-sort={ariaSort}
              key={`${col.label}${idx}`}
              className={col.sortable ? 'cursor-pointer sortable' : ''}
              onClick={col.sortable ? () => handleSortingChange(col) : undefined}
            >
              {typeof col.label === 'string' ? (
                <FlexRow alignItems="center">
                  <Box>{col.label}</Box>
                  {col.sortable && (
                    <IconButton aria-label={`sort-${col.label}`} id={`sort-${col.label}`}>
                      {sortIcon}
                    </IconButton>
                  )}
                  {col.tooltip && <CustomTooltip title={col.tooltip} />}
                </FlexRow>
              ) : (
                <FlexRow>{col.label}</FlexRow>
              )}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}
