import { Divider, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface IGridFooterProps {
  total: string
}

export function GridFooter({ total }: IGridFooterProps) {
  const { t } = useTranslation()

  return (
    <>
      <Divider flexItem sx={{ mt: '8px', ml: '8px', width: '100%' }} />
      <Grid container item spacing={4} sx={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>
        <Grid item xs={11} sx={{ padding: 0 }}>
          {t('common.total')}
        </Grid>
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          width="111px"
          margin="auto"
          sx={{ textWrap: 'nowrap', padding: 0 }}
        >
          {total}
        </Grid>
      </Grid>
    </>
  )
}
