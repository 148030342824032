import 'dayjs/locale/en'
import 'dayjs/locale/fi'
import 'dayjs/locale/sv'
import { Button, InputLabel, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import {
  AccountDto,
  OrganizationListItemDto,
  PermissionDto,
  PermissionDtoPermissionsItem,
  ProductionDeviceListViewDto,
  UserDto,
  UserDtoLanguage,
  UserEditDto,
  UserViewDto
} from '../../../api/types'
import { useEffect, useState } from 'react'
import OrganizationSelect from '../../organization-select/OrganizationSelect'
import { ECERT_API } from '../../../services/ecert-api'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useParams } from 'react-router-dom'
import {
  getWithoutPermissions,
  isAccountAdmin,
  isAdmin,
  isAnyPermissionSet,
  isPermissionSet,
  isUser
} from '../../../utils/permission-utils'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { UserFormSchemaType, createUserFormSchema } from '../../../validation/user-form-schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ControlledTextField from '../../inputs/ControlledTextField'
import { ControlledRadioGroup } from '../../inputs/ControlledRadioGroup'
import { useLoginUser } from '../../../LoginContext'
import { InfoBox } from '../../info-box/InfoBox'
import AutorizationEntityPermissions from './AutorizationEntityPermissions'

type FormMode = 'CREATE' | 'EDIT'

interface IOrganizationFormProps {
  mode: FormMode
  existingUser?: UserViewDto
  handleSubmitCreate?: (organizationId: number, dto: UserDto) => void
  handleSubmitEdit?: (organizationId: number, dto: UserEditDto) => void
  handleCancel: () => void
}

const ADMIN_ORGANIZATION_ID = 172 // Fingrid organization
const INSPECTOR_ORGANIZATION_ID = 7533 // Energiavirasto organization

export default function UserForm({
  mode,
  existingUser,
  handleSubmitCreate,
  handleSubmitEdit,
  handleCancel
}: IOrganizationFormProps) {
  const loginUser = useLoginUser()
  const params = useParams()
  const { t } = useTranslation()
  const [organizations, setOrganizations] = useState<OrganizationListItemDto[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListItemDto | null>(null)
  const [organizationProductionDevices, setOrganizationProductionDevices] = useState<
    ProductionDeviceListViewDto[] | undefined
  >(undefined)
  const [organizationAccounts, setOrganizationAccounts] = useState<AccountDto[] | undefined>(undefined)
  const [permissions, setPermissions] = useState<PermissionDto>(
    existingUser
      ? existingUser.permission!
      : {
          permissions: [],
          productionDevices: [],
          accounts: []
        }
  )

  const form = useForm<UserFormSchemaType>({
    resolver: zodResolver(createUserFormSchema()),
    mode: 'all',
    defaultValues: existingUser
      ? { ...existingUser }
      : {
          language: 'FINNISH'
        }
  })

  useEffect(() => {
    if (isAdmin(loginUser)) {
      ECERT_API.getAllOrganizations().then((response) => {
        setOrganizations(response.data)
        setSelectedOrganization(response.data.find((org) => org.id! === +params.organizationId!)!)
      })
    }

    if (isAccountAdmin(loginUser)) {
      ECERT_API.getAccounts(loginUser.organizationId!).then((response) => {
        setOrganizationAccounts(response.data)
      })

      ECERT_API.getOrganizationProductionDevices(loginUser.organizationId!).then((response) => {
        setOrganizationProductionDevices(response.data)
      })
    }
    // eslint-disable-next-line
  }, [loginUser])

  useEffect(() => {
    if (mode === 'EDIT') {
      ECERT_API.getOrganizationProductionDevices(existingUser?.organizationId!).then((response) => {
        setOrganizationProductionDevices(response.data)
      })

      ECERT_API.getAccountsForOrganization(existingUser?.organizationId!).then((response) => {
        setOrganizationAccounts(response.data)
      })
    }
    // eslint-disable-next-line
  }, [existingUser])

  useEffect(() => {
    if (mode === 'CREATE' && selectedOrganization) {
      ECERT_API.getOrganizationProductionDevices(selectedOrganization.id!).then((response) => {
        setOrganizationProductionDevices(response.data)
      })

      ECERT_API.getAccountsForOrganization(selectedOrganization.id!).then((response) => {
        setOrganizationAccounts(response.data)
      })
    }
    // eslint-disable-next-line
  }, [selectedOrganization])

  const getPermissionsForSubmit = (data: PermissionDto): PermissionDto => {
    let permissions = data.permissions ?? []
    if (isPermissionSet(data, PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL)) {
      permissions = getWithoutPermissions(permissions, [
        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED,
        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_VIEW,
        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION
      ])
    }
    if (isPermissionSet(data, PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL)) {
      permissions = getWithoutPermissions(permissions, [
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION
      ])
    }
    return {
      permissions: permissions,
      productionDevices: isAnyPermissionSet(data, [
        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL,
        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED
      ])
        ? []
        : data.productionDevices,
      accounts: isAnyPermissionSet(data, [
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL,
        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED
      ])
        ? []
        : data.accounts
    }
  }

  const handleFormSubmit: SubmitHandler<any> = (data: UserFormSchemaType) => {
    if (mode === 'CREATE') {
      const user: UserDto = {
        id: 0,
        username: data.username!,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        language: data.language as UserDtoLanguage,
        permission: getPermissionsForSubmit(permissions)
      }

      handleSubmitCreate?.(selectedOrganization?.id || 0, user)
    } else {
      const user: UserEditDto = {
        id: existingUser?.id,
        username: existingUser?.username!,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        language: data.language as UserDtoLanguage,
        permission: getPermissionsForSubmit(permissions),
        oldPassword: '' // oldPassword is being overwritten in confirm dialog
      }

      handleSubmitEdit?.(0, user)
    }
  }

  return (
    <FlexColumn id={mode === 'CREATE' ? 'organization-create' : 'organization-edit'} gap="16px">
      <Typography variant="h1">
        {t(
          mode === 'CREATE'
            ? 'title.userNew'
            : existingUser?.id === loginUser.id
            ? 'user.editTitle'
            : 'user.editAdminTitle'
        )}
      </Typography>
      <InfoBox>{t('common.mandatoryFields')}</InfoBox>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleFormSubmit)}>
          <FlexColumn gap="16px" width="max-content">
            {mode === 'CREATE' && isAdmin(loginUser) && (
              <OrganizationSelect
                canSelectAll={false}
                value={selectedOrganization}
                organizations={organizations}
                onSelect={(organization) => {
                  setSelectedOrganization(organization)
                }}
              />
            )}

            {mode === 'EDIT' ? (
              <>
                <InputLabel>{t('user.info.username')}</InputLabel>
                <Typography variant="body1">{existingUser?.username}</Typography>
              </>
            ) : (
              <ControlledTextField
                id="username"
                name="username"
                label={t('user.info.username')}
                required
                placeholder={t('user.info.username')}
                control={form.control}
              />
            )}
            <ControlledTextField
              id="firstName"
              name="firstName"
              label={t('user.info.firstName')}
              required
              placeholder={t('user.info.firstName')}
              control={form.control}
            />
            <ControlledTextField
              id="lastName"
              name="lastName"
              label={t('user.info.lastName')}
              required
              placeholder={t('user.info.lastName')}
              control={form.control}
            />
            <ControlledTextField
              id="email"
              name="email"
              label={t('user.info.email')}
              required
              placeholder={t('user.info.email')}
              control={form.control}
            />
            <ControlledTextField
              id="phone"
              name="phone"
              label={t('user.info.phone')}
              required
              placeholder={t('user.info.phone')}
              tooltip={t('user.info.phoneInfo')}
              control={form.control}
            />
            <ControlledRadioGroup
              name="language"
              values={[
                { label: `${t('language.FINNISH')}`, value: 'FINNISH' },
                { label: `${t('language.ENGLISH')}`, value: 'ENGLISH' },
                { label: `${t('language.SWEDISH')}`, value: 'SWEDISH' }
              ]}
              label={t('user.info.language')}
              required
              control={form.control}
            />

            <FlexColumn gap="16px">
              {permissions &&
                organizationProductionDevices !== undefined &&
                organizationAccounts !== undefined &&
                selectedOrganization?.id !== ADMIN_ORGANIZATION_ID &&
                selectedOrganization?.id !== INSPECTOR_ORGANIZATION_ID &&
                (isAdmin(loginUser) || isAccountAdmin(loginUser)) &&
                (mode === 'CREATE' || (existingUser && isUser(existingUser))) && (
                  <>
                    <AutorizationEntityPermissions
                      localizationKeyPerfix="user.permission.productionDevice"
                      authorizationTargetAccessor="productionDevices"
                      permissionsDefinition={permissions}
                      setPermissionsDefinition={setPermissions}
                      generalPermission={PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_GENERAL}
                      unlimitedPermission={PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_UNLIMITED}
                      selectablePermissions={[
                        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_VIEW,
                        PermissionDtoPermissionsItem.PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION
                      ]}
                      selectableAuthorizationTargets={organizationProductionDevices.map((device) => ({
                        id: device.id!,
                        label: `${device.name} (${device.gsrn})`
                      }))}
                    />
                    <AutorizationEntityPermissions
                      localizationKeyPerfix="user.permission.account"
                      authorizationTargetAccessor="accounts"
                      permissionsDefinition={permissions}
                      setPermissionsDefinition={setPermissions}
                      generalPermission={PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_GENERAL}
                      unlimitedPermission={PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_UNLIMITED}
                      selectablePermissions={[
                        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_VIEW,
                        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_CANCELLATION,
                        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION,
                        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_DOMESTIC,
                        PermissionDtoPermissionsItem.PERMISSION_ACCOUNT_TRANSFER_EXTERNAL
                      ]}
                      selectableAuthorizationTargets={organizationAccounts.map((account) => ({
                        id: account.id!,
                        label: `${account.name} ${account.accountNumber}`
                      }))}
                    />
                  </>
                )}

              <FlexRow marginTop="16px" gap="16px">
                <Button startIcon={<CheckIcon />} variant="contained" disabled={!form.formState.isValid} type="submit">
                  {t(mode === 'EDIT' ? 'common.save' : 'user.link.new')}
                </Button>
                <Button startIcon={<ClearIcon />} variant="outlined" onClick={handleCancel}>
                  {t('common.cancel')}
                </Button>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </form>
      </FormProvider>
    </FlexColumn>
  )
}
