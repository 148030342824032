import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { OrganizationViewDto, UserDto } from '../../api/types'
import './Organization.css'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import ListIcon from '@mui/icons-material/List'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import { Alert, Box, Button, Divider, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { ECERT_API } from '../../services/ecert-api'
import { EcertRoutes } from '../../ecert-routes'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import SelectRepresentativesDialog from './SelectRepresentativesDialog'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  clearOrganization,
  fetchOrganization,
  selectOrganization
} from '../../features/store-slices/organizationsSlice'
import StyleGuide from '../../styles/StyleGuide'
import { isAdmin } from '../../utils/permission-utils'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { LockEntityButton } from '../../features/buttons/LockEntityButton'
import { useLoginUser } from '../../LoginContext'

export default function Organization() {
  const { t } = useTranslation()
  const params = useParams()
  const theme = useTheme()
  const lgBreakpoint = useMediaQuery(theme.breakpoints.down(1400))
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up(1400))
  const navigate = useNavigate()
  const [openSelectRepresentativesModal, setOpenSelectRepresentativesModal] = useState(false)
  const dispatch = useAppDispatch()
  const organization = useAppSelector<OrganizationViewDto | null>(selectOrganization)
  const loginUser = useLoginUser()

  useEffect(() => {
    reloadOrganization()
    document.title = `Ecert - ${t('title.organization')}`

    return () => {
      dispatch(clearOrganization())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reloadOrganization = () => {
    if (params.id) {
      dispatch(fetchOrganization(+params.id))
    }
  }

  const InfoRow = (props: { label: string; propertyName: string }) => {
    return (
      <Box>
        <Typography variant="label">{props.label}</Typography>
        <Box sx={{ marginTop: '4px' }}>{organization?.[props.propertyName]}</Box>
      </Box>
    )
  }

  const RepresentativeRow = (props: { representative: UserDto }) => {
    return (
      <Box className="representative-row flex-column flex-gap-10">
        <Box className="flex-row-centered flex-gap-10">
          <PersonOutlineIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          <Link
            onClick={() => navigate(EcertRoutes.organizationUser(params.id, props.representative.id))}
            style={{ color: StyleGuide.constants.COLOR_BRAND }}
          >{`${props.representative.firstName} ${props.representative.lastName}`}</Link>
        </Box>
        <Box className="flex-row-centered flex-gap-10">
          <PhoneOutlinedIcon />
          <span>{props.representative.phone}</span>
        </Box>
        <Box className="flex-row-centered flex-gap-10">
          <MailOutlineIcon />
          <span>{props.representative.email}</span>
        </Box>
      </Box>
    )
  }

  const ContractValidityRow = () => {
    return (
      <Box>
        <Typography variant="label">{t('organization.organization.contractValidity')}</Typography>
        <Box sx={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
          <span>{dayjs(organization?.contractStart, 'YYYY-MM-DD').format('DD.MM.YYYY')}</span>
          <span>&nbsp;-&nbsp;</span>
          {organization?.contractEnd ? (
            dayjs(organization?.contractEnd, 'YYYY-MM-DD').diff() > 0 ? (
              <Typography sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                {t('organization.info.organization.contractValid')}
              </Typography>
            ) : (
              <>
                <Typography sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                  {dayjs(organization?.contractEnd, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </Typography>
                &nbsp;
                <Typography sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                  {t('organization.info.organization.contractInvalid')}
                </Typography>
              </>
            )
          ) : (
            <Typography sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
              {t('organization.info.organization.contractValid')}
            </Typography>
          )}
        </Box>
      </Box>
    )
  }

  const StreetAddressRow = () => {
    return (
      <Box>
        <Typography variant="label">{t('organization.organization.streetAddress')}</Typography>
        <Box sx={{ marginTop: '4px' }}>{`${organization?.streetAddress1} ${organization?.streetAddress2 || ''}`}</Box>
      </Box>
    )
  }

  const ContactLanguageRow = () => {
    return (
      <Box>
        <Typography variant="label">{t('organization.organization.contactLanguage.caption')}</Typography>
        <Box sx={{ marginTop: '4px' }}>{t(`language.${organization?.contactLanguage}`)}</Box>
      </Box>
    )
  }

  const handleLockOrganization = (comment: string) => {
    if (!params.id) return

    ECERT_API.lockOrganization(+params.id, comment).then(() => {
      reloadOrganization()
    })
  }

  const handleUnlockOrganization = () => {
    if (!params.id) return

    ECERT_API.unlockOrganization(+params.id).then(() => {
      reloadOrganization()
    })
  }

  const handleSelectRepresentatives = (organizationMainUserIds: number[]) => {
    if (!params.id) return

    ECERT_API.updateOrganizationMainUsers(+params.id, organizationMainUserIds).then(() => {
      setOpenSelectRepresentativesModal(false)
      reloadOrganization()
      enqueueSnackbar(t('form.valid.organization.mainUserChanged'), snacky.successOpts)
    })
  }

  return (
    <Box id="organization">
      {organization?.lockedStatus?.locked && (
        <Alert severity="warning">
          <Box>
            {t('organization.lockedMessage', {
              locker: organization?.lockedStatus?.entityLockerName
            })}
            {t('locking.comment')}:
          </Box>
          <Box>
            <pre>{organization?.lockedStatus?.lockingComment ?? t('locking.noComment')}</pre>
          </Box>
        </Alert>
      )}
      <Box className="flex-row-centered flex-gap-20" sx={{ marginBottom: '28px', marginTop: '20px' }}>
        <Typography variant="h1">{organization?.name}</Typography>

        {isAdmin(loginUser) && (
          <LockEntityButton
            lockStatus={organization?.lockedStatus}
            onLock={handleLockOrganization}
            onUnlock={handleUnlockOrganization}
          />
        )}
      </Box>
      <Box className={`data-container ${lgBreakpoint ? 'flex-column' : ''} ${xlBreakpoint ? 'flex-row' : ''}`}>
        <Box className="info">
          <Box className="heading flex-row-centered" sx={{ marginBottom: '24px' }}>
            <Typography variant="h2" sx={{ marginRight: '24px' }}>
              {t('organization.info.organization.title')}
            </Typography>
            {isAdmin(loginUser) && (
              <Button
                disabled={organization?.lockedStatus?.locked}
                onClick={() => navigate(EcertRoutes.organizationEdit(params.id))}
                className="ecert-btn-secondary"
                variant="outlined"
                startIcon={<EditOutlinedIcon />}
              >
                {t('organization.info.organization.edit')}
              </Button>
            )}
          </Box>
          <Divider sx={{ marginBottom: '16px' }} />
          <Box className="flex-column flex-gap-10">
            <InfoRow label={t('organization.organization.id')} propertyName="id"></InfoRow>
            <InfoRow label={t('organization.organization.name')} propertyName="name"></InfoRow>
            <ContractValidityRow></ContractValidityRow>
            <InfoRow label={t('organization.organization.customerNumber')} propertyName="customerNumber"></InfoRow>
            <InfoRow label={t('organization.organization.contractNumber')} propertyName="contractNumber"></InfoRow>
            <InfoRow label={t('organization.organization.code')} propertyName="code"></InfoRow>
            <InfoRow label={t('organization.organization.aibCode')} propertyName="mainAccountNumber"></InfoRow>
            <InfoRow label={t('organization.organization.invoiceReference')} propertyName="invoiceReference"></InfoRow>
            <StreetAddressRow></StreetAddressRow>
            <InfoRow label={t('organization.organization.postNumber')} propertyName="postNumber"></InfoRow>
            <InfoRow label={t('organization.organization.region')} propertyName="region"></InfoRow>
            <InfoRow label={t('organization.organization.country')} propertyName="countryName"></InfoRow>
            <ContactLanguageRow></ContactLanguageRow>
          </Box>
        </Box>
        <Box className="representatives">
          <Box className="heading flex-row-centered" sx={{ marginBottom: '24px' }}>
            <Typography variant="h2" sx={{ marginRight: '24px' }}>
              {t('organization.info.user.title')}
            </Typography>
            {isAdmin(loginUser) && (
              <Button
                disabled={organization?.lockedStatus?.locked}
                onClick={() => setOpenSelectRepresentativesModal(true)}
                className="ecert-btn-secondary"
                variant="outlined"
                startIcon={<EditOutlinedIcon />}
              >
                {t('organization.info.user.change')}
              </Button>
            )}
          </Box>
          <Divider sx={{ marginBottom: '20px' }} />

          <Box className="flex-column flex-gap-20">
            {organization?.mainUsers?.map((user) => <RepresentativeRow key={user.id} representative={user} />)}
          </Box>
        </Box>

        <Box className="quick-links">
          <Box className="heading">
            <Typography variant="h2" sx={{ marginBottom: '30px' }}>
              {t('organization.info.shortcuts.title')}
            </Typography>
          </Box>
          <Divider sx={{ marginBottom: '20px' }} />
          <Box className="flex-column flex-gap-10">
            <Box className="flex-row-centered flex-gap-10">
              <PeopleOutlineIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              <Link
                onClick={() => navigate(EcertRoutes.organizationUsers(params.id))}
                style={{ color: StyleGuide.constants.COLOR_BRAND }}
              >{`${t('organization.bar.menu.users')} (${organization?.userCount})`}</Link>
            </Box>
            <Box className="flex-row-centered flex-gap-10">
              <ListIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              <Link
                onClick={() => navigate(EcertRoutes.organizationAccounts(params.id))}
                style={{ color: StyleGuide.constants.COLOR_BRAND }}
              >{`${t('organization.bar.menu.accounts')} (${organization?.accountCount})`}</Link>
            </Box>
            <Box className="flex-row-centered flex-gap-10">
              <FlashOnIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
              <Link
                onClick={() => navigate(EcertRoutes.organizationProductionDevices(params.id))}
                style={{ color: StyleGuide.constants.COLOR_BRAND }}
              >{`${t('organization.bar.menu.powerPlants')} (${organization?.productionDeviceCount})`}</Link>
            </Box>
          </Box>
        </Box>
      </Box>

      {organization && organization.id && (
        <SelectRepresentativesDialog
          isOpen={openSelectRepresentativesModal}
          organizationId={organization.id}
          handleClose={() => setOpenSelectRepresentativesModal(false)}
          handleConfirm={handleSelectRepresentatives}
        />
      )}
    </Box>
  )
}
