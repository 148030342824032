import styled from 'styled-components'
import StyleGuide from '../../styles/StyleGuide'

const FixedBottom = styled.div.attrs((props: { justifyContent: string }) => props)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  background-color: ${StyleGuide.constants.COLOR_SECONDARY};
  color: ${StyleGuide.constants.COLOR_WHITE};
`

export { FixedBottom }
