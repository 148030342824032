import { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../services/ecert-api'
import StyleGuide from '../../styles/StyleGuide'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import { EcertRoutes } from '../../ecert-routes'
import { InfoBox } from '../../features/info-box/InfoBox'

export default function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [sendStatus, setSendStatus] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [tokenLoginError, setTokenLoginError] = useState(false)

  useEffect(() => {
    document.title = `Ecert - ${t('title.userForgotPassword')}`
  }, [t])

  useEffect(() => {
    if (searchParams.has('tokenLoginError')) {
      setTokenLoginError(true)
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams])

  const handleStateChange = (event: any) => {
    if (!event.target.value.match('^\\S+@\\S+$')) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }

    setEmail(event.target.value)
    setSendStatus(false)
  }

  const forgotPasswordSubmit = (event) => {
    event.preventDefault()
    ECERT_API.forgotPassword(email).then(() => {
      setSendStatus(true)
    })
  }

  return (
    <Box
      sx={{
        backgroundImage: 'url(./img/login_bg.png)',
        backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'fit-content',
          alignSelf: 'center',
          padding: '48px 168px',
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
          flexDirection: 'column',
          minHeight: '600px',
          maxWidth: '800px'
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box
            id="login"
            component="img"
            src="./img/fingrid-logo.svg"
            alt="ecert-navbar-logo"
            sx={{ height: '30px', marginBottom: '16px' }}
          />
          <Box>
            <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: '16px' }}>
              {t('forgotPassword.title')}
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '16px' }}>
              {t('forgotPassword.info')}
            </Typography>

            <Box
              id="login"
              component="form"
              onSubmit={forgotPasswordSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <TextField
                variant="outlined"
                id="email"
                name="email"
                type="email"
                label={t('forgotPassword.email')}
                value={email}
                onChange={handleStateChange}
              />
              {!formValid && email.length > 0 && (
                <InfoBox type="ERROR">{t('form.invalid.mainUser.email.email')}</InfoBox>
              )}
              {sendStatus && <InfoBox type="SUCCESS">{t('forgotPassword.emailSend')}</InfoBox>}
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button id="send-email" startIcon={<LoginIcon />} variant="contained" type="submit">
                  {t('common.send')}
                </Button>
                <Button startIcon={<ClearIcon />} variant="outlined" onClick={() => navigate(EcertRoutes.paths.login)}>
                  {t('common.cancel')}
                </Button>
              </Box>
              {tokenLoginError && (
                <Box sx={{ marginTop: '50px' }}>
                  <InfoBox type="ERROR">{t('forgotPassword.tokenLoginError')}</InfoBox>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
