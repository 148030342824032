/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ProductionDeviceDtoIssuingPeriod = typeof ProductionDeviceDtoIssuingPeriod[keyof typeof ProductionDeviceDtoIssuingPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductionDeviceDtoIssuingPeriod = {
  EVERY_MONTH: 'EVERY_MONTH',
  EVERY_3_MONTHS: 'EVERY_3_MONTHS',
  EVERY_6_MONTHS: 'EVERY_6_MONTHS',
} as const;
