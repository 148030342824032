import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

// Translation files
import translationFI from './locales/fi/translation.json'
import translationEN from './locales/en/translation.json'
import translationSV from './locales/sv/translation.json'

const resources = {
  fi: {
    translation: translationFI
  },
  en: {
    translation: translationEN
  },
  sv: {
    translation: translationSV
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fi',
    interpolation: {
      escapeValue: false
    },
    keySeparator: '.',
    supportedLngs: ['fi', 'en', 'sv'],
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      lookupCookie: 'appLanguage',
      caches: ['cookie']
    }
})

export default i18n
