import { ProductionInfoTransferDto } from '../../api/types'
import { RequestStatus, RequestError } from './store-types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ECERT_API } from '../../services/ecert-api'
import { RootState } from '../../store'

interface IProductionInfoTransfersState {
  productionInfoTransfers: ProductionInfoTransferDto[] | undefined
  status: RequestStatus
  requestError: RequestError
}

const initialState: IProductionInfoTransfersState = {
  productionInfoTransfers: undefined,
  status: 'idle',
  requestError: undefined
}

// Reducers
export const productionInfoTransfersSlice = createSlice({
  name: 'productionInfoTransfers',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<ProductionInfoTransferDto>) => {
      if (state.productionInfoTransfers) {
        state.productionInfoTransfers.push({ ...action.payload })
      } else {
        state.productionInfoTransfers = [action.payload]
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductionInfoTransfers.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchProductionInfoTransfers.fulfilled, (state, action) => {
        state.status = 'success'
        state.productionInfoTransfers = action.payload
      })
      .addCase(fetchProductionInfoTransfers.rejected, (state, action) => {
        state.status = 'fail'
        state.requestError = action.error.message
      })
  }
})

// Actions
export const { add } = productionInfoTransfersSlice.actions

// Selectors
export const selectProductionInfoTransfers = (state: RootState) => state.productionInfoTransfers.productionInfoTransfers

// Effects/Thunks
export const fetchProductionInfoTransfers = createAsyncThunk('production-info-transfers/fetchAll', async () => {
  const response = await ECERT_API.getProductionInfoTransfers()
  return response.data
})

export default productionInfoTransfersSlice.reducer
