import { CertificateBundleDto } from '../../api/types'
import {
  selectCertificates,
  SelectedCertificate,
  SelectMode,
  unSelectCertificates
} from '../../features/store-slices/certificateSlice'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { sum } from '../../utils/utils'
import { enqueueSnackbar } from 'notistack'
import { fetchAccountDetails, unSelectBundles } from '../../features/store-slices/organizationAccountsSlice'

export const selectCertificatesUpToAmount = (
  t,
  processedCertificateAmount: number,
  dispatch,
  certificateBundles: CertificateBundleDto[],
  selectMode: SelectMode
) => {
  if (certificateBundles.length === 0) {
    enqueueSnackbar(t('account.transactionProcessingErrorNoCertificates'), snacky.errorOpts)
    return
  }

  const remainingAmount = sum(certificateBundles.map((bundle) => bundle.amount!))
  if (processedCertificateAmount > remainingAmount) {
    let snackbarText = ''
    if (selectMode === 'TRANSFER') {
      snackbarText = 'transfer.missingCertificatesFromSourceTransaction'
    } else if (selectMode === 'CANCEL') {
      snackbarText = 'cancellation.missingCertificatesFromSourceTransaction'
    } else if (selectMode === 'CORRECTION') {
      snackbarText = 'correction.missingCertificatesFromSourceTransaction'
    }
    enqueueSnackbar(
      t(snackbarText, { sourceTransactionAmount: processedCertificateAmount, actualAmount: remainingAmount }),
      snacky.warningOpts
    )
  }

  const selectedBundlesWithCertificateCounts: SelectedCertificate[] = []
  certificateBundles.forEach((bundle) => {
    if (remainingAmount > 0) {
      if (remainingAmount <= bundle.amount!) {
        selectedBundlesWithCertificateCounts.push({
          bundle: bundle,
          selectedAmount: remainingAmount
        })
      } else {
        selectedBundlesWithCertificateCounts.push({
          bundle: bundle,
          selectedAmount: bundle.amount!
        })
      }
    }
  })
  dispatch(selectCertificates({ selectedCertificates: selectedBundlesWithCertificateCounts, selectMode: selectMode }))
}

export const cleanAfterSubmit = (dispatch, organizationId: number, accountId: number) => {
  dispatch(unSelectCertificates())
  dispatch(unSelectBundles())
  dispatch(
    fetchAccountDetails({
      organizationId: organizationId,
      accountId: accountId
    })
  )
}
