/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type LogEntryDtoOperation = typeof LogEntryDtoOperation[keyof typeof LogEntryDtoOperation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogEntryDtoOperation = {
  GET: 'GET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UNKNOWN: 'UNKNOWN',
} as const;
