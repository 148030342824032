/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ProductionDeviceViewDtoStatus = typeof ProductionDeviceViewDtoStatus[keyof typeof ProductionDeviceViewDtoStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductionDeviceViewDtoStatus = {
  DRAFT: 'DRAFT',
  WAITING_APPROVAL: 'WAITING_APPROVAL',
  RETURNED: 'RETURNED',
  REFUSED: 'REFUSED',
  APPROVED: 'APPROVED',
} as const;
