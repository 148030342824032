import { SyntheticEvent, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Navbar from './features/navbar/Navbar'
import Front from './pages/front/Front'
import Footer from './features/footer/Footer'
import { Organizations } from './pages/organizations/Organizations'
import RequireAuth from './features/require-auth/RequireAuth'
import Organization from './pages/organization/Organization'
import OrganizationCreate from './pages/organization-create/OrganizationCreate'
import OrganizationEdit from './pages/organization-edit/OrganizationEdit'
import Accounts from './pages/accounts/Accounts'
import Users from './pages/users/Users'
import User from './pages/user/User'
import UserCreate from './pages/user-create/UserCreate'
import ProductionDevices from './pages/production-devices/ProductionDevices'
import ProductionInfoTransfers from './pages/production-info-transfers/ProductionInfoTransfers'
import AddProductionDevice from './pages/production-devices/AddProductionDevice'
import EditProductionDevice from './pages/production-devices/EditProductionDevice'
import { RegisteredProductionDevices } from './pages/reports/registered-production-devices/RegisteredProductionDevices'
import { LabelTags } from './pages/reports/label-tags/LabelTags'
import { EcertRoutes } from './ecert-routes'
import NotificationEdit from './pages/notifications/notification-edit/NotificationEdit'
import Settings from './pages/settings/Settings'
import { Contacts } from './pages/reports/contacts/Contacts'
import { InvoicePeriodList } from './pages/reports/invoice/InvoicePeriodList'
import { InvoicePeriod } from './pages/reports/invoice/InvoicePeriod'
import { Issuing } from './pages/reports/issuing/Issuing'
import AibMembers from './pages/aib-members/AibMembers'
import { Box } from '@mui/material'
import { StyledMain, StyledSkipLink } from './App.styles'
import Login from './pages/login/Login'
import ForgotPassword from './pages/login/ForgotPassword'
import ChangePasswordForced from './pages/login/ChangePasswordForced'
import OrganizationAccount from './pages/organization-account/OrganizationAccount'
import OrganizationAccountTransaction from './pages/organization-account/transaction/OrganizationAccountTransaction'
import { CancellationEdit } from './pages/organization-account/transaction/CancellationEdit'
import { ExternalTransfer } from './pages/reports/external-transfer/ExternalTransfer'
import { EventLog } from './pages/reports/event-log/EventLog'
import { TransactionsReport } from './pages/reports/transactions/TransactionsReport'
import { ProductionInfoTransfer } from './pages/production-info-transfer/ProductionInfoTransfer'
import { UserNotificationEdit } from './pages/user-notification-edit/UserNotificationEdit'
import { UserEdit } from './pages/user-edit/UserEdit'
import ProductionDevice from './pages/production-device/ProductionDevice'
import { NotificationArchive } from './pages/notifications/notification-archive/NotificationArchive'
import { getTranslation, isValidationError } from './utils/utils'
import {
  canCreateProductionDevices,
  canCreateUser,
  canEditProductionDevice,
  canEditUser,
  canViewAccount,
  canViewAccounts,
  canViewEventLog,
  canViewExternalTransfers,
  canViewLabel,
  canViewInvoices,
  canViewIssuings,
  canViewOrganization,
  canViewOrganizations,
  canViewProductionDevice,
  canViewProductionDevices,
  canViewProductionInfoTransfers,
  canViewSignedDocuments,
  canViewTransaction,
  canViewTransactions,
  canViewUser,
  canViewUsers,
  isAdmin
} from './utils/permission-utils'
import { useApiError, useAppLanguage } from './hooks'
import { ApiErrorType } from './services/ecert-api'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import * as snacky from './features/custom-snackbar-provider/CustomSnackbarProvider'
import { Notifications } from './pages/notifications/Notifications'
import { NotificationCreate } from './pages/notifications/notification-create/NotificationCreate'
import { SignedDocuments } from './pages/reports/signed-documents/SignedDocuments'

function App() {
  const { t } = useTranslation()
  const location = useLocation()
  const { currentLanguage } = useAppLanguage()

  useApiError((error, response) => {
    if (!isValidationError(response)) {
      const errorMessage = (() => {
        switch (error) {
          case ApiErrorType.CONNECTION:
            return t('common.error.connection')
          case ApiErrorType.AUTHORIZATION:
            return t('common.error.missingAuthorization')
          case ApiErrorType.OTHER_CLIENT:
            return t('common.error.unexpectedClient')
          case ApiErrorType.OTHER_SERVER:
            return t('common.error.unexpectedServer')
          case ApiErrorType.UNKNOWN:
            return t('common.error.unexpected')
          default:
            return null
        }
      })()
      if (errorMessage) {
        enqueueSnackbar(errorMessage, snacky.errorOpts)
      }
    }
  })

  useEffect(() => {
    document.documentElement.lang = currentLanguage
  }, [currentLanguage])

  useEffect(() => {
    const container: HTMLElement | null = document.getElementById('app-container')
    if (container) {
      container.focus()
    }
  }, [location])

  const handleSkipToContentOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    const container: HTMLElement | null = document.querySelector('main:first-of-type')

    if (container) {
      container.tabIndex = -1
      container.focus()
      setTimeout(() => container.removeAttribute('tabindex'))
    }
  }

  return (
    <Box
      id="app-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: '100%'
      }}
      tabIndex={0}
    >
      <Box>
        <StyledSkipLink href="#main-id" onClick={handleSkipToContentOnClick}>
          {getTranslation('skipToMainContent')}
        </StyledSkipLink>
      </Box>
      <Navbar />
      <StyledMain id="main-id" isLogin={EcertRoutes.isLoginPage(location)}>
        <Routes>
          <Route path={EcertRoutes.paths.login} element={<Login />} />
          <Route path={EcertRoutes.paths.forgotPassword} element={<ForgotPassword />} />
          <Route path={EcertRoutes.paths.changePasswordForced} element={<ChangePasswordForced />} />
          <Route
            path={EcertRoutes.paths.root}
            element={
              <RequireAuth>
                <Front />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.organizations.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewOrganizations}>
                <Organizations />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.organizations.organization}
            element={
              <RequireAuth hasPermissionToRoute={canViewOrganization}>
                <Organization />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.organizations.create}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <OrganizationCreate />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.organizations.edit}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <OrganizationEdit />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.accounts.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewAccounts}>
                <Accounts />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.users.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewUsers}>
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.productionDevice.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewProductionDevice}>
                <ProductionDevice />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.productionDevices}
            element={
              <RequireAuth hasPermissionToRoute={canViewProductionDevices}>
                <ProductionDevices />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.addProductionDevice}
            element={
              <RequireAuth hasPermissionToRoute={canCreateProductionDevices}>
                <AddProductionDevice />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.editProductionDevice}
            element={
              <RequireAuth hasPermissionToRoute={canEditProductionDevice}>
                <EditProductionDevice />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.users.user.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewUser}>
                <User />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.users.create}
            element={
              <RequireAuth hasPermissionToRoute={canCreateUser}>
                <UserCreate />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.users.user.edit}
            element={
              <RequireAuth hasPermissionToRoute={canEditUser}>
                <UserEdit />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.productionInfoTransfers.root}
            element={
              <RequireAuth hasPermissionToRoute={canViewProductionInfoTransfers}>
                <ProductionInfoTransfers />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.productionInfoTransfers.productionInfoTransfer}
            element={
              <RequireAuth hasPermissionToRoute={canViewProductionInfoTransfers}>
                <ProductionInfoTransfer />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.registeredProductionDevices}
            element={
              <RequireAuth>
                <RegisteredProductionDevices />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.labels}
            element={
              <RequireAuth hasPermissionToRoute={canViewLabel}>
                <LabelTags />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.notifications.edit}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <NotificationEdit />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.notifications.archive}
            element={
              <RequireAuth>
                <NotificationArchive />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.configuration}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <Settings />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.contacts}
            element={
              <RequireAuth>
                <Contacts />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.invoice}
            element={
              <RequireAuth hasPermissionToRoute={canViewInvoices}>
                <InvoicePeriodList />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.invoicePeriod}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <InvoicePeriod />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.aibMembers.root}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <AibMembers />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.accounts.account}
            element={
              <RequireAuth hasPermissionToRoute={canViewAccount}>
                <OrganizationAccount />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.accounts.accountTransaction}
            element={
              <RequireAuth hasPermissionToRoute={canViewTransaction}>
                <OrganizationAccountTransaction />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.accounts.accountTransactionEdit}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <CancellationEdit />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.issuing}
            element={
              <RequireAuth hasPermissionToRoute={canViewIssuings}>
                <Issuing />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.externalTransfer}
            element={
              <RequireAuth hasPermissionToRoute={canViewExternalTransfers}>
                <ExternalTransfer />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.eventLog}
            element={
              <RequireAuth hasPermissionToRoute={canViewEventLog}>
                <EventLog />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.transactions}
            element={
              <RequireAuth hasPermissionToRoute={canViewTransactions}>
                <TransactionsReport />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.reports.signedDocuments}
            element={
              <RequireAuth hasPermissionToRoute={canViewSignedDocuments}>
                <SignedDocuments />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.users.user.notificationEdit}
            element={
              <RequireAuth hasPermissionToRoute={canEditUser}>
                <UserNotificationEdit />
              </RequireAuth>
            }
          />
          <Route
            path={EcertRoutes.paths.notifications.root}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <Notifications></Notifications>
              </RequireAuth>
            }
          ></Route>
          <Route
            path={EcertRoutes.paths.notifications.create}
            element={
              <RequireAuth hasPermissionToRoute={isAdmin}>
                <NotificationCreate></NotificationCreate>
              </RequireAuth>
            }
          ></Route>
          <Route path="*" element={<Navigate to={EcertRoutes.paths.root} />} />
        </Routes>
      </StyledMain>
      <Footer />
    </Box>
  )
}

export default App
