import styled from 'styled-components'

const FlexRow = styled.div.attrs(
  (props: { justifyContent: string; alignItems: string; gap: string; flexGrow: number; styles: any }) => props
)`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  gap: ${(props) => props.gap};
  flex-grow: ${(props) => props.flexGrow};
  ${(props) => ({ ...props.styles })};
  ${(props) => ({ ...props })};
`

const FlexColumn = styled.div.attrs(
  (props: { justifyContent: string; alignItems: string; gap: string; flexGrow: number; styles: any }) => props
)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  gap: ${(props) => props.gap};
  flex-grow: ${(props) => props.flexGrow};
  ${(props) => ({ ...props.styles })};
  ${(props) => ({ ...props })};
`

export { FlexRow, FlexColumn }
