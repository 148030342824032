import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import './services/i18n'
import './mui-overrides-theme'
import { ThemeProvider } from '@mui/material'
import { muiOverridesTheme } from './mui-overrides-theme'
import { CustomSnackbarProvider } from './features/custom-snackbar-provider/CustomSnackbarProvider'
import { LoginContextProvider } from './LoginContext'
import { AppInfoContextProvider } from './AppInfoContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <ThemeProvider theme={muiOverridesTheme}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <CustomSnackbarProvider>
          <AppInfoContextProvider>
            <LoginContextProvider>
              <App />
            </LoginContextProvider>
          </AppInfoContextProvider>
        </CustomSnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
)
