import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../services/ecert-api'
import StyleGuide from '../../styles/StyleGuide'
import { useNavigate } from 'react-router-dom'
import ClearIcon from '@mui/icons-material/Clear'
import { EcertRoutes } from '../../ecert-routes'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { PasswordChangeDto } from '../../api/types'
import { LoginStatus, useLoginContext } from '../../LoginContext'
import { InfoBox } from '../../features/info-box/InfoBox'
import { AxiosError } from 'axios'

type SendStatus = 'SUCCESS' | 'FAIL'

type ErrorResponse = { messageKey: string }

export default function ChangePasswordForced() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { loginStatus, refreshLoginContext } = useLoginContext()
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordAgain, setNewPasswordAgain] = useState('')
  const [sendStatus, setSendStatus] = useState<SendStatus>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const isPasswordInvalid = () => {
    if (!newPassword || !newPasswordAgain) return true

    if (newPassword.length < 8 || !newPassword.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/)) return true

    if (newPassword !== newPasswordAgain) return true

    return false
  }

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value)
  }

  const handleNewPasswordAgainChange = (event) => {
    setNewPasswordAgain(event.target.value)
  }

  const changePasswordSubmit = async (event) => {
    event.preventDefault()

    const passwordChangeDto: PasswordChangeDto = {
      newPassword: newPassword,
      newPasswordAgain: newPasswordAgain,
      password: ''
    }
    ECERT_API.changePassword(passwordChangeDto)
      .then(() => {
        setSendStatus('SUCCESS')
      })
      .catch((e: AxiosError<ErrorResponse>) => {
        setSendStatus('FAIL')

        if (!e.response) {
          return
        }

        setErrorMessage(e.response.data[0].messageKey)
      })
  }

  const handleLogin = async () => {
    await refreshLoginContext()
    navigate(EcertRoutes.paths.root)
  }

  const handleCancel = async () => {
    await ECERT_API.logout()
    await refreshLoginContext()
    navigate(EcertRoutes.paths.login)
  }

  if (loginStatus === LoginStatus.NO_LOGIN || loginStatus === LoginStatus.UNVERIFIED_LOGIN) {
    navigate(EcertRoutes.paths.login)
  }

  return (
    <Box
      sx={{
        backgroundImage: 'url(./img/login_bg.png)',
        backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'fit-content',
          alignSelf: 'center',
          padding: '48px 168px',
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
          flexDirection: 'column',
          minHeight: '712px',
          maxWidth: '800px'
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box
            id="change-password"
            component="img"
            src="./img/fingrid-logo.svg"
            alt="ecert-navbar-logo"
            sx={{ height: '30px', marginBottom: '16px' }}
          />
          <Box>
            <Typography variant="h2" sx={{ textAlign: 'center', marginBottom: '16px' }}>
              {t('user.passwordNeedsChangingTitle')}
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: '16px' }}>
              {t('user.passwordFormatInfo')}
            </Typography>

            <Box
              id="login"
              component="form"
              onSubmit={changePasswordSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <TextField
                variant="outlined"
                id="newPassword"
                name="newPassword"
                type="password"
                label={t('user.newPassword')}
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
              {newPassword && newPassword.length < 8 && (
                <Typography
                  sx={{
                    textAlign: 'left',
                    color: StyleGuide.constants.COLOR_BRAND
                  }}
                >
                  {t('form.invalid.password.newPassword.minlength')}
                </Typography>
              )}
              {newPassword && !newPassword.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/) && (
                <Typography
                  sx={{
                    textAlign: 'left',
                    color: StyleGuide.constants.COLOR_BRAND
                  }}
                >
                  {t('form.invalid.password.newPassword.pattern')}
                </Typography>
              )}
              <TextField
                variant="outlined"
                id="newPasswordAgain"
                name="newPasswordAgain"
                type="password"
                label={t('user.newPasswordAgain')}
                value={newPasswordAgain}
                onChange={handleNewPasswordAgainChange}
              />
              {newPasswordAgain && newPassword !== newPasswordAgain && (
                <Typography
                  sx={{
                    textAlign: 'left',
                    color: StyleGuide.constants.COLOR_BRAND
                  }}
                >
                  {t('form.invalid.password.newPassword.equals')}
                </Typography>
              )}
              {sendStatus && (
                <>
                  {sendStatus === 'SUCCESS' && <InfoBox type="SUCCESS">{t('form.valid.user.passwordChanged')}</InfoBox>}
                  {sendStatus === 'FAIL' && !errorMessage && (
                    <InfoBox type="ERROR">{t('form.invalid.unknown')}</InfoBox>
                  )}
                  {sendStatus === 'FAIL' && errorMessage === 'samePasswordAsOldPassword' && (
                    <InfoBox type="ERROR">{t('forgotPassword.samePasswordAsOldPassword')}</InfoBox>
                  )}
                </>
              )}
              {sendStatus === 'SUCCESS' && (
                <Button variant="contained" onClick={handleLogin}>
                  {t('loginPage.login')}
                </Button>
              )}
              {(!sendStatus || sendStatus === 'FAIL') && (
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Button
                    id="change-password"
                    startIcon={<CheckOutlinedIcon />}
                    variant="contained"
                    type="submit"
                    disabled={isPasswordInvalid()}
                  >
                    {t('user.changePassword')}
                  </Button>
                  <Button startIcon={<ClearIcon />} variant="outlined" onClick={handleCancel}>
                    {t('common.cancel')}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
