import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../styles/StyleGuide'
import { Button, ButtonGroup } from '@mui/material'
import { useAppLanguage } from '../../hooks'

function LanguageSelect() {
  const [t] = useTranslation()
  const { currentLanguage, changeLanguage } = useAppLanguage()

  return (
    <Box sx={{ minWidth: 60, marginLeft: 'auto' }}>
      <ButtonGroup aria-label="Change language button group" variant="text">
        <Button
          onClick={() => changeLanguage('fi')}
          sx={{
            color:
              currentLanguage === 'fi' ? StyleGuide.constants.COLOR_TEXT_PRIMARY : StyleGuide.constants.COLOR_BRAND,
            ':hover': {
              color: StyleGuide.constants.COLOR_TEXT_PRIMARY
            }
          }}
        >
          {t('languageCode.FINNISH')}
        </Button>
        <Button
          onClick={() => changeLanguage('en')}
          sx={{
            color:
              currentLanguage === 'en' ? StyleGuide.constants.COLOR_TEXT_PRIMARY : StyleGuide.constants.COLOR_BRAND,
            ':hover': {
              color: StyleGuide.constants.COLOR_TEXT_PRIMARY
            }
          }}
        >
          {t('languageCode.ENGLISH')}
        </Button>
        <Button
          onClick={() => changeLanguage('sv')}
          sx={{
            color:
              currentLanguage === 'sv' ? StyleGuide.constants.COLOR_TEXT_PRIMARY : StyleGuide.constants.COLOR_BRAND,
            ':hover': {
              color: StyleGuide.constants.COLOR_TEXT_PRIMARY
            }
          }}
        >
          {t('languageCode.SWEDISH')}
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default LanguageSelect
