import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { FormControl, MenuItem, Select, Typography, Box, FormHelperText } from '@mui/material'
import StyleGuide from '../../styles/StyleGuide'
import InputContainer from './InputContainer'
import { isDeepEqual, isEmptyObj, isObj } from '../../utils/utils'

type ItemType<V> = {
  value: V // Value to be returned
  label: string // options
  key?: string | number
  description?: string // If you want to show some description text above selections
}

interface SelectMenuItemProps<V> {
  descriptionVisible: boolean
  item: ItemType<V>
}

const SelectMenuItem = <V,>({ descriptionVisible, item }: SelectMenuItemProps<V>) => {
  return (
    <Box>
      {descriptionVisible && item.description && (
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {item.description}
        </Typography>
      )}
      {item.label}
    </Box>
  )
}

interface ControlledSelectProps<T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>> {
  id: string
  name: P
  label?: string
  required?: boolean
  placeholder?: string
  fullWidth?: boolean
  errorAccessor?: string
  disabled?: boolean
  items: ItemType<V>[]
  customErrorText?: string
  saveAsObject?: boolean
  control: Control<T>
}

const ControlledSelect = <T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>>({
  id,
  name,
  label,
  required,
  placeholder,
  fullWidth = true,
  items,
  errorAccessor,
  disabled = false,
  customErrorText,
  control
}: ControlledSelectProps<T, P, V>) => {
  const getSelectValue = (formValue: V): V | '' => {
    // Use !formValue because form data value 0 is considered as a not selected value
    if (!formValue || isEmptyObj(formValue)) {
      return ''
    }
    if (isObj(formValue)) {
      return items.find((item) => isDeepEqual(item.value, formValue))?.value ?? ''
    }
    return formValue
  }

  return (
    <InputContainer id={id} label={label} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field, formState }) => {
          return (
            <FormControl fullWidth={fullWidth}>
              <Select
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                displayEmpty={!!placeholder}
                value={getSelectValue(field.value)}
                renderValue={(value: V | '') => {
                  if (!value && placeholder) {
                    return <em>{placeholder}</em>
                  }
                  const item = items.find((item) => item.value === value)
                  if (item) {
                    return <SelectMenuItem descriptionVisible={false} item={item} />
                  }
                  return undefined
                }}
                onChange={field.onChange}
              >
                {items.map((item) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      <SelectMenuItem descriptionVisible={true} item={item} />
                    </MenuItem>
                  )
                })}
              </Select>
              {formState.errors &&
                errorAccessor &&
                formState.errors[errorAccessor] &&
                formState.errors[errorAccessor]?.message && (
                  <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                    {formState.errors[errorAccessor]?.message?.toString() || ''}
                  </FormHelperText>
                )}

              {customErrorText && (
                <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>{customErrorText}</FormHelperText>
              )}
            </FormControl>
          )
        }}
      />
    </InputContainer>
  )
}

export default ControlledSelect
