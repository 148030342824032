import React from 'react'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { Box, Popper, Typography } from '@mui/material'

export const CertificateLabelCode = (props: { code: string }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closePopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'ics-code-popper' : undefined

  return (
    <>
      <ElectricalServicesIcon
        component="svg"
        aria-describedby={id}
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
      />
      <Popper
        style={{ zIndex: 99999 }}
        open={open}
        anchorEl={anchorEl}
        placement={'top'}
        sx={{
          pointerEvents: 'none'
        }}
      >
        <Box sx={{ border: 1, bgcolor: 'background.paper' }}>
          <Typography sx={{ pointerEvents: 'auto', p: 1 }}>{props.code}</Typography>
        </Box>
      </Popper>
    </>
  )
}
