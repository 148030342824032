import { ReactNode } from 'react'
import { downloadFile } from '../../utils/utils'
import { Link } from '@mui/material'

interface IDownloadLinkProps {
  fileUrl: string
  fileName: string
  children: ReactNode
}

export const DownloadLink = ({ fileUrl, fileName, children }: IDownloadLinkProps) => {
  return <Link onClick={() => downloadFile({ fileUrl, fileName })}>{children}</Link>
}
