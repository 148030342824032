import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import StyleGuide from '../../styles/StyleGuide'

const StyledLink = styled(NavLink)`
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1.625rem;
  box-sizing: border-box;
  color: ${StyleGuide.constants.COLOR_TEXT_PRIMARY};

  &.active {
    background-color: ${StyleGuide.constants.COLOR_SECONDARY};
    color: ${StyleGuide.constants.COLOR_WHITE};
  }
`

export { StyledLink }
