import { TextField, Typography } from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { getValue } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../../styles/containers/FlexContainers'
import InputContainer from './InputContainer'

interface ControlledTextFieldProps<T extends FieldValues, P extends Path<T>> {
  id?: string
  name: P
  label?: string
  tooltip?: string
  required?: boolean
  type?: string
  placeholder?: string
  fullWidth?: boolean
  accessor?: string
  endSuffix?: string
  multiline?: boolean
  minRows?: number
  characterLimit?: number
  disabled?: boolean
  control: Control<T>
}

const ControlledTextField = <T extends FieldValues, P extends Path<T>>({
  id,
  name,
  label,
  required,
  type = 'text',
  fullWidth = true,
  placeholder = '',
  accessor,
  endSuffix,
  multiline = false,
  minRows,
  characterLimit,
  disabled = false,
  tooltip,
  control
}: ControlledTextFieldProps<T, P>) => {
  const { t } = useTranslation()

  return (
    <InputContainer id={id} label={label} required={required} tooltip={tooltip}>
      <Controller
        key={id}
        control={control}
        name={name}
        render={({ field, formState }) => {
          return (
            <>
              <TextField
                disabled={disabled}
                InputLabelProps={{ shrink: false }}
                placeholder={placeholder}
                id={id}
                fullWidth={fullWidth}
                type={type}
                multiline={multiline}
                minRows={minRows}
                InputProps={{
                  endAdornment: endSuffix ? endSuffix : null
                }}
                onChange={
                  !accessor ? field.onChange : (e) => field.onChange({ ...field.value, [accessor]: e.target.value })
                }
                value={!accessor ? field.value ?? '' : field.value[accessor ?? '']}
                error={!!getValue(formState.errors, name)}
                helperText={getValue(formState.errors, name) ? getValue(formState.errors, `${name}.message`) : ''}
              />
              {characterLimit !== undefined && (
                <FlexRow justifyContent="flex-end">
                  <Typography variant="body1">
                    {`${t('form.charactersLeft')}: `}
                    {Math.max(characterLimit - ((field.value as string)?.length ?? 0), 0)}
                  </Typography>
                </FlexRow>
              )}
            </>
          )
        }}
      />
    </InputContainer>
  )
}

export default ControlledTextField
