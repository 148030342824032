import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'
import NavMenu from '../nav-menu/NavMenu'
import { EcertRoutes } from '../../ecert-routes'
import { StyledNav, StyledList, StyledLink } from './Navbar.styles'
import Header from '../header/Header'
import {
  canViewAccounts,
  canViewEventLog,
  canViewExternalTransfers,
  canViewLabel,
  canViewInvoices,
  canViewIssuings,
  canViewOrganizations,
  canViewProductionDevices,
  canViewProductionInfoTransfers,
  canViewSignedDocuments,
  canViewTransactions,
  canViewUsers,
  isAdmin
} from '../../utils/permission-utils'
import { LoginStatus, useLoginContext } from '../../LoginContext'

export default function Navbar() {
  const { loginStatus, loginUser } = useLoginContext()
  const { t } = useTranslation()
  const theme = useTheme()
  const lgBreakpoint = useMediaQuery(theme.breakpoints.down(1400))
  const xlBreakpoint = useMediaQuery(theme.breakpoints.up(1400))

  const getNavMenuItems = () => {
    const menuItems: any[] = []
    if (loginUser) {
      if (canViewOrganizations(loginUser)) {
        menuItems.push({
          label: t('title.organizationList'),
          link: EcertRoutes.organizations()
        })
      }

      if (canViewAccounts(loginUser)) {
        menuItems.push({
          label: t('title.accountList'),
          link: EcertRoutes.accounts()
        })
      }

      if (canViewUsers(loginUser)) {
        menuItems.push({
          label: t('title.userList'),
          link: EcertRoutes.users()
        })
      }

      if (canViewProductionDevices(loginUser)) {
        menuItems.push({
          label: t('title.productionDeviceList'),
          link: EcertRoutes.productionDevices()
        })
      }

      if (canViewProductionInfoTransfers(loginUser)) {
        menuItems.push({
          label: t('productionInfo.menuName'),
          link: EcertRoutes.productionInfoTransfers()
        })
      }

      if (isAdmin(loginUser)) {
        menuItems.push({
          label: t('title.notifications'),
          link: EcertRoutes.notifications()
        })
      }
    }
    return menuItems
  }

  const getReportMenuItems = () => {
    const menuItems: any[] = []

    menuItems.push({
      label: t('productionDeviceReport.menuName'),
      link: EcertRoutes.reportRegisteredProductionDevices()
    })

    if (loginUser) {
      if (canViewIssuings(loginUser)) {
        menuItems.push({
          label: t('issuingReport.menuName'),
          link: EcertRoutes.reportIssuing()
        })
      }

      if (canViewLabel(loginUser)) {
        menuItems.push({
          label: t('labelReport.menuName'),
          link: EcertRoutes.reportLabel()
        })
      }

      if (canViewTransactions(loginUser)) {
        menuItems.push({
          label: t('transactionReport.menuName'),
          link: EcertRoutes.reportTransactions()
        })
      }

      if (canViewSignedDocuments(loginUser)) {
        menuItems.push({
          label: t('signedDocumentsReport.menuName'),
          link: EcertRoutes.reportSignedDocuments()
        })
      }

      if (canViewExternalTransfers(loginUser)) {
        menuItems.push({
          label: t('externalTransferReport.menuName'),
          link: EcertRoutes.reportExternalTransfer()
        })
      }

      if (canViewEventLog(loginUser)) {
        menuItems.push({
          label: t('eventLog.menuName'),
          link: EcertRoutes.reportEventLog()
        })
      }

      if (canViewInvoices(loginUser)) {
        menuItems.push({
          label: t('invoiceDetailsReport.menuName'),
          link: EcertRoutes.reportInvoice()
        })
      }
    }

    menuItems.push({
      label: t('organizationContacts.menuName'),
      link: EcertRoutes.reportContacts()
    })

    return menuItems
  }

  return (
    <>
      <Header />
      {loginStatus === LoginStatus.VERIFIED_LOGIN && (
        <StyledNav>
          <StyledList>
            <>
              {xlBreakpoint && (
                <>
                  <li>
                    <StyledLink to="/" end>
                      {t('title.frontPage')}
                    </StyledLink>
                  </li>
                  {getNavMenuItems().map(({ label, link }) => (
                    <li key={label}>
                      <StyledLink to={link}>{label}</StyledLink>
                    </li>
                  ))}
                </>
              )}

              {lgBreakpoint && (
                <li>
                  <NavMenu buttonText={t('common.functions')} menuItems={getNavMenuItems()}></NavMenu>
                </li>
              )}
              <li>
                <NavMenu buttonText={t('common.reports')} menuItems={getReportMenuItems()}></NavMenu>
              </li>
              {loginUser && isAdmin(loginUser) && (
                <>
                  <li className="verified-li">
                    <StyledLink to="/configuration">{t('appConfig.menuName')}</StyledLink>
                  </li>
                  <li className="verified-li">
                    <StyledLink to="/aib-members">{t('aibMember.menuName')}</StyledLink>
                  </li>
                </>
              )}
            </>
          </StyledList>
        </StyledNav>
      )}
    </>
  )
}
