import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../services/ecert-api'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import StyleGuide from '../../../styles/StyleGuide'
import { useFieldArray, useFormContext } from 'react-hook-form'
import FuelForm from '../forms/FuelForm'
import AddIcon from '@mui/icons-material/Add'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

export default function FuelFormContainer() {
  const { t } = useTranslation()
  const { control } = useFormContext<ProductionDeviceSchemaType>()
  const { fields, remove, append } = useFieldArray({ control, name: 'fuels' })

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="16px"
        marginBottom="16px"
      >
        <Typography variant="h2">{t('productionDevice.energySource.energySources')}</Typography>
        <DownloadButton
          variant="outlined"
          fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_FUEL_CORRESPONDENCE}
          fileName={t('productionDevice.energySource.guide') + '.xlsx'}
          startIcon={<DownloadOutlinedIcon />}
        >
          {t('productionDevice.energySource.guide')}
        </DownloadButton>
      </Box>

      <Box display="flex" gap="16px" flexDirection="column">
        {fields.map((fuel, idx) => {
          return (
            <Box key={fuel.id} bgcolor={StyleGuide.constants.COLOR_SECONDARY_LIGHT_4}>
              <Box bgcolor={StyleGuide.constants.COLOR_SECONDARY_LIGHT_4} display="flex" gap="16px" padding="16px">
                <FuelForm fuelIndex={idx} removeRow={() => remove(idx)} />
              </Box>
            </Box>
          )
        })}
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              append({
                fuelCode: 'F00000000',
                fuel: {
                  description1: '',
                  description2: '',
                  description3: '',
                  description4: ''
                },
                labels: [],
                productionSupports: []
              })
            }
          >
            {t('productionDevice.energySource.addEnergySource')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
