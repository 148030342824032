import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import ControlledTextField from '../../../features/inputs/ControlledTextField'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import { useFormContext } from 'react-hook-form'
import { ParticipantDto } from '../../../api/types'
import { useCallback, useEffect, useState } from 'react'

interface OwnersFormProps {
  index: number
  participants: ParticipantDto[]
}

export default function OwnerForm({ index, participants }: OwnersFormProps) {
  const { t } = useTranslation()
  const [inputMethod, setInputMethod] = useState<'select' | 'type'>('select')
  const [selectedOwnerCode, setSelectedOwnerCode] = useState<string>()
  const { getValues, setValue, trigger, control } = useFormContext<ProductionDeviceSchemaType>()

  const getPrticipantByCode = useCallback(
    (code: string) => {
      return participants.find((participant) => code === participant.code)
    },
    [participants]
  )

  const updateTextFields = useCallback(
    (participant: ParticipantDto | undefined) => {
      if (participant) {
        setValue(`owners.${index}.code`, participant.code ?? '')
        setValue(`owners.${index}.name`, participant.name)
        trigger('owners')
      }
    },
    [setValue, trigger, index]
  )

  useEffect(() => {
    const participant = getPrticipantByCode(getValues(`owners.${index}.code`))
    if (inputMethod === 'select') {
      setSelectedOwnerCode(participant ? participant.code : undefined)
      updateTextFields(participant)
    }
  }, [inputMethod, getPrticipantByCode, updateTextFields, getValues, index])

  const getParticipantDisplayValue = (selector: string | ParticipantDto) => {
    const participant = typeof selector === 'string' ? getPrticipantByCode(selector) : selector
    return participant ? `${participant.name} (${participant.code})` : ''
  }

  const handleInputMethodToggle = () => {
    setInputMethod(inputMethod === 'select' ? 'type' : 'select')
  }

  const handleOwnerSelect = (code: string) => {
    setSelectedOwnerCode(code)
    updateTextFields(getPrticipantByCode(code))
  }

  return (
    <Box padding="16px" width="100%">
      <FormControl>
        <RadioGroup
          aria-label="owner-radio-group"
          name="owner-radio-group"
          sx={{ display: 'flex', flexDirection: 'row' }}
          value={inputMethod}
          onChange={handleInputMethodToggle}
        >
          <FormControlLabel
            value="select"
            defaultChecked
            control={<Radio />}
            label={t('productionDevice.owner.selectOwner')}
          />
          <FormControlLabel value="type" control={<Radio />} label={t('productionDevice.owner.inputOwner')} />
        </RadioGroup>
      </FormControl>
      {inputMethod === 'select' ? (
        <FlexColumn flex="1">
          <InputLabel>{t('productionDevice.owner.owner')}</InputLabel>
          <Select
            labelId="owner-select"
            id="owner-select"
            value={selectedOwnerCode ? selectedOwnerCode : ''}
            onChange={(event) => handleOwnerSelect(event.target.value)}
            renderValue={(value) => (value ? getParticipantDisplayValue(value) : undefined)}
          >
            {participants.map((participant) => {
              return (
                <MenuItem key={participant.code} value={participant.code}>
                  {getParticipantDisplayValue(participant)}
                </MenuItem>
              )
            })}
          </Select>
        </FlexColumn>
      ) : (
        <FlexRow gap="16px" flex="1">
          <FlexColumn flex="1">
            <ControlledTextField
              id={`owners.${index}.name`}
              name={`owners.${index}.name`}
              label={t('productionDevice.owner.name')}
              required
              control={control}
            />
          </FlexColumn>
          <FlexColumn flex="1">
            <ControlledTextField
              id={`owners.${index}.code`}
              name={`owners.${index}.code`}
              label={t('productionDevice.owner.code')}
              required
              control={control}
            />
          </FlexColumn>
        </FlexRow>
      )}
    </Box>
  )
}
