import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ControlledTextField from '../../../features/inputs/ControlledTextField'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useFormContext } from 'react-hook-form'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import ControlledNumberField from '../../../features/inputs/ControlledNumberField'
import { InfoBox } from '../../../features/info-box/InfoBox'

export default function LocationForm() {
  const { t } = useTranslation()

  const { control } = useFormContext<ProductionDeviceSchemaType>()

  return (
    <FlexColumn>
      <FlexColumn gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.location')}</Typography>
      </FlexColumn>

      <InfoBox margin="0 0 16px 0">{t('productionDevice.locationInfo')}</InfoBox>

      <FlexColumn gap="16px" max-width="400px">
        <Typography variant="h3">{t('bundle.productionDeviceAddress')}</Typography>
        <FlexRow gap="16px">
          <ControlledTextField
            id="postNumber"
            name="addressOrLocation.postNumber"
            label={t('productionDevice.postNumber')}
            required
            control={control}
          />
          <ControlledTextField
            id="region"
            name="addressOrLocation.region"
            label={t('productionDevice.region')}
            required
            control={control}
          />
        </FlexRow>

        <Typography variant="h3">{t('bundle.productionDeviceCoordinates')}</Typography>
        <FlexRow gap="16px">
          <ControlledNumberField
            id="latitude"
            name="addressOrLocation.latitude"
            label={t('productionDevice.latitude')}
            required
            acceptFloat
            control={control}
          />
          <ControlledNumberField
            id="longitude"
            name="addressOrLocation.longitude"
            label={t('productionDevice.longitude')}
            required
            acceptFloat
            control={control}
          />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}
