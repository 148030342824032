import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import { NotificationDto, PageNotificationViewDto, PageQuery } from '../../../api/types'
import Schedule from '@mui/icons-material/Schedule'
import { parseDate } from '../../../utils/utils'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Paginator } from '../../../features/paginator/Paginator'
import { useAppLanguage } from '../../../hooks'

export const NotificationArchive = () => {
  const { t } = useTranslation()
  const [notifications, setNotifications] = useState<PageNotificationViewDto | null>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const { currentLanguage } = useAppLanguage()

  useEffect(() => {
    document.title = `Ecert - ${t('title.notificationArchive')}`

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const pageQuery: PageQuery = { page: currentPage - 1 }
    ECERT_API.getArchivedNotifications(pageQuery).then((response) => {
      setNotifications(response.data)
    })
  }, [currentPage])

  const getProperContent = (content: NotificationDto) => {
    switch (currentLanguage) {
      case 'fi':
        return content.contentFi!
      case 'en':
        return content.contentEn!
      case 'sv':
        return content.contentSv!
      default:
        return content.contentFi!
    }
  }

  return (
    <FlexColumn gap="24px">
      <Typography variant="h1">{t('title.notificationArchive')}</Typography>
      {notifications?.content?.map((content, index, array) => (
        <FlexColumn key={index} gap="8px" styles={{ 'padding-top': '16px', 'padding-bottom': '16px' }}>
          <FlexRow gap="10px">
            <Schedule />
            {parseDate(content.startTime!).format('DD.MM.YYYY HH:mm')}
          </FlexRow>
          <FlexColumn>
            <Box dangerouslySetInnerHTML={{ __html: getProperContent(content) }} />
          </FlexColumn>
          {index < array.length - 1 && <Divider />}
        </FlexColumn>
      ))}
      {notifications?.pageable && (
        <Paginator
          displayDisabledPages={false}
          currentPage={currentPage}
          setCurrentPage={(page) => setCurrentPage(page)}
          maxPages={Math.ceil(notifications['total'] / notifications.pageable['size'])}
        />
      )}
    </FlexColumn>
  )
}
