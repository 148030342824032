import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { Button, Menu, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useNavigate } from 'react-router-dom'
import './ProfileMenu.css'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../styles/StyleGuide'
import { EcertRoutes } from '../../ecert-routes'
import { useLoginContext, useLoginUser } from '../../LoginContext'
import { ECERT_API } from '../../services/ecert-api'

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const loginUser = useLoginUser()
  const { refreshLoginContext } = useLoginContext()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleNavigateToProfile = () => {
    setAnchorEl(null)
    navigate(EcertRoutes.user(loginUser.organizationId, loginUser.id))
  }

  const onLogoutClick = async (event) => {
    event.preventDefault()
    setAnchorEl(null)
    await ECERT_API.logout()
    await refreshLoginContext()
    navigate(EcertRoutes.paths.login)
  }

  return (
    <Box sx={{ minWidth: 60 }}>
      <Button
        aria-controls={open ? 'profile-menu-button' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={
          open ? (
            <ExpandLessIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          ) : (
            <ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          )
        }
        onClick={handleMenuClick}
      >
        <Typography variant="body1">{`${loginUser.firstName} ${loginUser.lastName}`}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'profile-menu-button'
        }}
      >
        <MenuItem onClick={handleNavigateToProfile}>
          <Typography variant="body1">{t('user.viewOwnInfo')}</Typography>
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <Typography variant="body1">{t('user.logout')}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
