import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { InvoiceRowDtoProductCode } from '../../../../api/types'

interface IProductCodeSelectProps {
  value: InvoiceRowDtoProductCode | undefined
  onChange: (newValue: InvoiceRowDtoProductCode) => void
}

export default function ProductCodeSelect({ value, onChange }: IProductCodeSelectProps) {
  const [selectedCode, setSelectedCode] = useState<InvoiceRowDtoProductCode>(value ?? InvoiceRowDtoProductCode.ALKUE)

  const handleOnChange = (event: SelectChangeEvent) => {
    setSelectedCode(event.target.value as InvoiceRowDtoProductCode)
  }

  useEffect(() => {
    onChange(selectedCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCode])

  const items: InvoiceRowDtoProductCode[] = [
    InvoiceRowDtoProductCode.ALKUE,
    InvoiceRowDtoProductCode.ALKUE255,
    InvoiceRowDtoProductCode.ALKUM,
    InvoiceRowDtoProductCode.ALKUM255,
    InvoiceRowDtoProductCode.ALKUMU,
    InvoiceRowDtoProductCode.ALKUMU255,
    InvoiceRowDtoProductCode.ALKUT,
    InvoiceRowDtoProductCode.ALKUT255,
    InvoiceRowDtoProductCode.ALKUTO,
    InvoiceRowDtoProductCode.ALKUTO255,
    InvoiceRowDtoProductCode.ALKUTV,
    InvoiceRowDtoProductCode.ALKUTV255,
    InvoiceRowDtoProductCode.ALKUV,
    InvoiceRowDtoProductCode.ALKUV255,
    InvoiceRowDtoProductCode.ALKUVR,
    InvoiceRowDtoProductCode.ALKUVR255
  ]

  return (
    <Select
      fullWidth
      labelId="organization-select"
      defaultValue={selectedCode}
      value={selectedCode}
      onChange={handleOnChange}
      sx={{ marginBottom: '15px', maxWidth: '240px' }}
    >
      {items.map((e) => {
        return (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        )
      })}
    </Select>
  )
}
