import { Box, Button, Typography } from '@mui/material'
import { InfoBox } from '../../../features/info-box/InfoBox'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import StyleGuide from '../../../styles/StyleGuide'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import RichTextEditor from '../../../features/rich-text-editor/RichTextEditor'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import dayjs from 'dayjs'
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { NotificationDto, NotificationViewDto } from '../../../api/types'
import { useTranslation } from 'react-i18next'
import { useDeferredValue, useMemo, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { useNavigate } from 'react-router-dom'
import { ECERT_API } from '../../../services/ecert-api'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { enqueueSnackbar } from 'notistack'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { toLocaleISOString } from '../../../utils/utils'

export interface NotificationFormProps {
  existingNotification?: NotificationViewDto
}

const createEditorState = (notificationContent) => {
  return EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(notificationContent || '')))
}

export const NotificationForm = ({ existingNotification }: NotificationFormProps) => {
  const { t } = useTranslation()
  const [finEditorState, setFinEditorState] = useState(
    existingNotification ? createEditorState(existingNotification.contentFi) : EditorState.createEmpty()
  )
  const finEditorStateDeferred = useDeferredValue(finEditorState)
  const [enEditorState, setEnEditorState] = useState(
    existingNotification ? createEditorState(existingNotification.contentEn) : EditorState.createEmpty()
  )
  const enEditorStateDeferred = useDeferredValue(enEditorState)
  const [svEditorState, setSvEditorState] = useState(
    existingNotification ? createEditorState(existingNotification.contentSv) : EditorState.createEmpty()
  )
  const svEditorStateDeferred = useDeferredValue(svEditorState)
  const currentDate = dayjs().format()
  const [notificationState, setNotificationState] = useState<NotificationDto>(
    existingNotification || {
      startTime: currentDate
    }
  )
  const [rangeValid, setRangeValid] = useState(true)
  const navigate = useNavigate()

  const updateStartDateAndTime = (newValue) => {
    setNotificationState((prevState) => ({
      ...prevState,
      startTime: dayjs(newValue).isValid() ? dayjs(newValue).toISOString() : newValue
    }))
  }

  const updateEndDateAndTime = (newValue) => {
    setNotificationState((prevState) => ({
      ...prevState,
      endTime: dayjs(newValue).isValid() ? dayjs(newValue).toISOString() : newValue
    }))
  }

  const sendRequest = (payload: NotificationDto, createdSuccessfullyText: string, updatedSuccessfullyText: string) => {
    payload.contentFi = draftToHtml(convertToRaw(finEditorState.getCurrentContent()))
    payload.contentEn = draftToHtml(convertToRaw(enEditorState.getCurrentContent()))
    payload.contentSv = draftToHtml(convertToRaw(svEditorState.getCurrentContent()))
    if (existingNotification) {
      ECERT_API.updateNotification(existingNotification.id!, payload).then(() => {
        enqueueSnackbar(updatedSuccessfullyText, snacky.successOpts)
        navigate(-1)
      })
    } else {
      ECERT_API.createNotification(payload).then(() => {
        enqueueSnackbar(createdSuccessfullyText, snacky.successOpts)
        navigate(-1)
      })
    }
  }

  const saveAndPublish = () => {
    const newState = {
      ...notificationState,
      published: true,
      archived: false,
      endTime: notificationState.endTime ? toLocaleISOString(new Date(notificationState.endTime)) : '',
      startTime: toLocaleISOString(new Date(notificationState.startTime!))
    }

    sendRequest(newState, t('form.valid.notification.created'), t('form.valid.notification.updated'))
  }

  const saveDraft = () => {
    const newState = {
      ...notificationState,
      published: false,
      archived: false,
      endTime: notificationState.endTime ? toLocaleISOString(new Date(notificationState.endTime)) : '',
      startTime: toLocaleISOString(new Date(notificationState.startTime!))
    }

    sendRequest(newState, t('form.valid.notification.draftCreated'), t('form.valid.notification.draftUpdated'))
  }

  const discardDraft = () => {
    ECERT_API.deleteNotification(existingNotification?.id!).then(() => {
      enqueueSnackbar(t('form.valid.notification.draftDiscarded'), snacky.successOpts)
      navigate(-1)
    })
  }

  const RichTextEditorFinDeferred = useMemo(() => {
    return <RichTextEditor editorState={finEditorStateDeferred} onUpdate={setFinEditorState} />
  }, [finEditorStateDeferred, setFinEditorState])

  const RichTextEditorEnDeferred = useMemo(() => {
    return <RichTextEditor editorState={enEditorStateDeferred} onUpdate={setEnEditorState} />
  }, [enEditorStateDeferred, setEnEditorState])

  const RichTextEditorSvDeferred = useMemo(() => {
    return <RichTextEditor editorState={svEditorStateDeferred} onUpdate={setSvEditorState} />
  }, [svEditorStateDeferred, setSvEditorState])

  return (
    <FlexColumn styles={{ marginBottom: '16px' }}>
      <FlexColumn styles={{ marginBottom: '24px' }}>
        <Typography
          variant="h1"
          sx={{
            marginBottom: !notificationState.published ? '5px' : '16px'
          }}
        >
          {existingNotification ? t('notification.editPageTitle') : t('notification.createPageTitle')}
        </Typography>
        <Box alignItems="center">
          <InfoBox type="INFO">{t('notification.notificationEditInfo')}</InfoBox>
        </Box>
        {existingNotification && !notificationState.published && (
          <FlexRow styles={{ marginBottom: '16px' }}>
            <WarningAmberOutlinedIcon
              sx={{
                marginRight: '5px',
                color: StyleGuide.constants.COLOR_BRAND
              }}
            />
            <Typography variant="body1">{t('notification.editingPublishedNotification')}</Typography>
          </FlexRow>
        )}
        <EcertDateRangePicker
          rangeValid={rangeValid}
          setRangeValid={setRangeValid}
          views={['year', 'month', 'day', 'hours', 'minutes']}
          defaultValue={{
            startDate: notificationState.startTime,
            endDate: notificationState.endTime
          }}
          label={'Tiedotteen voimassaoloaika'}
          time
          onUpdateStart={updateStartDateAndTime}
          onUpdateEnd={updateEndDateAndTime}
        />
      </FlexColumn>
      <Box sx={{ marginBottom: '32px' }}>
        <Typography variant="h2" sx={{ marginBottom: '16px' }}>
          {t('notification.inFinnish')}
        </Typography>
        {RichTextEditorFinDeferred}
      </Box>

      <Box sx={{ marginBottom: '32px' }}>
        <Typography variant="h2" sx={{ marginBottom: '16px' }}>
          {t('notification.inEnglish')}
        </Typography>
        {RichTextEditorEnDeferred}
      </Box>

      <Box sx={{ marginBottom: '24px' }}>
        <Typography variant="h2" sx={{ marginBottom: '16px' }}>
          {t('notification.inSwedish')}
        </Typography>
        {RichTextEditorSvDeferred}
      </Box>
      <FlexRow gap="8px">
        <Button
          startIcon={<CheckOutlinedIcon />}
          endIcon={<InfoOutlinedIcon />}
          variant="contained"
          onClick={saveAndPublish}
          disabled={!dayjs(notificationState.startTime).isValid() || !rangeValid}
        >
          {t('notification.saveAndPublish')}
        </Button>
        <Button
          variant="contained"
          startIcon={<TextSnippetOutlinedIcon />}
          onClick={saveDraft}
          disabled={!dayjs(notificationState.startTime).isValid() || !rangeValid}
        >
          {t('notification.saveDraft')}
        </Button>
        {existingNotification && !notificationState.published && (
          <Button variant="outlined" startIcon={<TextSnippetOutlinedIcon />} onClick={discardDraft}>
            {t('notification.discard')}
          </Button>
        )}
        <Button variant="outlined" onClick={() => navigate(-1)}>
          {t('common.cancel')}
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
