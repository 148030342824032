import { FlexRow } from '../../styles/containers/FlexContainers'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { range } from '../../utils/utils'
import { useEffect, useState } from 'react'

interface IPaginatorProps {
  currentPage: number
  setCurrentPage: (page: number) => void
  maxPages: number
  displayDisabledPages?: boolean
}

export const Paginator = ({ currentPage, setCurrentPage, maxPages, displayDisabledPages = true }: IPaginatorProps) => {
  const { t } = useTranslation()
  const amountOfPagesSelectable = 10
  const [exactPageRange, setExactPageRange] = useState({ from: 1, to: 10 })

  useEffect(() => {
    const rangeEnd = Math.ceil(currentPage / amountOfPagesSelectable) * amountOfPagesSelectable
    const rangeStart = rangeEnd - (amountOfPagesSelectable - 1)
    setExactPageRange({
      from: rangeStart,
      to: rangeEnd
    })
    // eslint-disable-next-line
  }, [currentPage])

  const handleClickFirst = () => {
    setCurrentPage(1)
  }

  const handleClickPrevious = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleClickExact = (page: number) => {
    setCurrentPage(page)
  }

  const handleClickNext = () => {
    setCurrentPage(currentPage + 1)
  }

  const handleClickLast = () => {
    setCurrentPage(maxPages)
  }

  const handleClickRangePrevious = () => {
    setCurrentPage(exactPageRange.from - 1)
  }

  const handleClickRangeNext = () => {
    setCurrentPage(exactPageRange.to + 1)
  }

  return (
    <FlexRow>
      <Button id="paginator-first" variant="outlined" onClick={handleClickFirst} disabled={currentPage === 1}>
        {t('pagination.first')}
      </Button>
      <Button id="paginator-previous" variant="outlined" onClick={handleClickPrevious} disabled={currentPage === 1}>
        {t('pagination.previous')}
      </Button>
      {currentPage > amountOfPagesSelectable && (
        <Button id="paginator-range-previous" variant="outlined" onClick={handleClickRangePrevious}>
          ...
        </Button>
      )}
      {range(exactPageRange.from, displayDisabledPages ? exactPageRange.to : maxPages).map((page) => (
        <Button
          id={`paginator-page-${page}`}
          disabled={displayDisabledPages && page > maxPages}
          key={page}
          variant={page === currentPage ? 'contained' : 'outlined'}
          aria-current={page === currentPage ? 'true' : 'false'}
          onClick={() => handleClickExact(page)}
        >
          {page}
        </Button>
      ))}
      {currentPage < Math.floor(maxPages / amountOfPagesSelectable) * amountOfPagesSelectable && (
        <Button id="paginator-range-next" variant="outlined" onClick={handleClickRangeNext}>
          ...
        </Button>
      )}
      <Button id="paginator-next" variant="outlined" onClick={handleClickNext} disabled={currentPage === maxPages}>
        {t('pagination.next')}
      </Button>
      <Button id="paginator-last" variant="outlined" onClick={handleClickLast} disabled={currentPage === maxPages}>
        {t('pagination.last')}
      </Button>
    </FlexRow>
  )
}
