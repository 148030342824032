import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { Box, Button, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../../styles/StyleGuide'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { Edit } from '@mui/icons-material'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { ECERT_API } from '../../../services/ecert-api'
import { useParams } from 'react-router-dom'
import {
  FuelDistributionDto,
  FuelDistributionItemDto,
  FuelDistributionValueDto,
  ProductionDeviceViewDto
} from '../../../api/types'
import { deepClone, parseDate, range, uniqBy } from '../../../utils/utils'
import { WarningBox } from '../../../features/warning-box/WarningBox'
import { DownloadLink } from '../../../features/download-link/DownloadLink'
import { UploadButton } from '../../../features/buttons/UploadButton'
import { canEditFuelDistribution, isAdmin } from '../../../utils/permission-utils'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useLoginUser } from '../../../LoginContext'
import { AxiosResponse } from 'axios'
import { InfoBox } from '../../../features/info-box/InfoBox'

const now = dayjs()
const thisYear = now.year()
const selectableYears = range(2013, thisYear)

export const FuelDistributionTab = ({ productionDevice }: { productionDevice: ProductionDeviceViewDto }) => {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const params = useParams()
  const [fuelDistributionYear, setFuelDistributionYear] = useState(thisYear)
  const [fuelDistribution, setFuelDistribution] = useState<FuelDistributionDto | null>(null)
  const [fuelDistributionCopy, setFuelDistributionCopy] = useState<FuelDistributionDto | null>(null)
  const [columns, setColumns] = useState<IColumn[]>([])
  const [editingFuelDistribution, setEditingFuelDistribution] = useState(false)

  useEffect(() => {
    document.title = editingFuelDistribution
      ? `Ecert - ${t('title.productionDeviceFuelDistribution')}`
      : `Ecert - ${t('title.productionDevice')}`
  }, [editingFuelDistribution, t])

  const EditingFuelDistributionInput = ({ item }: { item: FuelDistributionItemDto }) => {
    const handleOnChange = (event) => {
      setFuelDistributionCopy((prevState) => {
        const newState = deepClone(prevState)
        const idx = newState.items!.findIndex(
          (itemCopy) => itemCopy.fuelTypeId === item.fuelTypeId && itemCopy.month === item.month
        )

        let val
        if (event.target.value === '') {
          val = undefined
        } else {
          val = +event.target.value.replace(',', '.')
        }

        newState.items![idx].value = isNaN(val) ? undefined : val
        return newState
      })
    }

    return <TextField onChange={handleOnChange} defaultValue={item.value} />
  }

  const FuelDistributionYearSelect = ({
    value,
    setValue
  }: {
    value: number
    setValue: Dispatch<SetStateAction<number>>
  }) => {
    const [year, setYear] = useState(value)

    return (
      <Select
        value={year}
        onChange={(event) => {
          setYear(+event.target.value)
          setValue(+event.target.value)
        }}
      >
        {selectableYears.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const fetchFuelDistribution = useCallback(() => {
    ECERT_API.getFuelDistribution(+params.organizationId!, +params.productionDeviceId!, fuelDistributionYear).then(
      (response) => {
        setFuelDistribution(response.data)
        // Deep clone item to allow editing without modifying original data
        setFuelDistributionCopy({
          productionDeviceId: response.data.productionDeviceId,
          productionDeviceName: response.data.productionDeviceName,
          productionDeviceGsrn: response.data.productionDeviceGsrn,
          items: response.data.items ? response.data.items.map((item) => ({ ...item })) : []
        })

        const uniqueFuelTypes = uniqBy<FuelDistributionItemDto>(response.data.items!, 'fuelTypeId').sort((a, b) =>
          a.fuelTypeCode!.localeCompare(b.fuelTypeCode!)
        )

        const columns: IColumn[] = [
          {
            label: <FuelDistributionYearSelect value={fuelDistributionYear} setValue={setFuelDistributionYear} />,
            accessor: (fuelDistributionItem: FuelDistributionItemDto[], index?: number) =>
              index! < 9 ? `0${index! + 1}` : index! + 1
          }
        ]
        uniqueFuelTypes.forEach((fuel) => {
          columns.push({
            label: (
              <FlexColumn>
                {fuel.fuelTypeCode} - {fuel.fuelTypeName}
                {fuel['isRemoved'] && <InfoBox>{t('fuelDistribution.removed')}</InfoBox>}
              </FlexColumn>
            ),
            accessor: (fuelDistributionItems: FuelDistributionItemDto[]) =>
              fuelDistributionItems.find((item) => item.fuelTypeCode === fuel.fuelTypeCode),
            transformFn: (fuelDistributionItem: FuelDistributionItemDto) => fuelDistributionItem?.value,
            whenEditingFn: (fuelDistributionItem: FuelDistributionItemDto) => {
              const isMissingValue =
                fuelDistributionItem?.editable && !dayjs(fuelDistributionItem?.month as string).isAfter(now, 'month')
              return isMissingValue ? (
                <EditingFuelDistributionInput item={fuelDistributionItem!} />
              ) : (
                <Typography>{fuelDistributionItem?.value}</Typography>
              )
            }
          })
        })
        columns.push({
          label: t('common.total'),
          accessor: (fuelDistributionItem: FuelDistributionItemDto[]) => {
            const sum = fuelDistributionItem.every((item) => item.value === null)
              ? null
              : fuelDistributionItem.reduce((prev, curr) => prev + (curr.value || 0), 0)
            return sum?.toFixed(2) || null
          }
        })
        setColumns(columns)
      }
    )
  }, [params.organizationId, params.productionDeviceId, fuelDistributionYear, t])

  useEffect(() => {
    fetchFuelDistribution()
  }, [fetchFuelDistribution])

  const editFuelDistribution = () => {
    setEditingFuelDistribution(true)
  }

  const groupFuelDistributionItemsByMonth = (items: FuelDistributionItemDto[]) => {
    const grouped: FuelDistributionItemDto[][] = []
    range(1, 12).forEach((month) => {
      grouped.push(
        items.filter((item) => (item.month as string) === `${fuelDistributionYear}-${month < 10 ? `0${month}` : month}`)
      )
    })
    return grouped
  }

  const rowBackgroundColorFn = (fuelItems: FuelDistributionItemDto[]) => {
    if (
      fuelItems.some((item) => item.value === null) &&
      parseDate(fuelItems[0].month as string).isBefore(now, 'month')
    ) {
      return StyleGuide.constants.COLOR_ORANGE_90
    } else {
      return 'initial'
    }
  }

  const hasMissingValues = () => {
    return fuelDistribution?.items
      ?.filter((item) => !parseDate(item.month as string).isAfter(now, 'month'))
      ?.some((item) => item.value === null)
  }

  const handleSaveFuelDistribution = () => {
    fuelDistributionCopy!.items = fuelDistributionCopy?.items?.filter((item) => item.editable)
    ECERT_API.updateFuelDistributions(+params.organizationId!, +params.productionDeviceId!, fuelDistributionCopy!).then(
      () => {
        setEditingFuelDistribution(false)
        fetchFuelDistribution()
        enqueueSnackbar(t('form.valid.fuelDistribution.updated'), snacky.successOpts)
      }
    )
  }

  const releaseAdminEdit = () => {
    setFuelDistributionCopy((prevState) => {
      const newState = { ...prevState }
      newState!.items = newState.items
        ?.filter((item) => {
          const itemDate = parseDate(item.month as string)
          return itemDate.year() === fuelDistributionYear && !itemDate.isAfter(now, 'month')
        })
        .map((item) => {
          item.editable = true
          return item
        })
      return newState
    })
    // Force table re-render, cannot use fuelDistributionCopy as table dependency
    // due to losing focus from edit inputs
    setFuelDistribution({ ...fuelDistribution })
  }

  const hasAdminEditableItems = () => {
    return fuelDistribution?.items?.some((item) => {
      const itemDate = parseDate(item.month as string)
      return itemDate.year() === fuelDistributionYear && !itemDate.isAfter(now, 'month') && !item.editable
    })
  }

  const EcertTableMemoized = useMemo(() => {
    if (!fuelDistribution || !fuelDistributionCopy) return <></>

    return (
      <EcertTable
        mode={editingFuelDistribution ? 'EDIT' : 'VIEW'}
        data={groupFuelDistributionItemsByMonth(
          editingFuelDistribution ? fuelDistributionCopy.items! : fuelDistribution.items!
        )}
        columns={columns}
        rowBackgroundColorFn={rowBackgroundColorFn}
      />
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, fuelDistribution, fuelDistributionYear, editingFuelDistribution])

  const handleExcelSelected = (file: File) => {
    ECERT_API.uploadFuelDistributionExcel(fuelDistributionYear, { file: file }).then(
      (response: AxiosResponse<FuelDistributionValueDto[]>) => {
        setFuelDistributionCopy((prevState) => {
          const newState = deepClone(prevState)
          newState?.items?.forEach((item) => {
            const updatedValue = response.data.find(
              (res) => res.month === item.month && res.fuelTypeCode === item.fuelTypeCode
            )
            if (updatedValue) {
              item.value = updatedValue.value
            }
          })
          return newState
        })
        // Force table re-render, cannot use fuelDistributionCopy as table dependency
        // due to losing focus from edit inputs
        setFuelDistribution({ ...fuelDistribution })
      }
    )
  }

  return (
    <FlexColumn gap="16px">
      <Typography variant="h1">
        {t('fuelDistribution.title')} {fuelDistributionYear}
      </Typography>

      <Box
        sx={{
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
          padding: '24px'
        }}
      >
        <ul>
          <li>{t('fuelDistribution.info1')}</li>
          <li>{t('fuelDistribution.info2')}</li>
          <li>{t('fuelDistribution.info3')}</li>
          <li>
            {t('fuelDistribution.info4')}&nbsp;
            <DownloadLink
              fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.FUEL_DISTRIBUTION_TEMPLATE(
                +params.organizationId!,
                +params.productionDeviceId!,
                fuelDistributionYear
              )}
              fileName={`fuelDistributionTemplate${+params.productionDeviceId!}`}
            >
              {t('fuelDistribution.downloadExcelTemplate')}
            </DownloadLink>
          </li>
          <li>{t('fuelDistribution.info5')}</li>
        </ul>
      </Box>

      {hasMissingValues() && (
        <FlexRow>
          <WarningBox>{t('fuelDistribution.missingValues')}</WarningBox>
        </FlexRow>
      )}

      <FlexRow gap="16px">
        {editingFuelDistribution && isAdmin(loginUser) && hasAdminEditableItems() && (
          <Button variant="contained" onClick={releaseAdminEdit}>
            {t('fuelDistribution.releaseAdminEdit')}
          </Button>
        )}
        {!editingFuelDistribution &&
          canEditFuelDistribution(loginUser, params) &&
          productionDevice.lockedStatus?.locked === false && (
            <Button startIcon={<Edit />} variant="outlined" onClick={editFuelDistribution}>
              {t('productionDevice.editFuelDistribution')}
            </Button>
          )}
        {editingFuelDistribution && (
          <UploadButton multiple={false} acceptFileTypes=".xlsx" handleSelected={handleExcelSelected}>
            {t('fuelDistribution.uploadFromExcel')}
          </UploadButton>
        )}
      </FlexRow>

      <Box>
        <DownloadLink
          fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.FUEL_GUIDE}
          fileName="tilastokeskuksen-polttoaineet-ja-eecs-2023.xlsx"
        >
          {t('productionDevice.energySource.guide')}
        </DownloadLink>
      </Box>

      {EcertTableMemoized}

      {editingFuelDistribution && (
        <FlexRow gap="8px">
          <Button variant="contained" onClick={handleSaveFuelDistribution}>
            {t('common.save')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setEditingFuelDistribution(false)
              setFuelDistribution({ ...fuelDistribution })
              setFuelDistributionCopy(fuelDistribution)
            }}
          >
            {t('common.cancel')}
          </Button>
        </FlexRow>
      )}
    </FlexColumn>
  )
}
