import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { isEmpty, uniqueArr } from '../../../utils/utils'
import {
  resetFilters,
  selectFilters,
  setFilterCertificateIssuingCountries,
  setFilterLabelCodes,
  setFilterInvestmentsNo,
  setFilterInvestmentsYes,
  setFilterProductionDeviceCapacityMax,
  setFilterProductionDeviceCapacityMin,
  setFilterProductionDeviceCommissioningEndDate,
  setFilterProductionDeviceCommissioningStartDate,
  setFilterProductionDeviceNames,
  setFilterProductionDeviceTechnologies,
  setFilterProductionTimeEndDate,
  setFilterProductionTimeStartDate,
  unSelectBundles
} from '../../../features/store-slices/organizationAccountsSlice'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Dayjs } from 'dayjs'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { EcertAutoCompleteMultiSelect } from '../../../features/autocomplete/EcertAutoCompleteMultiSelect'
import { AccountDetailsDto, CertificateBundleDto } from '../../../api/types'
import { unSelectCertificates } from '../../../features/store-slices/certificateSlice'
import { Clear } from '@mui/icons-material'

interface IBundleFiltersProps {
  accountDetails: AccountDetailsDto
}

export const BundleFilters = ({ accountDetails }: IBundleFiltersProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const clearFilters = () => {
    dispatch(unSelectCertificates())
    dispatch(resetFilters())
    dispatch(unSelectBundles())
  }

  const FilterProductionTime = () => {
    const filters = useAppSelector(selectFilters)

    const handleUpdateStart = (start: Dayjs) => {
      dispatch(setFilterProductionTimeStartDate({ start: start ? start.startOf('month').format('YYYY-MM-DD') : '' }))
    }

    const handleUpdateEnd = (end: Dayjs) => {
      dispatch(setFilterProductionTimeEndDate({ end: end ? end.endOf('month').format('YYYY-MM-DD') : '' }))
    }

    return (
      <Accordion
        defaultExpanded={
          !isEmpty(filters.productionTimeDateRange.start) || !isEmpty(filters.productionTimeDateRange.end)
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="productionTime">
          <Typography>{t('account.filter.productionTime')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertDateRangePicker
            clearable={true}
            label={''}
            defaultValue={{
              startDate: filters.productionTimeDateRange.start || '',
              endDate: filters.productionTimeDateRange.end || ''
            }}
            onUpdateStart={handleUpdateStart}
            onUpdateEnd={handleUpdateEnd}
            openTo="month"
            views={['year', 'month']}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterProductionTechnology = () => {
    const filters = useAppSelector(selectFilters)

    const handleChange = (productionTechnologyNames: string[]) => {
      dispatch(setFilterProductionDeviceTechnologies({ technologies: productionTechnologyNames }))
    }

    const selectableProductionDeviceTechnologies = (): string[] => {
      const arr = accountDetails.activeCertificateBundles!.map((bundle) => bundle.productionDeviceTechnologyName!)
      return [...new Set(arr)]
    }

    if (filters.productionDeviceTechnologies) {
      filters.productionDeviceTechnologies.map((technology) => {
        if (!selectableProductionDeviceTechnologies().includes(technology)) {
          dispatch(
            setFilterProductionDeviceTechnologies({
              technologies: filters.productionDeviceTechnologies.filter((item) => item !== technology)
            })
          )
        }
      })
    }

    return (
      <Accordion defaultExpanded={filters.productionDeviceTechnologies.length > 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="productionDeviceTechnology">
          <Typography>{t('account.filter.productionDeviceTechnology')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertAutoCompleteMultiSelect
            value={filters.productionDeviceTechnologies}
            id="filterProductionDeviceTechnology"
            label={t('account.filter.productionDeviceTechnology')}
            selectableItems={selectableProductionDeviceTechnologies()}
            handleChange={handleChange}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterProductionDevice = () => {
    const filters = useAppSelector(selectFilters)

    const handleChange = (productionDeviceNames: string[]) => {
      dispatch(setFilterProductionDeviceNames({ names: productionDeviceNames }))
    }

    const selectableProductionDevices = (): string[] => {
      return uniqueArr((accountDetails?.activeCertificateBundles || []).map((bundle) => bundle.productionDeviceName))
    }

    const selectableProductionDeviceGSRNNumbers = (): string[] => {
      const productionDevices = selectableProductionDevices()
      return productionDevices.map(
        (deviceName) =>
          accountDetails.activeCertificateBundles?.find((bundle) => bundle.productionDeviceName === deviceName)
            ?.productionDeviceGsrn || ''
      )
    }

    if (filters.productionDeviceNames) {
      const validFilters = filters.productionDeviceNames.filter((device) =>
        selectableProductionDevices().includes(device)
      )

      if (validFilters.length !== filters.productionDeviceNames.length) {
        dispatch(setFilterProductionDeviceNames({ names: validFilters }))
      }

      filters.productionDeviceNames.map((device) => {
        if (!selectableProductionDevices().includes(device)) {
          dispatch(
            setFilterProductionDeviceNames({ names: filters.productionDeviceNames.filter((item) => item !== device) })
          )
        }
      })
    }

    return (
      <Accordion defaultExpanded={filters.productionDeviceNames.length > 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="productionDevice">
          <Typography>{t('account.filter.productionDevice')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertAutoCompleteMultiSelect
            id="filterProductionDevice"
            label={t('account.filter.productionDevice')}
            selectableItems={selectableProductionDevices()}
            infoPoppers={selectableProductionDeviceGSRNNumbers()}
            handleChange={handleChange}
            value={filters.productionDeviceNames}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterCertificateIssuingCountry = () => {
    const filters = useAppSelector(selectFilters)

    const handleChange = (selectedCountries: string[]) => {
      dispatch(setFilterCertificateIssuingCountries({ countries: selectedCountries }))
    }

    const selectableIssuingCountries = (): string[] => {
      const arr = accountDetails.activeCertificateBundles!.map((bundle) => bundle.certificateIssuingCountry!)
      return [...new Set(arr)]
    }

    if (filters.certificateIssuingCountries) {
      const validFilters = filters.certificateIssuingCountries.filter((country) =>
        selectableIssuingCountries().includes(country)
      )

      if (validFilters.length !== filters.certificateIssuingCountries.length) {
        dispatch(setFilterCertificateIssuingCountries({ countries: validFilters }))
      }

      filters.certificateIssuingCountries.map((country) => {
        if (!selectableIssuingCountries().includes(country)) {
          dispatch(
            setFilterCertificateIssuingCountries({
              countries: filters.certificateIssuingCountries.filter((item) => item !== country)
            })
          )
        }
      })
    }

    return (
      <Accordion defaultExpanded={filters.certificateIssuingCountries.length > 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="certificateIssuingCountry">
          <Typography>{t('account.filter.certificateIssuingCountry')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertAutoCompleteMultiSelect
            value={filters.certificateIssuingCountries}
            id="filterCertificateIssuingCountry"
            label={t('account.filter.certificateIssuingCountry')}
            selectableItems={selectableIssuingCountries()}
            handleChange={handleChange}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterLabels = () => {
    const filters = useAppSelector(selectFilters)

    const getUniqueLabelCodes = (certificateBundles: CertificateBundleDto[]) => {
      return uniqueArr(certificateBundles.flatMap((bundle) => bundle.certificateLabelCodes || []) || []).concat(
        t('account.filter.noLabel')
      )
    }

    const handleChange = (labelCodes: string[]) => {
      dispatch(setFilterLabelCodes({ labelCodes: labelCodes }))
    }

    if (filters.labelCodes) {
      const validFilters = filters.labelCodes.filter((label) =>
        getUniqueLabelCodes(accountDetails.activeCertificateBundles!).includes(label)
      )

      if (validFilters.length !== filters.labelCodes.length) {
        dispatch(setFilterLabelCodes({ labelCodes: validFilters }))
      }

      filters.labelCodes.map((label) => {
        if (!getUniqueLabelCodes(accountDetails.activeCertificateBundles!).includes(label)) {
          dispatch(setFilterLabelCodes({ labelCodes: filters.labelCodes.filter((item) => item !== label) }))
        }
      })
    }

    return (
      <Accordion defaultExpanded={filters.labelCodes.length > 0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="label">
          <Typography>{t('account.filter.label')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertAutoCompleteMultiSelect
            value={filters.labelCodes}
            id="filterLabels"
            label={t('account.filter.label')}
            selectableItems={getUniqueLabelCodes(accountDetails?.activeCertificateBundles || [])}
            handleChange={handleChange}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterProductionDeviceCommissioningDate = () => {
    const filters = useAppSelector(selectFilters)

    const handleUpdateStart = (start: Dayjs) => {
      dispatch(setFilterProductionDeviceCommissioningStartDate({ start: start ? start.format('YYYY-MM-DD') : '' }))
    }

    const handleUpdateEnd = (end: Dayjs) => {
      dispatch(setFilterProductionDeviceCommissioningEndDate({ end: end ? end.format('YYYY-MM-DD') : '' }))
    }

    return (
      <Accordion
        defaultExpanded={
          !isEmpty(filters.productionDeviceCommissioningDateRange.start) ||
          !isEmpty(filters.productionDeviceCommissioningDateRange.end)
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="productionDeviceDateOfCommissioning">
          <Typography>{t('account.filter.productionDeviceDateOfCommissioning')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertDateRangePicker
            label={''}
            defaultValue={{
              startDate: filters.productionDeviceCommissioningDateRange.start || '',
              endDate: filters.productionDeviceCommissioningDateRange.end || ''
            }}
            onUpdateStart={handleUpdateStart}
            onUpdateEnd={handleUpdateEnd}
            clearable={true}
          />
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterInvestments = () => {
    const filters = useAppSelector(selectFilters)

    const handleCheckNo = () => {
      dispatch(setFilterInvestmentsNo())
    }

    const handleCheckYes = () => {
      dispatch(setFilterInvestmentsYes())
    }

    return (
      <Accordion defaultExpanded={filters.productionDeviceInvestments.no || filters.productionDeviceInvestments.yes}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="investments">
          <Typography>{t('account.filter.investments')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={filters.productionDeviceInvestments.no} onChange={handleCheckNo} name="no" />}
              label={t('form.no')}
            />
            <FormControlLabel
              control={
                <Checkbox checked={filters.productionDeviceInvestments.yes} onChange={handleCheckYes} name="yes" />
              }
              label={t('form.yes')}
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    )
  }

  const FilterCapacity = () => {
    const filters = useAppSelector(selectFilters)

    const handleMinCapacity = (event) => {
      dispatch(setFilterProductionDeviceCapacityMin({ min: event ? event.target.value : undefined }))
    }

    const handleMaxCapacity = (event) => {
      dispatch(setFilterProductionDeviceCapacityMax({ max: event ? event.target.value : undefined }))
    }

    return (
      <Accordion
        defaultExpanded={
          !isEmpty(filters.productionDeviceCapacity.min) || !isEmpty(filters.productionDeviceCapacity.max)
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="productionDeviceCapacity">
          <Typography>{t('account.filter.productionDeviceCapacity')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexRow alignItems="center" gap="10px">
            <TextField
              id="production-device-capacity-min"
              value={filters.productionDeviceCapacity.min || ''}
              onChange={handleMinCapacity}
              InputProps={{
                endAdornment: (
                  <>
                    {filters.productionDeviceCapacity.min && (
                      <IconButton onClick={() => handleMinCapacity(undefined)}>
                        <Clear />
                      </IconButton>
                    )}
                    <Typography>{'MW'}</Typography>
                  </>
                )
              }}
            ></TextField>
            <span>&mdash;</span>
            <TextField
              id="production-device-capacity-max"
              value={filters.productionDeviceCapacity.max || ''}
              onChange={handleMaxCapacity}
              InputProps={{
                endAdornment: (
                  <>
                    {filters.productionDeviceCapacity.max && (
                      <IconButton onClick={() => handleMaxCapacity(undefined)}>
                        <Clear />
                      </IconButton>
                    )}
                    <Typography>{'MW'}</Typography>
                  </>
                )
              }}
            ></TextField>
          </FlexRow>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <FlexColumn gap="10px">
      <FlexRow gap="10px">
        <FlexColumn gap="10px" flexGrow="1">
          <FilterProductionTime />

          <FilterProductionTechnology />
        </FlexColumn>
        <FlexColumn gap="10px" flexGrow="1">
          <FilterProductionDevice />

          <FilterCertificateIssuingCountry />
        </FlexColumn>
        <FlexColumn gap="10px" flexGrow="1">
          <FilterLabels />

          <FilterProductionDeviceCommissioningDate />
        </FlexColumn>
        <FlexColumn gap="10px" flexGrow="1">
          <FilterInvestments />

          <FilterCapacity />
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <Button onClick={() => clearFilters()} variant="contained">
          {t('common.clearFilters')}
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}
