import { useAppSelector } from '../../../hooks'
import { selectFilters } from '../../../features/store-slices/organizationAccountsSlice'
import { AccountDetailsDto, CertificateBundleDto } from '../../../api/types'
import { useTranslation } from 'react-i18next'
import { filteredBundles } from './filters'

interface IFilteredTotalCertificateCountProps {
  accountDetails: AccountDetailsDto
}

export const FilteredTotalCertificateCount = ({ accountDetails }: IFilteredTotalCertificateCountProps) => {
  const filters = useAppSelector(selectFilters)
  const { t } = useTranslation()

  const totalCertificateCount = (): number => {
    if (!accountDetails?.activeCertificateBundles) return 0

    return filteredBundles(filters, accountDetails.activeCertificateBundles, t).reduce(
      (prev: number, curr: CertificateBundleDto) => {
        return (prev += curr.amount || 0)
      },
      0
    )
  }

  return <>{totalCertificateCount()}</>
}
