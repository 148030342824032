import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CertificateBundleDto } from '../../api/types'
import { RootState } from '../../store'

export type SelectMode = 'TRANSFER' | 'CANCEL' | 'CORRECTION' | null

export type SelectedCertificate = {
  bundle: CertificateBundleDto
  selectedAmount: number
}

interface ICertificatesState {
  selectMode: SelectMode
  selectedCertificates: SelectedCertificate[]
}

const initialState: ICertificatesState = {
  selectMode: null,
  selectedCertificates: []
}

// Reducers
export const certificateSlice = createSlice({
  name: 'certificates',
  initialState,
  reducers: {
    selectCertificates: (
      state,
      action: PayloadAction<{ selectedCertificates: SelectedCertificate[]; selectMode: SelectMode }>
    ) => {
      state.selectedCertificates = action.payload.selectedCertificates
      state.selectMode = action.payload.selectMode
    },
    unSelectCertificates: (state) => {
      state.selectedCertificates = []
      state.selectMode = null
    }
  }
})

// Actions
export const { selectCertificates, unSelectCertificates } = certificateSlice.actions

// Selectors
export const selectSelectedCertificates = (state: RootState) => state.certificates.selectedCertificates

export const selectSelectMode = (state: RootState) => state.certificates.selectMode

export default certificateSlice.reducer
