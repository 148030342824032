/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ProductionInfoNotificationDtoSeverity = typeof ProductionInfoNotificationDtoSeverity[keyof typeof ProductionInfoNotificationDtoSeverity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductionInfoNotificationDtoSeverity = {
  WARNING: 'WARNING',
  ERROR: 'ERROR',
} as const;
