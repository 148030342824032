/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type CancellationDetailsUpdateDtoConversionRegistry = typeof CancellationDetailsUpdateDtoConversionRegistry[keyof typeof CancellationDetailsUpdateDtoConversionRegistry];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancellationDetailsUpdateDtoConversionRegistry = {
  ENERGY_AUTHORITY: 'ENERGY_AUTHORITY',
  GASGRID_FINLAND: 'GASGRID_FINLAND',
} as const;
