import { ProductionDeviceViewDto } from '../../../api/types'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

export function createInitialFormDataForAdd(adminForm: boolean): ProductionDeviceSchemaType {
  return {
    adminForm: adminForm,
    organizationId: 0,
    name: '',
    productionDeviceCode: '',
    codeGeneratorId: 0,
    dateOfCommissioning: '',
    owners: [
      {
        name: '',
        code: ''
      }
    ],
    addressOrLocation: {
      latitude: undefined,
      longitude: undefined,
      postNumber: '',
      region: ''
    },
    beneficiaries: [
      {
        name: '',
        accountNumber: '',
        share: 0
      }
    ],
    capacity: 0,
    technology: {
      description1: '',
      description2: undefined,
      description3: undefined,
      radioactiveWasteProduced: undefined
    },
    technologyType: '',
    fuels: [
      {
        fuelCode: '',
        fuel: {
          description1: '',
          description2: '',
          description3: '',
          description4: ''
        },
        labels: [],
        productionSupports: []
      }
    ],
    investments: [],
    validityStartDate: '',
    validityEndDate: '',
    issuingPeriod: 'EVERY_MONTH',
    poa: null,
    attachments: [],
    comment: '',
    totalShares: 0
  }
}

export function createInitialFormDataForEdit(
  adminForm: boolean,
  device: ProductionDeviceViewDto
): ProductionDeviceSchemaType {
  return {
    adminForm: adminForm,
    organizationId: device.organization!.id!,
    name: device.name,
    productionDeviceCode: device.productionDeviceCode ?? '',
    codeGeneratorId: device.codeGeneratorId ?? 0,
    dateOfCommissioning: device.dateOfCommissioning!,
    owners: device.owners.map((owner) => ({
      code: owner.code,
      name: owner.name
    })),
    addressOrLocation: {
      latitude: device.latitude ? Number(device.latitude.replace(',', '.')) : undefined,
      longitude: device.longitude ? Number(device.longitude.replace(',', '.')) : undefined,
      postNumber: device.postNumber!,
      region: device.region!
    },
    beneficiaries: device.beneficiaries.map((beneficiary) => ({
      name: beneficiary.name!,
      accountNumber: beneficiary.accountNumber!,
      share: beneficiary.share!
    })),
    capacity: device.capacity!,
    technology: {
      description1: device.technologyType.description1!,
      description2: device.technologyType.description2!,
      description3: device.technologyType.description3!,
      radioactiveWasteProduced: device.radioactiveWasteProduced!
    },
    technologyType: device.technologyType.code!,
    fuels: device.fuels?.map((fuel) => ({
      fuelCode: fuel.fuel!.code!,
      fuel: {
        description1: fuel.fuel!.description1!,
        description2: fuel.fuel!.description2!,
        description3: fuel.fuel!.description3!,
        description4: fuel.fuel!.description4!
      },
      labels:
        fuel.labels?.map((label) => ({
          typeCode: label.typeCode,
          validityStartDate: label.validityStartDate,
          validityEndDate: label.validityEndDate
        })) || [],
      productionSupports:
        fuel.productionSupports?.map((prodSupport) => ({
          investment: {
            abbreviation: prodSupport.investment.abbreviation,
            country: prodSupport.investment.country,
            expiryDate: prodSupport.investment.expiryDate ?? undefined,
            scheme: prodSupport.investment.scheme!,
            type: prodSupport.investment.type
          },
          validityStartDate: prodSupport.validityStartDate,
          validityEndDate: prodSupport.validityEndDate
        })) || []
    })),
    investments:
      device.investments?.map((inv) => ({
        investment: {
          abbreviation: inv.investment!.abbreviation,
          country: inv.investment!.country,
          expiryDate: inv.investment!.expiryDate ?? undefined,
          scheme: inv.investment!.scheme!,
          type: inv.investment!.type
        }
      })) || [],
    validityStartDate: device.validityStartDate,
    validityEndDate: device.validityEndDate,
    issuingPeriod: device.issuingPeriod!,
    poa: device.poa
      ? {
          id: device.poa.id!,
          name: device.poa.name!,
          createdAt: device.poa.createdAt!,
          content: null
        }
      : null,
    attachments:
      device.attachments?.map((attachment) => ({
        id: attachment.id!,
        name: attachment.name!,
        createdAt: attachment.createdAt!,
        content: null
      })) || [],
    comment: '',
    totalShares: 0
  }
}
