import { Button, ButtonProps } from '@mui/material'
import { Edit } from '@mui/icons-material'

interface IEditButtonProps extends ButtonProps {}

export const EditButton = (props: IEditButtonProps) => {
  return (
    <Button
      sx={{
        padding: '12px 24px 12px 24px',
        '& .MuiButton-startIcon': {
          margin: '0px'
        }
      }}
      variant="contained"
      startIcon={<Edit />}
      {...props}
    >
      {props.children}
    </Button>
  )
}
