import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductionDeviceInvestmentTypeDto, ProductionDeviceTypeDto, TechnologyFuelRuleDto } from '../../../api/types'
import { ECERT_API } from '../../../services/ecert-api'
import { useFormContext, useWatch } from 'react-hook-form'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn } from '../../../styles/containers/FlexContainers'
import CloseIcon from '@mui/icons-material/Close'
import FuelProductionSupports from './FuelProductionSupports'
import FuelLabels from './FuelLabels'

interface FuelFormProps {
  fuelIndex: number
  removeRow: () => void
}

export default function FuelForm({ fuelIndex, removeRow }: FuelFormProps) {
  const { t } = useTranslation()
  const [energySourceTypes, setEnergySourceTypes] = useState<ProductionDeviceTypeDto[]>()
  const [labelSelectItems, setLabelSelectItems] = useState<string[]>([])
  const [productionSupportSelectItems, setProductionSupportSelectItems] = useState<ProductionDeviceInvestmentTypeDto[]>(
    []
  )
  const [tier1, setTier1] = useState<ProductionDeviceTypeDto[]>([])
  const [tier2, setTier2] = useState<ProductionDeviceTypeDto[]>([])
  const [tier3, setTier3] = useState<ProductionDeviceTypeDto[]>([])
  const [tier4, setTier4] = useState<ProductionDeviceTypeDto[]>([])
  const [selectedTier1, setSelectedTier1] = useState<string | null>(null)
  const [selectedTier2, setSelectedTier2] = useState<string | null>(null)
  const [selectedTier3, setSelectedTier3] = useState<string | null>(null)
  const [selectedTier4, setSelectedTier4] = useState<string | null>(null)
  const FUEL_TYPE_NUCLEAR = 'Nuclear'

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger
  } = useFormContext()

  const selectedType = useWatch({
    control,
    name: `fuels`
  })

  const [allowedFuels, setAllowedFuels] = useState<TechnologyFuelRuleDto>()

  const deleteButtonStyles = {
    maxHeight: '40px',
    maxWidth: '40px',
    minWidth: '0px',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginBottom: '10px'
  }

  useEffect(() => {
    ECERT_API.getAllEenrgySourceTypes().then((response) => setEnergySourceTypes(response.data))

    ECERT_API.getAllProductionDeviceProductionSupportTypes().then((response) =>
      setProductionSupportSelectItems(response.data)
    )

    ECERT_API.getAllLabelTypesCodes().then((response) => setLabelSelectItems(response.data))
  }, [])

  useEffect(() => {
    if (energySourceTypes) {
      setTier1([
        {
          code: 'F03010100',
          description1: FUEL_TYPE_NUCLEAR,
          description2: 'Solid',
          description3: 'Radioactive fuel'
        }
      ])
      setTier2([])
      setTier3([])
      setTier4([])
      energySourceTypes.forEach((type) => {
        if (type.description1 && !type.description2 && !type.description3 && !type.description4) {
          setTier1((prevState) => [...prevState, type])
        }
      })

      setSelectedTier1(getValues(`fuels.${fuelIndex}.fuel` as 'fuels.0.fuel').description1 ?? null)

      setSelectedTier2(getValues(`fuels.${fuelIndex}.fuel` as 'fuels.0.fuel').description2 ?? null)

      setSelectedTier3(getValues(`fuels.${fuelIndex}.fuel` as 'fuels.0.fuel').description3 ?? null)

      setSelectedTier4(getValues(`fuels.${fuelIndex}.fuel` as 'fuels.0.fuel').description4 ?? null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energySourceTypes])

  useEffect(() => {
    setTier2([])
    setTier3([])
    setTier4([])
    if (selectedTier1 === FUEL_TYPE_NUCLEAR) {
      setTier2([
        {
          code: 'F03010100',
          description1: FUEL_TYPE_NUCLEAR,
          description2: 'Solid',
          description3: 'Radioactive fuel'
        }
      ])
      setTier3([
        {
          code: 'F03010100',
          description1: FUEL_TYPE_NUCLEAR,
          description2: 'Solid',
          description3: 'Radioactive fuel'
        }
      ])
      setSelectedTier2('Solid')
      setSelectedTier3('Radioactive fuel')
    } else {
      energySourceTypes?.forEach((type) => {
        if (type.description1 === selectedTier1 && type.description2) {
          setTier2((prevState) => {
            return !prevState.some((e) => e.description2 === type.description2) ? [...prevState, type] : [...prevState]
          })
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier1])

  useEffect(() => {
    setTier3([])
    if (selectedTier1 === FUEL_TYPE_NUCLEAR && selectedTier2 === 'Solid') {
      setTier3([
        {
          code: 'F03010100',
          description1: FUEL_TYPE_NUCLEAR,
          description2: 'Solid',
          description3: 'Radioactive fuel'
        }
      ])
      setSelectedTier3('Radioactive fuel')
    } else {
      energySourceTypes?.forEach((type) => {
        if (type.description1 === selectedTier1 && type.description2 === selectedTier2 && type.description3) {
          setTier3((prevState) =>
            !prevState.some((e) => e.description3 === type.description3) ? [...prevState, type] : [...prevState]
          )
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier2])

  useEffect(() => {
    setTier4([])
    energySourceTypes?.forEach((type) => {
      if (
        type.description1 === selectedTier1 &&
        type.description2 === selectedTier2 &&
        type.description3 === selectedTier3 &&
        type.description4
      ) {
        setTier4((prevState) => [...prevState, type])
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier3])

  useEffect(() => {
    if (getValues('technologyType'))
      ECERT_API.getAllowedFuelsByTechnologyType(getValues('technologyType')).then((response) => {
        setAllowedFuels(response.data)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('technologyType')])

  // Get allowed fuels by technologyType and set fuel code
  useEffect(() => {
    energySourceTypes?.forEach((type) => {
      if (
        type.description1 === selectedTier1 &&
        type.description2 === selectedTier2 &&
        type.description3 === selectedTier3 &&
        type.description4 === selectedTier4
      ) {
        setValue(`fuels.${fuelIndex}.fuel.description1` as any, type.description1, {
          shouldValidate: true
        })
        setValue(`fuels.${fuelIndex}.fuelCode` as any, type.code, {
          shouldValidate: true
        })
        trigger('fuels')
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTier1, selectedTier2, selectedTier3, selectedTier4])

  const handleTier1Change = (event: SelectChangeEvent) => {
    setSelectedTier1((event.target.value as string) === t('form.unspecified') ? null : (event.target.value as string))
    setSelectedTier2(null)
    setSelectedTier3(null)
    setSelectedTier4(null)
  }

  const handleTier2Change = (event: SelectChangeEvent) => {
    setSelectedTier2((event.target.value as string) === t('form.unspecified') ? null : (event.target.value as string))
    const firstTier3Option =
      tier2.find((e) => e.description2 === event.target.value && e.description3 !== undefined)?.description3 ?? null
    setSelectedTier3(firstTier3Option)
    setSelectedTier4(null)
  }

  const handleTier3Change = (event: SelectChangeEvent) => {
    setSelectedTier3((event.target.value as string) === t('form.unspecified') ? null : (event.target.value as string))
    const firstTier4Option =
      tier3.find((e) => e.description3 === event.target.value && e.description4 !== undefined)?.description4 ?? null
    setSelectedTier4(firstTier4Option)
  }

  const handleTier4Change = (event: SelectChangeEvent) => {
    setSelectedTier4((event.target.value as string) === t('form.unspecified') ? null : (event.target.value as string))
  }

  return (
    <Box display="flex" flex="1" gap="16px" flexDirection="column">
      <Box display="flex" gap="16px" flex="1">
        {tier1 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.energySource.energySource')}</InputLabel>
            <Select
              labelId="fuel-tier1-select-label"
              id="fuel-tier1-select"
              value={selectedTier1 ? selectedTier1 : t('form.unspecified')}
              onChange={handleTier1Change}
              renderValue={(value) => {
                return value
              }}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier1.map((e, idx) => {
                return (
                  <MenuItem key={`${e.code}-${idx}`} value={e.description1}>
                    {e.description1}
                  </MenuItem>
                )
              })}
            </Select>
            {errors &&
              errors.fuels &&
              errors.fuels[fuelIndex] &&
              errors.fuels[fuelIndex].fuel &&
              errors.fuels[fuelIndex].fuel.description1 && (
                <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                  {errors.fuels[fuelIndex].fuel.description1.message
                    ? errors.fuels[fuelIndex].fuel.description1.message.toString()
                    : ''}
                </FormHelperText>
              )}
          </FlexColumn>
        )}

        {tier2 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.energySource.extension')}</InputLabel>
            <Select
              labelId="fuel-tier2-select-label"
              id="fuel-tier2-select"
              value={selectedTier2 ? selectedTier2 : t('form.unspecified')}
              onChange={handleTier2Change}
              renderValue={(value) => value}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier2.map((e) => {
                return (
                  <MenuItem key={`${e.code}-${fuelIndex}`} value={e.description2}>
                    {e.description2}
                  </MenuItem>
                )
              })}
            </Select>
          </FlexColumn>
        )}

        {tier3 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.energySource.extension')}</InputLabel>
            <Select
              labelId="fuel-tier3-select-label"
              id="fuel-tier3-select"
              value={selectedTier3 ? selectedTier3 : t('form.unspecified')}
              onChange={handleTier3Change}
              renderValue={(value) => value}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier3.map((e) => {
                return (
                  <MenuItem key={`${e.code}-${fuelIndex}`} value={e.description3}>
                    {e.description3}
                  </MenuItem>
                )
              })}
            </Select>
          </FlexColumn>
        )}

        {tier4 && (
          <FlexColumn flex="1">
            <InputLabel>{t('productionDevice.energySource.extension')}</InputLabel>
            <Select
              labelId="fuel-tier4-select-label"
              id="fuel-tier4-select"
              value={selectedTier4 ? selectedTier4 : t('form.unspecified')}
              onChange={handleTier4Change}
              renderValue={(value) => value}
            >
              <MenuItem key="Unspecified" value={t('form.unspecified')}>
                {t('form.unspecified')}
              </MenuItem>
              {tier4.map((e) => {
                return (
                  <MenuItem key={`${e.code}-${fuelIndex}`} value={e.description4}>
                    {e.description4}
                  </MenuItem>
                )
              })}
            </Select>
          </FlexColumn>
        )}

        <FlexColumn>
          <InputLabel htmlFor="fuel-code">{t('productionDevice.production.code')}</InputLabel>
          <TextField
            id="fuel-code"
            name="fuel-code"
            fullWidth
            disabled
            value={selectedType[fuelIndex].fuelCode ? selectedType[fuelIndex].fuelCode : 'F00000000'}
            InputLabelProps={{ shrink: true }}
          />
        </FlexColumn>
        {!allowedFuels?.fuels?.includes(selectedType[fuelIndex]?.fuelCode) && (
          <Tooltip title={t('productionDevice.fuelCombinationError')} sx={{ alignSelf: 'center' }}>
            <WarningAmberOutlinedIcon
              sx={{
                color: StyleGuide.constants.COLOR_ORANGE_50,
                alignSelf: 'center',
                marginTop: '21px',
                fontSize: '24px'
              }}
            />
          </Tooltip>
        )}

        <Button aria-label="remove-row" variant="outlined" sx={deleteButtonStyles} onClick={removeRow}>
          <CloseIcon />
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        <FuelProductionSupports fuelIndex={fuelIndex} productionSupportSelectItems={productionSupportSelectItems} />
        <FuelLabels fuelIndex={fuelIndex} labelSelectItems={labelSelectItems} />
      </Box>
    </Box>
  )
}
