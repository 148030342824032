import { Upload } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'
import { ChangeEvent, useRef } from 'react'

interface UploadButtonProps extends ButtonProps {
  acceptFileTypes?: string
}

interface UploadSingleButtonProps extends UploadButtonProps {
  multiple: false
  handleSelected: (file: File) => void
}

interface UploadMultipleButtonProps extends UploadButtonProps {
  multiple: true
  handleSelected: (files: File[]) => void
}

export const UploadButton = ({
  startIcon = <Upload />,
  variant = 'outlined',
  multiple,
  acceptFileTypes = '',
  handleSelected,
  children,
  ...rest
}: UploadSingleButtonProps | UploadMultipleButtonProps) => {
  const hiddenFileInput = useRef(null)

  const handleClick = () => {
    ;(hiddenFileInput.current as any)?.click()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      if (multiple) {
        handleSelected(Array.from(event.target.files))
      } else {
        handleSelected(event.target.files[0])
      }
    }
  }

  return (
    <>
      <Button {...rest} startIcon={startIcon} variant={variant} onClick={handleClick}>
        {children}
      </Button>
      <input
        multiple={multiple}
        type="file"
        accept={acceptFileTypes}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  )
}
