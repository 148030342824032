/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type PermissionDtoPermissionsItem = typeof PermissionDtoPermissionsItem[keyof typeof PermissionDtoPermissionsItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PermissionDtoPermissionsItem = {
  PERMISSION_PRODUCTIONDEVICE_GENERAL: 'PERMISSION_PRODUCTIONDEVICE_GENERAL',
  PERMISSION_PRODUCTIONDEVICE_UNLIMITED: 'PERMISSION_PRODUCTIONDEVICE_UNLIMITED',
  PERMISSION_PRODUCTIONDEVICE_VIEW: 'PERMISSION_PRODUCTIONDEVICE_VIEW',
  PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION: 'PERMISSION_PRODUCTIONDEVICE_FUELDISTRIBUTION',
  PERMISSION_ACCOUNT_GENERAL: 'PERMISSION_ACCOUNT_GENERAL',
  PERMISSION_ACCOUNT_UNLIMITED: 'PERMISSION_ACCOUNT_UNLIMITED',
  PERMISSION_ACCOUNT_VIEW: 'PERMISSION_ACCOUNT_VIEW',
  PERMISSION_ACCOUNT_CANCELLATION: 'PERMISSION_ACCOUNT_CANCELLATION',
  PERMISSION_ACCOUNT_TRANSFER_EXTERNAL: 'PERMISSION_ACCOUNT_TRANSFER_EXTERNAL',
  PERMISSION_ACCOUNT_TRANSFER_DOMESTIC: 'PERMISSION_ACCOUNT_TRANSFER_DOMESTIC',
  PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION: 'PERMISSION_ACCOUNT_TRANSFER_ORGANIZATION',
} as const;
