import { useEffect } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { ProductionDeviceSchemaType, createProductionDeviceSchema } from '../../../validation/production-device-schema'
import ProductionDeviceInfoForm from './ProductionDeviceInfoForm'
import LocationForm from './LocationForm'
import BeneficiariesFormContainer from '../components/BeneficiariesFormContainer'
import EnergySourceForm from './EnergySourceForm'
import FuelFormContainer from '../components/FuelFormContainer'
import InvestmentSupportform from './InvestmentSupportForm'
import AuditForm from './AuditForm'
import IssuingPeriodForm from './IssuingPeriodForm'
import PowerOfAttorney from './PowerOfAttorney'
import Attachments from './Attachments'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import ControlledTextField from '../../../features/inputs/ControlledTextField'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProductionDeviceViewDto } from '../../../api/types'
import { isAdmin } from '../../../utils/permission-utils'
import { createInitialFormDataForAdd, createInitialFormDataForEdit } from './defaultFormValues'
import { ProductionDeviceBody } from '../../../services/ecert-api'
import { hasValidationErrorWithField, isValidationError } from '../../../utils/utils'
import { TFunction } from 'i18next'
import { useLoginUser } from '../../../LoginContext'
import { InfoBox } from '../../../features/info-box/InfoBox'
import OwnersFormContainer from '../components/OwnersFormContainer'

export function getProductionDeviceBackendErrorMessage(error: any, t: TFunction) {
  if (hasValidationErrorWithField('beneficiary.accountNumberField', error)) {
    return t(`productionDevice.saveAccountError`, { account: error.response.data[0].rejectedValue })
  }
  if (hasValidationErrorWithField('productionDeviceCode', error)) {
    return t('form.invalid.productionDevice.code.reserved')
  }
  if (hasValidationErrorWithField('energySource.fuels', error)) {
    return t('productionDevice.fuelCombinationError')
  }
  if (isValidationError(error)) {
    return t('productionDevice.saveError')
  }
  return null
}

interface ProductionDeviceFormProps {
  device: ProductionDeviceViewDto | null
  handleSave?: (data: ProductionDeviceBody) => void
  handleSaveDraft?: (data: ProductionDeviceBody) => void
  handleSaveAndSubmit?: (data: ProductionDeviceBody) => void
  handleCancel: () => void
}

export default function ProductionDeviceForm({
  device,
  handleSave,
  handleSaveDraft,
  handleSaveAndSubmit,
  handleCancel
}: ProductionDeviceFormProps) {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const form = useForm<ProductionDeviceSchemaType>({
    resolver: zodResolver(createProductionDeviceSchema()),
    mode: 'all'
  })

  const dividerStyles = {
    marginTop: '24px',
    marginBottom: '32px'
  }

  useEffect(() => {
    if (!device) {
      form.reset(createInitialFormDataForAdd(isAdmin(loginUser) ?? false))
    } else {
      form.reset(createInitialFormDataForEdit(isAdmin(loginUser) ?? false, device))
    }
  }, [device, loginUser, form])

  const getProductionDeviceBody = () => {
    return toProductionDeviceBody(device?.id ?? 0, form.getValues())
  }

  const toProductionDeviceBody = (deviceId: number, formData: ProductionDeviceSchemaType): ProductionDeviceBody => {
    return {
      organizationId: +(formData.organizationId || loginUser.organizationId!),
      dto: {
        id: deviceId,
        name: formData.name,
        productionDeviceCode: formData.productionDeviceCode ? formData.productionDeviceCode : undefined,
        codeGeneratorId: formData.codeGeneratorId ? formData.codeGeneratorId : undefined,
        dateOfCommissioning: formData.dateOfCommissioning,
        owners: formData.owners,
        postNumber:
          formData.addressOrLocation.postNumber && formData.addressOrLocation.region
            ? formData.addressOrLocation.postNumber
            : undefined,
        region:
          formData.addressOrLocation.postNumber && formData.addressOrLocation.region
            ? formData.addressOrLocation.region
            : undefined,
        latitude:
          formData.addressOrLocation.latitude != null && formData.addressOrLocation.longitude != null
            ? formData.addressOrLocation.latitude.toString()
            : undefined,
        longitude:
          formData.addressOrLocation.latitude != null && formData.addressOrLocation.longitude != null
            ? formData.addressOrLocation.longitude.toString()
            : undefined,
        beneficiaries: formData.beneficiaries,
        capacity: formData.capacity,
        radioactiveWasteProduced:
          formData.technology.radioactiveWasteProduced && formData.technology.radioactiveWasteProduced > 0
            ? formData.technology.radioactiveWasteProduced
            : undefined,
        technologyType: formData.technologyType,
        fuels: formData.fuels.map((fuel) => ({
          fuelCode: fuel.fuelCode,
          labels: fuel.labels,
          productionSupports: fuel.productionSupports.map((support) => ({
            investment: {
              type: support.investment.type,
              abbreviation: support.investment.abbreviation,
              country: support.investment.country,
              expiryDate: support.investment.expiryDate ?? undefined,
              scheme: support.investment.scheme
            },
            validityStartDate: support.validityStartDate,
            validityEndDate: support.validityEndDate
          }))
        })),
        investments: formData.investments?.map((investment) => ({
          investment: {
            type: investment.investment.type,
            abbreviation: investment.investment.abbreviation,
            country: investment.investment.country,
            expiryDate: investment.investment.expiryDate ?? undefined,
            scheme: investment.investment.scheme
          }
        })),
        validityStartDate: formData.validityStartDate,
        validityEndDate: formData.validityEndDate,
        issuingPeriod: formData.issuingPeriod,
        poaId: formData.poa?.id,
        attachments: formData.attachments,
        description: formData.comment || undefined
      },
      poa: formData.poa?.content ? formData.poa.content : undefined,
      files: formData.attachments.filter((attachment) => attachment.content).map((attachment) => attachment.content!)
    }
  }

  return (
    <>
      <InfoBox margin="0 0 16px 0">{t('common.mandatoryFields')}</InfoBox>
      {form.formState.defaultValues && (
        <FormProvider {...form}>
          <form>
            <ProductionDeviceInfoForm gsrnCodeExists={!!device?.gsrn} />
            <Divider sx={dividerStyles} />
            <OwnersFormContainer />
            <Divider sx={dividerStyles} />
            <LocationForm />
            <Divider sx={dividerStyles} />
            <BeneficiariesFormContainer organization={device?.organization ?? null} />
            <Divider sx={dividerStyles} />
            <EnergySourceForm />
            <Divider sx={dividerStyles} />
            <FuelFormContainer />
            <Divider sx={dividerStyles} />
            <InvestmentSupportform />
            <Divider sx={dividerStyles} />
            <AuditForm />
            <Divider sx={dividerStyles} />
            <IssuingPeriodForm />
            <Divider sx={dividerStyles} />
            <PowerOfAttorney />
            <Divider sx={dividerStyles} />
            <Attachments />
            <Divider sx={dividerStyles} />
            <FlexRow flexDirection="column">
              <Box marginBottom="16px">
                <Typography variant="h2">{t('productionDevice.description.title')}</Typography>
              </Box>
              <ControlledTextField
                id="comment"
                name="comment"
                label={t('productionDevice.description.description')}
                required={!isAdmin(loginUser)}
                multiline
                minRows={3}
                characterLimit={300}
                control={form.control}
              />
            </FlexRow>
            <Divider sx={dividerStyles} />
            <FlexColumn gap="20px">
              {handleSaveDraft && <InfoBox>{t('productionDevice.saveDraftInfo')}</InfoBox>}
              <FlexRow gap="16px">
                {handleSave && (
                  <Button
                    aria-label="submit"
                    disabled={!form.formState.isValid}
                    startIcon={<CheckOutlinedIcon />}
                    variant="contained"
                    onClick={() => handleSave(getProductionDeviceBody())}
                  >
                    {t('common.save')}
                  </Button>
                )}
                {handleSaveDraft && (
                  <Button
                    aria-label="submit"
                    disabled={!form.formState.isValid}
                    startIcon={<CheckOutlinedIcon />}
                    variant="contained"
                    onClick={() => handleSaveDraft(getProductionDeviceBody())}
                  >
                    {t('productionDevice.saveDraft')}
                  </Button>
                )}
                {handleSaveAndSubmit && (
                  <Button
                    aria-label="submit"
                    disabled={!form.formState.isValid}
                    startIcon={<CheckOutlinedIcon />}
                    variant="contained"
                    onClick={() => handleSaveAndSubmit(getProductionDeviceBody())}
                  >
                    {t('productionDevice.saveAndSubmitForApproval')}
                  </Button>
                )}
                <Button aria-label="cancel" startIcon={<CloseOutlinedIcon />} variant="outlined" onClick={handleCancel}>
                  {t('productionDevice.cancel')}
                </Button>
              </FlexRow>
            </FlexColumn>
          </form>
        </FormProvider>
      )}
    </>
  )
}
