import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { ECERT_API } from './services/ecert-api'
import dayjs, { Dayjs } from 'dayjs'

type AppInfo = {
  productionEnv: boolean
  version: string
  buildTime: Dayjs
}

export const useAppInfo = () => useContext(AppInfoContext).appInfo

interface Ctx {
  appInfo: AppInfo | undefined
}

const AppInfoContext = createContext<Ctx>({} as Ctx)

export function AppInfoContextProvider({ children }: PropsWithChildren<unknown>) {
  const [appInfo, setAppInfo] = useState<AppInfo>()

  useEffect(() => {
    ECERT_API.getAppInfo().then((response) => {
      setAppInfo({
        productionEnv: response.data.productionEnv === 'true',
        version: response.data.build.version,
        buildTime: dayjs(response.data.build.time)
      })
    })
  }, [])

  return <AppInfoContext.Provider value={{ appInfo }}>{children}</AppInfoContext.Provider>
}
