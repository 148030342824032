import dayjs, { Dayjs } from 'dayjs'
import { ECERT_API } from '../services/ecert-api'
import i18n from '../services/i18n'
import { ReactNode } from 'react'
import * as snacky from '../features/custom-snackbar-provider/CustomSnackbarProvider'
import { enqueueSnackbar } from 'notistack'
import { AxiosError, isAxiosError } from 'axios'
import { ValidationError } from '../api/types'

export const UPLOAD_MAX_FILE_SIZE_BYTES = 104857600

export class BidirectionalMap<T, K> {
  map: Map<T, K>
  reverseMap: Map<K, T>

  constructor(map: Map<T, K>) {
    this.map = map
    this.reverseMap = new Map<K, T>()
    map.forEach((value, key) => {
      this.reverseMap.set(value, key)
    })
  }

  get(key: T) {
    return this.map.get(key)
  }

  revGet(key: K) {
    return this.reverseMap.get(key)
  }
}

export type LocaleCode = 'fi' | 'en' | 'sv'

export type LanguageBody = 'FINNISH' | 'ENGLISH' | 'SWEDISH'

export const languages: BidirectionalMap<LocaleCode, LanguageBody> = new BidirectionalMap<LocaleCode, LanguageBody>(
  new Map([
    ['fi', 'FINNISH'],
    ['en', 'ENGLISH'],
    ['sv', 'SWEDISH']
  ])
)

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.call(args)
    }, timeout)
  }
}

export const parseDate = (dateString: string): Dayjs => {
  return dayjs(dateString, ['YYYY-MM-DDTHH:mm:ss', 'DD.MM.YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY-MM'])
}

// Returns given date as ISO string in current locale time
export const toLocaleISOString = (date: Date): string => {
  const tzOffsetMs = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - tzOffsetMs).toISOString().slice(0, -1)
}

export const translate = (lowerCase: boolean, translatePrefix: string, t): ((val: any) => string) => {
  return (val: any) => translateData(val, { lowerCase: lowerCase, prefix: translatePrefix }, t)
}

export const transformDate = (format: string): ((val: any | undefined) => string) => {
  return (val: any | undefined) => (val ? dayjs(val).format(format) : '')
}

export const transformProductionAmount = (amount: any | undefined): string => {
  return `${amount || 0} MWh`
}

export function isObj(value: any): boolean {
  return typeof value === 'object'
}

export function isEmptyObj(value: any): boolean {
  return isObj(value) && Object.keys(value).length === 0
}

export function isEmpty(value: string | undefined): boolean {
  return !value || value.length === 0
}

export function isDeepEqual(value1: any, value2: any): boolean {
  if (value1 === value2) {
    return true
  }
  if (value1 == null && value2 == null) {
    return true
  }
  if (value1 == null || value2 == null) {
    return false
  }
  if (isObj(value1) && isObj(value2)) {
    const descriptors1 = Object.getOwnPropertyDescriptors(value1)
    const descriptors2 = Object.getOwnPropertyDescriptors(value2)
    if (Object.keys(descriptors1).length !== Object.keys(descriptors2).length) {
      return false
    }
    return Object.keys(descriptors1).every((fieldName) =>
      isDeepEqual(descriptors1[fieldName].value, descriptors2[fieldName]?.value)
    )
  }
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) {
      return false
    }
    return value1.every((item, index) => isDeepEqual(item, value2[index]))
  }
  return false
}

// Gets a value from an object with a given key.
// Supports nested accessing.
export const getValue = (obj: object, key: string): string => {
  const keys = key.split('.')
  let value = obj
  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]]
    if (!value) {
      break
    }
  }

  return (value || '').toString()
}

export const translateData = (target: string, translation: any, t): string => {
  const prefix = translation.prefix ? `${translation.prefix}.` : ''
  const key = translation.lowerCase ? target.toLocaleLowerCase() : target
  return t(`${prefix}${key}`)
}

interface DownloadFileProps {
  fileUrl: string
  fileName: string
  callBack?: () => void
  customErrorHandler?: (error) => void
}

export const downloadFile = (props: DownloadFileProps) => {
  ECERT_API.downloadFile(props.fileUrl)
    .then(
      (response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', props.fileName)
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      },
      (error) => {
        if (props.customErrorHandler) {
          props.customErrorHandler(error)
        } else {
          console.error('File download error', error, props.fileUrl)
        }
      }
    )
    .finally(() => {
      if (props.callBack) props.callBack()
    })
}

export const uniqueArr = (arr: any[]): any[] => {
  return Array.from(new Set(arr))
}

export const deepClone = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj))
}

export const range = (from: number, to: number) => {
  const r: number[] = []
  for (let i = from; i <= to; i++) {
    r.push(i)
  }
  return r
}

export const charRange = (from: string, to: string) => {
  const r: string[] = []
  for (let i = from.charCodeAt(0); i <= to.charCodeAt(0); i++) {
    r.push(String.fromCharCode(i))
  }
  return r
}

export const sum = (values: number[]) => {
  return values.reduce((prev, curr) => (curr += prev), 0)
}

function* genId(): Generator<number> {
  let id = 1
  while (true) {
    yield id++
  }
}

export const idGenerator = genId()

/**
 * Useful when you need translations outside the body of a function component.
 */
export const getTranslation = (message: string, options?: any) => {
  return i18n.t(message, options) as string
}
export const enqueueSuccessNotification = (content: ReactNode) => {
  enqueueSnackbar('', {
    ...snacky.customSuccessOpts,
    // @ts-ignore
    node: content
  })
}

export const enqueueErrorNotification = (content: ReactNode) => {
  enqueueSnackbar('', {
    ...snacky.customErrorOpts,
    // @ts-ignore
    node: content
  })
}

export const getRoleTranslation = (role: string | undefined) => {
  if (!role) return ''

  if (role === 'ROLE_ACCOUNT') return getTranslation('user.role.account')

  if (role === 'ROLE_ACCOUNT_ADMIN') return getTranslation('user.role.accountAdmin')

  if (role === 'ROLE_INSPECTOR') return getTranslation('user.role.inspector')

  if (role === 'ROLE_ADMIN') return getTranslation('user.role.admin')
}

export const isJSONObject = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString)

    if (o && typeof o === 'object') return true
  } catch (e) {
    console.log(e)
  }

  return false
}

export const isArraysDifferent = (a1: any[], a2: any[], comparatorAccessor: string) => {
  if (a1.length !== a2.length) return true

  return a1.some((e1) => {
    return !a2.some((e2) => getValue(e1, comparatorAccessor) === getValue(e2, comparatorAccessor))
  })
}

export const associateWithId = <T>(entities: T[]): { [key: number]: T } => {
  return Object.fromEntries(entities.map((entity) => [entity['id'] ?? 0, entity]))
}

export const isClientErrorResponse = (error: any): boolean => {
  return isAxiosError(error) && error.code === 'ERR_BAD_REQUEST'
}

export const isValidationError = (error: any): error is AxiosError<ValidationError[]> => {
  if (isAxiosError(error)) {
    const validationErrors = error.response?.data
    if (Array.isArray(validationErrors) && validationErrors.length && 'messageKey' in validationErrors[0]) {
      return true
    }
  }
  return false
}

export const isValidationErrorWithBlob = async (error: any): Promise<boolean> => {
  if (isAxiosError(error)) {
    const errorData = error.response?.data
    if (errorData instanceof Blob) {
      return (
        (await errorData.text().then((text) => {
          const validationErrors = JSON.parse(text)
          if (Array.isArray(validationErrors) && validationErrors.length && 'messageKey' in validationErrors[0]) {
            return true
          }
        })) ?? false
      )
    }
  }
  return false
}

export const getValidationErrorsfromBlob = async (error: any): Promise<ValidationError[]> => {
  const errorData = error.response?.data
  return await errorData.text().then((text) => {
    return JSON.parse(text)
  })
}

export const hasValidationErrorWithKey = (key: string, error: any): boolean => {
  return isValidationError(error) && !!error.response?.data.some((error) => error.messageKey === key)
}

export const hasValidationErrorWithField = (field: string, error: any): boolean => {
  return isValidationError(error) && !!error.response?.data.some((error) => error.field === field)
}

// Return the unique elements of an array based on the values given by the propertyName
export const uniqBy = <T>(arr: T[], propertyName: keyof T): T[] => {
  const map = new Map<any, any>()
  arr.forEach((val) => {
    if (!map.get(val[propertyName])) {
      map.set(val[propertyName], val)
    }
  })
  return Array.from(map.values())
}

export const calculateFullDaysUntil = (date: string | Dayjs): number => {
  return Math.ceil(dayjs(date).diff(dayjs(), 'day', true))
}

export const formatNumberToFixed = (value: number, digits: number = 3) => {
  return Number(value.toFixed(digits))
}

export const isStringEmail = (value: string) => {
  return value.match(/^\S+@\S+$/) !== null
}
