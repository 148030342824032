import { Button, TextField } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IMessageInputProps {
  defaultMessage?: string
  billedAt?: string | undefined
  onSave?: (message: string) => void
  readOnly?: boolean
}

export function MessageInput({ defaultMessage, billedAt, onSave, readOnly = false }: IMessageInputProps) {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')

  return (
    <>
      <TextField
        disabled={billedAt || readOnly ? true : false}
        label={t('invoiceDetailsReport.invoiceMessage')}
        defaultValue={defaultMessage}
        onChange={(e) => setMessage(e.target.value)}
      />
      {(!billedAt || readOnly) && onSave && (
        <Button variant="outlined" onClick={() => onSave(message)}>
          {t('invoiceDetailsReport.editMessage')}
        </Button>
      )}
    </>
  )
}
