import { useAppSelector } from '../../../hooks'
import { Dictionary } from '@reduxjs/toolkit'
import { selectFilters, selectSelectedBundles } from '../../../features/store-slices/organizationAccountsSlice'
import { AccountDetailsDto, CertificateBundleDto } from '../../../api/types'
import { filteredBundles } from './filters'
import { useTranslation } from 'react-i18next'

interface ISelectedBundleCountProps {
  accountDetails: AccountDetailsDto
}

export const SelectedBundleCount = ({ accountDetails }: ISelectedBundleCountProps) => {
  const { t } = useTranslation()
  const selectedBundles = useAppSelector<Dictionary<number>>(selectSelectedBundles)
  const filters = useAppSelector(selectFilters)

  const selectedBundleCount = (): number => {
    if (!accountDetails?.activeCertificateBundles) return 0

    return filteredBundles(filters, accountDetails.activeCertificateBundles, t).reduce(
      (prev: number, curr: CertificateBundleDto) => {
        return (prev += +(selectedBundles[curr.id!] || 0))
      },
      0
    )
  }

  return <>{selectedBundleCount()}</>
}
