import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FlexRow } from '../../styles/containers/FlexContainers'

interface IPermissionAccordionProps {
  title: string
  children: React.ReactNode
  permission: string
}

export default function PermissionAccordion({ title, permission, children }: IPermissionAccordionProps) {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={title}>
          <FlexRow justifyContent="space-between" styles={{ width: '100%' }}>
            <Typography variant="body1">{title}</Typography>
            <Typography variant="body1">{permission}</Typography>
          </FlexRow>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  )
}
