import { NotificationForm } from '../notification-form/NotificationForm'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const NotificationCreate = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Ecert - ${t('title.notificationCreate')}`
  }, [t])

  return <NotificationForm></NotificationForm>
}
