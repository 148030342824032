import { AttachFile } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'
import { ClearButton } from '../../../../features/buttons/ClearButton'
import { UploadButton } from '../../../../features/buttons/UploadButton'
import { DownloadLink } from '../../../../features/download-link/DownloadLink'
import { ECERT_API } from '../../../../services/ecert-api'
import { FlexRow } from '../../../../styles/containers/FlexContainers'
import { ProductionDeviceManagementHistoryEntryView } from '../../../../api/types'
import * as snacky from '../../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { ProductionDeviceManagerSchemaType } from '../../../../validation/production-device-manager-schema'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

interface ManagerPoaFileFormProps {
  mode: 'CREATE' | 'EDIT_CURRENT' | 'EDIT_PREVIOUS'
  currentEditingManagementHistoryEntry: ProductionDeviceManagementHistoryEntryView | undefined
  handleClearExistingPoa: () => void
}

export const ManagerPoaFileForm = ({
  mode,
  currentEditingManagementHistoryEntry,
  handleClearExistingPoa
}: ManagerPoaFileFormProps) => {
  const { setValue, watch } = useFormContext<ProductionDeviceManagerSchemaType>()
  const poaAttachment = watch('poa')

  const handleFileSelected = (file: File) => {
    handleClearExistingPoa()
    setValue('poa', {
      id: 0,
      name: file.name,
      createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      content: file
    })
  }

  const removeFile = () => {
    setValue('poa', null)
  }

  return (
    <>
      <Typography variant="body1">{t('productionDevice.management.poa')}</Typography>
      {mode !== 'CREATE' && currentEditingManagementHistoryEntry?.poa && !poaAttachment && (
        <FlexRow gap="8px" justifyContent="space-between" alignItems="center">
          <DownloadLink
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
              currentEditingManagementHistoryEntry?.organizationId!,
              currentEditingManagementHistoryEntry?.id!,
              currentEditingManagementHistoryEntry.poa.id!
            )}
            fileName={currentEditingManagementHistoryEntry?.poa?.name!}
          >
            <FlexRow alignItems="flex-end" gap="8px">
              <AttachFile />
              {currentEditingManagementHistoryEntry?.poa?.name}
            </FlexRow>
          </DownloadLink>
          <ClearButton
            handleClear={() => {
              handleClearExistingPoa()
            }}
          />
        </FlexRow>
      )}
      {poaAttachment && (
        <FlexRow alignItems="center" gap="8px">
          <AttachFile />
          {poaAttachment.name}
          <ClearButton
            handleClear={() => {
              removeFile()
            }}
          />
        </FlexRow>
      )}
      {!currentEditingManagementHistoryEntry?.poa?.name && !poaAttachment && (
        <Typography>{t('productionDevice.management.poaNotAttached')}</Typography>
      )}
      <UploadButton
        acceptFileTypes=".pdf"
        multiple={false}
        startIcon={<AttachFile />}
        variant="contained"
        handleSelected={(file) => {
          if (file.type !== 'application/pdf') {
            enqueueSnackbar(
              t('productionDevice.attachmentTypeNotSupported', { supportedTypes: '.pdf' }),
              snacky.errorOpts 
            )
            return
          }
          handleFileSelected(file)
        }}
      >
        {t('productionDevice.attachFile')}
      </UploadButton>
    </>
  )
}
