import { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ControlledTextField from '../../../features/inputs/ControlledTextField'
import ControlledNumberField from '../../../features/inputs/ControlledNumberField'
import { AccountSelectItemDto, ParticipantDto } from '../../../api/types'
import { useFormContext } from 'react-hook-form'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import ControlledSelect from '../../../features/inputs/ControlledSelect'

interface BeneficiaryFormProps {
  index: number
  addDefaultOrganizationValues: boolean
  addDefaultAccountValues: boolean
  participants: ParticipantDto[]
  accounts: AccountSelectItemDto[]
}

export default function BeneficiaryForm({
  index,
  addDefaultOrganizationValues,
  addDefaultAccountValues,
  participants,
  accounts
}: BeneficiaryFormProps) {
  const { t } = useTranslation()
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<string>('')
  const [beneficiaryInputMethod, setBeneficiaryInputMethod] = useState<'type' | 'select'>(
    addDefaultOrganizationValues ? 'type' : 'select'
  )
  const [accountInputMethod, setAccountInputMethod] = useState<'type' | 'select'>(
    addDefaultAccountValues ? 'type' : 'select'
  )
  const {
    setValue,
    getValues,
    formState: { errors },
    control
  } = useFormContext()

  useEffect(() => {
    if (getValues(`beneficiaries.${index}.name` as any)) {
      setSelectedBeneficiary(getValues(`beneficiaries.${index}.name` as 'beneficiaries.0.name'))
    }
  }, [getValues, index])

  useEffect(() => {
    const selectedAccountNumber = getValues(`beneficiaries.${index}.accountNumber`)
    if (
      selectedAccountNumber &&
      accounts.length &&
      !accounts.some((account) => account.accountNumber === selectedAccountNumber)
    ) {
      setAccountInputMethod('type')
    }
  }, [getValues, accounts, index])

  const toggleBeneficiaryInputMethod = () => {
    setBeneficiaryInputMethod((currentValue) => {
      return currentValue === 'select' ? 'type' : 'select'
    })
  }

  const toggleAccountInputMethod = () => {
    setAccountInputMethod((currentValue) => {
      return currentValue === 'select' ? 'type' : 'select'
    })
  }

  const BeneficiaryRadioGroup = () => {
    return (
      <FormControl>
        <RadioGroup
          aria-label="beneficiary-radio-group"
          name="beneficiary-radio-group"
          value={beneficiaryInputMethod}
          onChange={toggleBeneficiaryInputMethod}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value="select"
            defaultChecked
            control={<Radio />}
            label={t('productionDevice.beneficiary.selectBeneficiary')}
          />
          <FormControlLabel
            value="type"
            control={<Radio />}
            label={t('productionDevice.beneficiary.inputBeneficiary')}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const AccountRadioGroup = () => {
    return (
      <FormControl>
        <RadioGroup
          aria-label="account-radio-group"
          name="account-radio-group"
          value={accountInputMethod}
          onChange={toggleAccountInputMethod}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <FormControlLabel
            value="select"
            defaultChecked
            control={<Radio />}
            label={t('productionDevice.beneficiary.selectAccount')}
          />
          <FormControlLabel value="type" control={<Radio />} label={t('productionDevice.beneficiary.inputAccount')} />
        </RadioGroup>
      </FormControl>
    )
  }

  const handleBeneficiaryChange = (event: SelectChangeEvent) => {
    setSelectedBeneficiary(JSON.parse(event.target.value).name)
    setValue(`beneficiaries.${index}.name` as 'beneficiaries.0.name', JSON.parse(event.target.value).name, {
      shouldValidate: true
    })
  }

  const beneficiaryForm = () => {
    return (
      <FlexRow gap="16px">
        {beneficiaryInputMethod === 'select' ? (
          <FlexRow gap="16px" flex="1">
            <FlexColumn flex="1">
              <InputLabel>{t('productionDevice.beneficiary.beneficiary')}</InputLabel>
              <Select
                labelId="beneficiary-select"
                id="beneficiary-select"
                value={selectedBeneficiary ? selectedBeneficiary : ''}
                onChange={handleBeneficiaryChange}
                renderValue={(value) => value}
              >
                {participants.map((participant) => {
                  return (
                    <MenuItem key={participant.id} value={JSON.stringify(participant)}>
                      {participant.name}
                    </MenuItem>
                  )
                })}
              </Select>
              {errors && errors.beneficiaries && errors.beneficiaries[index] && errors.beneficiaries[index].name && (
                <FormHelperText sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
                  {errors.beneficiaries[index].name.message ? errors.beneficiaries[index].name.message.toString() : ''}
                </FormHelperText>
              )}
            </FlexColumn>
          </FlexRow>
        ) : (
          <FlexRow gap="16px" flex="1">
            <FlexColumn flex="1">
              <ControlledTextField
                id={`beneficiaries.${index}.name`}
                name={`beneficiaries.${index}.name`}
                label={t('productionDevice.beneficiary.name')}
                required
                control={control}
              />
            </FlexColumn>
          </FlexRow>
        )}
      </FlexRow>
    )
  }

  const accountForm = () => {
    return (
      <FlexRow flex="1" gap="16px">
        {accountInputMethod === 'select' ? (
          <FlexRow gap="16px" flex="1">
            <FlexColumn flex="1">
              <ControlledSelect
                id={`beneficiaries.${index}.accountNumber`}
                name={`beneficiaries.${index}.accountNumber`}
                label={t('productionDevice.beneficiary.account')}
                required
                items={accounts.map((account) => {
                  return {
                    key: account.id,
                    value: account.accountNumber,
                    description: account.organizationName,
                    label: `${account.name ? account.name : ''} (${account.accountNumber!})`
                  }
                })}
                control={control}
              />
            </FlexColumn>
          </FlexRow>
        ) : (
          <FlexColumn flex="1">
            <ControlledTextField
              id={`beneficiaries.${index}.accountNumber`}
              name={`beneficiaries.${index}.accountNumber`}
              label={t('productionDevice.beneficiary.accountNumber')}
              required
              control={control}
            />
          </FlexColumn>
        )}
      </FlexRow>
    )
  }

  return (
    <Box display="flex" gap="16px" flex="1">
      <Box display="flex" flex="1" flexDirection="column" gap="16px" marginBottom="16px">
        <BeneficiaryRadioGroup />
        {beneficiaryForm()}
      </Box>

      <Box display="flex" flex="1" flexDirection="column" gap="16px" marginBottom="16px">
        <AccountRadioGroup />
        {accountForm()}
      </Box>

      <FlexColumn justifyContent="flex-end" marginBottom="16px">
        <ControlledNumberField
          acceptFloat
          id={`beneficiaries.${index}.share`}
          name={`beneficiaries.${index}.share`}
          label={t('productionDevice.beneficiary.share')}
          required
          endSuffix="%"
          customErrorText={{
            enabled: true,
            message:
              errors?.beneficiaries &&
              errors?.beneficiaries[index] &&
              errors?.beneficiaries[index].share &&
              errors?.beneficiaries[index].share.message
                ? errors.beneficiaries[index].share.message
                : undefined
          }}
          control={control}
        />
      </FlexColumn>
    </Box>
  )
}
