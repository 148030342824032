import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ECERT_API, ProductionDeviceBody } from '../../services/ecert-api'
import { useLocation, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import ProductionDeviceForm, { getProductionDeviceBackendErrorMessage } from './forms/ProductionDeviceForm'
import { ProductionDeviceViewDto } from '../../api/types'
import { EcertRoutes } from '../../ecert-routes'
import { isAdmin } from '../../utils/permission-utils'
import { AxiosResponse } from 'axios'
import { useLoginUser } from '../../LoginContext'

export default function EditProductionDevice() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId, productionDeviceId } = useParams()
  const loginUser = useLoginUser()
  const { state } = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const [device, setDevice] = useState<ProductionDeviceViewDto>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionDeviceEdit')}`
  }, [t])

  useEffect(() => {
    if (organizationId && productionDeviceId) {
      if (!state.isDraft) {
        ECERT_API.getCurrentProductionDevice(+organizationId, +productionDeviceId).then((response) =>
          setDevice(response.data)
        )
      } else {
        ECERT_API.getProductionDeviceDraft(+organizationId, +productionDeviceId).then((response) =>
          setDevice(response.data)
        )
      }
    }
  }, [state, organizationId, productionDeviceId])

  const handleResponse = (successMessage: string, primise: Promise<AxiosResponse<ProductionDeviceViewDto>>) => {
    primise
      .then(() => {
        navigate(EcertRoutes.productionDevice(organizationId, productionDeviceId))
        enqueueSnackbar(successMessage, snacky.successOpts)
      })
      .catch((error) => {
        const errorMessage = getProductionDeviceBackendErrorMessage(error, t)
        if (errorMessage) {
          enqueueSnackbar(errorMessage, snacky.errorOpts)
        }
      })
  }

  const handleSave = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.updated'),
      ECERT_API.updateApprovedProductionDevice(+organizationId!, +productionDeviceId!, data)
    )
  }

  const handleSaveDraft = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.updated'),
      ECERT_API.updateDraftProductionDevice(+organizationId!, +productionDeviceId!, false, data)
    )
  }

  const handleSaveAndSubmit = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.updatedAndSubmittedForApproval'),
      ECERT_API.updateDraftProductionDevice(+organizationId!, +productionDeviceId!, true, data)
    )
  }

  const handleCancel = () => {
    navigate(EcertRoutes.productionDevice(organizationId, productionDeviceId))
  }

  return (
    <>
      <Box marginBottom="18px">
        <Typography variant="h1">{t('productionDevice.edit')}</Typography>
      </Box>
      {device && (
        <ProductionDeviceForm
          device={device}
          handleSave={isAdmin(loginUser) && device.status === 'APPROVED' ? handleSave : undefined}
          handleSaveDraft={
            !isAdmin(loginUser) || (isAdmin(loginUser) && device.status === 'WAITING_APPROVAL')
              ? handleSaveDraft
              : undefined
          }
          handleSaveAndSubmit={
            !isAdmin(loginUser) && ['APPROVED', 'DRAFT', 'RETURNED'].includes(device.status!)
              ? handleSaveAndSubmit
              : undefined
          }
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
