/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type CancellationTransactionDtoBeneficiaryType = typeof CancellationTransactionDtoBeneficiaryType[keyof typeof CancellationTransactionDtoBeneficiaryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancellationTransactionDtoBeneficiaryType = {
  SUPPLIER: 'SUPPLIER',
  ENDUSER: 'ENDUSER',
  OWNER: 'OWNER',
} as const;
