import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { AutomaticTransferDto, AutomaticTransferRecipientDto } from '../../../api/types'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { EditButton } from '../../../features/buttons/EditButton'
import { DeleteButton } from '../../../features/buttons/DeleteButton'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmButton from '../../../features/buttons/ConfirmButton'
import CancelButton from '../../../features/buttons/CancelButton'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import { canEditAccount } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import { useParams } from 'react-router-dom'

interface IExistingAutomaticTransferProps {
  automaticTransfer: AutomaticTransferDto
  handleEdit: () => void
  handleDelete: () => void
}

export const ExistingAutomaticTransfer = ({
  automaticTransfer,
  handleEdit,
  handleDelete
}: IExistingAutomaticTransferProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const loginUser = useLoginUser()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const columns: IColumn[] = [
    {
      label: t('account.automaticTransfer.recipient'),
      accessor: (recipient: AutomaticTransferRecipientDto) =>
        `${recipient.recipientName} (${recipient.targetAccountNumber})`,
      sortable: false
    },
    {
      label: t('account.automaticTransfer.additionalDetails'),
      accessor: 'additionalDetails',
      sortable: false
    },
    {
      label: `${t('account.automaticTransfer.amount')} %`,
      accessor: 'amount',
      sortable: false
    }
  ]

  const handleEditAutomaticTransfer = () => {
    handleEdit()
  }

  const handleDeleteAutomaticTransfer = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    handleDelete()
    setDeleteDialogOpen(false)
  }

  return (
    <FlexColumn gap="10px">
      {canEditAccount(loginUser, params) && (
        <FlexRow gap="10px">
          <EditButton onClick={handleEditAutomaticTransfer}>{t('common.edit')}</EditButton>
          <DeleteButton onClick={handleDeleteAutomaticTransfer}>{t('common.remove')}</DeleteButton>
        </FlexRow>
      )}
      <EcertTable data={automaticTransfer.recipients!} columns={columns} />
      <Typography>
        {t('account.automaticTransfer.transferDayInfo', { transferDay: automaticTransfer.transferDay })}
      </Typography>

      <CustomDialog
        isOpen={deleteDialogOpen}
        title={t('account.automaticTransfer.removeDialogTitle')}
        handleClose={() => setDeleteDialogOpen(false)}
        buttons={
          <>
            <ConfirmButton handleConfirm={handleDeleteConfirm} />
            <CancelButton handleCancel={() => setDeleteDialogOpen(false)} />
          </>
        }
      >
        <Box>{t('account.automaticTransfer.removeDialogMessage')}</Box>
      </CustomDialog>
    </FlexColumn>
  )
}
