import { Button, Grid } from '@mui/material'
import { FeeTypeDtoName, InvoiceRowDto } from '../../../../api/types'
import { useTranslation } from 'react-i18next'
import { calculateSum, formatCurrency, formatNumberWithSeparator } from '../invoice-utls'
import { useState } from 'react'
import ProductionDeviceSelectDialog from './ProductionDeviceSelectDialog'
import { DeviceFilter } from './ProductionDeviceSelectTable'

interface InvoiceDisplayRowProps {
  row: InvoiceRowDto
  deviceFilter?: DeviceFilter
}

export function InvoiceDisplayRow({ row, deviceFilter }: InvoiceDisplayRowProps) {
  const { t } = useTranslation()

  const [deviceDialogOpen, setDeviceDialogOpen] = useState(false)

  const getTypeDisplay = () => {
    const typeTranslation = t(`invoiceDetailsReport.${row.type}`)
    switch (row.type) {
      case FeeTypeDtoName.OTHER_WORK_PER_HOUR:
        return (
          <>
            {typeTranslation} {row.reason ? `(${row.reason})` : ''}
          </>
        )
      case FeeTypeDtoName.FREE_TEXT_AND_AMOUNT:
        return <>{row.reason}</>
      default:
        return <>{typeTranslation}</>
    }
  }

  return (
    <Grid container item spacing={4}>
      <Grid item xs={3}>
        {getTypeDisplay()}
      </Grid>
      <Grid item xs={3}>
        {`${formatNumberWithSeparator(row.amount ? row.amount : 0)} ${t(`invoiceDetailsReport.unit.${row.unit}`)}`}
        {deviceFilter && !!row.deviceIds?.length && (
          <Button variant="text" sx={{ padding: 0, lineHeight: '14px' }} onClick={() => setDeviceDialogOpen(true)}>
            {t('invoiceDetailsReport.show')}
          </Button>
        )}
      </Grid>
      <Grid item xs={5}>
        {`${row.unitPrice} € / ${t(`invoiceDetailsReport.unit.${row.unit}`)}`}
      </Grid>
      <Grid
        display="flex"
        justifyContent="flex-end"
        item
        textAlign="right"
        width="111px"
        margin="auto"
        sx={{ textWrap: 'nowrap' }}
      >
        {formatCurrency(calculateSum(row))}
      </Grid>
      {deviceFilter && deviceDialogOpen && (
        <ProductionDeviceSelectDialog
          deviceIds={row.deviceIds ?? []}
          deviceFilter={deviceFilter}
          toggleModal={() => setDeviceDialogOpen(!deviceDialogOpen)}
        />
      )}
    </Grid>
  )
}
