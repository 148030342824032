import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { Autocomplete, Box, TextField } from '@mui/material'
import InputContainer from './InputContainer'

type Option<V> = {
  id: V
  name: string
}

interface IControlledAutocomplete<T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>> {
  id?: string
  name: P
  label?: string
  required?: boolean
  options: Option<V>[]
  control: Control<T>
}

export const ControlledAutocomplete = <T extends FieldValues, P extends Path<T>, V extends PathValue<T, P>>({
  id = '',
  name,
  label,
  required,
  options,
  control
}: IControlledAutocomplete<T, P, V>) => {
  return (
    <InputContainer id={id} label={label} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field, formState }) => (
          <Autocomplete
            id={id}
            value={options.find((opt) => opt.id === field.value) || null}
            options={options}
            autoHighlight
            onChange={(_event, value) => field.onChange(value?.id || null)}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.name || ''}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                name={name}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                error={!!formState.errors[name]}
                helperText={formState.errors[name] ? formState.errors[name]?.message?.toString() : ''}
              />
            )}
          />
        )}
      />
    </InputContainer>
  )
}
