/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ExternalTransferQueryStatus = typeof ExternalTransferQueryStatus[keyof typeof ExternalTransferQueryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalTransferQueryStatus = {
  ANY: 'ANY',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;
