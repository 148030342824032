import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserEditDto, UserViewDto } from '../../api/types'
import { ECERT_API } from '../../services/ecert-api'
import { EcertRoutes } from '../../ecert-routes'
import UserForm from '../../features/forms/user/UserForm'
import { Box, TextField, Typography } from '@mui/material'
import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { isAccountAdmin, isAdmin } from '../../utils/permission-utils'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useLoginUser, useLoginContext } from '../../LoginContext'

export const UserEdit = () => {
  const loginUser = useLoginUser()
  const { refreshLoginContext } = useLoginContext()
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [user, setUser] = useState<UserViewDto | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [editDto, setEditDto] = useState<UserEditDto | null>(null)
  const [password, setPassword] = useState('')

  useEffect(() => {
    const userId = params.userId
    if (loginUser.id === +userId!) {
      setUser(loginUser)
    } else {
      if (isAdmin(loginUser) || isAccountAdmin(loginUser)) {
        ECERT_API.getUser(+params.organizationId!, +params.userId!).then((response) => {
          setUser(response.data)
        })
      }
    }

    document.title = `Ecert - ${t('title.userEdit')}`
    // eslint-disable-next-line
  }, [loginUser])

  const handleFormSubmit = (organizationId: number, userEditDto: UserEditDto) => {
    setEditDto(userEditDto)
    setDialogOpen(true)
  }

  const handleCancelEdit = () => {
    navigate(-1)
  }

  const confirmEdit = () => {
    editDto!.oldPassword = password;
    const promise = loginUser.id === editDto?.id
      ? ECERT_API.editLoggedInUser(editDto!)
      : ECERT_API.editUser(+params.organizationId!, editDto!)
    promise.then((response) => {
      if (response.data.id) {
        enqueueSnackbar(t('eventLog.event.USER_UPDATE'), snacky.successOpts)
        navigate(EcertRoutes.user(+params.organizationId!, response.data.id));
      }
      if (loginUser.id === editDto?.id) {
        refreshLoginContext();
      }
    }).catch(() => enqueueSnackbar(t('form.invalid.password.password.invalid'), snacky.errorOpts));
  };

  return (
    <Box>
      {user && (
        <UserForm mode="EDIT" existingUser={user} handleSubmitEdit={handleFormSubmit} handleCancel={handleCancelEdit} />
      )}
      <CustomDialog
        isOpen={dialogOpen}
        title={t('user.confirmModal.title')}
        handleClose={() => setDialogOpen(false)}
        buttons={
          <>
            <ConfirmButton handleConfirm={confirmEdit} />
            <CancelButton handleCancel={() => setDialogOpen(false)} />
          </>
        }
      >
        <FlexColumn gap="16px">
          <Typography variant="body1">{t('user.confirmModal.description')}</Typography>
          <TextField
            label={t('user.confirmModal.password')}
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FlexColumn>
      </CustomDialog>
    </Box>
  )
}
