import { CertificateTransfer } from '../../../features/certificate-transfer/CertificateTransfer'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { Typography } from '@mui/material'
import { ClearButton } from '../../../features/buttons/ClearButton'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { AutomaticTransferSchemaType } from '../../../validation/automatic-transfer-schema'
import ControlledNumberField from '../../../features/inputs/ControlledNumberField'

interface ITransferRecipientProps {
  targetOrganizationId: number
  accountId: number
  recipientNumber: number
  handleClear: (index: number) => void
}

export const TransferRecipient = ({
  targetOrganizationId,
  accountId,
  recipientNumber,
  handleClear
}: ITransferRecipientProps) => {
  const { t } = useTranslation()
  const formContext = useFormContext<AutomaticTransferSchemaType>()

  const recipientContainerStyle = {
    backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
    padding: '16px 40px 16px 40px'
  }

  return (
    <FlexColumn styles={recipientContainerStyle}>
      <FlexRow justifyContent="space-between">
        <Typography variant="h3">{`${t('account.automaticTransfer.recipient')} ${recipientNumber}`}</Typography>
        <ClearButton handleClear={() => handleClear(recipientNumber - 1)} />
      </FlexRow>
      <FlexRow>
        <CertificateTransfer
          targetOrganizationId={targetOrganizationId}
          accountId={accountId}
          paths={{
            type: `transferRecipients.${recipientNumber - 1}.transfer.type`,
            selectedAccount: `transferRecipients.${recipientNumber - 1}.transfer.selectedAccount`,
            targetAccountNumber: `transferRecipients.${recipientNumber - 1}.transfer.targetAccountNumber`,
            recipientName: `transferRecipients.${recipientNumber - 1}.transfer.recipientName`,
            additionalDetails: `transferRecipients.${recipientNumber - 1}.transfer.additionalDetails`
          }}
        ></CertificateTransfer>
      </FlexRow>

      <FlexRow>
        <ControlledNumberField
          id={`transferRecipients.${recipientNumber - 1}.amount`}
          name={`transferRecipients.${recipientNumber - 1}.amount`}
          label={t('account.automaticTransfer.amount')}
          endSuffix="%"
          fullWidth={false}
          acceptFloat
          control={formContext.control}
        />
      </FlexRow>
    </FlexColumn>
  )
}
