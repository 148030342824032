import { Typography } from '@mui/material'
import { getTranslation } from '../../../../utils/utils'
import { FlexColumn } from '../../../../styles/containers/FlexContainers'

interface ILocationInfoProps {
  postNumber: string
  region: string
  latitude: string
  longitude: string
}

export default function LocationInfo({ postNumber, region, latitude, longitude }: ILocationInfoProps) {
  const PostNumberAndRegion = () => {
    return (
      <>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation('productionDevice.postNumber')}
        </Typography>
        <Typography variant="body1" marginBottom="8px">
          {postNumber}
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          {getTranslation('productionDevice.region')}
        </Typography>
        <Typography variant="body1">{region}</Typography>
      </>
    )
  }

  const LatitudeAndLongitude = () => {
    return (
      <>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation('productionDevice.latitude')}
        </Typography>
        <Typography variant="body1" marginBottom="8px">
          {latitude}
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          {getTranslation('productionDevice.longitude')}
        </Typography>
        <Typography variant="body1">{longitude}</Typography>
      </>
    )
  }

  return (
    <FlexColumn>
      {postNumber && <PostNumberAndRegion />}
      {latitude && <LatitudeAndLongitude />}
    </FlexColumn>
  )
}
