import { SyntheticEvent, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { FeeTypeDto, FeeTypeDtoName, InvoiceDetailsDto, InvoicingPeriodDto } from '../../../api/types'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography } from '@mui/material'
import { ECERT_API } from '../../../services/ecert-api'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import StyleGuide from '../../../styles/StyleGuide'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InvoiceOrganizationContent } from './components/InvoiceOrganizationContent'
import { associateWithId, isValidationError, parseDate } from '../../../utils/utils'
import PublishDialog from './components/PublishDialog'
import BillingDialog from './components/BillingDialog'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { EcertRoutes } from '../../../ecert-routes'
import InvoiceSummaryContent from './components/InvoiceSummaryContent'
import { formatInvoicingPeriod } from './invoice-utls'
import OrganizationFilter from './components/OrganizationFilter'
import LoadingIndicator from '../../../features/loading-indicator/LoadingIndicator'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import DownloadButton from '../../../features/buttons/DownloadButton'
import DownloadOutlined from '@mui/icons-material/DownloadOutlined'
import { LockOutlined } from '@mui/icons-material'
import { FlexRow } from '../../../styles/containers/FlexContainers'

function getCustomFeeTypes(types: FeeTypeDto[]): FeeTypeDto[] {
  const customFeeTypeNames = [
    FeeTypeDtoName.EECS_CERTIFICATION.toString(),
    FeeTypeDtoName.OTHER_WORK_PER_HOUR.toString(),
    FeeTypeDtoName.ANNUAL_ACCOUNT_FEE.toString(),
    FeeTypeDtoName.FREE_TEXT_AND_AMOUNT.toString(),
    FeeTypeDtoName.NEW_PRODUCTION_DEVICE.toString(),
    FeeTypeDtoName.ANNUAL_PRODUCTION_DEVICE_FEE.toString()
  ]
  return types.filter((type) => type.name && customFeeTypeNames.includes(type.name))
}

export function InvoicePeriod() {
  const { t } = useTranslation()
  const periodId = Number(useParams().id)
  const organizations = associateWithId(useOrganizations(true)[0])
  const [feeTypes, setFeeTypes] = useState<FeeTypeDto[]>()
  const [filterText, setFilterText] = useState('')
  const [invoicingPeriod, setInvoicingPeriod] = useState<InvoicingPeriodDto>()
  const [expandedId, setExpandedId] = useState<number | null>(null)
  const [isPublishModalOpen, setIsPublishModalOpen] = useState<boolean>(false)
  const [isBillingDialogOpen, setIsBillingDialogOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Ecert - ${t('title.invoiceDetails')}`
  }, [t])

  useEffect(() => {
    ECERT_API.getFeeTypes().then((response) => setFeeTypes(response.data))
  }, [])

  useEffect(() => {
    ECERT_API.getInvoicingPeriod(periodId).then((response) => setInvoicingPeriod(response.data))
  }, [periodId])

  const getInvoiceDetails = (): (InvoiceDetailsDto & { organizationName: string; organizationLocked: boolean })[] => {
    const result =
      invoicingPeriod?.invoiceDetails?.map((detail) => ({
        organizationLocked: organizations[detail.organization!]?.locked ?? false,
        organizationName: organizations[detail.organization!]?.name ?? detail.organization?.toString() ?? '',
        ...detail
      })) ?? []
    return result.sort((a, b) => a.organizationName.localeCompare(b.organizationName, 'fi'))
  }

  const getFilteredInvoiceDetails = () => {
    return getInvoiceDetails().filter((detail) =>
      detail.organizationName.toLocaleLowerCase().includes(filterText?.toLocaleLowerCase() || '')
    )
  }

  const handleAccordionChange = (id: number) => (_event: SyntheticEvent, newExpanded: boolean) => {
    setExpandedId(newExpanded ? id : null)
  }

  const publish = () => {
    ECERT_API.publishInvoicingPeriod(invoicingPeriod!.id!)
      .then(() => {
        enqueueSnackbar(t('form.valid.invoiceDetailsReport.publishSuccessful'), snacky.successOpts)
        navigate(EcertRoutes.reportInvoice())
      })
      .catch((error) => {
        if (isValidationError(error)) {
          enqueueSnackbar(t('form.unknown'), snacky.errorOpts)
        }
      })
  }

  const exportToBilling = () => {
    ECERT_API.exportInvoicingPeriodForBilling(invoicingPeriod!.id!)
      .then(() => {
        enqueueSnackbar(t('form.valid.invoiceDetailsReport.exportSuccessful'), snacky.successOpts)
        navigate(EcertRoutes.reportInvoice())
      })
      .catch((error) => {
        if (isValidationError(error)) {
          enqueueSnackbar(t('form.unknown'), snacky.errorOpts)
        }
      })
  }

  return (
    <>
      {!invoicingPeriod && <LoadingIndicator />}
      {invoicingPeriod && invoicingPeriod.invoiceDetails && organizations && (
        <Box sx={{ flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '16px'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              <Typography variant="h1">{t('invoiceDetailsReport.menuName')}</Typography>
              <Typography variant="h2">{formatInvoicingPeriod(invoicingPeriod)}</Typography>
              <Divider orientation="vertical" />
              <Typography variant="body1">
                {invoicingPeriod.publishedAt
                  ? `${t('invoiceDetailsReport.publishedAt')} ${parseDate(invoicingPeriod.publishedAt).format(
                      'DD.MM.YYYY HH:mm'
                    )}`
                  : t('invoiceDetailsReport.awaitingPublication')}
              </Typography>
              <Divider orientation="vertical" />
              <Typography variant="body1">
                {invoicingPeriod.billedAt
                  ? `${t('invoiceDetailsReport.billedAt')} ${parseDate(invoicingPeriod.billedAt).format(
                      'DD.MM.YYYY HH:mm'
                    )}`
                  : t('invoiceDetailsReport.awaitingBilling')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <OrganizationFilter onChange={(value) => setFilterText(value)} />
              <Button
                disabled={parseDate(invoicingPeriod.publishedAt!).isBefore(dayjs())}
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                onClick={() => setIsPublishModalOpen(true)}
              >
                {t('invoiceDetailsReport.publish')}
              </Button>
              <Button
                disabled={parseDate(invoicingPeriod.billedAt!).isBefore(dayjs())}
                variant="contained"
                endIcon={<ArrowRightAltIcon />}
                onClick={() => setIsBillingDialogOpen(true)}
              >
                {t('invoiceDetailsReport.export')}
              </Button>
            </Box>
          </Box>

          <DownloadButton
            sx={{ marginBottom: '16px' }}
            startIcon={<DownloadOutlined />}
            fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_INVOICING_PERIOD_ALL(invoicingPeriod.id!)}?downloadId=`}
            fileName={`${t('invoiceDetailsReport.menuName')}_${formatInvoicingPeriod(invoicingPeriod!)}.zip`}
          >
            {t('invoiceDetailsReport.downloadAllInvoices')}
          </DownloadButton>

          <Box>
            <Box
              sx={{
                padding: '16px 0 16px 16px',
                backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
              }}
            >
              <Typography variant="body1">{t('organization.organization.name')}</Typography>
            </Box>
          </Box>
          <Accordion expanded={expandedId === periodId} onChange={handleAccordionChange(periodId)} disableGutters>
            <AccordionSummary
              aria-controls={`panel$Alld-content`}
              id={`panelAlld-header`}
              sx={{
                backgroundColor: StyleGuide.constants.COLOR_WHITE,
                flexDirection: 'row-reverse',
                '.MuiAccordionSummary-content': {
                  justifyContent: 'space-between'
                }
              }}
              expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
            >
              <Typography>{t('common.all')}</Typography>
              <Typography>{t('invoiceDetailsReport.menuName')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
              {expandedId === periodId && <InvoiceSummaryContent periodId={periodId} />}
              <Box marginTop="16px">
                <DownloadButton
                  startIcon={<DownloadOutlined />}
                  fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_INVOIVING_PERIOD}${invoicingPeriod.id}?downloadId=`}
                  fileName={`${t('invoiceDetailsReport.menuName')}_ALL_${formatInvoicingPeriod(invoicingPeriod!)}`}
                >
                  {t('invoiceDetailsReport.download')}
                </DownloadButton>
              </Box>
            </AccordionDetails>
          </Accordion>
          <PublishDialog
            toggleModal={() => setIsPublishModalOpen(!isPublishModalOpen)}
            isOpen={isPublishModalOpen}
            onConfirm={() => publish()}
          />
          <BillingDialog
            toggleModal={() => setIsBillingDialogOpen(!isBillingDialogOpen)}
            isOpen={isBillingDialogOpen}
            onConfirm={() => exportToBilling()}
          />
          {getFilteredInvoiceDetails().map((detail) => (
            <Accordion
              key={detail.id}
              expanded={expandedId === detail.id}
              onChange={handleAccordionChange(detail.id!)}
              disableGutters
            >
              <AccordionSummary
                aria-controls={`panel${detail.id}d-content`}
                id={`panel${detail.id}d-header`}
                sx={{
                  backgroundColor: StyleGuide.constants.COLOR_WHITE,
                  flexDirection: 'row-reverse',
                  '.MuiAccordionSummary-content': {
                    justifyContent: 'space-between'
                  }
                }}
                expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
              >
                <FlexRow gap="5px">
                  <Typography>{detail.organizationName}</Typography>
                  {detail.organizationLocked ? <LockOutlined sx={{ color: StyleGuide.constants.COLOR_BRAND }} /> : ''}
                </FlexRow>
                <Typography>{t('invoiceDetailsReport.menuName')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: StyleGuide.constants.COLOR_WHITE }}>
                {expandedId === detail.id && (
                  <InvoiceOrganizationContent
                    organizationName={detail.organizationName}
                    invoiceDetailId={detail.id!}
                    invoicingPeriod={invoicingPeriod}
                    customFeeTypes={getCustomFeeTypes(feeTypes ?? [])}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </>
  )
}
