import { EcertLabel } from '../../styled-components/EcertLabel'
import { EcertDatePicker } from '../date-picker/EcertDatePicker'
import { Box, Typography } from '@mui/material'
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models/views'
import { parseDate } from '../../../utils/utils'

interface IEcertDateRangePickerProps {
  label?: string
  defaultValue: { startDate?: string; endDate?: string }
  views?: CalendarOrClockPickerView[]
  smallLabel?: string
  startLabel?: string
  endLabel?: string
  time?: boolean
  onUpdateStart?: (Dayjs) => any
  onUpdateEnd?: (Dayjs) => any
  clearable?: boolean
  rangeValid?: boolean
  setRangeValid?: (valid: boolean) => void
  openTo?: string
  valuesRequired?: { startDate: boolean; endDate: boolean }
  setAnyDateRangeErrors?: Dispatch<SetStateAction<boolean>>
}

export const EcertDateRangePicker = ({
  label,
  defaultValue,
  views,
  smallLabel,
  startLabel,
  endLabel,
  time = false,
  onUpdateStart,
  onUpdateEnd,
  clearable,
  rangeValid,
  setRangeValid,
  openTo,
  valuesRequired,
  setAnyDateRangeErrors
}: IEcertDateRangePickerProps) => {
  const { t } = useTranslation()
  const [range, setRange] = useState<{ from: Dayjs | null; to: Dayjs | null }>({
    from: defaultValue.startDate ? parseDate(defaultValue.startDate) : null,
    to: defaultValue.endDate ? parseDate(defaultValue.endDate) : null
  })

  useEffect(() => {
    if (range.from && range.to) {
      setRangeValid?.(!range.from.isAfter(range.to))
    } else {
      setRangeValid?.(true)
    }
    // eslint-disable-next-line
  }, [range])

  useEffect(() => {
    if (
      rangeValid === false ||
      (valuesRequired?.startDate && range.from === null) ||
      (range.from !== null && !range.from.isValid()) ||
      (valuesRequired?.endDate && range.from === null) ||
      (range.to !== null && !range.to.isValid())
    ) {
      setAnyDateRangeErrors?.(true)
    } else {
      setAnyDateRangeErrors?.(false)
    }
    // eslint-disable-next-line
  }, [range, rangeValid])

  const ErrorText = ({ children }: { children: ReactNode }) => {
    return (
      <Typography variant="caption" sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
        {children}
      </Typography>
    )
  }

  return (
    <Box className="date-range-picker-container flex-column flex-gap-5">
      {label && <EcertLabel>{label}</EcertLabel>}
      <FlexRow gap="8px" alignItems="center">
        <FlexColumn>
          {startLabel && <Typography variant="body1">{startLabel}</Typography>}
          <EcertDatePicker
            name="startDate"
            views={views}
            openTo={openTo}
            defaultValue={defaultValue.startDate}
            time={time}
            onUpdate={(day: Dayjs) => {
              onUpdateStart?.(day)
              setRange((prevState) => {
                const newState = { ...prevState }
                newState.from = day
                return newState
              })
            }}
            clearable={clearable}
          />
        </FlexColumn>
        <Typography variant="body1">&mdash;</Typography>
        <FlexColumn>
          {endLabel && <Typography variant="body1">{endLabel}</Typography>}
          <EcertDatePicker
            name="endDate"
            views={views}
            openTo={openTo}
            defaultValue={defaultValue.endDate}
            time={time}
            onUpdate={(day: Dayjs) => {
              onUpdateEnd?.(day)
              setRange((prevState) => {
                const newState = { ...prevState }
                newState.to = day
                return newState
              })
            }}
            clearable={clearable}
          />
        </FlexColumn>
      </FlexRow>
      {range.from !== null && !range.from?.isValid() && (
        <ErrorText>{t('form.invalid.dateRange.startInvalid')}</ErrorText>
      )}
      {range.to !== null && !range.to?.isValid() && <ErrorText>{t('form.invalid.dateRange.endInvalid')}</ErrorText>}
      {valuesRequired?.startDate && range.from === null && (
        <ErrorText>{t('form.invalid.dateRange.startRequired')}</ErrorText>
      )}
      {valuesRequired?.endDate && range.to === null && <ErrorText>{t('form.invalid.dateRange.endRequired')}</ErrorText>}
      {rangeValid === false && <ErrorText>{t('form.invalid.dateRange.rangeValid')}</ErrorText>}
      {smallLabel && (
        <Typography variant="caption" sx={{ alignSelf: 'flex-end' }}>
          {smallLabel}
        </Typography>
      )}
    </Box>
  )
}
