import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'

interface ICloseButtonProps {
  handleClose: () => void
}

export default function CloseButton({ handleClose }: ICloseButtonProps) {
  const { t } = useTranslation()

  return (
    <Button variant="outlined" startIcon={<CloseIcon />} onClick={handleClose}>
      {t('common.close')}
    </Button>
  )
}
