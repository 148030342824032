import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

interface ICancelButtonProps {
  handleCancel: () => void
  disabled?: boolean
}

export default function CancelButton({ handleCancel, disabled = false }: ICancelButtonProps) {
  const { t } = useTranslation()

  return (
    <Button disabled={disabled} onClick={handleCancel} variant="outlined" startIcon={<ClearIcon />}>
      {t('common.cancel')}
    </Button>
  )
}
