/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ProductionDeviceInvestmentTypeDtoType = typeof ProductionDeviceInvestmentTypeDtoType[keyof typeof ProductionDeviceInvestmentTypeDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductionDeviceInvestmentTypeDtoType = {
  INVESTMENT: 'INVESTMENT',
  PRODUCTION: 'PRODUCTION',
} as const;
