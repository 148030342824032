import { UserDto } from '../../api/types'
import { AxiosResponse } from 'axios'
import { useNavigate } from 'react-router-dom'
import { ECERT_API } from '../../services/ecert-api'
import { EcertRoutes } from '../../ecert-routes'
import UserForm from '../../features/forms/user/UserForm'
import { enqueueSuccessNotification } from '../../utils/utils'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isAccountAdmin } from '../../utils/permission-utils'
import { useEffect } from 'react'
import { useLoginUser } from '../../LoginContext'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'

export default function UserCreate() {
  const loginUser = useLoginUser()
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Ecert - ${t('title.userNew')}`
  }, [t])

  const handleFormSubmit = (organizationId: number, userDto: UserDto) => {
    organizationId = isAccountAdmin(loginUser) ? loginUser.organizationId! : organizationId
    ECERT_API.createUser(organizationId, userDto).then(
      (response: AxiosResponse<UserDto>) => {
        if (response.data.id) {
          enqueueSuccessNotification(<Typography>{t('form.valid.user.created')}</Typography>)
          navigate(EcertRoutes.organizationUser(organizationId, response.data.id))
        }
      },
      (error) => {
        if (error.response.data.find((d) => d.messageKey === 'reserved' && d.field === 'username')) {
          enqueueSnackbar(t('form.invalid.user.username.reserved'), snacky.errorOpts)
        }
      }
    )
  }

  const cancelCreateOrganization = () => {
    navigate(EcertRoutes.users())
  }

  return <UserForm mode="CREATE" handleSubmitCreate={handleFormSubmit} handleCancel={cancelCreateOrganization} />
}
