import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import { useDeferredValue, useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import {
  AccountDto,
  OrganizationListItemDto,
  TransactionQuery,
  TransactionQueryResult,
  TransactionQueryTransactionTypesItem,
  TransactionReportAmendmentDto,
  TransactionReportItemDto
} from '../../../api/types'
import AccountSelect from '../../../features/account-select/AccountSelect'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import { Dayjs } from 'dayjs'
import StyleGuide from '../../../styles/StyleGuide'
import Search from '@mui/icons-material/Search'
import DownloadOutlined from '@mui/icons-material/DownloadOutlined'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { parseDate } from '../../../utils/utils'
import HelpOutline from '@mui/icons-material/HelpOutline'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../../ecert-routes'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import CancelButton from '../../../features/buttons/CancelButton'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { Paginator } from '../../../features/paginator/Paginator'
import { canViewOrganizations, canViewTransaction, isAdmin } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { InfoBox } from '../../../features/info-box/InfoBox'

export const TransactionsReport = () => {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const navigate = useNavigate()
  const [organizations, setOrganizations] = useState<OrganizationListItemDto[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListItemDto | null>(null)
  const [accounts, setAccounts] = useState<AccountDto[]>([])
  const [selectedAccount, setSelectedAccount] = useState<AccountDto | null>(null)
  const [fromTime, setFromTime] = useState<Dayjs | null>(null)
  const [toTime, setToTime] = useState<Dayjs | null>(null)
  const [selectedTypes, setSelectedTypes] = useState<TransactionQueryTransactionTypesItem[]>([])
  const [transactions, setTransactions] = useState<TransactionQueryResult | null>(null)
  const [amendmentAmountDialogOpen, setAmendmentAmountDialogOpen] = useState(false)
  const [selectedReportItem, setSelectedReportItem] = useState<TransactionReportItemDto | null>(null)
  const [currentPage, setCurrentPage] = useState(-1)
  const currentPageDeferred = useDeferredValue(currentPage)
  const [idSearchText, setIdSearchText] = useState<string | null>('')
  const columns: IColumn[] = [
    {
      label: t('transactionReport.results.identifier'),
      accessor: 'id',
      sortable: false
    },
    {
      label: t('transactionReport.results.time'),
      accessor: (obj: TransactionReportItemDto) => parseDate(obj.time!).format('DD.MM.YYYY HH:mm'),
      sortable: false
    },
    {
      label: t('transactionReport.results.type'),
      accessor: (obj: TransactionReportItemDto) => t(`transaction.transactionType.${obj.type}`),
      sortable: false
    },
    {
      label: t('transactionReport.results.amount'),
      accessor: 'processedCertificateAmount',
      sortable: false
    },
    {
      label: t('transactionReport.results.source'),
      accessor: (obj: TransactionReportItemDto) =>
        obj.sourceParticipantName ? (
          <FlexRow alignItems="center">
            <Typography variant="body1">{`${obj.sourceParticipantName} (${obj.sourceAccountNumber})`}</Typography>
            {obj.sourceOrganizationId !== 0 &&
              canViewTransaction(loginUser, {
                organizationId: obj.sourceOrganizationId!,
                accountId: obj.sourceAccountId!
              }) && (
                <IconButton
                  aria-label={`source-${obj.id}`}
                  onClick={() =>
                    navigate(
                      EcertRoutes.organizationAccountTransaction(obj.sourceOrganizationId, obj.sourceAccountId, obj.id)
                    )
                  }
                >
                  <HelpOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
                </IconButton>
              )}
          </FlexRow>
        ) : (
          ''
        ),
      sortable: false
    },
    {
      label: t('transactionReport.results.target'),
      accessor: (obj: TransactionReportItemDto) =>
        obj.targetParticipantName ? (
          <FlexRow alignItems="center">
            <Typography variant="body1">{`${obj.targetParticipantName} (${obj.targetAccountNumber})`}</Typography>
            {obj.targetOrganizationId !== 0 &&
              canViewTransaction(loginUser, {
                organizationId: obj.targetOrganizationId!,
                accountId: obj.targetAccountId!
              }) && (
                <IconButton
                  aria-label={`target-${obj.id}`}
                  onClick={() =>
                    navigate(
                      EcertRoutes.organizationAccountTransaction(obj.targetOrganizationId, obj.targetAccountId, obj.id)
                    )
                  }
                >
                  <HelpOutline sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
                </IconButton>
              )}
          </FlexRow>
        ) : (
          ''
        ),
      sortable: false
    },
    {
      label: t('transactionReport.results.user'),
      accessor: 'userFullName',
      sortable: false
    },
    {
      label: t('transactionReport.results.amendmentAmount'),
      accessor: (obj: TransactionReportItemDto) =>
        obj.amendmentAmount && obj.amendmentAmount > 0 ? (
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedReportItem(obj)
              setAmendmentAmountDialogOpen(true)
            }}
          >
            {obj.amendmentAmount}
          </Button>
        ) : (
          ''
        ),
      sortable: false
    }
  ]
  const amendmentDialogTableColumns: IColumn[] = [
    {
      label: t('transactionReport.results.identifier'),
      accessor: (obj: TransactionReportAmendmentDto) => obj.transactionId,
      sortable: false
    },
    {
      label: t('transactionReport.results.time'),
      accessor: (obj: TransactionReportAmendmentDto) => parseDate(obj.date!).format('DD.MM.YYYY HH:mm'),
      sortable: false
    },
    {
      label: t('transactionReport.results.amount'),
      accessor: 'amount',
      sortable: false
    },
    {
      label: t('transactionReport.results.user'),
      accessor: 'userFullName',
      sortable: false
    }
  ]

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      ECERT_API.getAllOrganizations().then((response) => {
        setOrganizations(response.data)
      })
    } else {
      if (!loginUser.organizationId) return

      ECERT_API.getAccounts(loginUser.organizationId).then((response) => {
        setAccounts(response.data)
      })
    }
  }, [loginUser])

  useEffect(() => {
    if (loginUser.organizationId) {
      setSelectedOrganization(organizations.find((o) => o.id === loginUser.organizationId) || null)
    }
  }, [loginUser, organizations])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      const request =
        selectedOrganization === null ? ECERT_API.getAllAccounts() : ECERT_API.getAccounts(selectedOrganization.id!)
      request.then((response) => {
        setAccounts(response.data)
      })
    }
  }, [loginUser, selectedOrganization])

  useEffect(() => {
    if (currentPageDeferred !== -1) {
      fetchTransactions({
        organizationId: canViewOrganizations(loginUser) ? selectedOrganization?.id || 0 : loginUser.organizationId!,
        fromTime: fromTime?.format('YYYY-MM-DD'),
        toTime: toTime?.format('YYYY-MM-DD'),
        transactionTypes: selectedTypes,
        accountId: selectedAccount?.id || 0,
        page: currentPageDeferred - 1
      })
    }
    // eslint-disable-next-line
  }, [currentPageDeferred])

  const fetchTransactions = (query: TransactionQuery) => {
    setTransactions((prevState) => {
      const newState = { ...prevState }
      newState.items = undefined
      return newState
    })
    if (query.organizationId !== undefined) {
      ECERT_API.searchTransactions(query.organizationId, query).then((response) => {
        setTransactions(response.data)
      })
    }
  }

  const handleToggleType = (event, checked: boolean) => {
    setSelectedTypes((prevState) => {
      const newState = [...prevState]
      const existingValueIndex = newState.findIndex((type) => type === event.target.value)
      if (checked && existingValueIndex === -1) {
        newState.push(event.target.value)
      } else if (!checked && existingValueIndex !== -1) {
        newState.splice(existingValueIndex, 1)
      }
      return newState
    })
  }

  const buildTransactionsFileUrl = (withCertificates: boolean) => {
    const organizationId = canViewOrganizations(loginUser) ? selectedOrganization?.id || 0 : loginUser.organizationId!
    const accountId = selectedAccount?.id || 0
    const fromTimeFormatted = fromTime?.format('YYYY-MM-DD') || ''
    const toTimeFormatted = toTime?.format('YYYY-MM-DD') || ''
    const transactionTypesFormatted = selectedTypes.map((type) => `transactionTypes=${type}`).join('&')

    return ECERT_API.FILE_DOWNLOAD_URLS.TRANSACTION_REPORT(
      organizationId,
      accountId,
      fromTimeFormatted,
      toTimeFormatted,
      currentPage - 1,
      transactionTypesFormatted,
      withCertificates
    )
  }

  const customErrorHandler = (error) => {
    if (error.code === 'ERR_BAD_REQUEST') {
      enqueueSnackbar(t('transactionReport.error.download'), snacky.errorOpts)
    }
  }

  return (
    <FlexColumn gap="16px">
      <Typography id="title-report-transactions" variant="h1">
        {t('transactionReport.title')}
      </Typography>
      <InfoBox type="INFO">{t('transactionReport.searchInfo')}</InfoBox>
      <FlexColumn>
        <InputLabel>{t('transactionReport.results.identifier')}</InputLabel>
        <FlexRow gap="8px">
          <TextField
            placeholder={t('transactionReport.searchTransactionPlaceholder')}
            variant="outlined"
            value={idSearchText}
            InputProps={{
              sx: { height: '50px', width: '240px' }
            }}
            onChange={(event) => setIdSearchText(event.target.value)}
          />
          <Button
            disabled={!idSearchText || isNaN(+idSearchText)}
            onClick={() => {
              fetchTransactions({
                organizationId: canViewOrganizations(loginUser) ? 0 : loginUser.organizationId!,
                fromTime: undefined,
                transactionTypes: [],
                toTime: undefined,
                page: 0,
                transactionId: +idSearchText!,
                accountId: undefined
              })
            }}
            variant="contained"
            startIcon={<Search />}
          >
            {t('transactionReport.searchTransaction')}
          </Button>
        </FlexRow>
      </FlexColumn>
      <Divider />
      <FlexRow gap="16px" alignItems="flex-end">
        {canViewOrganizations(loginUser) && (
          <Box sx={{ flex: 1 }}>
            <OrganizationSelect
              value={selectedOrganization}
              organizations={organizations}
              onSelect={setSelectedOrganization}
            />
          </Box>
        )}
        <Box sx={{ flex: 1 }}>
          <AccountSelect accounts={accounts} onSelect={setSelectedAccount} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <EcertDateRangePicker
            clearable={true}
            label={t('transactionReport.time')}
            defaultValue={{}}
            onUpdateStart={setFromTime}
            onUpdateEnd={setToTime}
          />
        </Box>
      </FlexRow>
      <FlexColumn
        style={{
          backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4,
          alignSelf: 'flex-start',
          padding: '16px 24px'
        }}
      >
        <InputLabel>{t('transactionReport.type')}</InputLabel>
        <FlexRow>
          <FlexColumn>
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.DOMESTIC_TRANSFER}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.DOMESTIC_TRANSFER')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.EXTERNAL_TRANSFER}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.EXTERNAL_TRANSFER')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.EXTERNAL_TRANSFER_ROLLBACK}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.EXTERNAL_TRANSFER_ROLLBACK')}
            />
          </FlexColumn>
          <FlexColumn>
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.DOMESTIC_CANCELLATION}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.DOMESTIC_CANCELLATION')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.EXTERNAL_CANCELLATION}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.EXTERNAL_CANCELLATION')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.CONVERSION_ENERGY_AUTHORITY}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.CONVERSION_ENERGY_AUTHORITY')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.CONVERSION_GASGRID_FINLAND}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.CONVERSION_GASGRID_FINLAND')}
            />
          </FlexColumn>
          <FlexColumn>
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.ISSUING}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.ISSUING')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.EXPIRATION}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.EXPIRATION')}
            />
            <FormControlLabel
              onChange={handleToggleType}
              value={TransactionQueryTransactionTypesItem.CORRECTION}
              control={<Checkbox />}
              label={t('transactionReport.transactionType.CORRECTION')}
            />
            {isAdmin(loginUser) && (
              <FormControlLabel
                onChange={handleToggleType}
                value={TransactionQueryTransactionTypesItem.CANCELLATION_ROLLBACK}
                control={<Checkbox />}
                label={t('transactionReport.transactionType.CANCELLATION_ROLLBACK')}
              />
            )}
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
      <FlexRow gap="8px">
        <Button
          onClick={() => {
            if (currentPage === -1 || currentPage !== 1) {
              setCurrentPage(1)
            } else {
              fetchTransactions({
                organizationId: canViewOrganizations(loginUser)
                  ? selectedOrganization?.id || 0
                  : loginUser.organizationId!,
                fromTime: fromTime?.format('YYYY-MM-DD'),
                toTime: toTime?.format('YYYY-MM-DD'),
                transactionTypes: selectedTypes,
                accountId: selectedAccount?.id || 0,
                page: currentPageDeferred - 1
              })
            }
          }}
          variant="contained"
          startIcon={<Search />}
        >
          {t('transactionReport.search')}
        </Button>
        <DownloadButton
          fileUrl={buildTransactionsFileUrl(false)}
          customErrorHandler={customErrorHandler}
          fileName="transactions.xlsx"
          startIcon={<DownloadOutlined sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
        >
          {t('transactionReport.exportTransactions')}
        </DownloadButton>
        <DownloadButton
          fileUrl={buildTransactionsFileUrl(true)}
          customErrorHandler={customErrorHandler}
          fileName="transactions.xlsx"
          startIcon={<DownloadOutlined sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
        >
          {t('transactionReport.exportTransactionCertificates')}
        </DownloadButton>
      </FlexRow>
      {transactions && (
        <>
          <EcertTable data={transactions?.items} columns={columns} />
          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPages={Math.ceil(transactions?.count! / 50)}
          />
        </>
      )}
      <CustomDialog
        isOpen={amendmentAmountDialogOpen}
        title={`${t('transactionReport.results.amendmentTotal')}: ${selectedReportItem?.amendmentAmount}`}
        handleClose={() => setAmendmentAmountDialogOpen(false)}
        buttons={
          <>
            <CancelButton handleCancel={() => setAmendmentAmountDialogOpen(false)} />
          </>
        }
      >
        <EcertTable
          ariaDescribedBy="title-report-transactions"
          data={selectedReportItem?.amendments}
          columns={amendmentDialogTableColumns}
        />
      </CustomDialog>
    </FlexColumn>
  )
}
