import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../styles/StyleGuide'
import { CircularProgress, Typography } from '@mui/material'

function LoadingIndicator() {
  const [t] = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '400px',
        backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4
      }}
    >
      <CircularProgress sx={{ color: StyleGuide.constants.COLOR_BRAND }} aria-label="loading" />
      <Typography variant="body1">{t('invoiceDetailsReport.loadingText')}</Typography>
    </Box>
  )
}

export default LoadingIndicator
