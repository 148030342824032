import constants from './constants'
import { StyledRightAligned, StyledTableHeaderContainer, StyledTable } from './containers/TableContainers'

const StyleGuide = {
  constants,
  containers: {
    StyledRightAligned,
    StyledTableHeaderContainer,
    StyledTable
  }
}

export default StyleGuide
