import { Typography } from '@mui/material'
import { DownloadLink } from '../../../../features/download-link/DownloadLink'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import { getTranslation, parseDate } from '../../../../utils/utils'
import { ECERT_API } from '../../../../services/ecert-api'
import { ProductionDeviceAttachmentDto } from '../../../../api/types'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

interface IAttachmentsInfoProps {
  attachments: ProductionDeviceAttachmentDto[]
}

export default function AttachmentsInfo({ attachments }: IAttachmentsInfoProps) {
  const { organizationId, productionDeviceId } = useParams()

  return (
    <FlexColumn gap="8px">
      {attachments
        .sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1))
        .map((attachment) => (
          <FlexRow key={attachment.id} justifyContent="space-between">
            <DownloadLink
              fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
                +organizationId!,
                +productionDeviceId!,
                attachment.id!
              )}`}
              fileName={attachment.name!}
            >
              {attachment.name}
            </DownloadLink>

            <Typography>{`${getTranslation('productionDevice.added')} ${parseDate(attachment.createdAt!).format(
              'DD.MM.YYYY hh:mm'
            )}`}</Typography>
          </FlexRow>
        ))}
    </FlexColumn>
  )
}
