import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { Box, Button, Typography } from '@mui/material'
import { AttachFile, Edit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import { useNavigate, useParams } from 'react-router-dom'
import {
  OrganizationListItemDto,
  ProductionDeviceManagementHistoryEntryView,
  ProductionDeviceManagementHistoryView,
  SetNewProductionDeviceManagerBody,
  UpdateProductionDeviceManagerBody
} from '../../../api/types'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { parseDate, range } from '../../../utils/utils'
import { DownloadLink } from '../../../features/download-link/DownloadLink'
import dayjs from 'dayjs'
import { Manager } from './Manager'
import { EditButton } from '../../../features/buttons/EditButton'
import { EcertRoutes } from '../../../ecert-routes'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { InfoBox } from '../../../features/info-box/InfoBox'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import CloseButton from '../../../features/buttons/CloseButton'
import { ProductionDeviceManagerSchemaType } from '../../../validation/production-device-manager-schema'

export const ManagementTab = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = useParams()
  const [managementHistory, setManagementHistory] = useState<ProductionDeviceManagementHistoryView | null>(null)
  const [settingNewManager, setSettingNewManager] = useState(false)
  const [editingExistingManager, setEditingExistingManager] = useState(false)
  const [organizations, setOrganizations] = useState<OrganizationListItemDto[] | null>(null)
  const [newProductionDeviceManagerBody] = useState<SetNewProductionDeviceManagerBody>({
    command: {}
  })
  const [selectedAttachments, setSelectedAttachments] = useState<File[] | null>(null)
  const [currentEditingManagementHistoryEntry, setCurrentEditingManagementHistoryEntry] =
    useState<ProductionDeviceManagementHistoryEntryView | null>(null)
  const [dialogState, setDialogState] = useState({ dialogOpen: false, organizationId: 0 })

  const columns: IColumn[] = [
    {
      label: t('productionDevice.management.startTime'),
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView) =>
        parseDate(managementObj.startTime!).format('DD.MM.YYYY')
    },
    {
      label: t('productionDevice.management.endTime'),
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView) =>
        managementObj.endTime ? parseDate(managementObj.endTime).format('DD.MM.YYYY') : ''
    },
    {
      label: t('productionDevice.management.organization'),
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView, index?: number) => {
        return index === 0
          ? `${managementObj.organizationName} (${t('productionDevice.management.current')})`
          : managementObj.organizationName
      }
    },
    {
      label: t('productionDevice.management.poa'),
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView) => {
        if (managementObj.poa) {
          return (
            <DownloadLink
              fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
                +params.organizationId!,
                +params.productionDeviceId!,
                managementObj.poa.id!
              )}
              fileName={managementObj.poa.name!}
            >
              <FlexRow>
                <AttachFile />
                <span>{managementObj.poa.name}</span>
              </FlexRow>
            </DownloadLink>
          )
        } else {
          return t('productionDevice.management.poaNotAttached')
        }
      }
    },
    {
      label: t('productionDevice.management.poaExtraAttachments'),
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView) => {
        if ((managementObj.attachments?.length || 0) > 0) {
          return (
            <Box>
              {managementObj.attachments?.map((attachment) => (
                <DownloadLink
                  key={attachment.id}
                  fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
                    +params.organizationId!,
                    +params.productionDeviceId!,
                    attachment.id!
                  )}
                  fileName={attachment.name!}
                >
                  <FlexRow>
                    <AttachFile />
                    <span>{attachment.name}</span>
                  </FlexRow>
                </DownloadLink>
              ))}
            </Box>
          )
        } else {
          return t('productionDevice.management.poaExtraAttachmentsNotAdded')
        }
      }
    },
    {
      label: '',
      accessor: (managementObj: ProductionDeviceManagementHistoryEntryView, index?: number) =>
        (index || 0) > 0 ? (
          <EditButton
            onClick={() => {
              setEditingExistingManager(true)
              setCurrentEditingManagementHistoryEntry(managementObj)
            }}
          />
        ) : (
          ''
        )
    }
  ]

  useEffect(() => {
    ECERT_API.getAllOrganizations().then((response) => {
      setOrganizations(response.data)
    })
    reloadManagementHistory()
    // eslint-disable-next-line
  }, [])

  const isExistingManagementHistoryEntryCurrentManager = () => {
    return currentEditingManagementHistoryEntry?.id! === managementHistory?.managementHistoryEntrys?.[0].id!
  }

  const reloadManagementHistory = () => {
    ECERT_API.getProductionDeviceManagementHistory(+params.organizationId!, +params.productionDeviceId!).then(
      (response) => {
        response.data.managementHistoryEntrys = response.data.managementHistoryEntrys?.sort((a, b) =>
          parseDate(a.startTime!).isBefore(parseDate(b.startTime!)) ? 1 : -1
        )
        setManagementHistory(response.data)
      }
    )
  }

  const handleSetNewManager = (formData: ProductionDeviceManagerSchemaType) => {
    newProductionDeviceManagerBody.otherFiles = range(0, selectedAttachments?.length!)
      .map((index) => selectedAttachments?.at(index))
      .filter((item): item is File => !!item)

    const body: SetNewProductionDeviceManagerBody = {
      command: {
        startTime: dayjs(formData.dateRange.managementStartTime).format('YYYY-MM-DD'),
        endTime: formData.dateRange.managementEndTime
          ? dayjs(formData.dateRange.managementEndTime).format('YYYY-MM-DD')
          : undefined,
        poaId: undefined,
        oldAttachments: []
      },
      poa: formData.poa && formData.poa.content ? formData.poa.content : undefined,
      otherFiles:
        formData.attachments?.filter((attachment) => attachment.content).map((attachment) => attachment.content!) ||
        undefined
    }

    ECERT_API.setNewProductionDeviceManager(formData.organizationId!, +params.productionDeviceId!, body).then(() => {
      const selectedOrganization = organizations?.find((org) => org.id === formData.organizationId)
      if (dayjs(formData.dateRange.managementStartTime).isAfter(dayjs()) || !selectedOrganization?.mainAccountNumber) {
        setDialogState({ dialogOpen: true, organizationId: selectedOrganization?.id! })
      } else {
        navigate(EcertRoutes.productionDevice(formData.organizationId, +params.productionDeviceId!))
      }
      enqueueSnackbar(t('form.valid.productionDevice.managerSet'), snacky.successOpts)
    })
  }

  const handleUpdateExistingManager = (formData: ProductionDeviceManagerSchemaType) => {
    const body: UpdateProductionDeviceManagerBody = {
      command: {
        startTime: dayjs(formData.dateRange.managementStartTime).format('YYYY-MM-DD'),
        endTime: formData.dateRange.managementEndTime
          ? dayjs(formData.dateRange.managementEndTime!).format('YYYY-MM-DD')
          : undefined,
        poaId: currentEditingManagementHistoryEntry?.poa?.id!,
        oldAttachments: currentEditingManagementHistoryEntry?.attachments?.map((attachment) => attachment.id!)
      },
      poa: formData.poa && formData.poa.content ? formData.poa.content : undefined,
      otherFiles:
        formData.attachments?.filter((attachment) => attachment.content).map((attachment) => attachment.content!) ||
        undefined
    }

    if (isExistingManagementHistoryEntryCurrentManager()) {
      ECERT_API.updateCurrentProductionDeviceManager(formData?.organizationId!, +params.productionDeviceId!, body).then(
        () => {
          navigate(EcertRoutes.productionDevice(formData.organizationId, +params.productionDeviceId!))
          enqueueSnackbar(t('form.valid.productionDevice.managerUpdated'), snacky.successOpts)
        }
      )
    } else {
      ECERT_API.updateProductionDeviceManager(
        formData.organizationId!,
        +params.productionDeviceId!,
        currentEditingManagementHistoryEntry?.id!,
        body
      ).then(() => {
        navigate(EcertRoutes.productionDevice(Number(params?.organizationId), Number(params.productionDeviceId!)))
        enqueueSnackbar(t('form.valid.productionDevice.managerUpdated'), snacky.successOpts)
      })
    }
  }

  const handleSetEditingCurrentManager = () => {
    const currentManager = managementHistory?.managementHistoryEntrys?.[0]!
    setEditingExistingManager(true)
    setCurrentEditingManagementHistoryEntry(currentManager)
  }

  return (
    <FlexColumn gap="16px">
      <FlexRow marginTop="16px">
        <InfoBox type="INFO">{t('productionDevice.managerUpdateInfo')}</InfoBox>
      </FlexRow>
      <FlexRow gap="16px">
        {!editingExistingManager && !settingNewManager && (
          <FlexRow gap="8px">
            <Button variant="outlined" startIcon={<Edit />} onClick={handleSetEditingCurrentManager}>
              {t('productionDevice.management.updateCurrentManager')}
            </Button>
            <Button variant="outlined" startIcon={<Edit />} onClick={() => setSettingNewManager(true)}>
              {t('productionDevice.management.setNewManager')}
            </Button>
          </FlexRow>
        )}
        {settingNewManager && (
          <Manager
            mode="CREATE"
            organizations={organizations}
            selectedAttachments={selectedAttachments}
            setSelectedAttachments={setSelectedAttachments}
            handleSubmit={handleSetNewManager}
            handleCancel={() => setSettingNewManager(false)}
            managementHistory={managementHistory}
          />
        )}
        {editingExistingManager && currentEditingManagementHistoryEntry && (
          <Manager
            mode={isExistingManagementHistoryEntryCurrentManager() ? 'EDIT_CURRENT' : 'EDIT_PREVIOUS'}
            currentEditingManagementHistoryEntry={currentEditingManagementHistoryEntry}
            setCurrentEditingManagementHistoryEntry={setCurrentEditingManagementHistoryEntry}
            organizations={organizations}
            selectedAttachments={selectedAttachments}
            setSelectedAttachments={setSelectedAttachments}
            handleSubmit={handleUpdateExistingManager}
            handleCancel={() => setEditingExistingManager(false)}
          />
        )}
      </FlexRow>

      {organizations !== null && <EcertTable data={managementHistory?.managementHistoryEntrys} columns={columns} />}

      <CustomDialog
        isOpen={dialogState.dialogOpen}
        title={t('productionDevice.management.dialogTitle')}
        handleClose={() =>
          navigate(EcertRoutes.productionDevice(dialogState.organizationId, +params.productionDeviceId!))
        }
        buttons={
          <>
            <CloseButton
              handleClose={() =>
                navigate(EcertRoutes.productionDevice(dialogState.organizationId, +params.productionDeviceId!))
              }
            />
          </>
        }
      >
        <Typography>{t('productionDevice.management.infoText')}</Typography>
      </CustomDialog>
    </FlexColumn>
  )
}
