import { Button } from '@mui/material'
import StyleGuide from '../../styles/StyleGuide'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'

interface IOpenTransactionButtonProps {
  organizationId: number
  accountId: number
  transactionId: number
}

export const OpenTransactionButton = ({ organizationId, accountId, transactionId }: IOpenTransactionButtonProps) => {
  const navigate = useNavigate()

  return (
    <Button
      aria-label={`open-transaction-${transactionId}`}
      onClick={() => navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, transactionId))}
      sx={{ border: `2px solid ${StyleGuide.constants.COLOR_BRAND}` }}
    >
      <ArrowForward sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
    </Button>
  )
}
