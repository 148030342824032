import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationViewDto, AccountDto, OrganizationListItemDto } from '../../api/types'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { ECERT_API } from '../../services/ecert-api'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { requiredValidator } from '../../features/forms/validators'
import usePowerform from 'powerform-react'
import { isAdmin, isUser } from '../../utils/permission-utils'
import { useLoginUser } from '../../LoginContext'

interface IDialogFormProps {
  openModal: boolean
  setOpenModal: any
  organizations: OrganizationListItemDto[]
  onSubmit: (accountName: string, accountNumber: string, status: any) => void
}

export default function DialogForm({ openModal, setOpenModal, organizations, onSubmit }: IDialogFormProps) {
  const loginUser = useLoginUser()
  const handleClose = () => setOpenModal(false)
  const { t } = useTranslation()
  const [organization, setOrganization] = useState<OrganizationListItemDto>()
  const [visibilityRadioGroup, setVisibilityRadioGroup] = useState(false)
  const [newAccount, setNewAccount] = useState<AccountDto>({
    name: '',
    publicVisibility: false
  })
  const form = usePowerform({
    field: [requiredValidator('form.invalid.account.name.required')]
  })

  useEffect(() => {
    if (!loginUser.organizationId) return
    if (isUser(loginUser)) return

    const DEFAULT_ID = isAdmin(loginUser) ? 172 : loginUser.organizationId

    ECERT_API.getOrganization(DEFAULT_ID).then((response: AxiosResponse<OrganizationViewDto>) => {
      setOrganization(response.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser])

  const handleAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAccount({
      ...newAccount,
      name: event.target.value,
      publicVisibility: visibilityRadioGroup
    } as AccountDto)

    form.field.setData(event.target.value)
    form.field.validate()
  }

  const handleOrganizationSelect = (event: SelectChangeEvent) => {
    ECERT_API.getOrganization(Number(event.target.value)).then((response: AxiosResponse<OrganizationViewDto>) => {
      setOrganization(response.data)
    })
  }

  const handleModalVisibilitySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibilityRadioGroup((event.target as HTMLInputElement).value.toLocaleLowerCase() === 'true')
    setNewAccount({
      ...newAccount,
      publicVisibility: (event.target as HTMLInputElement).value.toLocaleLowerCase() === 'true'
    } as AccountDto)
  }

  const handleAccountCreateClick = (organizationId: number, account: AccountDto) => {
    ECERT_API.createAccount(organizationId, account).then((e) => {
      setOpenModal(false)
      onSubmit(e.data.name, e.data.accountNumber || '', 'success')
    })
  }

  const handleCreate = () => {
    const organizationId = isAdmin(loginUser) ? organization?.id : loginUser.organizationId
    if (organizationId && newAccount) {
      handleAccountCreateClick(organizationId, newAccount)
    }
  }

  return (
    <>
      <CustomDialog
        isOpen={openModal}
        title={t('account.add')}
        handleClose={handleClose}
        buttons={DialogButtons(handleCreate, handleClose, newAccount.name.length <= 0)}
      >
        {isAdmin(loginUser) && (
          <>
            <Typography sx={{ marginBottom: '5px' }}>{t('organization.menu.title')}</Typography>
            <Select
              autoFocus={true}
              fullWidth
              labelId="organization-select"
              value={organization?.id?.toString()}
              onChange={handleOrganizationSelect}
              sx={{ marginBottom: '15px' }}
            >
              {organizations.map(
                (e: OrganizationListItemDto) =>
                  !e.locked && (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </>
        )}
        <TextField
          sx={{ marginBottom: '15px', marginTop: '15px' }}
          error={!!form.field.getError()}
          onBlur={() => form.field.validate()}
          fullWidth
          placeholder={t('organization.list.name')}
          name="name"
          label={t('organization.list.name')}
          onChange={handleAccountNameChange}
          helperText={t(form.field.getError())}
        />
        <FormControl>
          <FormLabel>{t('account.visibility')}</FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={visibilityRadioGroup}
            onChange={handleModalVisibilitySelect}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <FormControlLabel value="false" control={<Radio />} label={t('account.hidden')} />
            <FormControlLabel value="true" control={<Radio />} label={t('account.visible')} />
          </RadioGroup>
        </FormControl>
      </CustomDialog>
    </>
  )
}

const DialogButtons = (handleCreate: () => void, handleClose: () => void, disabled: boolean): React.ReactNode => {
  const { t } = useTranslation()

  const boxStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    marginTop: '10px'
  }

  return (
    <>
      <Box sx={boxStyles}>
        <Button startIcon={<CheckIcon />} disabled={disabled} variant="contained" onClick={handleCreate}>
          {t('common.save')}
        </Button>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
          {t('common.cancel')}
        </Button>
      </Box>
    </>
  )
}
