import { Box, Button, Divider, Link, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ECERT_API } from '../../services/ecert-api'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AccountDetailsDto, AccountUpdateDto } from '../../api/types'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  SelectedCertificate,
  SelectMode,
  selectSelectedCertificates,
  selectSelectMode,
  unSelectCertificates
} from '../../features/store-slices/certificateSlice'
import OrganizationAccountTransactionTransfer from './transaction/OrganizationAccountTransactionTransfer'
import OrganizationAccountTransactionCancel from './transaction/OrganizationAccountTransactionCancel'
import { AutomaticTransfers } from './automatic-transfers/AutomaticTransfers'
import { Transactions } from './transactions/Transactions'
import { Actions } from './actions/Actions'
import { UnfilteredTotalCertificateCount } from './actions/UnfilteredTotalCertificateCount'
import { OrganizationAccountTransactionCorrection } from './transaction/OrganizationAccountTransactionCorrection'
import {
  fetchAccountDetails,
  resetFilters,
  selectAccountDetails,
  selectTabIndex,
  setSelectedTabIndex,
  unSelectBundles,
  updateAccountDetails
} from '../../features/store-slices/organizationAccountsSlice'
import { Edit } from '@mui/icons-material'
import { LockEntityButton } from '../../features/buttons/LockEntityButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StyleGuide from '../../styles/StyleGuide'
import { SaveButton } from '../../features/buttons/SaveButton'
import CancelButton from '../../features/buttons/CancelButton'
import { canEditAccount } from '../../utils/permission-utils'
import { EcertRoutes } from '../../ecert-routes'
import { useLoginUser } from '../../LoginContext'

export default function OrganizationAccount() {
  const { id, accountId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const [editingAccountName, setEditingAccountName] = useState(false)
  const { t } = useTranslation()
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)
  const selectMode: SelectMode = useAppSelector(selectSelectMode)
  const accountDetails = useAppSelector<AccountDetailsDto | null>(selectAccountDetails)
  const [accountName, setAccountName] = useState(accountDetails?.name || '')
  const storedTabIndex = useAppSelector(selectTabIndex)
  const loginUser = useLoginUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (accountId !== accountDetails?.id?.toString()) {
      dispatch(unSelectCertificates())
      dispatch(resetFilters())
      dispatch(unSelectBundles())
    }
  }, [accountId, accountDetails?.id, dispatch])

  useEffect(() => {
    const tabIndex = searchParams.get('tab')
    if (tabIndex !== null) {
      dispatch(setSelectedTabIndex({ tabIndex: +tabIndex! }))
    }
  }, [dispatch, searchParams])

  useEffect(() => {
    dispatch(
      fetchAccountDetails({
        organizationId: +id!,
        accountId: +accountId!
      })
    )
  }, [dispatch, id, accountId])

  useEffect(() => {
    document.title = `Ecert - ${t('title.account')}`
  }, [t])

  useEffect(() => {
    setAccountName(accountDetails?.name || '')
  }, [accountDetails])

  function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
    const { children, value, index, ...other } = props

    return (
      <div role="tabpanel" hidden={value !== index} id={`account-tabpanel-${index}`} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  const anyCertificatesSelectedForTransfer = () => {
    return anyCertificatesSelected() && selectMode === 'TRANSFER'
  }

  const anyCertificatesSelectedForCancel = () => {
    return anyCertificatesSelected() && selectMode === 'CANCEL'
  }

  const anyCertificatesSelectedForCorrection = () => {
    return anyCertificatesSelected() && selectMode === 'CORRECTION'
  }

  const anyCertificatesSelected = () => {
    return selectedCertificates.reduce((prev, curr) => (prev += +curr.selectedAmount), 0) > 0
  }

  const handleTabChanged = (event, value) => {
    setSearchParams(`?${new URLSearchParams({ tab: value })}`)
    dispatch(setSelectedTabIndex({ tabIndex: value }))
  }

  const handlePublicVisibilityChange = (event) => {
    const dto: AccountUpdateDto = {
      id: +id!,
      name: accountDetails?.name!,
      publicVisibility: event.target.value
    }
    dispatch(
      updateAccountDetails({
        organizationId: +id!,
        accountId: +accountId!,
        accountUpdateDto: dto
      })
    )
  }

  const handleAccountNameChange = () => {
    const dto: AccountUpdateDto = {
      id: +id!,
      name: accountName,
      publicVisibility: accountDetails?.publicVisibility
    }
    setEditingAccountName(false)
    dispatch(
      updateAccountDetails({
        organizationId: +id!,
        accountId: +accountId!,
        accountUpdateDto: dto
      })
    )
  }

  const handleOnLock = (additionalInfoText: string) => {
    ECERT_API.lockAccount(+id!, +accountId!, additionalInfoText).then(() => {
      dispatch(
        fetchAccountDetails({
          organizationId: +id!,
          accountId: +accountId!
        })
      )
    })
  }

  const handleOnUnlock = () => {
    ECERT_API.unlockAccount(+id!, +accountId!).then(() => {
      dispatch(
        fetchAccountDetails({
          organizationId: +id!,
          accountId: +accountId!
        })
      )
    })
  }

  return (
    <FlexColumn gap="10px">
      {accountDetails?.lockedStatus?.locked && (
        <FlexColumn
          styles={{
            backgroundColor: StyleGuide.constants.COLOR_ORANGE_95,
            padding: '15px'
          }}
        >
          <FlexRow gap="10px">
            <InfoOutlinedIcon />
            <Typography>
              {accountDetails.lockedStatus.organizationLocked === true && (
                <>
                  {t('account.organizationLockedMessage', {
                    locker: accountDetails.lockedStatus.organizationLockerName
                  })}
                  &nbsp;
                  <Link
                    onClick={() => navigate(EcertRoutes.organization(accountDetails?.lockedStatus?.organizationId))}
                  >
                    {accountDetails.lockedStatus.organizationName}
                  </Link>
                </>
              )}
              {accountDetails.lockedStatus.organizationLocked === false && (
                <>{t('account.lockedMessage', { locker: accountDetails?.lockedStatus?.entityLockerName })}</>
              )}
            </Typography>
          </FlexRow>
          {accountDetails.lockedStatus.organizationLocked === false && (
            <Typography>
              {`${t('locking.comment')}: ${accountDetails?.lockedStatus?.lockingComment ?? t('locking.noComment')}`}
            </Typography>
          )}
        </FlexColumn>
      )}
      <FlexRow justifyContent="space-between" alignItems="center">
        {editingAccountName ? (
          <FlexRow gap="10px">
            <TextField autoFocus={true} value={accountName} onChange={(event) => setAccountName(event.target.value)} />
            <SaveButton handleSave={handleAccountNameChange} />
            <CancelButton handleCancel={() => setEditingAccountName(false)} />
          </FlexRow>
        ) : (
          <Typography variant="h1">{accountDetails?.name}</Typography>
        )}

        {accountDetails?.mainAccount === false &&
          canEditAccount(loginUser, { organizationId: id, accountId: accountId }) && (
            <FlexRow gap="8px">
              {accountDetails?.lockedStatus?.locked
                ? undefined
                : accountDetails && (
                    <Select value={accountDetails.publicVisibility} onChange={handlePublicVisibilityChange}>
                      <MenuItem value={true as any}>{t('account.visible')}</MenuItem>
                      <MenuItem value={false as any}>{t('account.hidden')}</MenuItem>
                    </Select>
                  )}

              {accountDetails.lockedStatus?.organizationLocked === false && (
                <LockEntityButton
                  lockStatus={accountDetails?.lockedStatus}
                  onLock={handleOnLock}
                  onUnlock={handleOnUnlock}
                />
              )}

              {accountDetails?.lockedStatus?.locked ? undefined : (
                <Button
                  onClick={() => setEditingAccountName(!editingAccountName)}
                  startIcon={<Edit />}
                  variant="outlined"
                >
                  {t('account.rename')}
                </Button>
              )}
            </FlexRow>
          )}
      </FlexRow>
      <FlexRow justifyContent="space-between" alignItems="flex-end">
        <FlexRow>
          <Typography>{accountDetails?.organizationName}</Typography>
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Typography>{`${t('account.numberHeader')} ${accountDetails?.accountNumber}`}</Typography>
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Typography>
            <UnfilteredTotalCertificateCount accountDetails={accountDetails!} /> {t('account.bundleAmount')}
          </Typography>
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Typography>{`${accountDetails?.activeCertificateBundles?.length} ${t('account.amountBundles')}`}</Typography>
          <Divider orientation="vertical" sx={{ marginLeft: '10px', marginRight: '10px', height: '20px' }} />
          <Typography>{accountDetails?.publicVisibility ? t('account.visible') : t('account.hidden')}</Typography>
        </FlexRow>
      </FlexRow>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {!anyCertificatesSelected() && (
          <Tabs value={storedTabIndex} onChange={handleTabChanged} aria-label="tabs">
            <Tab label={t('account.actions')} />
            <Tab label={t('account.transactions')} />
            <Tab label={t('account.automaticTransfer.title')} />
          </Tabs>
        )}
      </Box>

      {!anyCertificatesSelected() && accountDetails && (
        <FlexColumn>
          <TabPanel value={storedTabIndex} index={0}>
            <Actions accountDetails={accountDetails!} />
          </TabPanel>

          <TabPanel value={storedTabIndex} index={1}>
            <Transactions
              organizationId={+id!}
              accountId={+accountId!}
              isLocked={accountDetails.lockedStatus!.locked!}
            />
          </TabPanel>
          <TabPanel value={storedTabIndex} index={2}>
            <AutomaticTransfers accountId={+accountId!} />
          </TabPanel>
        </FlexColumn>
      )}

      {anyCertificatesSelectedForTransfer() && (
        <OrganizationAccountTransactionTransfer organizationId={+id!} accountId={+accountId!} />
      )}

      {anyCertificatesSelectedForCancel() && (
        <OrganizationAccountTransactionCancel organizationId={+id!} accountId={+accountId!} />
      )}

      {anyCertificatesSelectedForCorrection() && (
        <OrganizationAccountTransactionCorrection organizationId={+id!} accountId={+accountId!} />
      )}
    </FlexColumn>
  )
}
