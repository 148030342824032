/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type ProductionInfoNotificationDtoType = typeof ProductionInfoNotificationDtoType[keyof typeof ProductionInfoNotificationDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductionInfoNotificationDtoType = {
  MONTH_ALREADY_ISSUED: 'MONTH_ALREADY_ISSUED',
  PRODUCTION_PERIOD_EXPIRING: 'PRODUCTION_PERIOD_EXPIRING',
  UNAPPROVED_DRAFT_EXISTS: 'UNAPPROVED_DRAFT_EXISTS',
  ZERO_AMOUNT: 'ZERO_AMOUNT',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  INVALID_STATUS: 'INVALID_STATUS',
  MISSING_FUEL_DISTRIBUTION: 'MISSING_FUEL_DISTRIBUTION',
  MISSING_PRODUCTION_DEVICE: 'MISSING_PRODUCTION_DEVICE',
  NO_DEVICE_MANAGER: 'NO_DEVICE_MANAGER',
  PRODUCTION_DEVICE_LOCKED: 'PRODUCTION_DEVICE_LOCKED',
  PRODUCTION_DEVICE_NOT_VALID: 'PRODUCTION_DEVICE_NOT_VALID',
  PRODUCTION_PERIOD_EXPIRED: 'PRODUCTION_PERIOD_EXPIRED',
  REMITTEE_ACCOUNT_LOCKED: 'REMITTEE_ACCOUNT_LOCKED',
} as const;
