import { EcertRoutes } from '../../../../ecert-routes'
import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../hooks'
import { unSelectCertificates } from '../../../../features/store-slices/certificateSlice'

interface IReturnButtonProps {
  organizationId: number
  accountId: number
}

export const ReturnButton = ({ organizationId, accountId }: IReturnButtonProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const returnToAccountPage = () => {
    navigate(EcertRoutes.organizationAccount(organizationId, accountId))
    dispatch(unSelectCertificates())
  }

  return (
    <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={returnToAccountPage}>
      {t('transaction.return')}
    </Button>
  )
}
