import { StyledTable, StyledTableHeaderContainer } from '../../styles/containers/TableContainers'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  fetchProductionInfoTransfers,
  selectProductionInfoTransfers
} from '../../features/store-slices/productionInfoTransfersSlice'
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { ProductionInfoTransferDto } from '../../api/types'
import { transformDate, transformProductionAmount } from '../../utils/utils'
import RefreshIcon from '@mui/icons-material/Refresh'
import { ECERT_API } from '../../services/ecert-api'
import AsyncButton from '../../features/buttons/AsyncButton'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'

export default function ProductionInfoTransfers() {
  const { t } = useTranslation()
  const productionInfoTransfers = useAppSelector<ProductionInfoTransferDto[] | undefined>(selectProductionInfoTransfers)
  const dispatch = useAppDispatch()
  const [displayProcessed, setDisplayProcessed] = useState(false)
  const navigate = useNavigate()

  const unprocessedColumns: IColumn[] = [
    {
      label: t('productionInfo.list.code'),
      accessibilityHrefFn: (obj: ProductionInfoTransferDto) => EcertRoutes.productionInfoTransfer(obj.id),
      accessor: 'code',
      sortable: false,
      role: 'rowheader'
    },
    {
      label: t('productionInfo.list.transferTime'),
      accessor: 'createTime',
      transformFn: transformDate('YYYY.MM.DD HH:mm'),
      sortable: false
    },
    {
      label: t('productionInfo.list.totalAmount'),
      accessor: 'totalProductionAmount',
      transformFn: transformProductionAmount,
      sortable: false
    },
    {
      label: t('productionInfo.list.issuedAmount'),
      accessor: 'issuedProductionAmount',
      transformFn: transformProductionAmount,
      sortable: false
    },
    {
      label: t('productionInfo.list.issueableAmount'),
      accessor: 'issuableProductionAmount',
      transformFn: transformProductionAmount,
      sortable: false
    },
    {
      label: t('productionInfo.list.warningCount'),
      accessor: 'warningCount',
      transformFn: (val) => val || 0,
      sortable: false
    },
    {
      label: t('productionInfo.list.errorCount'),
      accessor: 'errorCount',
      transformFn: (val) => val || 0,
      sortable: false
    },
    {
      label: t('productionInfo.note.title'),
      accessor: 'note',
      transformFn: (value: string) => (
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
          <Typography noWrap>{value}</Typography>
        </Box>
      ),
      sortable: false
    }
  ]

  const processedColumns = [
    {
      label: t('productionInfo.list.code'),
      accessor: 'code',
      sortable: false
    },
    {
      label: t('productionInfo.list.transferTime'),
      accessor: 'createTime',
      transformFn: transformDate('YYYY.MM.DD HH:mm'),
      sortable: false
    },
    {
      label: t('productionInfo.list.processedTime'),
      accessor: 'processedTime',
      transformFn: transformDate('YYYY.MM.DD HH:mm'),
      sortable: false
    },
    {
      label: t('productionInfo.list.totalAmount'),
      accessor: 'totalProductionAmount',
      transformFn: transformProductionAmount,
      sortable: false
    },
    {
      label: t('productionInfo.list.issuedAmount'),
      accessor: 'issuedProductionAmount',
      transformFn: transformProductionAmount,
      sortable: false
    }
  ]

  useEffect(() => {
    dispatch(fetchProductionInfoTransfers())
    document.title = `Ecert - ${t('title.productionInfoTransfers')}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleProductionInfoTransferClick = (productionInfoTransfer: ProductionInfoTransferDto) => {
    navigate(EcertRoutes.productionInfoTransfer(productionInfoTransfer.id))
  }

  const updateProductionInfosOperation = (): Promise<any> => {
    return ECERT_API.updateUnprocessedProductionInfoNotifications().then(() => {
      dispatch(fetchProductionInfoTransfers())
    })
  }

  return (
    <StyledTable className="flex-column flex-gap-20">
      <StyledTableHeaderContainer>
        <Typography id="title-production-info-transfers" variant="h1">
          {t('productionInfo.list.title')}
        </Typography>
      </StyledTableHeaderContainer>
      <Box className="flex-row-centered flex-gap-10">
        <AsyncButton variant="outlined" startIcon={<RefreshIcon />} asyncOperation={updateProductionInfosOperation}>
          {t('productionInfo.process.updateErrors')}
        </AsyncButton>
        <RadioGroup row value={displayProcessed} onChange={() => setDisplayProcessed(!displayProcessed)}>
          <FormControlLabel value="false" control={<Radio disableRipple />} label={t('productionInfo.unprocessed')} />
          <FormControlLabel value="true" control={<Radio disableRipple />} label={t('productionInfo.processed')} />
        </RadioGroup>
      </Box>
      <EcertTable
        unfilteredResultsLength={productionInfoTransfers?.length}
        ariaDescribedBy="title-production-info-transfers"
        data={
          productionInfoTransfers
            ? productionInfoTransfers.filter((pit) => (displayProcessed ? pit.processed : !pit.processed))
            : undefined
        }
        columns={displayProcessed ? processedColumns : unprocessedColumns}
        onRowClick={displayProcessed ? undefined : handleProductionInfoTransferClick}
      />
    </StyledTable>
  )
}
