import { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { FeeTypeDto, FeeTypeDtoName } from '../../../../api/types'
import { useTranslation } from 'react-i18next'

interface IFeeTypeSelect {
  customFeeTypeOptions: FeeTypeDto[]
  valueToShow: FeeTypeDtoName | undefined
  onChangeSelect: (newValue: FeeTypeDto) => void
}

function FeeTypeSelect({ onChangeSelect, valueToShow, customFeeTypeOptions }: IFeeTypeSelect) {
  const { t } = useTranslation()

  const [selectedFeeType, setSelectedFeeType] = useState<FeeTypeDto | undefined>(
    customFeeTypeOptions.find((option) => valueToShow === option.name)
  )

  return (
    <Autocomplete
      fullWidth
      sx={{ maxWidth: '320px' }}
      disableClearable
      options={customFeeTypeOptions!}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') return t(`invoiceDetailsReport.${option}`)

        if (option && option.name) return t(`invoiceDetailsReport.${option.name!}`)

        return t('invoiceDetailsReport.NOT_SELECTED')
      }}
      renderInput={(params) => <TextField {...params} label="" />}
      isOptionEqualToValue={(option, value) => option.name! === value.name!}
      onChange={(_event: any, newValue: FeeTypeDto) => {
        if (newValue) {
          onChangeSelect(newValue)
          setSelectedFeeType(selectedFeeType)
        }
      }}
      value={selectedFeeType}
    />
  )
}

export default FeeTypeSelect
