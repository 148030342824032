import { ProductionDeviceLabelReportDto, ProductionDeviceReportViewDto } from '../../api/types'
import { RequestStatus, RequestError } from './store-types'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ECERT_API } from '../../services/ecert-api'
import { RootState } from '../../store'
import { useDataSliceArray } from './hooks'

interface IReportState {
  registeredProductionDevices: ProductionDeviceReportViewDto[] | undefined
  labelTags: ProductionDeviceLabelReportDto[] | undefined
  registeredProductionDevicesStatus: RequestStatus
  icsTagsStatus: RequestStatus
  requestError: RequestError
}

const initialState: IReportState = {
  registeredProductionDevices: undefined,
  labelTags: undefined,
  registeredProductionDevicesStatus: 'idle',
  icsTagsStatus: 'idle',
  requestError: undefined
}

// Reducers
export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRegisteredProductionDevices.pending, (state) => {
        state.registeredProductionDevicesStatus = 'pending'
      })
      .addCase(fetchRegisteredProductionDevices.fulfilled, (state, action) => {
        state.registeredProductionDevicesStatus = 'success'
        state.registeredProductionDevices = action.payload
      })
      .addCase(fetchRegisteredProductionDevices.rejected, (state, action) => {
        state.registeredProductionDevicesStatus = 'fail'
        state.requestError = action.error.message
      })
      .addCase(fetchLabelTags.pending, (state) => {
        state.icsTagsStatus = 'pending'
      })
      .addCase(fetchLabelTags.fulfilled, (state, action) => {
        state.icsTagsStatus = 'success'
        state.labelTags = action.payload
      })
      .addCase(fetchLabelTags.rejected, (state, action) => {
        state.icsTagsStatus = 'fail'
        state.requestError = action.error.message
      })
  }
})

// Selectors
export const selectRegisteredProductionDevices = (state: RootState) => state.reports.registeredProductionDevices

export const selectLabelTags = (state: RootState) => state.reports.labelTags

// Effects/Thunks
export const fetchRegisteredProductionDevices = createAsyncThunk('registered-production-devices/fetchAll', async () => {
  const response = await ECERT_API.getRegisteredProductionDevices()
  return response.data
})

export const fetchLabelTags = createAsyncThunk('label-tags/fetchAll', async () => {
  const response = await ECERT_API.getLabelTags()
  return response.data
})

// Hooks
export function useRegisteredProductionDevices() {
  return useDataSliceArray(selectRegisteredProductionDevices, fetchRegisteredProductionDevices, true, false)
}

export function useLabelTags() {
  return useDataSliceArray(selectLabelTags, fetchLabelTags, true, false)
}

export default reportsSlice.reducer
