import { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import StyleGuide from '../../../styles/StyleGuide'
import BeneficiaryForm from '../forms/BeneficiaryForm'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import { AccountSelectItemDto, OrganizationViewDto, ParticipantDto } from '../../../api/types'
import CloseIcon from '@mui/icons-material/Close'
import { isAdmin } from '../../../utils/permission-utils'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import { useLoginUser } from '../../../LoginContext'
import { InfoBox } from '../../../features/info-box/InfoBox'
import { formatNumberToFixed } from '../../../utils/utils'
import { ECERT_API } from '../../../services/ecert-api'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'

export default function BeneficiariesFormContainer({ organization }: { organization: OrganizationViewDto | null }) {
  const loginUser = useLoginUser()
  const [participants, setParticipants] = useState<ParticipantDto[]>([])
  const [accounts, setAccounts] = useState<AccountSelectItemDto[]>([])
  const { control, setValue, getValues } = useFormContext<ProductionDeviceSchemaType>()
  const { fields, remove, append } = useFieldArray({ control, name: 'beneficiaries' })
  const { t } = useTranslation()
  const total = useWatch({ control, name: 'beneficiaries' })?.reduce((sum, el) => sum + el.share, 0)

  useEffect(() => {
    ECERT_API.getBeneficiaryParticipants().then((response) => {
      setParticipants(response.data)
    })

    ECERT_API.getAllPublicAccounts().then((response) => {
      setAccounts(response.data.filter(account => account.publicVisibility))
    })
  }, [])

  useEffect(() => {
    setValue('totalShares', formatNumberToFixed(total, 6), {
      shouldValidate: true
    })
  }, [setValue, total])

  const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  }

  const deleteButtonStyles = {
    maxHeight: '40px',
    maxWidth: '40px',
    minWidth: '0px',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    marginBottom: '26px',
    marginLeft: '16px'
  }

  const handleAddBeneficiaryByOrg = () => {
    if (!isAdmin(loginUser)) {
      append({
        name: loginUser.organizationName!,
        accountNumber: '',
        share: 0
      })
    }

    if (organization && isAdmin(loginUser)) {
      append({
        name: organization.name,
        accountNumber: organization.mainAccountNumber!,
        share: 0
      })
    }
  }

  return (
    <Box sx={boxStyles} padding="16px">
      <Box marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.beneficiary.issuingAccounts')}</Typography>
        <InfoBox>{t('productionDevice.beneficiary.accountDescription')}</InfoBox>
      </Box>
      <Box sx={boxStyles}>
        {fields.map((e: any, idx) => {
          return (
            <Box
              key={`${e.id}-${idx}`}
              bgcolor={StyleGuide.constants.COLOR_SECONDARY_LIGHT_4}
              padding="16px"
              display="flex"
              maxWidth="1300px"
            >
              <BeneficiaryForm
                index={idx}
                addDefaultOrganizationValues={e.code ? true : false}
                addDefaultAccountValues={e.code ? true : false}
                participants={participants}
                accounts={accounts}
              />
              {fields.length > 1 && (
                <Button variant="outlined" sx={deleteButtonStyles} onClick={() => remove(idx)}>
                  <CloseIcon />
                </Button>
              )}
            </Box>
          )
        })}
      </Box>
      <Box display="flex" gap="16px">
        <Button
          disabled={total >= 100}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => append({ name: '', accountNumber: '', share: 0 })}
        >
          {t('productionDevice.beneficiary.addBeneficiary')}
        </Button>
        <Button
          disabled={total >= 100}
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => handleAddBeneficiaryByOrg()}
        >
          {t('productionDevice.beneficiary.addBeneficiaryByOrganization')}
        </Button>
      </Box>
      <Box display="flex">
        <Typography>{t('productionDevice.beneficiary.total')}</Typography>
        <FlexColumn>
          <FlexRow>
            <Typography marginLeft="5px" fontWeight="600" sx={{ color: StyleGuide.constants.COLOR_BRAND }}>
              {`${formatNumberToFixed(total, 6)}%`}
            </Typography>
            <Typography fontWeight="600">/100%</Typography>
          </FlexRow>
          {(getValues('totalShares') > 100.0 || getValues('totalShares') < 0.001) && (
            <Typography
              variant="label"
              color={StyleGuide.constants.COLOR_BRAND}
              fontWeight="bold"
              sx={{ position: 'relative', left: '-71px' }}
            >
              {t('form.invalid.beneficiary.share.max', { min: '0.001', max: '100' }) + '%'}
            </Typography>
          )}
        </FlexColumn>
      </Box>
    </Box>
  )
}
