import { useEffect, useState } from 'react'
import './NotificationEdit.css'
import { useTranslation } from 'react-i18next'
import { NotificationDto } from '../../../api/types'
import { ECERT_API } from '../../../services/ecert-api'
import { useParams } from 'react-router-dom'
import { NotificationForm } from '../notification-form/NotificationForm'

export default function NotificationEdit() {
  const { t } = useTranslation()
  const params = useParams()
  const [existingNotification, setExistingNotification] = useState<NotificationDto>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.notificationEdit')}`
  }, [t])

  useEffect(() => {
    ECERT_API.getNotification(+params.id!).then((result) => {
      setExistingNotification(result.data)
    })
  }, [params.id])

  return existingNotification ? <NotificationForm existingNotification={existingNotification} /> : <></>
}
