import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../../features/custom-dialog/CustomDialog'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { unSelectCertificates } from '../../../features/store-slices/certificateSlice'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { Clear } from '@mui/icons-material'
import CancelButton from '../../../features/buttons/CancelButton'
import { useNavigate, useParams } from 'react-router-dom'
import { EcertRoutes } from '../../../ecert-routes'
import { ECERT_API } from '../../../services/ecert-api'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useAppDispatch } from '../../../hooks'
import {
  CancellationRollbackSchemaType,
  createCancellationRollbackSchema
} from '../../../validation/cancellation-rollback-schema'
import ControlledTextField from '../../../features/inputs/ControlledTextField'

interface CancellationProps {
  disabled?: boolean
}

export default function CancellationRollbackConfirmDialogButton({ disabled = false }: CancellationProps) {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const form = useForm<CancellationRollbackSchemaType>({
    resolver: zodResolver(createCancellationRollbackSchema()),
    mode: 'all'
  })

  const [open, setOpen] = useState(false)

  const handleConfirm = () => {
    ECERT_API.rollbackCancellation(+params.transactionId!, form.getValues().additionalDetails ?? '').then(() => {
      setOpen(false)
      dispatch(unSelectCertificates())
      enqueueSnackbar(t('form.valid.transaction.cancellationRollbacked'), snacky.successOpts)
      navigate(EcertRoutes.organizationAccount(params.id, params.accountId))
    })
  }

  const handleCancel = () => {
    form.reset()
    setOpen(false)
  }

  return (
    <>
      <Button
        disabled={disabled}
        startIcon={<Clear />}
        variant="outlined"
        sx={{ height: '50px' }}
        onClick={() => setOpen(true)}
      >
        {t('transaction.rollbackCancellation')}
      </Button>
      <CustomDialog
        isOpen={open}
        title={t('transaction.cancellationRollbackConfirmDialogTitle')}
        handleClose={handleCancel}
        buttons={
          <>
            <Button
              startIcon={<CheckOutlinedIcon />}
              variant="contained"
              disabled={!form.formState.isValid}
              onClick={handleConfirm}
            >
              {t('transaction.rollbackCancellation')}
            </Button>
            <CancelButton handleCancel={handleCancel} />
          </>
        }
      >
        <ControlledTextField
          id="additionalDetails"
          name="additionalDetails"
          label={t('transaction.cancellationRollbackAdditionalDetails')}
          multiline
          minRows={6}
          characterLimit={1000}
          control={form.control}
        />
      </CustomDialog>
    </>
  )
}
