/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type UserDtoLanguage = typeof UserDtoLanguage[keyof typeof UserDtoLanguage];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoLanguage = {
  FINNISH: 'FINNISH',
  ENGLISH: 'ENGLISH',
  SWEDISH: 'SWEDISH',
} as const;
