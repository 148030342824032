import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function GridHeader() {
  const { t } = useTranslation()

  return (
    <Grid container item spacing={4} sx={{ fontWeight: 'bold' }}>
      <Grid item xs={3}>
        {t('invoiceDetailsReport.invoicingType')}
      </Grid>
      <Grid item xs={3}>
        {t('invoiceDetailsReport.amount')}
      </Grid>
      <Grid item xs={5}>
        {t('invoiceDetailsReport.unitPrice')}
      </Grid>
      <Grid item textAlign="right" width="100px" margin="auto">
        {t('invoiceDetailsReport.sum')}
      </Grid>
    </Grid>
  )
}
