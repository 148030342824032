import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { AccountDto, OrganizationListItemDto } from '../../api/types'
import { Button, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { ECERT_API } from '../../services/ecert-api'
import { EcertRoutes } from '../../ecert-routes'
import { useTranslation } from 'react-i18next'
import AccountCreateDialog from './AccountCreateDialog'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { ToggleLocked } from '../../features/toggle-locked/ToggleLocked'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import {
  canCreateAccounts,
  canDownloadAccountsExcel,
  canViewOrganizations,
  isAdmin,
  singleOrganization
} from '../../utils/permission-utils'
import { useOrganizations } from '../../features/store-slices/organizationsSlice'
import OrganizationSelect from '../../features/organization-select/OrganizationSelect'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { WarningAmber } from '@mui/icons-material'
import StyleGuide from '../../styles/StyleGuide'
import DownloadButton from '../../features/buttons/DownloadButton'
import { FileExcelOutline } from 'mdi-material-ui'
import { useLoginUser } from '../../LoginContext'
import { useAccounts } from '../../features/store-slices/organizationAccountsSlice'

export default function Accounts() {
  const [searchParams, setSearchParams] = useSearchParams()
  const loginUser = useLoginUser()
  const [accounts, dataFetch] = useAccounts(
    true,
    true,
    singleOrganization(searchParams.get('organizationId'), loginUser)
  )
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [showLocked, setShowLocked] = useState(false)
  const showLockedDeferred = useDeferredValue(showLocked)
  const [filterText, setFilterText] = useState('')
  const filterTextDeferred = useDeferredValue(filterText)
  const [openModal, setOpenModal] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListItemDto | null>(null)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const columns: IColumn[] = [
    {
      label: t('account.name'),
      accessor: (obj: AccountDto) => (
        <FlexRow alignItems="center" gap="8px">
          {(obj.expiringBundleCount || 0) > 0 && <WarningAmber color="error" />}
          <NavLink
            onClick={(e) => e.stopPropagation()}
            to={EcertRoutes.organizationAccount(obj.organizationId, obj.id, 0)}
            style={{ color: StyleGuide.constants.COLOR_BRAND }}
          >
            {obj.name}
          </NavLink>
        </FlexRow>
      ),
      role: 'rowheader'
    },
    {
      label: t('account.accountNumber'),
      accessor: 'accountNumber'
    },
    {
      label: t('account.certificateAmount'),
      accessor: 'activeCertificateCount',
      transformFn: (data: any) => data || '0'
    },
    {
      label: t('account.visibility'),
      accessor: (obj: AccountDto) => (obj.publicVisibility ? t('account.visible') : t('account.hidden'))
    },
    {
      label: t('account.owner'),
      accessor: 'organizationName'
    }
  ]

  const handleOpen = () => setOpenModal(true)

  useEffect(() => {
    document.title = `Ecert - ${t('title.accountList')}`
  }, [t])

  useEffect(() => {
    const organizationId = searchParams.get('organizationId')
    setSelectedOrganization(organizations?.find((org) => org.id === +organizationId!) || null)
  }, [searchParams, organizations])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  const handleAccountClick = (account: AccountDto) => {
    if (account.id) navigate(EcertRoutes.organizationAccount(account.organizationId, account.id, 0))
  }

  const onSubmitDialog = (accountName: string, accountNumber: string) => {
    enqueueSnackbar(
      t('form.valid.account.created', {
        name: accountName,
        accountNumber: accountNumber
      }),
      snacky.successOpts
    )
    dataFetch()
  }

  const handleOrganizationSelect = (organization: OrganizationListItemDto | null) => {
    setSelectedOrganization(organization)
    setSearchParams(organization ? `?${new URLSearchParams({ organizationId: `${organization?.id}` })}` : '')
  }

  const filteredAccounts = useMemo(() => {
    if (!accounts) {
      return undefined
    }
    return accounts
      .filter((account) => (selectedOrganization ? account.organizationId === selectedOrganization.id : true))
      .filter((account) => (showLockedDeferred ? account.locked : !account.locked))
      .filter(
        (account) =>
          filterTextDeferred === '' ||
          account.name?.toLowerCase().includes(filterTextDeferred.toLowerCase()) ||
          account.accountNumber?.toLowerCase().includes(filterTextDeferred.toLowerCase())
      )
  }, [accounts, selectedOrganization, showLockedDeferred, filterTextDeferred])

  interface IFileProps {
    fileName: string
    fileUrl: string
  }

  const getFileProps = (): IFileProps => {
    if (isAdmin(loginUser)) {
      return selectedOrganization
        ? {
            fileUrl: ECERT_API.FILE_DOWNLOAD_URLS.ACCOUNT_EXPORT(selectedOrganization!.id!),
            fileName: `accountDetailsForOrganization${selectedOrganization.id!}.xlsx`
          }
        : {
            fileUrl: ECERT_API.FILE_DOWNLOAD_URLS.ACCOUNT_EXPORT_ALL(),
            fileName: `accountDetailsForAllOrganizations.xlsx`
          }
    }

    return {
      fileUrl: ECERT_API.FILE_DOWNLOAD_URLS.ACCOUNT_EXPORT(loginUser.organizationId!),
      fileName: `accountDetailsForOrganization${loginUser.organizationId!}.xlsx`
    }
  }

  return (
    <>
      <FlexColumn gap="8px">
        <FlexRow justifyContent="space-between" alignItems="center">
          <FlexRow gap="8px">
            <Typography id="title-accounts" variant="h1" sx={{ marginRight: '26px' }}>
              {t('title.accountList')}
            </Typography>
            <ToggleLocked showLocked={showLocked} toggleLocked={() => setShowLocked(!showLocked)} />
          </FlexRow>

          <FlexRow gap="8px" alignItems="flex-end">
            {canViewOrganizations(loginUser) && (
              <OrganizationSelect
                value={selectedOrganization}
                organizations={organizations || []}
                onSelect={handleOrganizationSelect}
              />
            )}
            <TextField
              placeholder={t('account.list.filter.name')}
              variant="outlined"
              value={filterText}
              InputProps={{
                sx: { height: '50px', width: '240px' }
              }}
              onChange={(event) => setFilterText(event.target.value)}
            />
            {canDownloadAccountsExcel(loginUser) && (
              <DownloadButton
                disabled={!accounts || accounts.length <= 0}
                startIcon={<FileExcelOutline />}
                {...getFileProps()}
              >
                {t('account.export')}
              </DownloadButton>
            )}
            {canCreateAccounts(loginUser) && (
              <Button sx={{ height: '50px' }} variant="contained" startIcon={<AddIcon />} onClick={handleOpen}>
                {t('account.add')}
              </Button>
            )}
          </FlexRow>
        </FlexRow>
        <EcertTable
          unfilteredResultsLength={accounts?.length}
          ariaDescribedBy="title-accounts"
          data={filteredAccounts}
          columns={columns}
          onRowClick={handleAccountClick}
        />
      </FlexColumn>
      <AccountCreateDialog
        openModal={openModal}
        setOpenModal={setOpenModal}
        organizations={organizations || []}
        onSubmit={onSubmitDialog}
      />
    </>
  )
}
