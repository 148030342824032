import { useTranslation } from 'react-i18next'
import { AttachFile } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { ClearButton } from '../../../../features/buttons/ClearButton'
import { UploadButton } from '../../../../features/buttons/UploadButton'
import { DownloadLink } from '../../../../features/download-link/DownloadLink'
import { ECERT_API } from '../../../../services/ecert-api'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import * as snacky from '../../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { ProductionDeviceManagementHistoryEntryView } from '../../../../api/types'
import { ProductionDeviceManagerSchemaType } from '../../../../validation/production-device-manager-schema'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'

interface ManagerExtraAttachmentsFormProps {
  mode: 'CREATE' | 'EDIT_CURRENT' | 'EDIT_PREVIOUS'
  currentEditingManagementHistoryEntry: ProductionDeviceManagementHistoryEntryView | undefined
  handleClearExistingAttachment: (attachmentId: number | null, index: number | null) => void
}

export const ManagerExtraAttachmentsForm = ({
  mode,
  currentEditingManagementHistoryEntry,
  handleClearExistingAttachment
}: ManagerExtraAttachmentsFormProps) => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext<ProductionDeviceManagerSchemaType>()
  const attachments = watch('attachments')

  const handleFilesSelected = (files: File[]) => {
    setValue(
      'attachments',
      attachments!.concat(
        files.map((file) => ({
          id: 0,
          name: file.name,
          createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          content: file
        }))
      )
    )
  }

  const removeFile = (removeIndex: number) => {
    setValue(
      'attachments',
      attachments!.filter((_item, index) => index !== removeIndex)
    )
  }

  return (
    <>
      <Typography variant="body1">{t('productionDevice.management.poaExtraAttachments')}</Typography>
      {mode !== 'CREATE' && (currentEditingManagementHistoryEntry?.attachments?.length || 0) > 0 && (
        <FlexColumn gap="8px">
          {currentEditingManagementHistoryEntry?.attachments?.map((attachment, index) => (
            <FlexRow key={`${attachment.id}`} gap="8px" justifyContent="space-between" alignItems="center">
              <DownloadLink
                key={attachment.id}
                fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
                  currentEditingManagementHistoryEntry?.organizationId!,
                  currentEditingManagementHistoryEntry?.id!,
                  attachment.id!
                )}
                fileName={attachment.name!}
              >
                <FlexRow alignItems="flex-end" gap="8px">
                  <AttachFile />
                  {attachment.name}
                </FlexRow>
              </DownloadLink>
              <ClearButton handleClear={() => handleClearExistingAttachment(attachment.id!, index)} />
            </FlexRow>
          ))}
        </FlexColumn>
      )}
      {(attachments?.length || 0) > 0 || (currentEditingManagementHistoryEntry?.attachments?.length || 0) > 0 ? (
        <FlexColumn gap="8px">
          {attachments?.map((file, index) => (
            <FlexRow gap="8px" alignItems="center" key={`${file.id}-${index}`}>
              <AttachFile />
              {file.name}
              <ClearButton handleClear={() => removeFile(index)} />
            </FlexRow>
          ))}
        </FlexColumn>
      ) : (
        <Typography>{t('productionDevice.management.poaExtraAttachmentsNotAdded')}</Typography>
      )}
      <UploadButton
        acceptFileTypes=".pdf"
        multiple={true}
        startIcon={<AttachFile />}
        variant="contained"
        handleSelected={(files) => {
          if (files.some((file) => file.type !== 'application/pdf')) {
            enqueueSnackbar(
              t('productionDevice.attachmentTypeNotSupported', { supportedTypes: '.pdf' }),
              snacky.errorOpts
            )
            return
          }

          handleFilesSelected(files)
        }}
      >
        {t('productionDevice.attachFile')}
      </UploadButton>
    </>
  )
}
