import { Typography } from '@mui/material'
import { DownloadLink } from '../../../../features/download-link/DownloadLink'
import { ECERT_API } from '../../../../services/ecert-api'
import { FlexColumn, FlexRow } from '../../../../styles/containers/FlexContainers'
import { parseDate } from '../../../../utils/utils'
import { useParams } from 'react-router-dom'
import { ProductionDeviceAttachmentDto } from '../../../../api/types/productionDeviceAttachmentDto'
import { useTranslation } from 'react-i18next'

interface IPoaInfoProps {
  poaValidityEndDate: string
  id: number
  name: string
  createdAt: string
  poaAttachments: ProductionDeviceAttachmentDto[] | undefined
}

export default function PoaInfo({ id, createdAt, name, poaValidityEndDate, poaAttachments }: IPoaInfoProps) {
  const { organizationId, productionDeviceId } = useParams()
  const { t } = useTranslation()

  return (
    <FlexColumn gap="8px">
      <FlexRow justifyContent="space-between">
        <DownloadLink
          fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
            +organizationId!,
            +productionDeviceId!,
            id!
          )}`}
          fileName={name!}
        >
          {name!}
        </DownloadLink>

        <Typography>{`${t('productionDevice.added')} ${parseDate(createdAt!).format('DD.MM.YYYY hh:mm')}`}</Typography>
      </FlexRow>
      <FlexColumn>
        <Typography fontWeight="bold">{t('productionDevice.management.poaValidity')}</Typography>
        <Typography>
          {poaValidityEndDate
            ? parseDate(poaValidityEndDate).format('DD.MM.YYYY')
            : t('productionDevice.management.validIndefinitely')}
        </Typography>
      </FlexColumn>
      <FlexColumn>
        <Typography fontWeight="bold">{t('productionDevice.management.poaExtraAttachments')}</Typography>
        <FlexColumn justifyContent="space-between">
          {poaAttachments?.length ? (
            poaAttachments.map((attachment) => (
              <FlexColumn key={attachment.id} marginTop="5px">
                <DownloadLink
                  fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICE_ATTACHMENT(
                    +organizationId!,
                    +productionDeviceId!,
                    attachment.id!
                  )}`}
                  fileName={attachment.name!}
                >
                  {attachment.name!}
                </DownloadLink>

                <Typography>{`${t('productionDevice.added')} ${parseDate(attachment.createdAt!).format(
                  'DD.MM.YYYY hh:mm'
                )}`}</Typography>
              </FlexColumn>
            ))
          ) : (
            <Typography>{t('productionDevice.management.poaExtraAttachmentsNotAdded')}</Typography>
          )}
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  )
}
