import { Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { useFormContext } from 'react-hook-form'
import StyleGuide from '../../../styles/StyleGuide'
import { FlexColumn } from '../../../styles/containers/FlexContainers'
import { ClearOutlined } from '@mui/icons-material'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import { UploadButton } from '../../../features/buttons/UploadButton'
import dayjs from 'dayjs'
import { InfoBox } from '../../../features/info-box/InfoBox'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import {UPLOAD_MAX_FILE_SIZE_BYTES} from '../../../utils/utils';

export default function Attachments() {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext<ProductionDeviceSchemaType>()
  const attachments = watch('attachments')

  const handleFilesSelected = (files: File[]) => {
    setValue(
      'attachments',
      attachments.concat(
        files.map((file) => ({
          id: 0,
          name: file.name,
          createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
          content: file
        }))
      )
    )
  }

  const removeFile = (removeIndex: number) => {
    setValue(
      'attachments',
      attachments.filter((_item, index) => index !== removeIndex)
    )
  }

  const deleteButtonStyles = {
    maxHeight: '40px',
    maxWidth: '40px',
    minWidth: '0px',
    justifyContent: 'center',
    alignSelf: 'flex-end'
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.attachments')}</Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="16px">
        <InfoBox>{t('productionDevice.attachmentsInfo')}</InfoBox>

        {attachments && (
          <FlexColumn gap="16px">
            {attachments.map((attachment, index) => (
              <Box
                key={`${attachment.name}-${index}`}
                display="flex"
                gap="16px"
                justifyContent="space-between"
                borderBottom={`1px solid ${StyleGuide.constants.COLOR_SECONDARY_LIGHT_3}`}
                padding="0 0 8px"
                maxWidth="435px"
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: 'flex',
                    alignSelf: 'center',
                    padding: '8px'
                  }}
                  key={`${attachment.name}-${index}`}
                >
                  {attachment.name}
                </Typography>
                <Button
                  aria-label={`remove-attachment-${index}`}
                  variant="outlined"
                  sx={deleteButtonStyles}
                  onClick={() => removeFile(index)}
                >
                  <ClearOutlined />
                </Button>
              </Box>
            ))}
          </FlexColumn>
        )}

        <Box>
          <UploadButton
            aria-label="add-attachments"
            startIcon={<AttachFileIcon />}
            variant="contained"
            multiple={true}
            handleSelected={(files) => {
              for (const file of files) {
                if (file.size > UPLOAD_MAX_FILE_SIZE_BYTES) {
                  enqueueSnackbar(t('form.invalid.file.size', {
                    fileName: file.name,
                    fileSizeMb: Number((file.size / 1000000).toFixed(2))
                  }), snacky.errorOpts)
                  return
                }
              }

              if (
                files.some((file) => {
                  return (
                    file.type !== 'application/pdf' &&
                    file.type !== 'image/png' &&
                    file.type !== 'image/jpeg' &&
                    file.type !== 'image/jpg' &&
                    file.type !== 'image/tiff'
                  )
                })
              ) {
                enqueueSnackbar(
                  t('productionDevice.attachmentTypeNotSupported', {
                    supportedTypes: '.pdf, .png, .tiff, .jpeg, .jpg'
                  }),
                  snacky.errorOpts
                )
                return
              }

              handleFilesSelected(files)
            }}
            acceptFileTypes=".pdf, .png, .tiff, .jpeg, .jpg"
          >
            {t('productionDevice.attachFile')}
          </UploadButton>
        </Box>
      </Box>
    </Box>
  )
}
