import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { UserViewDto } from './api/types'
import { ApiErrorType, ECERT_API } from './services/ecert-api'
import { useApiError, useAppLanguage } from './hooks'
import { languages } from './utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { EcertRoutes } from './ecert-routes'
import { enqueueSnackbar } from 'notistack'
import * as snacky from './features/custom-snackbar-provider/CustomSnackbarProvider'
import { useTranslation } from 'react-i18next'

export enum LoginStatus {
  NO_LOGIN = 'NO_LOGIN',
  UNVERIFIED_LOGIN = 'UNVERIFIED_LOGIN',
  FORCED_PASSWORD_CHANGE = 'FORCED_PASSWORD_CHANGE',
  VERIFIED_LOGIN = 'VERIFIED_LOGIN'
}

export const useLoginContext = () => useContext(LoginContext)

export const useLoginUser = () => {
  const user = useLoginContext().loginUser
  if (!user) {
    throw Error('Login user is missing from context')
  }
  return user
}

interface Ctx {
  loginStatus: LoginStatus
  loginUser: UserViewDto | null
  consumeInitialPath: () => string
  refreshLoginContext: () => Promise<UserViewDto | null>
}

const LoginContext = createContext<Ctx>({} as Ctx)

export function LoginContextProvider({ children }: PropsWithChildren<unknown>) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { changeLanguage } = useAppLanguage()
  const [loginUser, setLoginUser] = useState<UserViewDto | null>(null)
  const [loginStatus, setLoginStatus] = useState<LoginStatus>()
  const [initialPath, setInitialPath] = useState<string>(EcertRoutes.paths.root)

  const consumeInitialPath = useCallback(() => {
    setInitialPath(EcertRoutes.paths.root)
    return initialPath
  }, [initialPath])

  const getUserLoginStatus = (user: UserViewDto): LoginStatus => {
    if (user.unverifiedLogin) {
      return LoginStatus.UNVERIFIED_LOGIN
    }
    if (user.passwordNeedsChanging) {
      return LoginStatus.FORCED_PASSWORD_CHANGE
    }
    return LoginStatus.VERIFIED_LOGIN
  }

  const refreshLoginContext = useCallback(() => {
    return ECERT_API.loadSessionUser()
      .then((response) => {
        setLoginStatus(getUserLoginStatus(response.data))
        setLoginUser(response.data)
        return response.data
      })
      .catch(() => {
        setLoginStatus(LoginStatus.NO_LOGIN)
        setLoginUser(null)
        return null
      })
  }, [])

  useEffect(() => {
    refreshLoginContext()
  }, [refreshLoginContext])

  useEffect(() => {
    if (loginUser?.language) {
      changeLanguage(languages.revGet(loginUser.language) ?? 'fi')
    }
  }, [loginUser, changeLanguage])

  useEffect(() => {
    if (!EcertRoutes.isLoginPage(location)) {
      if (loginStatus === LoginStatus.NO_LOGIN || loginStatus === LoginStatus.UNVERIFIED_LOGIN) {
        setInitialPath(location.pathname)
        navigate(EcertRoutes.paths.login)
      }
      if (loginStatus === LoginStatus.FORCED_PASSWORD_CHANGE) {
        setInitialPath(location.pathname)
        navigate(EcertRoutes.paths.changePasswordForced)
      }
    }
  }, [loginStatus, location, navigate])

  useApiError((error) => {
    if (error === ApiErrorType.AUTHENTICATION && loginStatus === LoginStatus.VERIFIED_LOGIN) {
      setLoginStatus(LoginStatus.NO_LOGIN)
      setLoginUser(null)
      enqueueSnackbar(t('common.error.session'), snacky.errorOpts)
    }
  })

  return (
    <>
      {loginStatus && (
        <LoginContext.Provider value={{ loginStatus, loginUser, consumeInitialPath, refreshLoginContext }}>
          {children}
        </LoginContext.Provider>
      )}
    </>
  )
}
