import { Button, CircularProgress, SxProps, Theme } from '@mui/material'
import { ReactNode, useState } from 'react'
import { downloadFile } from '../../utils/utils'

interface IDownloadButtonProps {
  fileUrl: string
  fileName: string
  children: ReactNode
  displayLoadingProgress?: boolean
  variant?: 'text' | 'outlined' | 'contained' | undefined
  startIcon?: ReactNode
  disabled?: boolean
  customErrorHandler?: (error) => void
  sx?: SxProps<Theme>
}

export default function DownloadButton({
  fileUrl,
  fileName,
  children,
  displayLoadingProgress = true,
  variant = 'outlined',
  startIcon,
  disabled = false,
  customErrorHandler,
  sx
}: IDownloadButtonProps) {
  const [loading, setLoading] = useState(false)

  const downloadComplete = () => {
    setLoading(false)
  }

  const handleClick = () => {
    setLoading(true)
    downloadFile({ fileUrl, fileName, callBack: downloadComplete, customErrorHandler })
  }

  return (
    <Button
      aria-label={`download-${fileName}`}
      sx={sx ? sx : { height: '50px' }}
      onClick={handleClick}
      variant={variant}
      disabled={disabled || loading}
      startIcon={displayLoadingProgress && loading ? <CircularProgress size={20} /> : startIcon}
    >
      {children}
    </Button>
  )
}
