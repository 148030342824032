/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type LockedStatusEntityLockingLevel = typeof LockedStatusEntityLockingLevel[keyof typeof LockedStatusEntityLockingLevel];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LockedStatusEntityLockingLevel = {
  USER: 'USER',
  ADMIN: 'ADMIN',
} as const;
