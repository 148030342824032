import './threeDotsAnimation.css'
import { Box } from '@mui/material'

const ThreeDotsLoader = (props) => {
  return (
    <Box sx={props.sx} className="loader">
      <div></div>
      <div></div>
      <div></div>
    </Box>
  )
}

export default ThreeDotsLoader
