import { SxProps, Tooltip, Theme } from '@mui/material'
import { WarningAmberOutlined } from '@mui/icons-material'
import StyleGuide from '../../../../styles/StyleGuide'
import { getTranslation } from '../../../../utils/utils'

export default function AmberTooltip(sxProps: SxProps<Theme>) {
  return (
    <Tooltip sx={sxProps} title={getTranslation('productionDevice.containsChanges')}>
      <WarningAmberOutlined sx={{ color: StyleGuide.constants.COLOR_BRAND, marginTop: 'auto' }} />
    </Tooltip>
  )
}
