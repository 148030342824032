import { useMemo } from 'react'
import { ProductionDeviceListViewDto } from '../../api/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { ECERT_API } from '../../services/ecert-api'
import { RequestStatus, RequestError } from './store-types'
import { useDataSliceArray } from './hooks'

interface IProductionDevicesState {
  productionDevices: ProductionDeviceListViewDto[] | undefined
  status: RequestStatus
  requestError: RequestError
}

const initialState: IProductionDevicesState = {
  productionDevices: undefined,
  status: 'idle',
  requestError: undefined
}

// Reducers
export const productionDevicesSlice = createSlice({
  name: 'productionDevices',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<ProductionDeviceListViewDto>) => {
      if (state.productionDevices) {
        state.productionDevices.push({ ...action.payload })
      } else {
        state.productionDevices = [action.payload]
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProductionDevices.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchProductionDevices.fulfilled, (state, action) => {
        state.status = 'success'
        state.productionDevices = action.payload
      })
      .addCase(fetchProductionDevices.rejected, (state, action) => {
        state.status = 'fail'
        state.requestError = action.error.message
      })
      .addCase(fetchProductionDevicesForOrganization.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchProductionDevicesForOrganization.fulfilled, (state, action) => {
        state.status = 'success'
        state.productionDevices = action.payload
      })
      .addCase(fetchProductionDevicesForOrganization.rejected, (state, action) => {
        state.status = 'fail'
        state.requestError = action.error.message
      })
  }
})

// Actions
export const { add } = productionDevicesSlice.actions

// Selectors
export const selectProductionDevices = (state: RootState) => state.productionDevices.productionDevices

// Effects/Thunks
export const fetchProductionDevices = createAsyncThunk('production-devices/fetchAll', async () => {
  const response = await ECERT_API.getProductionDevices()
  return response.data
})

export const fetchProductionDevicesForOrganization = createAsyncThunk(
  'production-devices/organization/fetchAll',
  async ({ organizationId }: { organizationId: number }) => {
    const response = await ECERT_API.getOrganizationProductionDevices(organizationId)
    return response.data
  }
)

// Hooks
export function useProductionDevices(intialFetch: boolean, refreshFetch: boolean, organizationId: number | undefined) {
  const dataFetch = useMemo(() => {
    return organizationId === undefined
      ? fetchProductionDevices
      : createAsyncThunk('production-devices/organization/fetchAll', async () => {
          const response = await ECERT_API.getOrganizationProductionDevices(organizationId)
          return response.data
        })
  }, [organizationId])
  return useDataSliceArray(selectProductionDevices, dataFetch, intialFetch, refreshFetch)
}

export default productionDevicesSlice.reducer
