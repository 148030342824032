import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useFormContext } from 'react-hook-form'
import AddIcon from '@mui/icons-material/Add'
import { ControlledDateRangePicker } from '../../../features/inputs/ControlledDateRangePicker'
import { ClearOutlined } from '@mui/icons-material'
import ControlledSelect from '../../../features/inputs/ControlledSelect'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import { FlexColumn } from '../../../styles/containers/FlexContainers'

interface FuelIcesProps {
  fuelIndex: number
  labelSelectItems: string[]
}

export default function FuelLabels({ fuelIndex, labelSelectItems }: FuelIcesProps) {
  const { t } = useTranslation()

  const { control } = useFormContext<ProductionDeviceSchemaType>()

  const labels = useFieldArray({
    control,
    name: `fuels.${fuelIndex}.labels`
  })

  return (
    <Box>
      {labelSelectItems.length > 0 && (
        <>
          <Box display="flex" flexDirection="column" gap="16px">
            {labels.fields.map((ics, index: number) => {
              return (
                <Box key={ics.id} display="flex" alignItems="center" gap="16px">
                  <FlexColumn flex="1">
                    <ControlledSelect
                      id={`fuels.${fuelIndex}.labels.${index}.typeCode`}
                      name={`fuels.${fuelIndex}.labels.${index}.typeCode`}
                      label={t('productionDevice.energySource.labels.type')}
                      required
                      placeholder={t('common.choose')}
                      items={labelSelectItems.map((item) => ({
                        key: item,
                        value: item,
                        label: item
                      }))}
                      control={control}
                    />
                  </FlexColumn>
                  <ControlledDateRangePicker
                    startDateName={`fuels.${fuelIndex}.labels.${index}.validityStartDate`}
                    endDateName={`fuels.${fuelIndex}.labels.${index}.validityEndDate`}
                    label={t('productionDevice.energySource.labels.validity')}
                    required
                    control={control}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      marginTop: '24px',
                      maxWidth: '40px',
                      minWidth: '40px',
                      height: '40px',
                      padding: '12px 16px'
                    }}
                    onClick={() => labels.remove(index)}
                  >
                    <ClearOutlined />
                  </Button>
                </Box>
              )
            })}
          </Box>
          <Button
            sx={{ marginTop: '16px' }}
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() =>
              labels.append({
                typeCode: '',
                validityStartDate: '',
                validityEndDate: ''
              })
            }
          >
            {t('productionDevice.energySource.labels.add')}
          </Button>
        </>
      )}
    </Box>
  )
}
