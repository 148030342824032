/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type LogEntryQueryStatus = typeof LogEntryQueryStatus[keyof typeof LogEntryQueryStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LogEntryQueryStatus = {
  ANY: 'ANY',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const;
