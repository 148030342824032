import { TextField, InputAdornment } from '@mui/material'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import InputContainer from './InputContainer'
import { getValue } from '../../utils/utils'

interface ControlledNumberFieldProps<T extends FieldValues, P extends Path<T>> {
  id: string
  name: P
  label?: string
  required?: boolean
  fullWidth?: boolean
  endSuffix?: string
  customErrorText?: { enabled: boolean; message: string | undefined }
  acceptFloat?: boolean
  control: Control<T>
}

const ControlledNumberField = <T extends FieldValues, P extends Path<T>>({
  id,
  name,
  label,
  required,
  fullWidth = true,
  endSuffix,
  customErrorText = { enabled: false, message: '' },
  acceptFloat = false,
  control
}: ControlledNumberFieldProps<T, P>) => {
  return (
    <InputContainer id={id} label={label} required={required}>
      <Controller
        control={control}
        name={name}
        render={({ field, formState }) => {
          return (
            <TextField
              id={id}
              fullWidth={fullWidth}
              type="number"
              onFocus={(e) => e.target.select()}
              InputProps={{
                endAdornment: endSuffix ? <InputAdornment position="end">{endSuffix}</InputAdornment> : <></>
              }}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              value={field.value ?? ''}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  field.onChange(null)
                } else if (acceptFloat) {
                  !isNaN(parseFloat(e.target.value))
                    ? field.onChange(parseFloat(e.target.value))
                    : field.onChange(e.target.value)
                } else {
                  !isNaN(parseInt(e.target.value))
                    ? field.onChange(parseInt(e.target.value))
                    : field.onChange(e.target.value)
                }
              }}
              error={
                customErrorText?.enabled ? customErrorText.message !== undefined : !!getValue(formState.errors, name)
              }
              helperText={
                customErrorText?.enabled ? customErrorText.message : getValue(formState.errors, `${name}.message`)
              }
            />
          )
        }}
      />
    </InputContainer>
  )
}

export default ControlledNumberField
