import { z } from 'zod'
import { AccountSelectItemDto, AutomaticTransferRecipientDtoType } from '../api/types'
import { getTranslation } from '../utils/utils'
import { formPrefix } from './schema-utils'

export function createCertificateTransferSchema() {
  return z.object({
    type: z.nativeEnum(AutomaticTransferRecipientDtoType),
    selectedAccount: z.custom<AccountSelectItemDto>().nullable(),
    recipientName: z
      .string({
        required_error: getTranslation(`${formPrefix}.recipientName.required`)
      })
      .min(1, getTranslation(`${formPrefix}.recipientName.minlength`))
      .max(100, getTranslation(`${formPrefix}.recipientName.maxlength`)),
    targetAccountNumber: z
      .string({
        required_error: getTranslation(`${formPrefix}.recipientAccountNumber.required`)
      })
      .min(1, getTranslation(`${formPrefix}.recipientAccountNumber.minlength`))
      .max(100, getTranslation(`${formPrefix}.recipientAccountNumber.maxlength`)),
    additionalDetails: z.string().max(1000).nullish()
  })
}

const schema = createCertificateTransferSchema()
export type CertificateTransferSchemaType = z.infer<typeof schema>
