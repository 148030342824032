import { calculateFullDaysUntil, enqueueSuccessNotification, parseDate, transformDate } from '../../utils/utils'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  Popover,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  NotificationViewDto,
  TaskAccountDto,
  TaskDto,
  TaskProductionDeviceAuditDto,
  TaskProductionDeviceDto,
  TaskUncompletedExternalTransferDto
} from '../../api/types'
import { AxiosResponse } from 'axios'
import { ECERT_API } from '../../services/ecert-api'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../styles/StyleGuide'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import dayjs, { Dayjs } from 'dayjs'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { EcertRoutes } from '../../ecert-routes'
import { useNavigate } from 'react-router-dom'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ConfirmButton from '../../features/buttons/ConfirmButton'
import CancelButton from '../../features/buttons/CancelButton'
import CustomDialog from '../../features/custom-dialog/CustomDialog'
import { isAccountAdmin, isAdmin, isInspector } from '../../utils/permission-utils'
import { useNotification } from '../../features/store-slices/notificationSlice'
import ThreeDotsLoader from '../../features/three-dots-loader/ThreeDotsLoader'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useLoginUser } from '../../LoginContext'
import { useAppLanguage } from '../../hooks'
import { InfoBox } from '../../features/info-box/InfoBox'
import AsyncButton from '../../features/buttons/AsyncButton'
import { sortByDate } from '../../utils/date-utils'

export default function Front() {
  const loginUser = useLoginUser()
  const [tasks, setTasks]: [TaskDto, Dispatch<SetStateAction<TaskDto>>] = useState({})
  const [notifications, fetchNotifications] = useNotification(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loadingNotifications, setLoadingNotifications] = useState(true)
  const [loadingTasks, setLoadingTasks] = useState(true)
  const { currentLanguage } = useAppLanguage()
  const [expandedNotificationAccordion, setExpandedNotificationAccordion] = useState(false)
  const [showReadNotifications, toggleShowReadNotifications] = useState(false)
  const filteredNotifications = notifications?.filter((n) => n.read === showReadNotifications)
  const unreadNotificationsCount = notifications?.filter((n) => !n.read).length

  useEffect(() => {
    document.title = `Ecert - ${t('title.frontPage')}`
  }, [t])

  useEffect(() => {
    reloadTasks()
    fetchNotifications().finally(() => {
      if (notifications && notifications.length > 0) {
        setExpandedNotificationAccordion(true)
      }
      setLoadingNotifications(false)
    })
  }, [notifications, fetchNotifications])

  const reloadTasks = () => {
    setLoadingTasks(true)
    ECERT_API.getTasks()
      .then((tasks: AxiosResponse<TaskDto>) => {
        setTasks(tasks.data)
      })
      .finally(() => setLoadingTasks(false))
  }

  const humanReadableExpirationTransform = (date: string | Dayjs): string => {
    const daysUntilExpiration = calculateFullDaysUntil(date)
    const humanReadableDaysLeft = (() => {
      if (daysUntilExpiration > 0) {
        return t('common.afterDays', { days: daysUntilExpiration })
      }
      if (daysUntilExpiration === 0) {
        return t('common.today')
      }
      return t('common.expired')
    })()
    return `${humanReadableDaysLeft} (${dayjs(date).format('DD.MM.YYYY')})`
  }

  const markNotificationRead = (notificationId: number) => {
    const promise = ECERT_API.markNotificationReadForCurrentUser(notificationId)
    promise
      .then(() => enqueueSuccessNotification(<Typography>{t('notification.markedAsRead')}</Typography>))
      .finally(() => fetchNotifications())
    return promise
  }

  const MarkNotificationReadButton = ({ notification }: { notification: NotificationViewDto }) => {
    return (
      <AsyncButton
        variant="contained"
        sx={{ marginBottom: '16px' }}
        asyncOperation={() => markNotificationRead(notification.id!)}
      >
        {t('notification.markAsRead')}
      </AsyncButton>
    )
  }

  const NotificationContainer = () => {
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '32px',
            marginBottom: '16px'
          }}
        >
          <Link onClick={() => navigate(EcertRoutes.notificationArchive())}>
            <Typography
              variant="body1"
              sx={{
                color: StyleGuide.constants.COLOR_BRAND,
                textAlign: 'center'
              }}
            >
              {t('notification.openArchive')}
            </Typography>
          </Link>
        </Box>
        <RadioGroup
          aria-label="toggle-show-read-notifications"
          row
          value={showReadNotifications}
          onChange={() => toggleShowReadNotifications(!showReadNotifications)}
        >
          <FormControlLabel value="false" control={<Radio />} label={t('notification.unread')} />
          <FormControlLabel value="true" control={<Radio />} label={t('notification.read')} />
        </RadioGroup>
        {filteredNotifications &&
          filteredNotifications
            .sort((a, b) => sortByDate(a.lastModificationTime!, b.lastModificationTime!, true))
            .map((notification) => {
              return (
                <FlexColumn key={notification.id!}>
                  <FlexRow justifyContent="space-between">
                    <FlexRow alignItems="center" gap="10px" marginTop="16px" marginBottom="16px">
                      <>
                        <AccessTimeIcon />
                        {notification?.lastModificationTime &&
                          dayjs(notification.lastModificationTime).format('DD.MM.YYYY HH:mm')}
                      </>
                    </FlexRow>
                  </FlexRow>

                  <FlexColumn>
                    {currentLanguage === 'fi' && (
                      <FlexColumn>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: notification?.contentFi || ''
                          }}
                        />
                        {!notification.read && (
                          <Box>
                            <MarkNotificationReadButton notification={notification} />
                          </Box>
                        )}
                        <Divider />
                      </FlexColumn>
                    )}
                    {currentLanguage === 'en' && (
                      <FlexColumn>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: notification?.contentEn || ''
                          }}
                        />
                        {!notification.read && (
                          <Box>
                            <MarkNotificationReadButton notification={notification} />
                          </Box>
                        )}
                        <Divider />
                      </FlexColumn>
                    )}
                    {currentLanguage === 'sv' && (
                      <FlexColumn>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: notification?.contentSv || ''
                          }}
                        />
                        {!notification.read && (
                          <Box>
                            <MarkNotificationReadButton notification={notification} />
                          </Box>
                        )}
                        <Divider />
                      </FlexColumn>
                    )}
                  </FlexColumn>
                </FlexColumn>
              )
            })}

        {(!filteredNotifications || filteredNotifications.length === 0) && (
          <Typography>{t('notification.noActiveNotifications')}</Typography>
        )}
      </Box>
    )
  }

  const ProductionDevicesTable = () => {
    const columns: IColumn[] = [
      {
        label: t('productionDevice.name'),
        accessor: 'name',
        accessibilityHrefFn: (productionDevice: TaskProductionDeviceDto) =>
          EcertRoutes.productionDevice(productionDevice.organizationId, productionDevice.id),
        sortable: false,
        role: 'rowheader'
      },
      {
        label: t('productionDevice.gsrn'),
        accessor: 'gsrn',
        sortable: false
      },
      {
        label: t('productionDevice.modified'),
        accessor: (productionDevice: TaskProductionDeviceDto) => productionDevice.status?.modified || '',
        transformFn: transformDate('DD.MM.YYYY HH:mm'),
        sortable: false
      },
      {
        label: t('productionDevice.modifier'),
        accessor: (productionDevice: TaskProductionDeviceDto) => productionDevice.status?.modifier || '',
        sortable: false
      },
      {
        label: t('productionDevice.comments'),
        accessor: (productionDevice: TaskProductionDeviceDto) => productionDevice.status?.description || '',
        sortable: false
      }
    ]

    const handleRowClick = (productionDevice: TaskProductionDeviceDto) => {
      navigate(EcertRoutes.productionDevice(productionDevice.organizationId, productionDevice.id))
    }

    return (
      <Box>
        {tasks.productionDevices && tasks.productionDevices.length > 0 ? (
          <EcertTable
            ariaDescribedBy="title-accordion-production-devices"
            data={tasks.productionDevices}
            columns={columns}
            onRowClick={handleRowClick}
          />
        ) : (
          <Typography>{t('productionDevice.task.emptyMessage')}</Typography>
        )}
      </Box>
    )
  }

  const ProductionDeviceAuditsTable = () => {
    const columns: IColumn[] = [
      {
        label: t('productionDevice.audit.task.name'),
        accessor: 'productionDeviceName',
        accessibilityHrefFn: (productionDevice: TaskProductionDeviceAuditDto) =>
          EcertRoutes.productionDevice(
            productionDevice.productionDeviceOrganizationId,
            productionDevice.productionDeviceId
          ),
        sortable: false,
        role: 'rowheader'
      },
      {
        label: t('productionDevice.gsrn'),
        accessor: 'productionDeviceGsrn',
        sortable: false
      },
      {
        label: t('productionDevice.accountHolderName'),
        accessor: 'productionDeviceOrganizationName',
        sortable: false
      },
      {
        label: t('productionDevice.audit.task.expiration'),
        accessor: 'expiration',
        transformFn: (date: string) => humanReadableExpirationTransform(date),
        sortable: false
      }
    ]

    const handleRowClick = (productionDeviceAudit: TaskProductionDeviceAuditDto) => {
      navigate(
        EcertRoutes.productionDevice(
          productionDeviceAudit.productionDeviceOrganizationId,
          productionDeviceAudit.productionDeviceId
        )
      )
    }

    return (
      <Box>
        {tasks.productionDeviceAudits && tasks.productionDeviceAudits.length > 0 ? (
          <>
            <InfoBox>{t('productionDevice.audit.task.info')}</InfoBox>
            <EcertTable
              ariaDescribedBy="title-accordion-production-device-audits"
              data={tasks.productionDeviceAudits}
              columns={columns}
              onRowClick={handleRowClick}
            />
          </>
        ) : (
          <Typography>{t('productionDevice.audit.task.emptyMessage')}</Typography>
        )}
      </Box>
    )
  }

  const ProductionDeviceManagementTable = () => {
    const columns: IColumn[] = [
      {
        label: t('productionDevice.management.task.name'),
        accessor: 'productionDeviceName',
        accessibilityHrefFn: (productionDevice: TaskProductionDeviceAuditDto) =>
          EcertRoutes.productionDevice(
            productionDevice.productionDeviceOrganizationId,
            productionDevice.productionDeviceId
          ),
        sortable: false,
        role: 'rowheader'
      },
      {
        label: t('productionDevice.gsrn'),
        accessor: 'productionDeviceGsrn',
        sortable: false
      },
      {
        label: t('productionDevice.management.task.managerName'),
        accessor: 'productionDeviceOrganizationName',
        sortable: false
      },
      {
        label: t('productionDevice.management.task.expiration'),
        accessor: 'expiration',
        transformFn: (date: string) => humanReadableExpirationTransform(date),
        sortable: false
      }
    ]

    const handleRowClick = (productionDeviceAudit: TaskProductionDeviceAuditDto) => {
      navigate(
        EcertRoutes.productionDevice(
          productionDeviceAudit.productionDeviceOrganizationId,
          productionDeviceAudit.productionDeviceId
        )
      )
    }

    return (
      <Box>
        {tasks.productionDevicePoas && tasks.productionDevicePoas.length > 0 ? (
          <>
            <InfoBox>{t('productionDevice.management.task.info')}</InfoBox>
            <EcertTable
              ariaDescribedBy="title-accordion-production-device-management"
              data={tasks.productionDevicePoas}
              columns={columns}
              onRowClick={handleRowClick}
            />
          </>
        ) : (
          <Typography>{t('productionDevice.management.task.emptyMessage')}</Typography>
        )}
      </Box>
    )
  }

  const UncompletedExternalTransfersActionButtons = (props: { transferId: number }) => {
    const [acknowledgeTransferOpen, setAcknowledgeTransferOpen] = useState(false)
    const [rollbackTransferOpen, setRollbackTransferOpen] = useState(false)

    const handleAcknowledgeTransfer = () => {
      ECERT_API.acknowledgeExternalTransfer(props.transferId).then(() => {
        reloadTasks()
        enqueueSnackbar(t('productionInfo.process.acknowledged'), snacky.successOpts)
      })
    }

    const handleRollbackTransfer = () => {
      ECERT_API.rollbackExternalTransfer(props.transferId).then(() => {
        reloadTasks()
        enqueueSnackbar(t('transaction.rollbackedTransaction'), snacky.successOpts)
      })
    }

    return (
      <FlexRow gap="10px">
        <Button variant="outlined" onClick={() => setAcknowledgeTransferOpen(true)}>
          {t('externalTransferTasks.acknowledgeTransfer')}
        </Button>
        <Button variant="outlined" onClick={() => setRollbackTransferOpen(true)}>
          {t('externalTransferTasks.rollbackTransfer')}
        </Button>

        <CustomDialog
          isOpen={acknowledgeTransferOpen}
          title={t('externalTransferTasks.confirmaAcknowledgementDialogTitle')}
          handleClose={() => setAcknowledgeTransferOpen(false)}
          buttons={
            <>
              <ConfirmButton handleConfirm={handleAcknowledgeTransfer} />
              <CancelButton handleCancel={() => setAcknowledgeTransferOpen(false)} />
            </>
          }
        >
          <Box>{t('externalTransferTasks.confirmAcknowledgementDialogMessage')}</Box>
        </CustomDialog>

        <CustomDialog
          isOpen={rollbackTransferOpen}
          title={t('externalTransferTasks.confirmaRollbackDialogTitle')}
          handleClose={() => setRollbackTransferOpen(false)}
          buttons={
            <>
              <ConfirmButton handleConfirm={handleRollbackTransfer} />
              <CancelButton handleCancel={() => setRollbackTransferOpen(false)} />
            </>
          }
        >
          <Box>{t('externalTransferTasks.confirmARollbackDialogMessage')}</Box>
        </CustomDialog>
      </FlexRow>
    )
  }

  const UncompletedExternalTransfersTransactionId = ({
    uncompletedExternalTransfer
  }: {
    uncompletedExternalTransfer: TaskUncompletedExternalTransferDto
  }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleClick = () => {
      navigate(
        EcertRoutes.organizationAccountTransaction(
          uncompletedExternalTransfer.accountOrganizationId,
          uncompletedExternalTransfer.accountId,
          uncompletedExternalTransfer.transactionId
        )
      )
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const isValidTransactionId = (transactionId: number | undefined) => {
      return transactionId !== undefined && transactionId !== 0
    }

    return (
      <FlexRow alignItems="center" gap="10px">
        {!isValidTransactionId(uncompletedExternalTransfer.transactionId) && '0'}

        {isValidTransactionId(uncompletedExternalTransfer.transactionId) && (
          <>
            <Typography>{uncompletedExternalTransfer.transactionId}</Typography>
            <IconButton onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} onClick={handleClick}>
              <InfoOutlinedIcon />
            </IconButton>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              sx={{
                pointerEvents: 'none'
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
            >
              <Typography sx={{ p: 2, maxWidth: '200px' }}>{t('externalTransferTasks.transactionInfo')}</Typography>
            </Popover>
          </>
        )}
      </FlexRow>
    )
  }

  const UncompletedExternalTransfersState = (props: { state: string }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
      <FlexRow gap="5px" alignItems="center">
        <Typography>{t(`transaction.externalTransferStateType.${props.state}`)}</Typography>
        <IconButton onClick={handleClick}>
          <HelpOutlineIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <Typography sx={{ p: 2, maxWidth: '300px' }}>
            {t(`transaction.externalTransferStateDescription.${props.state}`)}
          </Typography>
        </Popover>
      </FlexRow>
    )
  }

  const AccountsTable = () => {
    const columns: IColumn[] = [
      {
        label: t('account.task.accontName'),
        accessor: 'name',
        sortable: false,
        role: 'rowheader',
        accessibilityHrefFn: (taskAccountDto: TaskAccountDto) =>
          EcertRoutes.organizationAccount(taskAccountDto.organizationId, taskAccountDto.id, 0)
      },
      {
        label: t('account.task.accontNumber'),
        accessor: 'accountNumber',
        sortable: false
      },
      {
        label: t('account.task.bundleCount'),
        accessor: 'expiringBundleCount',
        sortable: false
      },
      {
        label: t('account.task.certificateCount'),
        accessor: 'expiringCertificateCount',
        sortable: false
      },
      {
        label: t('account.task.nextExpiration'),
        accessor: (taskAccountDto: TaskAccountDto) => {
          return humanReadableExpirationTransform(
            parseDate(taskAccountDto.minCertificateProductionTime || '').add(1, 'year')
          )
        },
        sortable: false
      }
    ]

    const handleRowClick = (taskAccountDto: TaskAccountDto) => {
      navigate(EcertRoutes.organizationAccount(taskAccountDto.organizationId, taskAccountDto.id, 0))
    }

    return (
      <Box>
        {tasks.accounts && tasks.accounts.length > 0 ? (
          <>
            <FlexRow alignItems="center" gap="10px" styles={{ 'padding-top': '10px', 'padding-bottom': '10px' }}>
              <InfoOutlinedIcon />
              <Typography>{t('account.task.infoStart')}</Typography>
              <WarningAmberIcon color="warning" />
              <Typography>{t('account.task.infoEnd')}</Typography>
            </FlexRow>
            <EcertTable
              ariaDescribedBy="title-accordion-accounts"
              data={tasks.accounts}
              columns={columns}
              onRowClick={handleRowClick}
            />
          </>
        ) : (
          <Typography>{t('account.task.emptyMessage')}</Typography>
        )}
      </Box>
    )
  }

  const UncompletedExternalTransfersTable = () => {
    const columns: IColumn[] = [
      {
        label: t('externalTransferTasks.transactionId'),
        accessor: (uncompletedExternalTransfer: TaskUncompletedExternalTransferDto) => uncompletedExternalTransfer,
        transformFn: (uncompletedExternalTransfer: TaskUncompletedExternalTransferDto) => (
          <UncompletedExternalTransfersTransactionId uncompletedExternalTransfer={uncompletedExternalTransfer} />
        ),
        sortable: false,
        role: 'rowheader'
      },
      {
        label: t('externalTransferTasks.messageId'),
        accessor: 'messageId',
        sortable: false
      },
      {
        label: t('externalTransferTasks.createTime'),
        accessor: 'createdAt',
        transformFn: transformDate('DD.MM.YYYY HH:mm:ss'),
        sortable: false
      },
      {
        label: t('externalTransferTasks.state'),
        accessor: 'state',
        transformFn: (state: string) => <UncompletedExternalTransfersState state={state} />,
        sortable: false
      },
      {
        label: t('externalTransferTasks.participant'),
        accessor: (uncompletedExternalTransfer: TaskUncompletedExternalTransferDto) =>
          uncompletedExternalTransfer.participant || '0',
        sortable: false
      },
      {
        label: t('externalTransferTasks.user'),
        accessor: 'userFullName',
        sortable: false
      },
      ...(!isAdmin(loginUser)
        ? []
        : [
            {
              label: t('externalTransferTasks.actions'),
              accessor: 'id',
              transformFn: (id: number) => <UncompletedExternalTransfersActionButtons transferId={id} />,
              sortable: false
            }
          ])
    ]

    return (
      <Box>
        {tasks.uncompletedExternalTransfers && tasks.uncompletedExternalTransfers.length > 0 ? (
          <EcertTable
            ariaDescribedBy="title-accordion-uncompleted-external-transfer"
            data={tasks.uncompletedExternalTransfers}
            columns={columns}
          />
        ) : (
          <Typography>{t('externalTransferTasks.emptyMessage')}</Typography>
        )}
      </Box>
    )
  }

  const notificationBoxStyles = {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  }

  interface IAccordionSummaryContentProps {
    icon: React.ReactNode
    notificationAmount: number
    title: string
    titleId?: string
    loading: boolean
  }

  const AccordionSummaryContent = ({
    icon,
    notificationAmount,
    title,
    titleId,
    loading
  }: IAccordionSummaryContentProps) => {
    return (
      <>
        <Box
          sx={{
            ...notificationBoxStyles
          }}
        >
          {icon}
          {notificationAmount <= 0 && loading ? (
            <ThreeDotsLoader sx={{ marginLeft: '9px' }} />
          ) : (
            <Typography
              sx={{
                marginLeft: '9px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '16px',
                color:
                  notificationAmount > 0 ? StyleGuide.constants.COLOR_BRAND : StyleGuide.constants.COLOR_TEXT_PRIMARY
              }}
            >
              {notificationAmount}
            </Typography>
          )}
        </Box>
        <Typography
          id={titleId}
          variant="label"
          sx={{
            marginLeft: '9px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '16px',
            color: StyleGuide.constants.COLOR_TEXT_PRIMARY
          }}
        >
          {title}
        </Typography>
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <div id="authorized-front">
        <FlexColumn gap="16px">
          {notifications && (
            <Accordion
              id="notification"
              square
              expanded={expandedNotificationAccordion}
              onChange={() => setExpandedNotificationAccordion(!expandedNotificationAccordion)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND, order: -1 }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <AccordionSummaryContent
                  icon={
                    <WarningAmberIcon
                      sx={{
                        color:
                          unreadNotificationsCount && unreadNotificationsCount > 0
                            ? StyleGuide.constants.COLOR_BRAND
                            : StyleGuide.constants.COLOR_SECONDARY
                      }}
                    />
                  }
                  notificationAmount={unreadNotificationsCount || 0}
                  title={t('notification.title')}
                  loading={loadingNotifications}
                />
              </AccordionSummary>
              <AccordionDetails>
                <NotificationContainer />
              </AccordionDetails>
            </Accordion>
          )}
          {!isInspector(loginUser) && (
            <>
              <Accordion id="production-devices" square disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <AccordionSummaryContent
                    titleId="title-accordion-production-devices"
                    icon={<NotificationsNoneIcon sx={{ color: StyleGuide.constants.COLOR_SECONDARY }} />}
                    notificationAmount={tasks.productionDevices?.length || 0}
                    title={t('productionDevice.task.title')}
                    loading={loadingTasks}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <ProductionDevicesTable />
                </AccordionDetails>
              </Accordion>

              <Accordion id="production-device-audits" square disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <AccordionSummaryContent
                    titleId="title-accordion-production-device-audits"
                    icon={<NotificationsNoneIcon sx={{ color: StyleGuide.constants.COLOR_SECONDARY }} />}
                    notificationAmount={tasks.productionDeviceAudits?.length || 0}
                    title={t('productionDevice.audit.task.title')}
                    loading={loadingTasks}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <ProductionDeviceAuditsTable />
                </AccordionDetails>
              </Accordion>

              <Accordion id="production-device-management" square disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <AccordionSummaryContent
                    titleId="title-accordion-production-device-management"
                    icon={<NotificationsNoneIcon sx={{ color: StyleGuide.constants.COLOR_SECONDARY }} />}
                    notificationAmount={tasks.productionDevicePoas?.length || 0}
                    title={t('productionDevice.management.task.title')}
                    loading={loadingTasks}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <ProductionDeviceManagementTable />
                </AccordionDetails>
              </Accordion>

              <Accordion square disableGutters>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <AccordionSummaryContent
                    titleId="title-accordion-accounts"
                    icon={<NotificationsNoneIcon sx={{ color: StyleGuide.constants.COLOR_SECONDARY }} />}
                    notificationAmount={tasks.accounts?.length || 0}
                    title={t('account.task.title')}
                    loading={loadingTasks}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <AccountsTable />
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {(isAdmin(loginUser) || isAccountAdmin(loginUser)) && (
            <Accordion id="uncompleted-external-transfer" square disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}
                aria-controls="panel6a-content"
                id="panel6a-header"
              >
                <AccordionSummaryContent
                  titleId="title-accordion-uncompleted-external-transfer"
                  icon={<NotificationsNoneIcon sx={{ color: StyleGuide.constants.COLOR_SECONDARY }} />}
                  notificationAmount={tasks.uncompletedExternalTransfers?.length || 0}
                  title={t('externalTransferTasks.title')}
                  loading={loadingTasks}
                />
              </AccordionSummary>
              <AccordionDetails>
                <UncompletedExternalTransfersTable />
              </AccordionDetails>
            </Accordion>
          )}
        </FlexColumn>
      </div>
    </Box>
  )
}
