import { ProductionDeviceViewDtoStatus } from '../../../../api/types'
import { Button } from '@mui/material'
import { CheckOutlined, ArrowBackOutlined, Close, EditOutlined } from '@mui/icons-material'
import { getTranslation } from '../../../../utils/utils'
import { EcertRoutes } from '../../../../ecert-routes'
import { useNavigate, useParams } from 'react-router-dom'

interface IAdminButtonsProps {
  productionDeviceStatus: ProductionDeviceViewDtoStatus
  registrationCodeMissing: boolean
  approvedDialogFunc: () => void
  returnDialogFunc: () => void
  refusedDialogFunc: () => void
}

export default function AdminButtons({
  productionDeviceStatus,
  registrationCodeMissing,
  approvedDialogFunc,
  returnDialogFunc,
  refusedDialogFunc
}: IAdminButtonsProps) {
  const { organizationId, productionDeviceId } = useParams()
  const navigate = useNavigate()

  const navigateToEdit = () => {
    navigate(EcertRoutes.productionDeviceEdit(+organizationId!, +productionDeviceId!), {
      state: {
        isDraft: productionDeviceStatus !== ProductionDeviceViewDtoStatus.APPROVED
      }
    })
  }

  return (
    <>
      {productionDeviceStatus === 'WAITING_APPROVAL' && (
        <>
          <Button onClick={approvedDialogFunc} startIcon={<CheckOutlined />} variant="contained">
            {registrationCodeMissing
              ? getTranslation('productionDevice.status.fillInfoAndApprove')
              : getTranslation('productionDevice.status.approve')}
          </Button>
          <Button onClick={returnDialogFunc} startIcon={<ArrowBackOutlined />} variant="contained">
            {getTranslation('productionDevice.status.return')}
          </Button>
          <Button onClick={refusedDialogFunc} startIcon={<Close />} variant="contained">
            {getTranslation('productionDevice.status.refuse')}
          </Button>
        </>
      )}
      {(productionDeviceStatus === 'APPROVED' || productionDeviceStatus === 'WAITING_APPROVAL') && (
        <Button onClick={navigateToEdit} startIcon={<EditOutlined />} variant="outlined">
          {productionDeviceStatus === 'APPROVED'
            ? getTranslation('productionDevice.editApproved')
            : getTranslation('productionDevice.edit')}
        </Button>
      )}
    </>
  )
}
