import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Dictionary } from '@reduxjs/toolkit'
import { selectSelectedBundles } from '../../../features/store-slices/organizationAccountsSlice'
import { selectCertificates, SelectMode } from '../../../features/store-slices/certificateSlice'
import { FlexRow } from '../../../styles/containers/FlexContainers'
import { Button, Typography } from '@mui/material'
import StyleGuide from '../../../styles/StyleGuide'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { SelectedBundleCount } from './SelectedBundleCount'
import { FilteredTotalCertificateCount } from './FilteredTotalCertificateCount'
import { useTranslation } from 'react-i18next'
import { AccountDetailsDto } from '../../../api/types'
import { canCancelCertificates, canCorrectCertificates, canTransferCertificates } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import { useParams } from 'react-router-dom'

interface IBottomContentProps {
  accountDetails: AccountDetailsDto
}

export const BottomContent = ({ accountDetails }: IBottomContentProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const loginUser = useLoginUser()
  const selectedBundles = useAppSelector<Dictionary<number>>(selectSelectedBundles)
  const dispatch = useAppDispatch()

  const totalSelectedBundles = () => {
    return Object.values(selectedBundles).reduce((prev, curr) => (prev || 0) + +(curr || 0), 0)
  }

  const selectBundles = (selectMode: SelectMode) => {
    const selectedBundlesWithCertificateCounts = accountDetails!
      .activeCertificateBundles!.filter((bundle) => (selectedBundles[bundle.id!] || 0) > 0)
      .map((selectedBundle) => ({
        bundle: selectedBundle,
        selectedAmount: selectedBundles[selectedBundle.id!] || 0
      }))
    dispatch(selectCertificates({ selectedCertificates: selectedBundlesWithCertificateCounts, selectMode: selectMode }))
  }

  const buttonDefs = [
    ...(canTransferCertificates(loginUser, params)
      ? [{ label: t('transaction.transfer'), action: () => selectBundles('TRANSFER') }]
      : []),
    ...(canCancelCertificates(loginUser, params)
      ? [{ label: t('transaction.cancel'), action: () => selectBundles('CANCEL') }]
      : []),
    ...(canCorrectCertificates(loginUser)
      ? [{ label: t('transaction.correct'), action: () => selectBundles('CORRECTION') }]
      : [])
  ]

  return (
    <FlexRow alignItems="center" gap="10px" styles={{ 'padding-right': '20px' }}>
      <Typography color={StyleGuide.constants.COLOR_WHITE}>{t('account.selected')}</Typography>
      <Typography color={StyleGuide.constants.COLOR_WHITE}>
        <SelectedBundleCount accountDetails={accountDetails} />
      </Typography>
      <Typography color={StyleGuide.constants.COLOR_WHITE}>/</Typography>
      <Typography color={StyleGuide.constants.COLOR_WHITE}>
        <FilteredTotalCertificateCount accountDetails={accountDetails} />
      </Typography>
      {buttonDefs.map((def, index) => (
        <FlexRow key={index} alignItems="inherit" gap="inherit">
          {index > 0 && (
            <Typography color={StyleGuide.constants.COLOR_WHITE}>{t('account.buttonSeparatorText')}</Typography>
          )}
          <Button
            onClick={def.action}
            startIcon={<ArrowForwardIcon />}
            variant="contained"
            disabled={totalSelectedBundles() === 0}
          >
            {def.label}
          </Button>
        </FlexRow>
      ))}
    </FlexRow>
  )
}
