import { useNavigate, useParams } from 'react-router-dom'
import { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Box, Divider, Link, Tab, Tabs, Typography } from '@mui/material'
import { ECERT_API } from '../../services/ecert-api'
import { useTranslation } from 'react-i18next'
import StyleGuide from '../../styles/StyleGuide'
import { ProductionDeviceViewDto } from '../../api/types'
import InfoTab from './components/InfoTab'
import { FuelDistributionTab } from './components/FuelDistributionTab'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { LockEntityButton } from '../../features/buttons/LockEntityButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ManagementTab } from './components/ManagementTab'
import { canEditProductionDevice, isAdmin } from '../../utils/permission-utils'
import { EcertRoutes } from '../../ecert-routes'
import { useLoginUser } from '../../LoginContext'

export default function ProductionDevice() {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const { organizationId, productionDeviceId } = useParams()
  const [device1, setDevice1] = useState<ProductionDeviceViewDto>()
  const [device2, setDevice2] = useState<ProductionDeviceViewDto>()
  const [selectedTab, setSelectedTab] = useState(0)
  const navigate = useNavigate()

  const loadProductionDevice = useCallback(() => {
    ECERT_API.getCurrentProductionDevice(Number(organizationId), Number(productionDeviceId)).then((response) => {
      setDevice1(response.data)
      if (response.data.statusOfDraft && response.data.statusOfDraft !== response.data.status) {
        ECERT_API.getProductionDeviceDraft(Number(organizationId), Number(productionDeviceId)).then((response) => {
          setDevice2(response.data)
        })
      } else {
        setDevice2(undefined)
      }
    })
  }, [organizationId, productionDeviceId])

  useEffect(() => {
    loadProductionDevice()
  }, [loadProductionDevice])

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionDevice')}`
  }, [t])

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    loadProductionDevice()
    setSelectedTab(newValue)
  }

  const handleLock = (message: string) => {
    ECERT_API.lockProductionDevice(organizationId, productionDeviceId, message).then(() => loadProductionDevice())
  }

  const handleUnlock = () => {
    ECERT_API.lockProductionDevice(organizationId, productionDeviceId, '').then(() => loadProductionDevice())
  }
  interface TabPanelProps {
    children?: ReactNode
    index: number
    value: number
  }
  const TabPanel = (props: TabPanelProps) => {
    const { children, index, value, ...other } = props
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    )
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="20px" marginBottom="16px">
        {device1?.lockedStatus?.locked && (
          <FlexColumn
            styles={{
              backgroundColor: StyleGuide.constants.COLOR_ORANGE_95,
              padding: '15px'
            }}
          >
            <FlexRow gap="10px">
              <InfoOutlinedIcon />
              <Typography>
                {device1.lockedStatus.organizationLocked === true && (
                  <>
                    {t('productionDevice.organizationLockedMessage', {
                      locker: device1.lockedStatus.organizationLockerName
                    })}
                    &nbsp;
                    <Link onClick={() => navigate(EcertRoutes.organization(device1?.lockedStatus?.organizationId))}>
                      {device1.lockedStatus.organizationName}
                    </Link>
                  </>
                )}
                {device1.lockedStatus.organizationLocked === false && (
                  <>{t('productionDevice.lockedMessage', { locker: device1?.lockedStatus?.entityLockerName })}</>
                )}
              </Typography>
            </FlexRow>
            {device1.lockedStatus.organizationLocked === false && (
              <Typography>
                {`${t('locking.comment')}: ${device1?.lockedStatus?.lockingComment ?? t('locking.noComment')}`}
              </Typography>
            )}
          </FlexColumn>
        )}
        <Box display="flex" gap="24px">
          <Typography variant="h1" sx={{ marginRight: '26px' }}>
            {device1?.name}
          </Typography>
          {canEditProductionDevice(loginUser, { organizationId, productionDeviceId }) &&
            device1?.lockedStatus?.organizationLocked === false && (
              <LockEntityButton lockStatus={device1?.lockedStatus} onLock={handleLock} onUnlock={handleUnlock} />
            )}
        </Box>
        <Box display="flex" gap="16px">
          <Typography>{device1?.gsrn}</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography>{device1?.organization?.name}</Typography>
        </Box>
      </Box>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="production device tabs">
        <Tab label={t('productionDevice.tabs.info')} />
        {device1?.multifuel && <Tab label={t('productionDevice.tabs.fuelDistribution')} />}
        {isAdmin(loginUser) && <Tab label={t('productionDevice.tabs.management')} />}
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        {device1 && <InfoTab device1={device1} device2={device2} reloadProductionDevice={loadProductionDevice} />}
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        {device1?.multifuel ? <FuelDistributionTab productionDevice={device1} /> : <ManagementTab />}
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <ManagementTab />
      </TabPanel>
    </Box>
  )
}
