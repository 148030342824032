import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { InvoicingPeriodDto } from '../../../api/types'
import { Typography } from '@mui/material'
import { EcertRoutes } from '../../../ecert-routes'
import { StyledTable, StyledTableHeaderContainer } from '../../../styles/containers/TableContainers'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../services/ecert-api'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { isAdmin } from '../../../utils/permission-utils'
import { parseDate } from '../../../utils/utils'
import { formatInvoicingPeriod } from './invoice-utls'
import { useLoginUser } from '../../../LoginContext'

export function InvoicePeriodList() {
  const loginUser = useLoginUser()
  const { t } = useTranslation()
  const [invoicingPeriods, setInvoicingPeriods] = useState<InvoicingPeriodDto[]>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.invoicingPeriods')}`
  }, [t])

  useEffect(() => {
    ECERT_API.getInvoicingPeriods().then((response) => setInvoicingPeriods(response.data))
  }, [])

  const sortInvoicingDates = (a: InvoicingPeriodDto, b: InvoicingPeriodDto) => {
    const aStart = parseDate(a.publishedAt!)
    const bStart = parseDate(b.publishedAt!)

    if (!a.publishedAt && b.publishedAt) {
      return 1
    } else if (a.publishedAt && !b.publishedAt) {
      return -1
    } else if (aStart.isBefore(bStart, 'day')) {
      return -1
    } else if (aStart.isAfter(bStart, 'day')) {
      return 1
    } else {
      return a.id!.toString().localeCompare(b.id!.toString())
    }
  };

  const columns: IColumn[] = [
    {
      label: t('invoiceDetailsReport.invoicingPeriod'),
      accessor: (data: InvoicingPeriodDto) => formatInvoicingPeriod(data),
      sortable: false,
      ...(isAdmin(loginUser) && {
        accessibilityHrefFn: (obj: InvoicingPeriodDto) => EcertRoutes.invoicingPeriod(obj.id!)
      }),
      role: 'rowheader'
    },
    {
      label: t('invoiceDetailsReport.publishedAt'),
      accessor: (data: InvoicingPeriodDto) =>
        data.publishedAt ? dayjs(data.publishedAt).format('DD.MM.YYYY') : t('invoiceDetailsReport.awaitingPublication'),
      sortable: true,
      sortByOrder: 'desc',
      sortFn: sortInvoicingDates
    },
    {
      label: t('invoiceDetailsReport.billedAt'),
      accessor: (data: InvoicingPeriodDto) =>
        data.billedAt ? dayjs(data.billedAt).format('DD.MM.YYYY') : t('invoiceDetailsReport.awaitingBilling'),
      sortable: true,
      sortByOrder: 'desc',
      sortFn: sortInvoicingDates
    },
    {
      label: t('common.functions'),
      accessor: (data: InvoicingPeriodDto) => {
        return data.publishedAt ? (
          <DownloadButton
            fileName={`${t('invoiceDetailsReport.menuname')}_${data.id!}.xlsx`}
            fileUrl={
              isAdmin(loginUser)
                ? `${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_INVOIVING_PERIOD}${data.id!}?downloadId=`
                : `${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_INVOIVING_PERIOD}${data.id!}/organization/${
                    loginUser.organizationId
                  }?downloadId=`
            }
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {t('invoiceDetailsReport.download')}
          </DownloadButton>
        ) : (
          <></>
        )
      },
      sortable: false
    }
  ]

  return (
    <>
      {invoicingPeriods && (
        <StyledTable gap="10px">
          <StyledTableHeaderContainer>
            <Typography id="title-report-invoice" variant="h1" sx={{ marginBottom: '16px' }}>
              {t('invoiceDetailsReport.title')}
            </Typography>
          </StyledTableHeaderContainer>
          <EcertTable ariaDescribedBy="title-report-invoice" data={invoicingPeriods} columns={columns}></EcertTable>
        </StyledTable>
      )}
    </>
  )
}
