import { Grid, Box } from '@mui/material'
import { FeeTypeDtoName, InvoiceDetailsDto, InvoiceRowDto, InvoiceRowDtoType } from '../../../../api/types'
import { formatCurrency } from '../invoice-utls'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../../services/ecert-api'
import { InvoiceDisplayRow } from './InvoiceDisplayRow'
import { GridHeader } from './GridHeader'
import { GridFooter } from './GridFooter'
import { idGenerator } from '../../../../utils/utils'

const mergeDuplicates = (rows: InvoiceRowDto[]): InvoiceRowDto[] => {
  const mergeRows = new Map<InvoiceRowDtoType, InvoiceRowDto>()
  const nonMergeRows: InvoiceRowDto[] = []
  rows.forEach((row) => {
    if (row.type !== FeeTypeDtoName.FREE_TEXT_AND_AMOUNT) {
      const mergeRow = mergeRows.get(row.type!)
      if (!mergeRow) {
        mergeRows.set(row.type!, {
          id: idGenerator.next().value,
          type: row.type,
          reason: undefined,
          amount: row.amount,
          productCode: undefined,
          unitPrice: row.unitPrice,
          unit: row.unit,
          additionalFee: row.additionalFee,
          deviceIds: undefined
        })
      } else {
        mergeRow.amount! += row.amount ?? 0
      }
    } else {
      nonMergeRows.push(row)
    }
  })
  return Array.from(mergeRows.values()).concat(nonMergeRows)
}

interface InvoiceSummaryContentProps {
  periodId: number
}

export default function InvoiceSummaryContent({ periodId }: InvoiceSummaryContentProps) {
  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailsDto>()

  useEffect(() => {
    ECERT_API.getCombinedInvoiceDetails(Number(periodId)).then((response) => {
      setInvoiceDetail(response.data)
    })
  }, [periodId])

  return (
    <>
      {invoiceDetail && (
        <Box>
          <Box>
            <Grid container spacing={1} sx={{ marginTop: '20px' }}>
              <GridHeader />
              {mergeDuplicates(invoiceDetail.invoiceRows ?? []).map((row) => (
                <InvoiceDisplayRow key={`${row.type}_${row.id ? row.id : row.type}`} row={row} />
              ))}
              <GridFooter total={formatCurrency(invoiceDetail.total ?? 0)} />
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}
