import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'

interface IConfirmButtonProps {
  handleConfirm: () => void
  disabled?: boolean
  startIcon?: JSX.Element
}

export default function ConfirmButton({ handleConfirm, disabled, startIcon }: IConfirmButtonProps) {
  const { t } = useTranslation()

  return (
    <Button
      onClick={handleConfirm}
      disabled={disabled}
      variant="contained"
      startIcon={startIcon ? startIcon : <CheckIcon />}
    >
      {t('common.yes')}
    </Button>
  )
}
