export const requiredValidator = (errorMsg: string) => {
  return (value: string) => {
    if (!value) {
      return errorMsg
    }
  }
}

export const minLengthValidator = (min: number, errorMsg: string) => {
  return (value: string) => {
    if (value.length < min) {
      return errorMsg
    }
  }
}

export const maxLengthValidator = (max: number, errorMsg: string) => {
  return (value: string) => {
    if (value.length > max) {
      return errorMsg
    }
  }
}

export const regexValidator = (regex: string, errorMsg: string) => {
  return (value: string) => {
    if (value && value.match(regex) === null) {
      return errorMsg
    }
  }
}
