import { useMemo } from 'react'
import { UserListItemDto } from '../../api/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { ECERT_API } from '../../services/ecert-api'
import { RequestStatus, RequestError } from './store-types'
import { useDataSliceArray } from './hooks'

interface IUsersState {
  users: UserListItemDto[] | undefined
  status: RequestStatus
  requestError: RequestError
}

const initialState: IUsersState = {
  users: undefined,
  status: 'idle',
  requestError: undefined
}

// Reducers
export const usersSlice = createSlice({
  name: 'productionDevices',
  initialState,
  reducers: {
    add: (state, action: PayloadAction<UserListItemDto>) => {
      if (state.users) {
        state.users.push({ ...action.payload })
      } else {
        state.users = [action.payload]
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'success'
        state.users = action.payload
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'fail'
        state.requestError = action.error.message
      })
      .addCase(fetchUsersForOrganization.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchUsersForOrganization.fulfilled, (state, action) => {
        state.status = 'success'
        state.users = action.payload
      })
      .addCase(fetchUsersForOrganization.rejected, (state, action) => {
        state.status = 'fail'
        state.requestError = action.error.message
      })
  }
})

// Actions
export const { add } = usersSlice.actions

// Selectors
export const selectUsers = (state: RootState) => state.users.users

// Effects/Thunks
export const fetchUsers = createAsyncThunk('users/fetchAll', async () => {
  const response = await ECERT_API.getAllUsers()
  return response.data
})

export const fetchUsersForOrganization = createAsyncThunk(
  'users/fetchForOrganization',
  async ({ organizationId }: { organizationId: number }) => {
    const response = await ECERT_API.getOrganizationUsers(organizationId)
    return response.data
  }
)

// Hooks
export function useUsers(initialFetch: boolean, refreshFetch: boolean, organizationId?: number) {
  const dataFetch = useMemo(() => {
    return organizationId === undefined
      ? fetchUsers
      : createAsyncThunk('users/fetchForOrganization', async () => {
          const response = await ECERT_API.getOrganizationUsers(organizationId)
          return response.data
        })
  }, [organizationId])

  return useDataSliceArray(selectUsers, dataFetch, initialFetch, refreshFetch)
}

export default usersSlice.reducer
