import { configureStore } from '@reduxjs/toolkit'
import organizationsReducer from './features/store-slices/organizationsSlice'
import organizationAccountsReducer from './features/store-slices/organizationAccountsSlice'
import usersReducer from './features/store-slices/usersSlice'
import productionDevicesReducer from './features/store-slices/productionDevicesSlice'
import productionInfoTransfersReducer from './features/store-slices/productionInfoTransfersSlice'
import reportsReducer from './features/store-slices/reportSlice'
import countriesReducer from './features/store-slices/countriesSlice'
import certificateReducer from './features/store-slices/certificateSlice'
import notificationReducer from './features/store-slices/notificationSlice'

export const store = configureStore({
  reducer: {
    organizations: organizationsReducer,
    organizationAccounts: organizationAccountsReducer,
    productionDevices: productionDevicesReducer,
    users: usersReducer,
    productionInfoTransfers: productionInfoTransfersReducer,
    reports: reportsReducer,
    countries: countriesReducer,
    certificates: certificateReducer,
    notification: notificationReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
