import { useTranslation } from 'react-i18next'
import { FlexColumn } from '../../styles/containers/FlexContainers'
import { Autocomplete, InputLabel, TextField } from '@mui/material'
import { AccountDto } from '../../api/types'
import { useEffect, useState } from 'react'

interface IAccountSelectProps {
  accounts: AccountDto[]
  onSelect: (account: AccountDto | null) => void
}

export default function AccountSelect({ accounts, onSelect }: IAccountSelectProps) {
  const { t } = useTranslation()
  const [clear, setClear] = useState<boolean>(false)

  useEffect(() => {
    setClear(!clear)

    // eslint-disable-next-line
  }, [accounts])

  return (
    <FlexColumn>
      <InputLabel htmlFor="account-select">{t('title.account')}</InputLabel>
      <Autocomplete
        key={`${clear}`}
        id="account-select"
        options={accounts}
        getOptionLabel={(option: AccountDto) => `${option.name} (${option.accountNumber})`}
        renderInput={(params) => <TextField {...params} key={params.id} label="" placeholder="" />}
        isOptionEqualToValue={(option, value) => option.id! === value.id!}
        onChange={(event, newValue) => {
          onSelect(newValue)
        }}
      />
    </FlexColumn>
  )
}
