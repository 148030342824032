import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../services/ecert-api'
import StyleGuide from '../../styles/StyleGuide'
import { downloadFile, languages } from '../../utils/utils'
import './Footer.css'
import { StyledFooter } from './Footer.styles'
import { useAppLanguage } from '../../hooks'
import { useAppInfo } from '../../AppInfoContext'

export default function Footer() {
  const [t] = useTranslation()
  const appInfo = useAppInfo()
  const { currentLanguage } = useAppLanguage()

  const labelStyles = {
    color: StyleGuide.constants.COLOR_WHITE,
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.625'
  }

  const boxStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px'
  }

  const handleDownload = (fileUrl: string, fileName: string) => {
    downloadFile({ fileUrl, fileName })
  }

  const properPrivacyStatement = () => {
    switch (currentLanguage) {
      case 'en':
        return ECERT_API.FILE_URLS.PRIVACY_STATEMENT_EN
      case 'fi':
        return ECERT_API.FILE_URLS.PRIVACY_STATEMENT_FI
      case 'sv':
        return ECERT_API.FILE_URLS.PRIVACY_STATEMENT_SV
      default:
        return ECERT_API.FILE_URLS.PRIVACY_STATEMENT_FI
    }
  }

  return (
    <>
      <StyledFooter>
        <Box sx={boxStyles}>
          <Typography sx={labelStyles}>{t('footerContacts.extraInfo')}</Typography>
          {/* eslint-disable-next-line */}
          <a
            onClick={() =>
              handleDownload(
                ECERT_API.FILE_DOWNLOAD_URLS.MANUAL(languages.get(currentLanguage)!),
                t('footerContacts.manual')
              )
            }
          >
            {t('footerContacts.manual')}
          </a>
          {/* eslint-disable-next-line */}
          <a target="_blank" href={properPrivacyStatement()}>
            {t('footerContacts.privacyPolicy')}
          </a>
          {/* eslint-disable-next-line */}
          <a
            onClick={() =>
              handleDownload(
                ECERT_API.FILE_DOWNLOAD_URLS.CORRECTION_INFO(languages.get(currentLanguage)!),
                t('footerContacts.correctionInfo')
              )
            }
          >
            {t('footerContacts.correctionInfo')}
          </a>
          {/* eslint-disable-next-line */}
          <a
            onClick={() =>
              handleDownload(
                ECERT_API.FILE_DOWNLOAD_URLS.ACCESSIBILITY_STATEMENT,
                t('footerContacts.accessibilityStatement')
              )
            }
          >
            {t('footerContacts.accessibilityStatement')}
          </a>
        </Box>
        <Box sx={boxStyles}>
          <Typography sx={labelStyles}>{t('footerContacts.servicedesk')}</Typography>
          <span>Finextra</span>
          <span>{t('footerContacts.phonenumber')} +358 30 395 5227</span>
          <span>
            {t('footerContacts.email')}
            <a href="mailto:go@finextra.fi">go(at)finextra.fi</a>
          </span>
        </Box>
        <Box sx={{ ...boxStyles, marginRight: '160px' }}>
          <Typography sx={labelStyles}>{t('footerContacts.helpdesk')}</Typography>
          <span>Solita Oy</span>
          <span>{t('footerContacts.phonenumber')} +358 44 402 8099</span>
          <span>
            {t('footerContacts.email')}
            <a href="mailto:finextra@solita.fi">finextra(at)solita.fi</a>
          </span>
        </Box>
      </StyledFooter>
      {appInfo && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {[
            appInfo.productionEnv ? undefined : t('testEnvInfo'),
            t('systemName'),
            `${appInfo.version} (${appInfo.buildTime.format('DD.MM.YY hh:mm')})`
          ]
            .filter((value) => !!value)
            .join(' - ')}
        </Box>
      )}
    </>
  )
}
