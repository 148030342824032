import { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ProductionDeviceViewDto } from '../../api/types'
import ProductionDeviceForm, { getProductionDeviceBackendErrorMessage } from './forms/ProductionDeviceForm'
import * as snacky from '../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { useSnackbar } from 'notistack'
import { EcertRoutes } from '../../ecert-routes'
import { isAdmin } from '../../utils/permission-utils'
import { AxiosResponse } from 'axios'
import { ECERT_API, ProductionDeviceBody } from '../../services/ecert-api'
import { useLoginUser } from '../../LoginContext'

export default function AddProductionDevice() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { organizationId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const loginUser = useLoginUser()

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionDeviceNew')}`
  }, [t])

  const handleResponse = (successMessage: string, primise: Promise<AxiosResponse<ProductionDeviceViewDto>>) => {
    primise
      .then((response) => {
        navigate(EcertRoutes.productionDevice(response.data.organization?.id, response.data.id))
        enqueueSnackbar(successMessage, snacky.successOpts)
      })
      .catch((error) => {
        const errorMessage = getProductionDeviceBackendErrorMessage(error, t)
        if (errorMessage) {
          enqueueSnackbar(errorMessage, snacky.errorOpts)
        }
      })
  }

  const handleSave = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.created'),
      ECERT_API.createApprovedProductionDevice(data.organizationId, data)
    )
  }

  const handleSaveDraft = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.created'),
      ECERT_API.createDraftProductionDevice(+organizationId!, false, data)
    )
  }

  const handleSaveAndSubmit = (data: ProductionDeviceBody) => {
    handleResponse(
      t('form.valid.productionDevice.createdAndSubmittedForApproval'),
      ECERT_API.createDraftProductionDevice(+organizationId!, true, data)
    )
  }

  const handleCancel = () => {
    navigate(EcertRoutes.productionDevices())
  }

  return (
    <>
      <Box marginBottom="18px">
        <Typography variant="h1">{t('productionDevice.add')}</Typography>
      </Box>
      <ProductionDeviceForm
        device={null}
        handleSave={isAdmin(loginUser) ? handleSave : undefined}
        handleSaveDraft={!isAdmin(loginUser) ? handleSaveDraft : undefined}
        handleSaveAndSubmit={!isAdmin(loginUser) ? handleSaveAndSubmit : undefined}
        handleCancel={handleCancel}
      />
    </>
  )
}
