import { Box, InputLabel, Tooltip } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import StyleGuide from "../../styles/StyleGuide";
import { FlexRow } from "../../styles/containers/FlexContainers";

interface InputContainerProps {
  id?: string
  label?: string
  required?: boolean
  tooltip?: string
  children: ReactNode
}

const InputContainer = ({ id, label, required = false, tooltip, children }: InputContainerProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <FlexRow>
        {label && (
          <InputLabel htmlFor={id}>
            {label} {!required ? <>({t('form.optional')})</> : <></>}
          </InputLabel>
        )}
        {tooltip && (
          <Tooltip title={tooltip} >
            <HelpOutlineOutlinedIcon sx={{ color: StyleGuide.constants.COLOR_BRAND, marginLeft: '5px', width: '18px' }} />
          </Tooltip>
        )}
      </FlexRow>
      {children}
    </Box>
  )
}

export default InputContainer
