import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { OrganizationListItemDto } from '../../api/types'
import { Button, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import { StyledRightAligned, StyledTable, StyledTableHeaderContainer } from '../../styles/containers/TableContainers'
import { ToggleLocked } from '../../features/toggle-locked/ToggleLocked'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { useTranslation } from 'react-i18next'
import { useOrganizations } from '../../features/store-slices/organizationsSlice'
import { isAdmin } from '../../utils/permission-utils'
import { useLoginUser } from '../../LoginContext'

export function Organizations() {
  const { t } = useTranslation()
  const [organizations] = useOrganizations(true, true)
  const loginUser = useLoginUser()
  const [showLocked, toggleLocked] = useState(false)
  const showLockedDeferred = useDeferredValue(showLocked)
  const [filterText, setFilterText] = useState('')
  const filterTextDeferred = useDeferredValue(filterText)
  const navigate = useNavigate()

  const columns: IColumn[] = [
    {
      label: t('organization.list.name'),
      accessibilityHrefFn: (obj: OrganizationListItemDto) => EcertRoutes.organization(obj.id),
      accessor: 'name',
      sortable: false
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.organizationList')}`
  }, [t])

  const handleOrganizationClick = (organization: OrganizationListItemDto) => {
    if (organization.id) navigate(EcertRoutes.organization(organization.id))
  }

  const handleOrganizationCreateClick = () => {
    navigate(EcertRoutes.newOrganization())
  }

  const filterOrganizations = (organizations: OrganizationListItemDto[]) => {
    return organizations
      .filter((o) => (showLockedDeferred ? o.locked : !o.locked))
      .filter((o) => filterTextDeferred === '' || o.name?.toLowerCase().includes(filterText.toLowerCase()))
  }

  const EcertTableDeferred = useMemo(() => {
    return (
      <EcertTable
        unfilteredResultsLength={organizations?.length || 0}
        ariaDescribedBy="title-organizations"
        data={organizations.length > 0 ? filterOrganizations(organizations) : undefined}
        onRowClick={handleOrganizationClick}
        columns={columns}
      />
    )
    // eslint-disable-next-line
  }, [organizations, showLockedDeferred, filterTextDeferred, t])

  return (
    <StyledTable gap="10px">
      <StyledTableHeaderContainer>
        <Typography id="title-organizations" variant="h1" sx={{ marginRight: '26px' }}>
          {t('title.organizationList')}
        </Typography>
        <ToggleLocked showLocked={showLocked} toggleLocked={() => toggleLocked(!showLocked)} />
        <StyledRightAligned>
          <TextField
            placeholder={t('organization.list.filter.name')}
            variant="outlined"
            value={filterText}
            InputProps={{
              sx: { height: '50px' }
            }}
            onChange={(event) => setFilterText(event.target.value)}
          ></TextField>
          {isAdmin(loginUser) && (
            <Button
              sx={{ height: '50px' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOrganizationCreateClick}
            >
              {t('organization.list.link.new')}
            </Button>
          )}
        </StyledRightAligned>
      </StyledTableHeaderContainer>
      {EcertTableDeferred}
    </StyledTable>
  )
}
