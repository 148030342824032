import { Typography } from '@mui/material'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useEffect } from 'react'
import { ReturnButton } from './components/ReturnButton'
import { useTranslation } from 'react-i18next'
import { SelectedCertificateBundles } from './components/SelectedCertificateBundles'
import { CancellationRecipient } from './components/CancellationRecipient'
import { SelectedCertificate, selectSelectedCertificates } from '../../../features/store-slices/certificateSlice'
import { useAppSelector } from '../../../hooks'
import { InfoBox } from '../../../features/info-box/InfoBox'

interface IOrganizationAccountTransactionCancelProps {
  organizationId: number
  accountId: number
}

export default function OrganizationAccountTransactionCancel({
  organizationId,
  accountId
}: IOrganizationAccountTransactionCancelProps) {
  const { t } = useTranslation()
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)

  useEffect(() => {
    document.title = `Ecert - ${t('title.cancelCertificates')}`
  }, [t])

  const totalSelectedCertificatesCount = () => {
    return selectedCertificates.reduce((prev, curr) => (prev += +curr.selectedAmount), 0)
  }

  const domesticallyBlockedIssuers = selectedCertificates
    .map((certificate) => certificate.bundle)
    .filter((bundle) => !bundle.domesticCancellationPermitted)
    .map((bundle) => `${bundle.certificateIssuingBodyName} (${bundle.certificateIssuingBodyCode})`)
    .filter((issuer, index, issuers) => issuers.indexOf(issuer) === index)

  return (
    <FlexColumn styles={{ width: '900px' }} gap="10px">
      <FlexRow>
        <ReturnButton organizationId={organizationId} accountId={accountId} />
      </FlexRow>
      <FlexColumn gap="10px">
        <Typography variant="h1">{t('cancellation.title')}</Typography>
        {domesticallyBlockedIssuers.length > 0 && (
          <InfoBox type="WARN">
            {t('cancellation.domesticCancellationBlockedWarning', {
              blockedIssuers: domesticallyBlockedIssuers.join(', ')
            })}
          </InfoBox>
        )}
        <SelectedCertificateBundles
          titleText={t('cancellation.bundles')}
          bundleDescription={t('cancellation.amount', {
            amount: totalSelectedCertificatesCount()
          })}
        />
        <CancellationRecipient
          organizationId={organizationId}
          accountId={accountId}
          domesticCancellationPermitted={domesticallyBlockedIssuers.length === 0}
        />
      </FlexColumn>
    </FlexColumn>
  )
}
