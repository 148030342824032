import { Button, ButtonProps } from '@mui/material'
import { ClearOutlined } from '@mui/icons-material'

interface IDeleteButtonProps extends ButtonProps {}

export const DeleteButton = (props: IDeleteButtonProps) => {
  return (
    <Button
      sx={{
        padding: '12px 24px 12px 24px'
      }}
      variant="outlined"
      startIcon={<ClearOutlined />}
      {...props}
    >
      {props.children}
    </Button>
  )
}
