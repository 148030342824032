import { OrganizationDto } from '../../api/types'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import OrganizationForm from '../../features/forms/organization/OrganizationForm'
import { useAppDispatch } from '../../hooks'
import { createOrganization } from '../../features/store-slices/organizationsSlice'
import { useEffect } from 'react'
import { t } from 'i18next'

export default function OrganizationCreate() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    document.title = `Ecert - ${t('title.organizationNew')}`
    //eslint-disable-next-line
  }, [])

  const handleFormSubmit = (organization: OrganizationDto) => {
    dispatch(createOrganization({ organization, navigate }))
  }

  const cancelCreateOrganization = () => {
    navigate(EcertRoutes.organizations())
  }

  return <OrganizationForm mode="CREATE" handleSubmit={handleFormSubmit} handleCancel={cancelCreateOrganization} />
}
