import { z } from 'zod'
import { OrganizationDtoContactLanguage, UserDtoLanguage } from '../api/types'
import { date, formPrefix, nullable, stringFromLengthRange, stringOfForm } from './schema-utils'
import { getTranslation } from '../utils/utils'

export function createAddOrganizationSchemaEdit() {
  return z.object({
    name: stringFromLengthRange(1, 100, `${formPrefix}.organization.name`),
    code: stringFromLengthRange(5, 31, `${formPrefix}.organization.code`),
    customerNumber: stringFromLengthRange(1, 20, `${formPrefix}.organization.customerNumber`),
    contractNumber: stringFromLengthRange(1, 50, `${formPrefix}.organization.contractNumber`),
    invoiceReference: stringFromLengthRange(1, 30, `${formPrefix}.organization.invoiceReference`),
    streetAddress1: stringFromLengthRange(1, 200, `${formPrefix}.organization.streetAddress1`),
    streetAddress2: stringFromLengthRange(0, 200, `${formPrefix}.organization.streetAddress1`),
    postNumber: stringFromLengthRange(1, 50, `${formPrefix}.organization.postNumber`),
    region: stringFromLengthRange(1, 100, `${formPrefix}.organization.region`),
    countryId: z.number({ invalid_type_error: getTranslation(`${formPrefix}.organization.country.required`) }),
    contactLanguage: z.nativeEnum(OrganizationDtoContactLanguage),
    contractStart: date(`${formPrefix}.contractStart`),
    contractEnd: nullable(date(`${formPrefix}.contractStart`).optional()),
    mainUser: z
      .object({
        username: stringFromLengthRange(2, 50, `${formPrefix}.mainUser.username`),
        firstName: stringFromLengthRange(1, 100, `${formPrefix}.mainUser.firstName`),
        lastName: stringFromLengthRange(1, 100, `${formPrefix}.mainUser.lastName`),
        email: stringOfForm('^\\S+@\\S+$', `${formPrefix}.mainUser.email.email`),
        phone: stringOfForm('^\\+ *\\d{10,20}$', `${formPrefix}.mainUser.phone.pattern`),
        language: z.nativeEnum(UserDtoLanguage)
      })
      .optional()
  })
}

const schema = createAddOrganizationSchemaEdit()
export type AddOrganizationSchemaEditType = z.infer<typeof schema>
