import { Box, Divider, IconButton, Typography } from '@mui/material'
import StyleGuide from '../../styles/StyleGuide'
import { StyledHeader } from './Header.styles'
import LanguageSelect from '../language-select/LanguageSelect'
import ProfileMenu from '../profile-menu/ProfileMenu'
import { useTranslation } from 'react-i18next'
import { FlexRow } from '../../styles/containers/FlexContainers'
import { useNotification } from '../store-slices/notificationSlice'
import { useNavigate } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { LoginStatus, useLoginContext } from '../../LoginContext'
import { useAppInfo } from '../../AppInfoContext'

export default function Header() {
  const appInfo = useAppInfo()
  const { loginStatus } = useLoginContext()
  const [notifications] = useNotification(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <StyledHeader>
      <Box sx={{ display: 'flex' }}>
        <Box
          onClick={() => navigate(EcertRoutes.paths.root)}
          sx={{
            height: '24px',
            cursor: 'pointer'
          }}
          component="img"
          src="./img/Fingrid_Finextra_logo_RGB.svg"
          alt="ecert-navbar-logo"
        />
        {appInfo && !appInfo.productionEnv && (
          <Typography
            variant="label"
            sx={{
              color: StyleGuide.constants.COLOR_BRAND,
              margin: 'auto 0 auto 10px'
            }}
          >
            BETA TESTING
          </Typography>
        )}
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            color: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
            margin: '0 10px'
          }}
        />
        <Typography variant="body1">{t('systemName')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LanguageSelect />
        {loginStatus === LoginStatus.VERIFIED_LOGIN && (
          <>
            <FlexRow>
              {notifications && notifications.filter((n) => !n.read).length > 0 && (
                <IconButton aria-label={t('notification.title')} onClick={() => navigate('/')}>
                  <WarningAmberIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
                </IconButton>
              )}
            </FlexRow>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                marginLeft: '16px',
                marginRight: '16px',
                alignSelf: 'center',
                height: '24px',
                color: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3
              }}
            />
            <ProfileMenu />
          </>
        )}
      </Box>
    </StyledHeader>
  )
}
