import { useState } from 'react'
import { TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

interface YearSelectProps {
  valueToShow: Dayjs
  callBack: (newValue: Dayjs | null) => void
}

function YearSelect({ valueToShow, callBack }: YearSelectProps) {
  const [year, setYear] = useState<Dayjs | null>(valueToShow)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Vuosi"
        views={['year']}
        openTo="year"
        value={year}
        onChange={(newValue) => {
          setYear(newValue)
          if (callBack) callBack(newValue)
        }}
        renderInput={(params) => {
          return <TextField sx={{ height: 'fit-content' }} {...params} />
        }}
      />
    </LocalizationProvider>
  )
}

export default YearSelect
