import { z } from 'zod'
import { formPrefix } from './schema-utils'
import { getTranslation } from '../utils/utils'

export function createCancellationRollbackSchema() {
  return z.object({
    additionalDetails: z
      .string()
      .max(1000, getTranslation(`${formPrefix}.additionalDetails.maxlength`))
      .optional()
  })
}

const schema = createCancellationRollbackSchema()
export type CancellationRollbackSchemaType = z.infer<typeof schema>
