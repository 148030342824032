import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'
import { ECERT_API } from '../../../../services/ecert-api'
import {
  GetProductionDevicesManagedByOrganizationDuringParams,
  ProductionDeviceListViewDto
} from '../../../../api/types'
import { Box, Typography } from '@mui/material'
import { associateWithId } from '../../../../utils/utils'
import { useOrganizations } from '../../../../features/store-slices/organizationsSlice'

export interface DeviceFilter {
  orgId: number
  params: GetProductionDevicesManagedByOrganizationDuringParams
}

interface IProductionDeviceSelectTableProps {
  deviceIds: number[]
  deviceFilter: DeviceFilter
  onChange?: (devices: number[]) => void
}

function CountrySelect({ deviceIds, deviceFilter, onChange }: IProductionDeviceSelectTableProps) {
  const { t } = useTranslation()
  const organizations = associateWithId(useOrganizations(true)[0])
  const [data, setData] = useState<ProductionDeviceListViewDto[]>()
  const [selectedDevices, setSelectedDevices] = useState<number[]>(deviceIds ? deviceIds : [])

  useEffect(() => {
    ECERT_API.getProductionDevicesManagedByOrganizationDuring(deviceFilter.orgId, deviceFilter.params).then(
      (response) => setData(response.data)
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (onChange && selectedDevices) {
      onChange(selectedDevices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('productionDeviceReport.deviceName'),
      sortable: true,
      flex: 0.5
    },
    {
      field: 'gsrn',
      headerName: t('productionDeviceReport.deviceGsrn'),
      sortable: true,
      flex: 0.8
    },
    {
      field: 'capacity',
      headerName: t('productionDeviceReport.deviceCapacity'),
      sortable: true,
      flex: 0.5
    },
    {
      field: 'technologyTypeName',
      headerName: t('productionDeviceReport.deviceTechnology'),
      sortable: true,
      flex: 1
    }
  ]

  const handleSelection = (e: GridSelectionModel) => {
    setSelectedDevices(e as number[])
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '0 5px 5px 5px' }}>
        <Typography fontSize="large">{organizations[deviceFilter.orgId]?.name}</Typography>
        <Typography fontSize="large">
          {t('invoiceDetailsReport.selected')}
          &nbsp;
          {selectedDevices.length} / {data?.length}
        </Typography>
      </Box>
      <Box sx={{ height: 400, width: '100%' }}>
        {data && (
          <DataGrid
            {...data}
            getRowHeight={() => 'auto'}
            columns={columns}
            rows={data}
            checkboxSelection
            disableColumnMenu
            selectionModel={selectedDevices}
            onSelectionModelChange={handleSelection}
            isRowSelectable={() => !!onChange}
            localeText={{
              footerRowSelected: (count: number) => (
                <>
                  {count === 1
                    ? t('productionDeviceReport.table.footerRowsSelectedSingle')
                    : t('productionDeviceReport.table.footerRowsSelectedPlural', { count })}
                </>
              )
            }}
            componentsProps={{
              pagination: {
                labelRowsPerPage: t('productionDeviceReport.table.footerRowsPerPage'),
                labelDisplayedRows: ({ from, to, count }) => (
                  <>
                    {t('productionDeviceReport.table.footerTotalVisibleRows', {
                      from,
                      to,
                      count
                    })}
                  </>
                )
              }
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default CountrySelect
