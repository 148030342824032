import { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { ReturnButton } from './components/ReturnButton'
import { Alert, Typography } from '@mui/material'
import { SelectedCertificateBundles } from './components/SelectedCertificateBundles'
import { CancellationRecipient } from './components/CancellationRecipient'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../../services/ecert-api'
import { InvoicingPeriodDto, TransactionWithFullDetailsDto } from '../../../api/types'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { SelectedCertificate, selectSelectedCertificates } from '../../../features/store-slices/certificateSlice'
import { selectCertificatesUpToAmount } from '../account-utils'
import dayjs from 'dayjs'

export const CancellationEdit = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id, accountId, transactionId } = useParams()
  const [transaction, setTransaction] = useState<TransactionWithFullDetailsDto | null>(null)
  const [invoicingPeriod, setInvoicingPeriod] = useState<InvoicingPeriodDto>()
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)

  useEffect(() => {
    document.title = `Ecert - ${t('title.editCancellationDetails')}`
  }, [t])

  useEffect(() => {
    ECERT_API.getTransaction(+id!, +accountId!, +transactionId!).then((response) => {
      setTransaction(response.data)
      selectCertificatesUpToAmount(
        t,
        response.data?.processedCertificateAmount!,
        dispatch,
        response.data?.bundles!,
        'CANCEL'
      )
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (transaction) {
      ECERT_API.getInvoicingPeriods().then((response) => {
        const cancellationTime = dayjs(transaction.time)
        setInvoicingPeriod(
          response.data.find(
            (period) =>
              dayjs(period.invoicingPeriodStart).isBefore(cancellationTime) &&
              dayjs(period.invoicingPeriodEnd).isAfter(cancellationTime)
          )
        )
      })
    }
  }, [transaction])

  const totalSelectedCertificatesCount = () => {
    return selectedCertificates.reduce((prev, curr) => (prev += +curr.selectedAmount), 0)
  }

  return (
    <FlexColumn styles={{ width: '800px' }} gap="10px">
      <FlexRow>
        <ReturnButton organizationId={+id!} accountId={+accountId!} />
      </FlexRow>
      <FlexColumn gap="10px">
        <Typography variant="h1">{t('cancellation.edit.title')}</Typography>
        <FlexColumn gap="10px">
          {transaction?.statementSignedAt && (
            <Alert severity="warning">
              <Typography>{t('cancellation.edit.statementSigned')}</Typography>
            </Alert>
          )}
          {invoicingPeriod?.publishedAt && !invoicingPeriod?.billedAt && (
            <Alert severity="warning">
              <Typography>{t('cancellation.edit.invoicePublished')}</Typography>
            </Alert>
          )}
          {invoicingPeriod?.billedAt && (
            <Alert severity="warning">
              <Typography>{t('cancellation.edit.invoiceBilled')}</Typography>
            </Alert>
          )}
        </FlexColumn>
        <SelectedCertificateBundles
          titleText={t('cancellation.bundles')}
          bundleDescription={t('cancellation.edit.amount', {
            amount: totalSelectedCertificatesCount()
          })}
        />
        {id && accountId && transaction && (
          <CancellationRecipient
            organizationId={+id}
            accountId={+accountId}
            domesticCancellationPermitted={true}
            existingTransaction={transaction}
            cancelCallback={() => {
              navigate(-1)
            }}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
