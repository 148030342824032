import { z } from 'zod'
import { createCertificateTransferSchema } from './certificate-transfer-schema'
import { getTranslation } from '../utils/utils'
import { formPrefix } from './schema-utils'

export function createAutomaticTransferSchema() {
  return z.object({
    transferRecipients: z.array(
      z.object({
        amount: z
          .number({
            invalid_type_error: getTranslation(`${formPrefix}.recipient.amount.required`)
          })
          .min(0.001, getTranslation(`${formPrefix}.recipient.amount.min`))
          .max(100, getTranslation(`${formPrefix}.recipient.amount.max`)),
        transfer: createCertificateTransferSchema()
      })
    ),
    transferDay: z.number().min(1).max(28)
  })
}

const schema = createAutomaticTransferSchema()
export type AutomaticTransferSchemaType = z.infer<typeof schema>
