import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import ControlledTextField from '../../../features/inputs/ControlledTextField'
import ControlledDatePicker from '../../../features/inputs/ControlledDatePicker'
import { useTranslation } from 'react-i18next'
import { CodeGeneratorDto } from '../../../api/types'
import { ECERT_API } from '../../../services/ecert-api'
import { useFormContext } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { isAdmin } from '../../../utils/permission-utils'
import { useParams } from 'react-router-dom'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'
import ControlledSelect from '../../../features/inputs/ControlledSelect'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import { useLoginUser } from '../../../LoginContext'

export default function ProductionDeviceInfoForm({ gsrnCodeExists = false }) {
  const { t } = useTranslation()
  const [managers, fetchManagers] = useOrganizations(false)
  const [gs1Codes, setGs1Codes] = useState<CodeGeneratorDto[]>()
  const { control } = useFormContext<ProductionDeviceSchemaType>()
  const loginUser = useLoginUser()
  const { productionDeviceId } = useParams()

  useEffect(() => {
    if (isAdmin(loginUser)) {
      fetchManagers()
      ECERT_API.getCodeGenerators().then((response) => setGs1Codes(response.data))
    }
  }, [loginUser, fetchManagers])

  return (
    <Box>
      <Box marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.info')}</Typography>
      </Box>

      <FlexRow gap="16px">
        {managers && isAdmin(loginUser) && (
          <>
            <FlexColumn flex="1">
              <ControlledSelect
                id="organizationId"
                name="organizationId"
                label={t('productionDevice.manager')}
                placeholder={t('common.choose')}
                required
                items={managers
                  .filter((manager) => !manager.locked)
                  .map((manager) => ({
                    key: manager.id!,
                    value: manager.id!,
                    label: manager.name!
                  }))}
                control={control}
              />
            </FlexColumn>
          </>
        )}
        <FlexColumn flex="1">
          <ControlledTextField
            disabled={!isAdmin(loginUser) && productionDeviceId !== undefined}
            id="name"
            name="name"
            label={t('productionDevice.name')}
            required
            control={control}
          />
        </FlexColumn>
        {isAdmin(loginUser) && (
          <FlexColumn flex="1">
            <ControlledTextField
              id="productionDeviceCode"
              name="productionDeviceCode"
              label={t('productionDevice.code')}
              required
              control={control}
            />
          </FlexColumn>
        )}
        {gs1Codes && isAdmin(loginUser) && (
          <>
            <FlexColumn flex="1">
              <ControlledSelect
                id="codeGeneratorId"
                name="codeGeneratorId"
                label={t('productionDevice.gs1Code')}
                placeholder={t('common.choose')}
                required
                disabled={gsrnCodeExists}
                items={gs1Codes.map((code) => ({
                  key: code.id!,
                  value: code.id!,
                  label: code.label!
                }))}
                control={control}
              />
            </FlexColumn>
          </>
        )}
        <FlexColumn flex="1">
          <ControlledDatePicker
            disabled={!isAdmin(loginUser) && productionDeviceId !== undefined}
            id="dateOfCommissioning"
            name="dateOfCommissioning"
            label={t('productionDevice.dateOfCommissioning')}
            required
            control={control}
          />
        </FlexColumn>
      </FlexRow>
    </Box>
  )
}
