import { Autocomplete, TextField } from '@mui/material'
import { useCountries } from '../store-slices/countriesSlice'

interface ICountrySelect {
  valueToShow: any
  onChangeSelect: (newValue) => void
}

function CountrySelect({ onChangeSelect, valueToShow }: ICountrySelect) {
  const [countries] = useCountries()

  return (
    <Autocomplete
      disableClearable
      options={countries!}
      getOptionLabel={(option: any) => option.name!}
      renderInput={(params) => <TextField {...params} label="" placeholder="" />}
      isOptionEqualToValue={(option, value) => option.id! === value.id!}
      onChange={(_event, newValue) => {
        if (newValue) onChangeSelect(newValue)
      }}
      value={valueToShow}
    />
  )
}

export default CountrySelect
