/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type TransactionWithFullDetailsDtoType = typeof TransactionWithFullDetailsDtoType[keyof typeof TransactionWithFullDetailsDtoType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionWithFullDetailsDtoType = {
  ISSUING: 'ISSUING',
  TRANSFER: 'TRANSFER',
  CANCELLATION: 'CANCELLATION',
  CANCELLATION_ROLLBACK: 'CANCELLATION_ROLLBACK',
  CORRECTION: 'CORRECTION',
  EXTERNAL_TRANSFER: 'EXTERNAL_TRANSFER',
  EXTERNAL_TRANSFER_ROLLBACK: 'EXTERNAL_TRANSFER_ROLLBACK',
  EXPIRATION: 'EXPIRATION',
} as const;
