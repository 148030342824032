import { TransactionDto } from '../../../api/types'
import React, { useState } from 'react'
import { Box, Button, Menu } from '@mui/material'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StyleGuide from '../../../styles/StyleGuide'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../hooks'
import { ECERT_API } from '../../../services/ecert-api'
import { selectCertificatesUpToAmount } from '../account-utils'
import { useLoginUser } from '../../../LoginContext'
import { canCancelCertificates, canTransferCertificates } from '../../../utils/permission-utils'
import { useParams } from 'react-router-dom'

interface ITransferButtonProps {
  organizationId: number
  accountId: number
  transaction: TransactionDto
  disabled: boolean
}

export const TransferButton = ({ organizationId, accountId, transaction, disabled = false }: ITransferButtonProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const loginUser = useLoginUser()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useAppDispatch()

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const navigateToTransactionTransfer = () => {
    ECERT_API.getTransactionRemainingBundles(organizationId, accountId, transaction.id!).then((response) => {
      selectCertificatesUpToAmount(t, transaction.processedCertificateAmount!, dispatch, response.data, 'TRANSFER')
    })
  }

  const navigateToTransactionCancel = () => {
    ECERT_API.getTransactionRemainingBundles(organizationId, accountId, transaction.id!).then((response) => {
      selectCertificatesUpToAmount(t, transaction.processedCertificateAmount!, dispatch, response.data, 'CANCEL')
    })
  }

  const menuItemDefs = [
    ...(canTransferCertificates(loginUser, params)
      ? [{ label: t('transaction.transferTransactionBundles'), action: navigateToTransactionTransfer }]
      : []),
    ...(canCancelCertificates(loginUser, params)
      ? [{ label: t('transaction.cancelTransactionBundles'), action: navigateToTransactionCancel }]
      : [])
  ]

  return (
    <>
      {menuItemDefs.length > 0 && (
        <Box>
          <Button
            disabled={disabled}
            aria-label={`transfer-${transaction.id}`}
            aria-controls={open ? 'transfer-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
          >
            <ArrowCircleRightOutlinedIcon />
            <ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'nav-menu-button',
              sx: {
                padding: 0,
                a: {
                  color: StyleGuide.constants.COLOR_TEXT_PRIMARY
                }
              }
            }}
          >
            {menuItemDefs.map((def, index) => (
              <MenuItem
                key={index}
                sx={{
                  backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_3,
                  color: StyleGuide.constants.COLOR_TEXT_PRIMARY
                }}
                onClick={def.action}
              >
                {def.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  )
}
