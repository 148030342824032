import { useState } from 'react'
import { FlexRow } from '../../../styles/containers/FlexContainers'
import { Box, InputLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import usePowerform from 'powerform-react'
import { maxLengthValidator } from '../validators'

interface IEcertTextAreaProps {
  autoFocus?: boolean
  initialText: string
  label: string
  characterLimit: number
  characterLimitExceededErrorText: string
  minRows: number
  onChange: (event) => void
  onError: (event) => void
}

export const EcertTextArea = ({
  autoFocus = false,
  initialText,
  label,
  characterLimit,
  characterLimitExceededErrorText,
  minRows,
  onChange,
  onError
}: IEcertTextAreaProps) => {
  const [text, setText] = useState(initialText)
  const { t } = useTranslation()
  const form = usePowerform({
    field: [maxLengthValidator(characterLimit, characterLimitExceededErrorText)]
  })

  const invalidTextStyle = {
    color: 'red'
  }

  return (
    <>
      <FlexRow justifyContent="space-between">
        <InputLabel>{label}</InputLabel>
      </FlexRow>
      <TextField
        autoFocus={autoFocus}
        multiline
        value={text}
        minRows={minRows}
        error={!!form.field.getError()}
        onChange={(event) => {
          setText(event.target.value)
          form.field.setData(event.target.value)
          form.field.validate()
          onError(!!form.field.getError())
          onChange(event)
        }}
      />
      {form.field.getError() ? (
        <Box className="error" style={invalidTextStyle}>
          {t(form.field.getError())}
        </Box>
      ) : (
        <Box>
          <Typography variant="body1">{`${t('form.charactersLeft')}: ${characterLimit - text.length}`}</Typography>
        </Box>
      )}
    </>
  )
}
