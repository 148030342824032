import StyleGuide from '../../styles/StyleGuide'
import { FlexRow } from '../../styles/containers/FlexContainers'
import { ReactNode } from 'react'
import { WarningAmber } from '@mui/icons-material'

interface IWarningBoxProps {
  children: ReactNode
}

export const WarningBox = ({ children }: IWarningBoxProps) => {
  const infoBoxStyles = {
    backgroundColor: StyleGuide.constants.COLOR_ORANGE_90,
    padding: '24px'
  }

  return (
    <FlexRow gap="10px" styles={infoBoxStyles} alignItems="center">
      <WarningAmber />
      {children}
    </FlexRow>
  )
}
