import { Box, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationContactsDto } from '../../../api/types'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../services/ecert-api'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

export function Contacts() {
  const [organizationContacts, setOrganizationContacts] = useState<OrganizationContactsDto[]>()
  const { t } = useTranslation()

  useEffect(() => {
    ECERT_API.getOrganizationContacts().then((response) => setOrganizationContacts(response.data))

    document.title = `Ecert - ${t('title.organizationContacts')}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '16px'
        }}
      >
        <Typography variant="h1">{t('organizationContacts.title')}</Typography>
        <DownloadButton
          fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_CONTACTS}
          fileName={`${t('organizationContacts.menuName')}.xlsx`}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          {t('organizationContacts.export')}
        </DownloadButton>
      </Box>

      {organizationContacts &&
        organizationContacts.map((organizationContact) => {
          return (
            <Box key={organizationContact.id}>
              <Divider sx={{ marginBottom: '16px' }} />
              <Typography variant="body1" sx={{ fontWeight: '700', marginBottom: '16px' }}>
                {organizationContact.name} ({organizationContact.code})
              </Typography>
              {organizationContact.contacts &&
                organizationContact.contacts.map((contact) => {
                  return (
                    <Box key={contact.fullName} sx={{ marginBottom: '16px' }}>
                      <Box sx={{ display: 'flex', marginBottom: '4px' }}>
                        <PersonOutlinedIcon sx={{ marginRight: '8px' }} />
                        <Typography>{contact.fullName}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', marginBottom: '4px' }}>
                        <PhoneOutlinedIcon sx={{ marginRight: '8px' }} />
                        <Typography>{contact.phone}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <EmailOutlinedIcon sx={{ marginRight: '8px' }} />
                        <Typography>{contact.email}</Typography>
                      </Box>
                    </Box>
                  )
                })}
            </Box>
          )
        })}
    </Box>
  )
}
