import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { OrganizationListItemDto, UserListItemDto } from '../../api/types'
import { Button, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { EcertRoutes } from '../../ecert-routes'
import { useTranslation } from 'react-i18next'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useUsers } from '../../features/store-slices/usersSlice'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { ToggleLocked } from '../../features/toggle-locked/ToggleLocked'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { canCreateUser, isAdmin, canViewOrganizations, singleOrganization } from '../../utils/permission-utils'
import OrganizationSelect from '../../features/organization-select/OrganizationSelect'
import { useOrganizations } from '../../features/store-slices/organizationsSlice'
import { getRoleTranslation } from '../../utils/utils'
import DownloadButton from '../../features/buttons/DownloadButton'
import { ECERT_API } from '../../services/ecert-api'
import { useLoginUser } from '../../LoginContext'

export default function Users() {
  const loginUser = useLoginUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const [users] = useUsers(true, true, singleOrganization(searchParams.get('organizationId'), loginUser))
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [showLocked, setShowLocked] = useState(false)
  const showLockedDeferred = useDeferredValue(showLocked)
  const [filterText, setFilterText] = useState('')
  const filterTextDeferred = useDeferredValue(filterText)
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListItemDto | null>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const columns: IColumn[] = [
    {
      label: t('user.username'),
      accessor: 'username',
      sortable: false,
      accessibilityHrefFn: (data: UserListItemDto) => EcertRoutes.user(data.organizationId, data.id),
      role: 'rowheader'
    },
    {
      label: t('user.name'),
      accessor: (data: UserListItemDto) => `${data.firstName} ${data.lastName}`,
      sortable: false
    },
    {
      label: t('user.info.organization'),
      accessor: 'organizationName',
      sortable: false
    },
    {
      label: t('user.info.language'),
      accessor: 'language',
      transformFn: (lang) => t(`language.${lang}`),
      sortable: false
    },
    {
      label: t('user.role.title'),
      accessor: (data: UserListItemDto) => getRoleTranslation(data.authorities?.at(-1)) || '',
      sortable: false
    },
    { label: t('user.info.phone'), accessor: 'phone', sortable: false }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.userList')}`
  }, [t])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    const organizationId = searchParams.get('organizationId')
    setSelectedOrganization(organizations?.find((org) => org.id === +organizationId!) || null)
  }, [searchParams, organizations])

  const handleUserClick = (user: UserListItemDto) => {
    if (user.id) navigate(EcertRoutes.user(user.organizationId, user.id))
  }

  const filterUsers = (users: UserListItemDto[]) => {
    return users
      .filter((u) => (selectedOrganization ? u.organizationId === selectedOrganization.id : true))
      .filter((u) => (showLockedDeferred ? u.locked : !u.locked))
      .filter(
        (u) =>
          filterTextDeferred === '' ||
          u.firstName?.toLowerCase().includes(filterText.toLowerCase()) ||
          u.lastName?.toLowerCase().includes(filterText.toLowerCase())
      )
  }

  const handleOrganizationSelect = (organization: OrganizationListItemDto | null) => {
    setSelectedOrganization(organization)
    setSearchParams(organization ? `?${new URLSearchParams({ organizationId: `${organization?.id}` })}` : '')
  }

  const EcertTableDeferred = useMemo(() => {
    return (
      <EcertTable
        unfilteredResultsLength={users?.length}
        ariaDescribedBy="title-users"
        data={users ? filterUsers(users) : undefined}
        columns={columns}
        onRowClick={handleUserClick}
      />
    )
    // eslint-disable-next-line
  }, [users, showLockedDeferred, filterTextDeferred, selectedOrganization, t])

  return (
    <FlexColumn gap="8px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow gap="8px">
          <Typography id="title-users" variant="h1">
            {t('title.userList')}
          </Typography>
          <ToggleLocked showLocked={showLocked} toggleLocked={() => setShowLocked(!showLocked)} />
        </FlexRow>
        <FlexRow gap="8px" alignItems="flex-end">
          {canViewOrganizations(loginUser) && (
            <OrganizationSelect
              value={selectedOrganization}
              organizations={organizations || []}
              onSelect={handleOrganizationSelect}
            />
          )}
          <TextField
            label={t('user.list.filter.name')}
            variant="outlined"
            value={filterText}
            InputProps={{
              sx: { height: '50px' }
            }}
            onChange={(event) => setFilterText(event.target.value)}
          ></TextField>
          {isAdmin(loginUser) && (
            <DownloadButton
              variant="outlined"
              startIcon={<FileDownloadOutlinedIcon />}
              fileName={t('title.userList')}
              fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.USER_LIST}${showLocked ? 'true' : 'false'}?downloadId=`}
            >
              {t('user.export')}
            </DownloadButton>
          )}
          {canCreateUser(loginUser, { organizationId: loginUser.organizationId! }) && (
            <Button
              variant="contained"
              sx={{ height: '50px' }}
              startIcon={<AddIcon />}
              onClick={() => navigate(EcertRoutes.newUser(loginUser.organizationId))}
            >
              {t('user.link.new')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>
      {EcertTableDeferred}
    </FlexColumn>
  )
}
