import { AccountDetailsDto, CertificateBundleDto } from '../../../api/types'

interface IUnfilteredTotalCertificateCountProps {
  accountDetails: AccountDetailsDto
}

export const UnfilteredTotalCertificateCount = ({ accountDetails }: IUnfilteredTotalCertificateCountProps) => {
  const totalBundleCount = (): number => {
    if (!accountDetails?.activeCertificateBundles) return 0

    return accountDetails.activeCertificateBundles.reduce((prev: number, curr: CertificateBundleDto) => {
      return (prev += curr.amount || 0)
    }, 0)
  }

  return <>{totalBundleCount()}</>
}
