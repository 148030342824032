import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ControlledDateRangePicker } from '../../../features/inputs/ControlledDateRangePicker'
import { useFormContext } from 'react-hook-form'
import { ProductionDeviceSchemaType } from '../../../validation/production-device-schema'

export default function AuditForm() {
  const { t } = useTranslation()

  const { control } = useFormContext<ProductionDeviceSchemaType>()

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="16px" marginBottom="16px">
        <Typography variant="h2">{t('productionDevice.validity.validity')}</Typography>
      </Box>

      <Box display="flex" gap="16px">
        <ControlledDateRangePicker
          startDateName="validityStartDate"
          endDateName="validityEndDate"
          label={t('productionDevice.investment.validity')}
          required
          control={control}
        />
      </Box>
    </Box>
  )
}
