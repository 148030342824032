import { useEffect, useState } from 'react'
import { useAppLanguage } from '../../../hooks'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { IconButton, TextField } from '@mui/material'
import { Dayjs } from 'dayjs'
import { parseDate } from '../../../utils/utils'
import Clear from '@mui/icons-material/Clear'
import { CalendarOrClockPickerView, CalendarPickerView } from '@mui/x-date-pickers/internals/models/views'
import { useTranslation } from 'react-i18next'

interface IEcertDatePickerProps {
  name: string
  views?: CalendarOrClockPickerView[]
  openTo?: string
  defaultValue?: string
  time?: boolean
  onUpdate?: (Dayjs) => any
  clearable?: boolean
}

export const EcertDatePicker = ({
  name,
  views = ['year', 'day'],
  openTo = 'day',
  defaultValue,
  time = false,
  onUpdate,
  clearable
}: IEcertDatePickerProps) => {
  const { t } = useTranslation()
  const [date, setDate] = useState<Dayjs | null>(null)
  const { currentLanguage } = useAppLanguage()

  useEffect(() => {
    if (!defaultValue) return

    if (!time) {
      const date = parseDate(defaultValue)
      if (date) {
        setDate(date)
      }
    } else {
      setDate(defaultValue as unknown as Dayjs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClear = () => {
    setDate(null)
    onUpdate?.(null)
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={currentLanguage}
      localeText={{ todayButtonLabel: t('datePicker.button.currentDate') }}
    >
      {!time ? (
        <DatePicker
          views={views as CalendarPickerView[]}
          openTo={openTo as CalendarPickerView}
          value={date}
          componentsProps={{
            actionBar: { actions: ['today'] }
          }}
          onChange={(newValue) => {
            setDate(newValue)
            if (onUpdate) onUpdate(newValue)
          }}
          renderInput={(params) => (
            <>
              {clearable && date ? (
                <TextField
                  id={name}
                  fullWidth
                  name={name}
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton aria-label="clear" onClick={handleClear}>
                          <Clear />
                        </IconButton>
                        {params.InputProps?.endAdornment}
                      </>
                    )
                  }}
                />
              ) : (
                <TextField id={name} fullWidth name={name} {...params} />
              )}
            </>
          )}
        />
      ) : (
        <DateTimePicker
          views={views}
          openTo={openTo as CalendarOrClockPickerView}
          value={date}
          componentsProps={{
            actionBar: { actions: ['today'] }
          }}
          inputFormat="DD.MM.YYYY HH:mm"
          onChange={(newValue) => {
            setDate(newValue)
            if (onUpdate) onUpdate(newValue)
          }}
          renderInput={(params) => (
            <TextField
              id={name}
              name={name}
              {...params}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton aria-label="clear" onClick={handleClear}>
                      <Clear />
                    </IconButton>
                    {params.InputProps?.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      )}
    </LocalizationProvider>
  )
}
