import StyleGuide from '../../../../styles/StyleGuide'
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { getTranslation } from '../../../../utils/utils'

import { ProductionDeviceUpdateEventViewDto, ProductionDeviceVersionHistoryItemDto } from '../../../../api/types'
import EcertTable from '../../../../features/table/EcertTable'
import { updateColumns, versionHistoryUpdateEventsColumns } from '../Columns'

interface InfoTabPanelContentProps {
  updateEvents: ProductionDeviceUpdateEventViewDto[]
  versionHistory: ProductionDeviceVersionHistoryItemDto[]
}

export default function Events({ updateEvents, versionHistory }: InfoTabPanelContentProps) {
  const getVersionHistoryEvents = () => {
    const temp: ProductionDeviceUpdateEventViewDto[] = []
    versionHistory.forEach((versionHistory) => {
      if (versionHistory && versionHistory.versionUpdateEvents)
        versionHistory.versionUpdateEvents.forEach((value) => temp.push(value))
    })

    return temp
  }

  return (
    <>
      <Typography variant="h2">{getTranslation('productionDevice.updateEvents')}</Typography>

      <Accordion disabled={updateEvents && updateEvents.length <= 0}>
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <Typography>
            {getTranslation('productionDevice.currentVersionUpdateEvents') + ` (${updateEvents.length})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertTable data={updateEvents ? updateEvents : undefined} columns={updateColumns} />
        </AccordionDetails>
      </Accordion>

      <Accordion disabled={versionHistory && getVersionHistoryEvents().length <= 0}>
        <AccordionSummary expandIcon={<ExpandMore sx={{ color: StyleGuide.constants.COLOR_BRAND }} />}>
          <Typography>
            {getTranslation('productionDevice.previousVersionsUpdateEvents') + ` (${getVersionHistoryEvents().length})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EcertTable
            data={versionHistory ? getVersionHistoryEvents() : undefined}
            columns={versionHistoryUpdateEventsColumns}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
