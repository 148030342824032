import { Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'
import {
  SelectedCertificate,
  selectSelectedCertificates,
  unSelectCertificates
} from '../../../features/store-slices/certificateSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  AutomaticTransferRecipientDtoType,
  TransferDomesticTransactionDto,
  TransferExternalTransactionDto,
  TransferPersonalTransactionDto,
  TransferResult
} from '../../../api/types'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { CertificateTransfer } from '../../../features/certificate-transfer/CertificateTransfer'
import { enqueueSuccessNotification } from '../../../utils/utils'
import { ECERT_API } from '../../../services/ecert-api'
import { ReturnButton } from './components/ReturnButton'
import { SelectedCertificateBundles } from './components/SelectedCertificateBundles'
import { EcertRoutes } from '../../../ecert-routes'
import { useNavigate } from 'react-router-dom'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { cleanAfterSubmit } from '../account-utils'
import { AxiosResponse } from 'axios'
import { FormProvider, useForm } from 'react-hook-form'
import {
  CertificateTransferSchemaType,
  createCertificateTransferSchema
} from '../../../validation/certificate-transfer-schema'
import { zodResolver } from '@hookform/resolvers/zod'
import * as snacky from '../../../features/custom-snackbar-provider/CustomSnackbarProvider'

interface IOrganizationAccountTransactionTransfer {
  organizationId: number
  accountId: number
}

const formDefaultValues = {
  type: AutomaticTransferRecipientDtoType.DOMESTIC,
  recipientName: '',
  targetAccountNumber: '',
  additionalDetails: undefined
}

export default function OrganizationAccountTransactionTransfer({
  organizationId,
  accountId
}: IOrganizationAccountTransactionTransfer) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const selectedCertificates: SelectedCertificate[] = useAppSelector(selectSelectedCertificates)
  const form = useForm<CertificateTransferSchemaType>({
    resolver: zodResolver(createCertificateTransferSchema()),
    mode: 'all',
    defaultValues: formDefaultValues
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    document.title = `Ecert - ${t('title.transferCertificates')}`
  }, [t])

  const enqueueCertificateTransferCompletedNotification = (notificationText: string, transactionId: number) => {
    const certificateTransferCompletedContent: React.ReactNode = (
      <Box>
        {notificationText}
        <Link
          onClick={() => {
            closeSnackbar()
            navigate(EcertRoutes.organizationAccountTransaction(organizationId, accountId, transactionId))
          }}
        >
          {t('transaction.transactionInfo')}
        </Link>
      </Box>
    )
    enqueueSuccessNotification(certificateTransferCompletedContent)
  }

  const submitTransfer = () => {
    const targets = selectedCertificates.map((cert) => ({
      id: cert.bundle.id,
      amount: cert.selectedAmount
    }))
    const transferAmount = targets.reduce((prev, curr) => (prev += +curr.amount), 0)

    const values = form.getValues()
    setIsSubmitting(true)

    const promise = (() => {
      switch (values.type) {
        case AutomaticTransferRecipientDtoType.DOMESTIC: {
          const transferDomesticDto: TransferDomesticTransactionDto = {
            sourceAccountId: accountId,
            targets: targets,
            additionalDetails: values.additionalDetails || undefined,
            targetAccountNumber: values.targetAccountNumber,
            recipientName: values.recipientName
          }
          return ECERT_API.transferDomestic(organizationId, accountId, transferDomesticDto)
        }
        case AutomaticTransferRecipientDtoType.ORGANIZATION: {
          const transferPersonalDto: TransferPersonalTransactionDto = {
            sourceAccountId: accountId,
            targets: targets,
            additionalDetails: values.additionalDetails || undefined,
            targetAccountId: values.selectedAccount?.id
          }
          return ECERT_API.transferOrganization(organizationId, accountId, transferPersonalDto)
        }
        case AutomaticTransferRecipientDtoType.EXTERNAL: {
          const transferExternalDto: TransferExternalTransactionDto = {
            sourceAccountId: accountId,
            targets: targets,
            additionalDetails: values.additionalDetails || undefined,
            targetAccountNumber: values.targetAccountNumber,
            recipientName: values.recipientName
          }
          return ECERT_API.transferExternal(organizationId, accountId, transferExternalDto)
        }
      }
    })()

    const notificationText =
      values.type === AutomaticTransferRecipientDtoType.ORGANIZATION
        ? t('form.valid.transfer.confirmedForOrganization', {
            transferAmount: transferAmount,
            accountName: values.recipientName,
            accountNumber: values.targetAccountNumber
          })
        : t('form.valid.transfer.confirmedForOther', {
            transferAmount: transferAmount,
            participant: values.recipientName,
            accountNumber: values.targetAccountNumber
          })

    promise
      .then((response: AxiosResponse<TransferResult>) => {
        enqueueCertificateTransferCompletedNotification(notificationText, response.data.transactionId!)
        cleanAfterSubmit(dispatch, organizationId, accountId)
      })
      .catch(() => {
        enqueueSnackbar(t('transfer.generalError'), snacky.errorOpts)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const cancelTransfer = () => {
    dispatch(unSelectCertificates())
  }

  const totalSelectedCertificatesCount = () => {
    return selectedCertificates.reduce((prev, curr) => (prev += +curr.selectedAmount), 0)
  }

  return (
    <FlexColumn styles={{ width: '800px' }} gap="10px">
      <FlexRow>
        <ReturnButton organizationId={organizationId} accountId={accountId} />
      </FlexRow>
      <FlexColumn gap="10px">
        <Typography variant="h1" marginTop="16px">
          {t('transfer.title')}
        </Typography>
        <SelectedCertificateBundles
          titleText={t('transfer.bundles')}
          bundleDescription={t('transfer.operationDescription', {
            amount: totalSelectedCertificatesCount()
          })}
        />
        <FormProvider {...form}>
          <CertificateTransfer
            targetOrganizationId={organizationId}
            accountId={accountId}
            paths={{
              type: 'type',
              selectedAccount: 'selectedAccount',
              targetAccountNumber: 'targetAccountNumber',
              recipientName: 'recipientName',
              additionalDetails: 'additionalDetails'
            }}
          />
          <FlexRow gap="10px">
            <Button
              disabled={!form.formState.isValid || isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : <CheckIcon />}
              variant="contained"
              onClick={submitTransfer}
            >
              {t('transfer.confirmTransfer')}
            </Button>
            <Button disabled={isSubmitting} startIcon={<CloseIcon />} variant="outlined" onClick={cancelTransfer}>
              {t('transfer.cancelTransfer')}
            </Button>
          </FlexRow>
        </FormProvider>
      </FlexColumn>
    </FlexColumn>
  )
}
