import { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomDialog from '../../../../features/custom-dialog/CustomDialog'
import ProductionDeviceSelectTable, { DeviceFilter } from './ProductionDeviceSelectTable'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

interface IProductionDeviceSelectDialogProps {
  deviceIds: number[]
  deviceFilter: DeviceFilter
  toggleModal: () => void
  onConfirm?: (devices: number[]) => void
}

export default function ProductionDeviceSelectDialog({
  deviceIds,
  deviceFilter,
  toggleModal,
  onConfirm
}: IProductionDeviceSelectDialogProps) {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true)
  const [selectedDevices, setSelectedDevices] = useState<number[]>([])

  const handleClose = () => {
    setIsModalOpen(false)
    toggleModal()
  }

  const confirm = () => {
    if (onConfirm) {
      onConfirm(selectedDevices)
    }
    setIsModalOpen(false)
    toggleModal()
  }

  const handleProductionSelect = (newDevices: number[]) => {
    setSelectedDevices(newDevices)
  }

  return (
    <CustomDialog
      title={
        onConfirm ? t('invoiceDetailsReport.selectDevicesTitle') : t('invoiceDetailsReport.selectDevicesTitleReadOnly')
      }
      isOpen={isModalOpen}
      handleClose={() => handleClose()}
      buttons={
        <>
          {onConfirm ? (
            <>
              <Button variant="contained" startIcon={<CheckIcon />} onClick={() => confirm()}>
                {t('common.choose')}
              </Button>
              <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => handleClose()}>
                {t('common.cancel')}
              </Button>
              <Typography sx={{ float: 'right' }}>{t('invoiceDetailsReport.selectDevicesSaveReminder')}</Typography>
            </>
          ) : (
            <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => handleClose()}>
              {t('common.close')}
            </Button>
          )}
        </>
      }
    >
      <ProductionDeviceSelectTable
        deviceIds={deviceIds}
        deviceFilter={deviceFilter}
        onChange={onConfirm ? handleProductionSelect : undefined}
      />
    </CustomDialog>
  )
}
