import { ProductionDeviceViewDtoStatus } from '../../../../api/types'
import { ArrowForwardOutlined, Close, EditOutlined } from '@mui/icons-material'
import { getTranslation } from '../../../../utils/utils'
import { Button } from '@mui/material'
import { EcertRoutes } from '../../../../ecert-routes'
import { useNavigate, useParams } from 'react-router-dom'

interface IUserButtons {
  isDraftAvailable: boolean
  productionDeviceStatus: ProductionDeviceViewDtoStatus
  submitForApproval: () => void
  removeDraft: () => void
}

export default function UserButtons({
  isDraftAvailable,
  productionDeviceStatus,
  submitForApproval,
  removeDraft
}: IUserButtons) {
  const { organizationId, productionDeviceId } = useParams()
  const navigate = useNavigate()

  const handleEditApproved = () => {
    navigate(EcertRoutes.productionDeviceEdit(+organizationId!, +productionDeviceId!), {
      state: { isDraft: isDraftAvailable ? true : false }
    })
  }

  return (
    <>
      {productionDeviceStatus === 'DRAFT' && (
        <>
          <Button onClick={submitForApproval} variant="contained" startIcon={<ArrowForwardOutlined />}>
            {getTranslation('productionDevice.status.submit_for_approval')}
          </Button>
        </>
      )}

      {((!isDraftAvailable && productionDeviceStatus === 'APPROVED') ||
        (isDraftAvailable && productionDeviceStatus !== 'APPROVED') ||
        productionDeviceStatus === 'RETURNED' ||
        productionDeviceStatus === 'DRAFT' ||
        productionDeviceStatus === 'WAITING_APPROVAL') && (
        <Button onClick={() => handleEditApproved()} startIcon={<EditOutlined />} variant="outlined">
          {getTranslation('productionDevice.edit')}
        </Button>
      )}
      {isDraftAvailable && (productionDeviceStatus === 'DRAFT' || productionDeviceStatus === 'RETURNED') && (
        <Button onClick={removeDraft} startIcon={<Close />} variant="outlined">
          {getTranslation('productionDevice.removeDraft')}
        </Button>
      )}
    </>
  )
}
