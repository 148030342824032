import { z } from 'zod'
import { createAttachmentSchema, date, formPrefix } from './schema-utils'
import { getTranslation } from '../utils/utils'
import { ProductionDeviceDtoIssuingPeriod, ProductionDeviceInvestmentTypeDtoType } from '../api/types'

function createOwnerSchema() {
  return z.object({
    name: z
      .string()
      .min(1, {
        message: getTranslation(`${formPrefix}.ownerName.required`)
      })
      .max(100, { message: getTranslation(`${formPrefix}.ownerName.maxlength`) }),
    code: z
      .string()
      .min(1, {
        message: getTranslation(`${formPrefix}.ownerCode.required`)
      })
      .max(30, { message: getTranslation(`${formPrefix}.ownerCode.maxlength`) })
      .refine((value) => value.length >= 5, {
        message: getTranslation(`${formPrefix}.ownerCode.minlength`)
      })
  })
}

function createAddressOrLocationSchema() {
  return z
    .object({
      // Finnish postnumber regex (01234)
      postNumber: z.string().optional().nullable(),
      region: z.string().optional().nullable(),
      latitude: z
        .number()
        .min(-90, getTranslation(`${formPrefix}.productionDevice.latitude.min`, { min: -90, max: 90 }))
        .max(90, getTranslation(`${formPrefix}.productionDevice.latitude.max`, { min: -90, max: 90 }))
        .optional()
        .nullable(),
      longitude: z
        .number()
        .min(-180, getTranslation(`${formPrefix}.productionDevice.longitude.min`, { min: -180, max: 180 }))
        .max(180, getTranslation(`${formPrefix}.productionDevice.longitude.max`, { min: -180, max: 180 }))
        .optional()
        .nullable()
    })
    .superRefine((data, ctx) => {
      const addressFilled = data.postNumber && data.region
      const coordsFilled = data.latitude != null && data.longitude != null

      if (!data.postNumber && !data.region && data.latitude == null && data.longitude == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.required`),
          path: ['postNumber']
        })
      }

      if (data.postNumber) {
        if (data.postNumber.length > 5) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getTranslation(`${formPrefix}.productionDevice.postNumber.min`, { min: '0', max: '99999' }),
            path: ['postNumber']
          })
        }

        if (!new RegExp(/^\d{5}$/).test(data.postNumber)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getTranslation(`${formPrefix}.productionDevice.postNumber.number`),
            path: ['postNumber']
          })
        }
      }

      if ((!data.postNumber && data.region) || (!data.postNumber && data.region && addressFilled)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.productionDevice.postNumber.required`),
          path: ['postNumber']
        })
      }

      if ((!data.region && data.postNumber) || (!data.region && data.postNumber && coordsFilled)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.productionDevice.region.required`),
          path: ['region']
        })
      }

      if (
        (data.latitude == null && data.longitude != null) ||
        (data.latitude == null && data.longitude != null && addressFilled)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.productionDevice.latitude.required`),
          path: ['latitude']
        })
      }

      if (
        (data.longitude == null && data.latitude != null) ||
        (data.longitude == null && data.latitude != null && addressFilled)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.productionDevice.longitude.required`),
          path: ['longitude']
        })
      }
    })
}

function createBeneficiariesSchema() {
  return z.object({
    name: z.string().min(1, {
      message: getTranslation(`${formPrefix}.required`)
    }),
    accountNumber: z
      .string()
      .min(1, {
        message: getTranslation(`${formPrefix}.beneficiary.accountNumberField.required`)
      })
      .max(30, { message: getTranslation(`${formPrefix}.beneficiary.accountNumberField.maxlength`) })
      .refine((i) => i.length >= 5, {
        message: getTranslation(`${formPrefix}.beneficiary.accountNumberField.minlength`)
      }),
    share: z
      .number({
        invalid_type_error: getTranslation(`${formPrefix}.beneficiary.share.number`)
      })
      .min(0.001, {
        message: getTranslation(`${formPrefix}.beneficiary.share.min`, {
          min: 0.001,
          max: 100
        })
      })
      .max(100, {
        message: getTranslation(`${formPrefix}.beneficiary.share.max`, {
          min: 0.001,
          max: 100
        })
      })
  })
}

function createTechnologySchema() {
  return z
    .object({
      description1: z.string().min(1, {
        message: getTranslation(`${formPrefix}.technologyDesc1.required`)
      }),
      description2: z.string().optional().nullable(),
      description3: z.string().optional().nullable(),
      radioactiveWasteProduced: z.number().optional().nullable()
    })
    .refine(
      (input) => {
        if (input.description1 !== 'Nuclear' || !input.radioactiveWasteProduced) {
          return true
        }
        return input.radioactiveWasteProduced >= 0.1 && input.radioactiveWasteProduced <= 100
      },
      {
        path: ['radioactiveWasteProduced']
      }
    )
}

function createFuelSchema() {
  return z.object({
    fuelCode: z.string().min(1),
    fuel: z.object({
      description1: z
        .string({
          invalid_type_error: getTranslation(`${formPrefix}.fuelType.energySourceDesc1.required`)
        })
        .min(1, {
          message: getTranslation(`${formPrefix}.fuelType.energySourceDesc1.required`)
        }),
      description2: z.string().nullable(),
      description3: z.string().nullable(),
      description4: z.string().nullable()
    }),
    labels: z.array(
      z.object({
        typeCode: z.string().min(1),
        validityStartDate: date(`${formPrefix}.validityStartDate`),
        validityEndDate: date(`${formPrefix}.validityEndDate`)
      })
    ),
    productionSupports: z.array(
      z.object({
        investment: z.object({
          abbreviation: z.string().optional(),
          country: z.string().optional(),
          expiryDate: z.string().optional().nullable(),
          scheme: z.string().optional(),
          type: z.nativeEnum(ProductionDeviceInvestmentTypeDtoType).optional()
        }),
        validityStartDate: date(`${formPrefix}.validityStartDate`),
        validityEndDate: date(`${formPrefix}.validityEndDate`)
      })
    )
  })
}

function createInvestmentSchema() {
  return z.object({
    investment: z.object({
      country: z.string().optional(),
      abbreviation: z.string().optional(),
      scheme: z.string(),
      expiryDate: z.string().optional().nullable(),
      type: z.nativeEnum(ProductionDeviceInvestmentTypeDtoType).optional()
    })
  })
}

function createBaseSchema() {
  return z.object({
    name: z.string().min(1, {
      message: getTranslation(`${formPrefix}.productionDevice.name.required`)
    }),
    dateOfCommissioning: date(`${formPrefix}.contractStart`),
    owners: z.array(createOwnerSchema()),
    addressOrLocation: createAddressOrLocationSchema(),
    beneficiaries: z.array(createBeneficiariesSchema()),
    capacity: z
      .number({
        invalid_type_error: getTranslation(`${formPrefix}.productionDevice.capacity.required`)
      })
      .min(0.000001, {
        message: getTranslation(`${formPrefix}.productionDevice.capacity.required`)
      }),
    technology: createTechnologySchema(),
    technologyType: z.string(),
    fuels: z.array(createFuelSchema()).superRefine((input, ctx) => {
      const codes = input.map((e) => e.fuelCode)

      codes.forEach((e, idx) => {
        if (codes.indexOf(e) !== codes.lastIndexOf(e)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: getTranslation(`${formPrefix}.fuelType.fuelAlreadySelected`, { code: e }),
            path: [idx, 'fuel', 'description1']
          })
        }
      })
    }),
    investments: z.array(createInvestmentSchema()).optional(),
    validityStartDate: date(`${formPrefix}.contractStart`),
    validityEndDate: date(`${formPrefix}.contractStart`),
    issuingPeriod: z.nativeEnum(ProductionDeviceDtoIssuingPeriod),
    poa: createAttachmentSchema().nullable(),
    attachments: z.array(createAttachmentSchema())
  })
}

function createAdminSchema() {
  return z
    .object({
      adminForm: z.literal(true),
      organizationId: z
        .number()
        .min(1, { message: getTranslation(`${formPrefix}.organization.productionDeviceOrganizationId.required`) }),
      productionDeviceCode: z
        .string()
        .min(1, { message: getTranslation(`${formPrefix}.productionDevice.code.required`) }),
      codeGeneratorId: z.number().min(1, { message: getTranslation(`${formPrefix}.required`) }),
      comment: z.string().max(300, { message: getTranslation(`${formPrefix}.productionDevice.description.maxlength`) }),
      totalShares: z.number().min(0.001).max(100.000)
    })
    .merge(createBaseSchema())
}

function createAccountUserSchema() {
  return z
    .object({
      adminForm: z.literal(false),
      organizationId: z.number().optional(),
      productionDeviceCode: z.string().optional(),
      codeGeneratorId: z.number().optional(),
      comment: z
        .string()
        .min(1, { message: getTranslation(`${formPrefix}.productionDevice.description.required`) })
        .max(300, { message: getTranslation(`${formPrefix}.productionDevice.description.maxlength`) }),
      totalShares: z.number().min(0.001).max(100.000)
    })
    .merge(createBaseSchema())
}

export function createProductionDeviceSchema() {
  return z.discriminatedUnion('adminForm', [createAdminSchema(), createAccountUserSchema()])
}

const schema = createProductionDeviceSchema()
export type ProductionDeviceSchemaType = z.infer<typeof schema>
