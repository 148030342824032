import 'dayjs/locale/en'
import 'dayjs/locale/fi'
import 'dayjs/locale/sv'
import { Box, Button, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { OrganizationDto, OrganizationViewDto } from '../../../api/types'
import { useAppSelector } from '../../../hooks'
import { RootState } from '../../../store'
import ControlledTextField from '../../inputs/ControlledTextField'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCountries } from '../../store-slices/countriesSlice'
import { ControlledAutocomplete } from '../../inputs/ControlledAutocomplete'
import { ControlledRadioGroup } from '../../inputs/ControlledRadioGroup'
import { ControlledDateRangePicker } from '../../inputs/ControlledDateRangePicker'
import { AddOrganizationSchemaType, createAddOrganizationSchema } from '../../../validation/add-organization-schema'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { createAddOrganizationSchemaEdit } from '../../../validation/add-organization-schema-edit'
import { InfoBox } from '../../info-box/InfoBox'

type FormMode = 'CREATE' | 'EDIT'

interface IOrganizationFormProps {
  mode: FormMode
  existingOrganization?: OrganizationViewDto
  handleSubmit: (organizationDto: OrganizationDto) => void
  handleCancel: () => void
}

export default function OrganizationForm({
  mode,
  existingOrganization,
  handleSubmit,
  handleCancel
}: IOrganizationFormProps) {
  const { t } = useTranslation()
  const createOrganizationStatus = useAppSelector((state: RootState) => state.organizations.createOrganizationStatus)
  const form = useForm<AddOrganizationSchemaType>({
    resolver: zodResolver(mode === 'CREATE' ? createAddOrganizationSchema() : createAddOrganizationSchemaEdit()),
    mode: 'all',
    defaultValues: {
      name: mode === 'EDIT' ? existingOrganization?.name : '',
      code: mode === 'EDIT' ? existingOrganization?.code : '',
      customerNumber: mode === 'EDIT' ? existingOrganization?.customerNumber : '',
      contractNumber: mode === 'EDIT' ? existingOrganization?.contractNumber : '',
      invoiceReference: mode === 'EDIT' ? existingOrganization?.invoiceReference : '',
      contractStart: mode === 'EDIT' ? existingOrganization?.contractStart : '',
      contractEnd: mode === 'EDIT' ? existingOrganization?.contractEnd : null,
      streetAddress1: mode === 'EDIT' ? existingOrganization?.streetAddress1 : '',
      streetAddress2: mode === 'EDIT' ? existingOrganization?.streetAddress2 ?? '' : '',
      postNumber: mode === 'EDIT' ? existingOrganization?.postNumber : '',
      region: mode === 'EDIT' ? existingOrganization?.region : '',
      countryId: mode === 'EDIT' ? existingOrganization?.countryId : undefined,
      contactLanguage: mode === 'EDIT' ? existingOrganization?.contactLanguage ?? 'FINNISH' : 'FINNISH',
      mainUser:
        mode === 'EDIT'
          ? undefined
          : {
              language: 'FINNISH'
            }
    }
  })
  const [countries] = useCountries()

  const handleFormSubmit: SubmitHandler<OrganizationDto> = (data) => {
    handleSubmit(data)
  }

  return (
    <FlexColumn id={mode === 'CREATE' ? 'organization-create' : 'organization-edit'} alignItems="flex-start" gap="10px">
      <Typography variant="h1" sx={{ marginBottom: '16px' }}>
        {t(mode === 'CREATE' ? 'title.organizationNew' : 'title.organizationEdit')}
      </Typography>
      <InfoBox>{t('common.mandatoryFields')}</InfoBox>
      <Box>
        {mode === 'CREATE' && (
          <Typography variant="h2" sx={{ marginTop: '16px', marginBottom: '16px' }}>
            {t('organization.organization.title')}
          </Typography>
        )}
      </Box>

      <FormProvider {...form}>
        <form className="flex-column flex-gap-20" onSubmit={form.handleSubmit(handleFormSubmit)}>
          <FlexColumn gap="20px">
            <ControlledTextField
              id="name"
              name="name"
              label={t('organization.organization.name')}
              required
              placeholder={t('organization.organization.name')}
              control={form.control}
            />
            <ControlledTextField
              id="code"
              name="code"
              label={t('organization.organization.code')}
              required
              placeholder={t('organization.organization.code')}
              control={form.control}
            />
            <ControlledTextField
              id="customerNumber"
              name="customerNumber"
              label={t('organization.organization.customerNumber')}
              required
              control={form.control}
            />
            <ControlledTextField
              id="contractNumber"
              name="contractNumber"
              label={t('organization.organization.contractNumber')}
              required
              control={form.control}
            />
            <ControlledTextField
              id="invoiceReference"
              name="invoiceReference"
              label={t('organization.organization.invoiceReference')}
              required
              control={form.control}
            />
            <ControlledDateRangePicker
              startDateName="contractStart"
              endDateName="contractEnd"
              label={t('organization.organization.contractValidity')}
              smallLabel={t('organization.organization.contractValidityHelp')}
              required
              control={form.control}
            />
            <ControlledTextField
              id="streetAddress1"
              name="streetAddress1"
              label={t('organization.organization.streetAddress')}
              required
              control={form.control}
            />
            <ControlledTextField
              id="streetAddress2"
              name="streetAddress2"
              label={t('organization.organization.streetAddress')}
              control={form.control}
            />
            <ControlledTextField
              id="postNumber"
              name="postNumber"
              label={t('organization.organization.postNumber')}
              required
              control={form.control}
            />
            <ControlledTextField
              id="region"
              name="region"
              label={t('organization.organization.region')}
              required
              control={form.control}
            />
            <ControlledAutocomplete
              id="country"
              name="countryId"
              label={t('organization.organization.country')}
              required
              options={countries.map((country) => ({
                id: country.id!,
                name: country.name!
              }))}
              control={form.control}
            />
            <ControlledRadioGroup
              name="contactLanguage"
              label={t('organization.organization.contactLanguage.caption')}
              required
              values={[
                { label: t('language.FINNISH'), value: 'FINNISH' },
                { label: t('language.ENGLISH'), value: 'ENGLISH' },
                { label: t('language.SWEDISH'), value: 'SWEDISH' }
              ]}
              control={form.control}
            />
          </FlexColumn>

          {mode === 'CREATE' && (
            <>
              <Typography variant="h2">{t('organization.mainuser.title')}</Typography>
              <FlexColumn gap="20px">
                <ControlledTextField
                  id="mainUser.username"
                  name="mainUser.username"
                  label={t('organization.mainuser.username')}
                  required
                  placeholder={t('organization.mainuser.username')}
                  control={form.control}
                />
                <ControlledTextField
                  id="mainUser.firstName"
                  name="mainUser.firstName"
                  label={t('organization.mainuser.firstname')}
                  required
                  placeholder={t('organization.mainuser.firstname')}
                  control={form.control}
                />
                <ControlledTextField
                  id="mainUser.lastName"
                  name="mainUser.lastName"
                  label={t('organization.mainuser.lastname')}
                  required
                  placeholder={t('organization.mainuser.lastname')}
                  control={form.control}
                />
                <ControlledTextField
                  id="mainUser.email"
                  name="mainUser.email"
                  label={t('organization.mainuser.email')}
                  required
                  placeholder={t('organization.mainuser.email')}
                  control={form.control}
                />
                <ControlledTextField
                  id="mainUser.phone"
                  name="mainUser.phone"
                  label={t('organization.mainuser.phonenumber')}
                  required
                  placeholder={t('organization.mainuser.phonenumber')}
                  control={form.control}
                />
                <ControlledRadioGroup
                  name="mainUser.language"
                  label={t('organization.mainuser.language')}
                  required
                  values={[
                    { label: t('language.FINNISH'), value: 'FINNISH' },
                    { label: t('language.ENGLISH'), value: 'ENGLISH' },
                    { label: t('language.SWEDISH'), value: 'SWEDISH' }
                  ]}
                  control={form.control}
                />
              </FlexColumn>
            </>
          )}

          <FlexRow gap="20px">
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              disabled={!form.formState.isValid || (mode === 'CREATE' && createOrganizationStatus === 'pending')}
              type="submit"
            >
              {t('organization.save')}
            </Button>
            <Button startIcon={<ClearIcon />} variant="outlined" onClick={handleCancel}>
              {t('organization.cancel')}
            </Button>
          </FlexRow>
        </form>
      </FormProvider>
    </FlexColumn>
  )
}
