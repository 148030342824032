import { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import {
  AdditionalInvoiceRowsDto,
  FeeTypeDto,
  FeeTypeDtoName,
  InvoiceDetailsDto,
  InvoiceRowDto,
  InvoicingPeriodDto
} from '../../../../api/types'
import DownloadButton from '../../../../features/buttons/DownloadButton'
import { ECERT_API } from '../../../../services/ecert-api'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { InvoiceEditRow } from './InvoiceEditRow'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { idGenerator } from '../../../../utils/utils'
import { GridFooter } from './GridFooter'
import { GridHeader } from './GridHeader'
import { MessageInput } from './MessageInput'
import { enqueueSnackbar } from 'notistack'
import * as snacky from '../../../../features/custom-snackbar-provider/CustomSnackbarProvider'
import { InvoiceDisplayRow } from './InvoiceDisplayRow'
import { formatInvoicingPeriod, formatCurrency } from '../invoice-utls'

interface InvoiceOrganizationContentProps {
  invoiceDetailId: number
  invoicingPeriod: InvoicingPeriodDto
  customFeeTypes: FeeTypeDto[]
  organizationName: string
}

export function InvoiceOrganizationContent({
  invoiceDetailId,
  invoicingPeriod,
  customFeeTypes,
  organizationName
}: InvoiceOrganizationContentProps) {
  const { t } = useTranslation()

  const [invoiceDetail, setInvoiceDetail] = useState<InvoiceDetailsDto>()

  const [editMode, setEditMode] = useState<boolean>(false)

  const [editModeRows, setEditModeRows] = useState<InvoiceRowDto[]>([])

  const [isValid, setIsValid] = useState<boolean>(true)

  const message = invoiceDetail?.invoiceMessage ? invoiceDetail.invoiceMessage : ''

  const deviceFilter = {
    orgId: invoiceDetail?.organization!,
    params: {
      start: invoicingPeriod.invoicingPeriodStart,
      end: invoicingPeriod.invoicingPeriodEnd
    }
  }

  const fetchAccordionData = useCallback(() => {
    ECERT_API.getInvoiceDetails(invoiceDetailId).then((response) => {
      setInvoiceDetail(response.data)
      setEditModeRows(
        response.data.invoiceRows!.filter((row) => customFeeTypes.some((option) => option.name === row.type))
      )
    })
  }, [invoiceDetailId, customFeeTypes])

  useEffect(() => {
    fetchAccordionData()
  }, [fetchAccordionData])

  useEffect(() => {
    setIsValid(validate(editModeRows))
  }, [editModeRows])

  const addEmptyInvoiceRow = () => {
    const id = idGenerator.next().value
    setEditModeRows((prev) => [...prev, { id: id }])
  }

  const removeInvoiceRow = (id: number) => {
    setEditModeRows((prev) => prev.filter((row) => row.id !== id))
  }

  const save = () => {
    ECERT_API.saveAdditionalInvoiceRows(invoiceDetail?.id!, {
      id: invoiceDetail?.id!,
      invoiceRows: editModeRows.map((row) => ({
        type: row.type,
        reason: row.reason,
        amount: row.amount,
        productCode: row.productCode,
        deviceIds: row.deviceIds
      })) as AdditionalInvoiceRowsDto
    }).then(() => {
      setEditMode(false)
      fetchAccordionData()
    })
  }

  const cancel = () => {
    setEditMode(false)
    fetchAccordionData()
  }

  const validate = (rows: InvoiceRowDto[]): boolean => {
    return rows.some((row) => {
      if (!row.amount) return true
      switch (row.type) {
        case FeeTypeDtoName.EECS_CERTIFICATION:
          if (!row.deviceIds) return true
          return false
        case FeeTypeDtoName.OTHER_WORK_PER_HOUR:
          if (!row.reason) return true
          return false
        case FeeTypeDtoName.ANNUAL_PRODUCTION_DEVICE_FEE:
          return false
        case FeeTypeDtoName.FREE_TEXT_AND_AMOUNT:
          if (!row.productCode || !row.reason) return true
          return false
        case FeeTypeDtoName.NEW_PRODUCTION_DEVICE:
          if (!row.deviceIds) return true
          return false
        case FeeTypeDtoName.ANNUAL_ACCOUNT_FEE:
          return false
        default:
          return false
      }
    })
  }

  const onChange = (newValue: InvoiceRowDto) => {
    setEditModeRows((prev) => {
      if (!prev.some((row) => row.id === newValue.id)) {
        return [...prev, newValue]
      } else {
        return prev.map((row) => (row.id === newValue.id ? newValue : row))
      }
    })
  }

  const saveMessage = (message: string) => {
    ECERT_API.updateInvoiceDetailsMessage(invoiceDetail?.id!, message).then(() => {
      enqueueSnackbar(t('form.updated'), snacky.successOpts)
    })
  }

  return (
    <>
      {invoiceDetail && (
        <Box>
          <Box sx={{ display: 'flex', gap: '16px', marginTop: '20px' }}>
            <MessageInput onSave={saveMessage} billedAt={invoicingPeriod.billedAt} defaultMessage={message} />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} sx={{ marginTop: '20px' }}>
              <GridHeader />
              {invoiceDetail.invoiceRows?.map((row) => (
                <InvoiceDisplayRow
                  key={`${row.type}_${row.id ? row.id : row.type}`}
                  row={row}
                  deviceFilter={deviceFilter}
                />
              ))}
              <GridFooter total={invoiceDetail.total ? formatCurrency(invoiceDetail.total) : '0 €'} />
            </Grid>
          </Box>
          {editMode && !invoicingPeriod.billedAt && (
            <Box
              sx={{
                marginTop: '24px',
                gap: '24px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography variant="h2">{t('invoiceDetailsReport.addOrEditAdditionalItems')}</Typography>

              {editModeRows.map((row: InvoiceRowDto) => (
                <Box sx={{ display: 'flex', gap: '16px' }} key={row.id!}>
                  <InvoiceEditRow
                    row={row}
                    deviceFilter={deviceFilter}
                    customFeeTypes={customFeeTypes}
                    onChange={onChange}
                  />
                  <IconButton onClick={() => removeInvoiceRow(row.id!)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}

              <Box>
                <Button variant="outlined" startIcon={<AddOutlinedIcon />} onClick={() => addEmptyInvoiceRow()}>
                  {t('invoiceDetailsReport.addBillableItem')}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Button disabled={isValid} variant="contained" startIcon={<CheckOutlinedIcon />} onClick={save}>
                  {t('common.save')}
                </Button>
                <Button variant="outlined" startIcon={<ClearOutlinedIcon />} onClick={cancel}>
                  {t('common.cancel')}
                </Button>
              </Box>
            </Box>
          )}
          {!editMode && (
            <Box sx={{ gap: '16px', display: 'flex', marginTop: '16px' }}>
              {!invoicingPeriod.billedAt && (
                <Button startIcon={<EditOutlinedIcon />} variant="contained" onClick={() => setEditMode(true)}>
                  {t('invoiceDetailsReport.addOrEditAdditionalItems')}
                </Button>
              )}
              <DownloadButton
                startIcon={<DownloadOutlinedIcon />}
                fileUrl={`${ECERT_API.FILE_DOWNLOAD_URLS.DOWNLOAD_INVOIVING_PERIOD}${invoicingPeriod.id}/organization/${invoiceDetail.organization}?downloadId=`}
                fileName={`${t('invoiceDetailsReport.menuName')}_${organizationName}_${formatInvoicingPeriod(
                  invoicingPeriod!
                )}`}
              >
                {t('invoiceDetailsReport.download')}
              </DownloadButton>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
