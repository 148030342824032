import { z } from 'zod'
import { formPrefix } from './schema-utils'
import {
  CancellationDetailsUpdateDtoBeneficiaryType,
  CancellationDetailsUpdateDtoConversionRegistry
} from '../api/types'
import dayjs from 'dayjs'
import { getTranslation } from '../utils/utils'

export enum CancellationTarget {
  COMPANY = 'COMPANY',
  ORGANIZATION = 'ORGANIZATION',
  CONVERSION = 'CONVERSION',
  FOO = 'FOO'
}

export enum Supplier {
  FINLAND = 'FINLAND',
  NOT_AIB = 'NOT_AIB',
  AIB = 'AIB'
}

function createCommonBaseSchema() {
  return z.object({
    consumptionYear: z.number(),
    consumptionPeriodStart: z.string(),
    consumptionPeriodEnd: z.string(),
    additionalDetails: z
      .string()
      .max(1000, getTranslation(`${formPrefix}.cancellation.additionalDetails`))
      .optional()
  })
}

function createBeneficiaryBaseSchema() {
  return z
    .object({
      beneficiaryType: z.enum([
        CancellationDetailsUpdateDtoBeneficiaryType.SUPPLIER,
        CancellationDetailsUpdateDtoBeneficiaryType.ENDUSER
      ]),
      supplier: z.enum([Supplier.FINLAND, Supplier.NOT_AIB, Supplier.AIB]),
      countryOfConsumption: z
        .number({ invalid_type_error: getTranslation(`${formPrefix}.cancellation.countryOfConsumption.required`) })
        .positive(),
      conversionRegistry: z.any(),
      conversionDeviceGsrn: z.any(),
      conversionDeviceName: z.any()
    })
    .merge(createCommonBaseSchema())
}

function createCompanySchema() {
  return z
    .object({
      cancellationTarget: z.literal(CancellationTarget.COMPANY),
      beneficiaryName: z
        .string()
        .min(1, getTranslation(`${formPrefix}.cancellation.beneficiaryName.required`))
        .max(100, getTranslation(`${formPrefix}.cancellation.beneficiaryName.maxlength`)),
      organizationCode: z
        .string()
        .min(1, getTranslation(`${formPrefix}.cancellation.beneficiaryCode.required`))
        .max(30, getTranslation(`${formPrefix}.cancellation.beneficiaryCode.maxlength`))
    })
    .merge(createBeneficiaryBaseSchema())
}

function createOrganizationSchema() {
  return z
    .object({
      cancellationTarget: z.literal(CancellationTarget.ORGANIZATION),
      beneficiaryName: z.any(),
      organizationCode: z.any()
    })
    .merge(createBeneficiaryBaseSchema())
}

function createConversionSchema() {
  return z
    .object({
      cancellationTarget: z.literal(CancellationTarget.CONVERSION),
      conversionRegistry: z.enum([
        CancellationDetailsUpdateDtoConversionRegistry.ENERGY_AUTHORITY,
        CancellationDetailsUpdateDtoConversionRegistry.GASGRID_FINLAND
      ]),
      conversionDeviceGsrn: z
        .string()
        .min(18, getTranslation(`${formPrefix}.cancellation.conversionDeviceGsrn.minlength`))
        .max(18, getTranslation(`${formPrefix}.cancellation.conversionDeviceGsrn.maxlength`)),
      conversionDeviceName: z
        .string()
        .min(1, getTranslation(`${formPrefix}.cancellation.conversionDeviceName.required`))
        .max(100, getTranslation(`${formPrefix}.cancellation.conversionDeviceName.maxlength`)),
      beneficiaryName: z.any(),
      organizationCode: z.any(),
      beneficiaryType: z.any(),
      supplier: z.any()
    })
    .merge(createCommonBaseSchema())
}

export function createCertificateCancellationSchema() {
  return z
    .discriminatedUnion('cancellationTarget', [
      createCompanySchema(),
      createOrganizationSchema(),
      createConversionSchema()
    ])
    .superRefine((schema, ctx) => {
      if (dayjs(schema.consumptionPeriodEnd).isBefore(dayjs(schema.consumptionPeriodStart))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getTranslation(`${formPrefix}.dateRange.rangeValid`),
          path: ['consumptionPeriodStart']
        })
      }
    })
}

const schema = createCertificateCancellationSchema()
export type CertificateCancellationSchemaType = z.infer<typeof schema>
